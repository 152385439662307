import React, { useState, useRef } from "react";
import MainContainer from "../../common/MainContainer";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../common/Loader";

const ChangePassword = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const basurl = process.env.REACT_APP_URL;
  const userToken = user.userToken;
  const { register, handleSubmit, watch, errors } = useForm();
  const new_password = useRef({});
  new_password.current = watch("new_password", "");
  // console.log("user", user)

  const onSubmit = (data, e) => {
    setIsLoading(true);
    axios
      .put(
        `${basurl}/users/reset-password/${user.user.id}`,
        {
          current_password: data.current_password,
          password: data.new_password,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result.message,
            showConfirmButton: false,
            timer: 2000,
          });
          e.target.reset();
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          dispatch({
            type: "USER_LOGOUT",
            payload: false,
            token: null,
            info: null,
          });
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        console.log(err);
      });
  };
  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Change Password
            </h4>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className={"card-body"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={"mb-2"}>
              <label>Current Password</label>
              <input
                id="password"
                name="current_password"
                placeholder="Enter your current password"
                ref={register({
                  required: "Current password is required.",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                  pattern: {
                    value:
                      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}/,
                    message:
                      "Please use atleast 1 special character, and 1 capital letter in the password.",
                  },
                })}
                type="password"
                className="form-control"
              />
              {errors.current_password && (
                <span className="isError">
                  {errors.current_password.message}
                </span>
              )}
            </div>
            <div className={"mb-2"}>
              <label>New Password</label>
              <input
                id="password"
                name="new_password"
                placeholder="Enter new password"
                ref={register({
                  required: "New password is required.",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                  pattern: {
                    value:
                      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}/,
                    message:
                      "Please use atleast 1 special character, and 1 capital letter in the password.",
                  },
                })}
                type="password"
                className="form-control"
              />
              {errors.new_password && (
                <span className="isError">{errors.new_password.message}</span>
              )}
            </div>
            <div className={"mb-2"}>
              <label>New Confirm Password</label>
              <input
                id="password"
                name="new_confirm_password"
                placeholder="Enter new password"
                ref={register({
                  validate: (value) =>
                    value === new_password.current ||
                    "The passwords do not match",
                })}
                type="password"
                className="form-control"
              />
              {errors.new_confirm_password && (
                <span className="isError">
                  {errors.new_confirm_password.message}
                </span>
              )}
            </div>
            <div className={"d-flex"}>
              <button
                type="submit"
                className="btn btn-primary"
                style={{ marginRight: 15 }}
              >
                Change Password
              </button>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};
export default ChangePassword;
