import MainContainer from "../../../common/MainContainer";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { userLogout } from "../../../redux/actions/UserActions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm } from "react-hook-form";

const Articles = (props) => {
  let history = useHistory();
  const [details, setDetails] = useState(null);
  const [description, setDescription] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [articles, setArticles] = useState([
    {
      genre: "",
      title: "",
      link: "",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm();
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;

  //get details
  const getDetails = () => {
    axios.get(`${basurl}/article/all`).then((result) => {
      const res = result.data;
      if (res.success && res.data !== null) {
        setValue("title", res.data.title);
        setDescription(res.data.description);
        setArticles(res.data.articles);

        setIsEmpty(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsEmpty(true);
      }
    });
  };

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  const onSubmit = (data) => {
    if (isEmpty) {
      if (articles[0].title === "" || articles[0].title === "") {
        Swal.fire({
          title: "Error",
          text: "Please fill the Genre and article link",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        setIsLoading(true);
        axios
          .post(
            `${basurl}/article/create`,
            {
              title: data.title,
              description: description,
              articles: articles,
            },
            {
              headers: {
                "auth-token": userToken,
              },
            }
          )
          .then((res) => {
            const result = res.data;
            if (result.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: result.message,
                showConfirmButton: false,
                timer: 1500,
              });
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("err>>>", err);
          });
      }
    } else {
      setIsLoading(true);
      axios
        .put(
          `${basurl}/article/update`,
          {
            title: data.title,
            description: description,
            articles: articles,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result.message,
              showConfirmButton: false,
              timer: 1500,
            });
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("err>>>", err);
        });
    }
  };

  //Todo: addArticle
  const addArticle = () => {
    setArticles([...articles, { genre: "", title: "", link: "" }]);
  };

  // Todo: removeArticle
  const removeArticle = (index) => {
    const newArticles = [...articles];
    newArticles.splice(index, 1);
    setArticles(newArticles);
  };

  // Todo: handleArticleChange
  const handleArticleChange = (e, index) => {
    const { name, value } = e.target;
    const newArticles = [...articles];
    newArticles[index][name] = value;
    setArticles(newArticles);
  };

  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <h4 className={"header-title"}>Daily Reads</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Title"
                ref={register({ required: true })}
                name={"title"}
              />
              {errors.title && (
                <span className="isError">Title is required</span>
              )}
            </div>
            <div className="mb-2">
              <label>Description</label>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                style={{ minHeight: "130px" }}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                  // console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </div>
            <h4 className={"header-title mt-4"}>Articles</h4>
            {articles.length > 0 && (
              <div>
                {articles.map((faq, index) => {
                  return (
                    <div key={index} className="row mb-4">
                      <div className="col-md-11">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-2">
                              <input
                                type="text"
                                placeholder="Genre"
                                className="form-control"
                                value={faq.genre}
                                name="genre"
                                onChange={(e) => handleArticleChange(e, index)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-2">
                              <input
                                type="text"
                                placeholder="Title of the article"
                                className="form-control"
                                value={faq.title}
                                name="title"
                                onChange={(e) => handleArticleChange(e, index)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-2">
                              <input
                                type="text"
                                placeholder="Link to the article"
                                className="form-control"
                                value={faq.link}
                                name="link"
                                onChange={(e) => handleArticleChange(e, index)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1">
                        <button
                          className="btn btn-danger"
                          style={{ width: "100%" }}
                          type="button"
                          onClick={() => removeArticle(index)}
                        >
                          <i className="ri-delete-bin-line"></i>
                        </button>
                      </div>
                    </div>
                  );
                })}
                <hr />
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-success"
                    onClick={addArticle}
                    type="button"
                  >
                    <i className="fri-add-line"></i> Add New Article
                  </button>
                </div>
              </div>
            )}
            <hr />
            <div>
              <button className="btn btn-primary" type="submit">
                Save changes
              </button>
            </div>
          </form>

          {/* <div className={"d-flex"}>
            <button
              type="button"
              className="btn btn-success"
              onClick={!details ? handleSubmit : handleUpdate}
              style={{ marginRight: 15 }}
            >
              Save changes
            </button>
            <button
              type={"button"}
              className="btn btn-primary"
              onClick={() => history.goBack()}
            >
              Go Back
            </button>
          </div> */}
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Articles);
