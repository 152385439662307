import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import { userLogout } from "../../redux/actions/UserActions";

const CreateCategory = (props) => {
  const history = useHistory();
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors, reset } = useForm();
  const onSubmit = (data, e) => {
    setIsLoading(true);
    axios
      .post(
        `${basurl}/results/create-category`,
        {
          title: data.category,
          description: data.description,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Category added successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          e.target.reset();
          props.history.push("/results/categories");
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        console.log("result >> ", result);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };
  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="card">
        <div className={"card-header"}>
          <div className="row">
            <div className="col-sm-6">
              <h4>Add Result Category</h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label>Category Name</label>
              <input
                type="text"
                className="form-control"
                placeholder={"Category Name"}
                name="category"
                ref={register({ required: true })}
              />
              {errors.category && (
                <span className="isError">Category name is required.</span>
              )}
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                className="form-control"
                placeholder={"Description"}
                name="description"
                ref={register({ required: false })}
              />
            </div>

            <div className={"d-flex"}>
              <button
                type="submit"
                className="btn btn-success"
                style={{ marginRight: 15 }}
              >
                Add Category
              </button>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateCategory);
