import React, { useState, useEffect } from "react";
import axios from "axios";
import MainContainer from "../../common/MainContainer";
import { connect } from "react-redux";
import { userLogout } from "../../redux/actions/UserActions";
import Loader from "../../common/Loader";
import { Select, DatePicker, Switch } from "antd";
import AWS from "aws-sdk";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import Dropzone from "react-dropzone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditBundle = (props) => {
  let history = useHistory();
  const { Option } = Select;
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [courses, setCourses] = useState([]);
  const [mocks, setMocks] = useState([]);
  const [quizes, setQuizes] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedMocks, setSelectedMocks] = useState([]);
  const [selectedQuizes, setSelectedQuizes] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [featuredImages, setFeaturedImages] = useState(null);
  const [enrollment, setEnrollment] = useState(true);
  const [expiryDate, setExpiryDate] = useState(null);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const { register, handleSubmit, errors, setValue } = useForm();

  const onChangeSwitch = (checked) => {
    setEnrollment(checked);
  };

  // AWS configurations
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new/bundle" },
    region: "ap-south-1",
  });

  // get all courses
  const getCourses = () => {
    axios
      .get(`${basurl}/course/front-all`)
      .then((res) => {
        const courseList = res.data;
        if (courseList.success) {
          setCourses(courseList.data.reverse());
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // get all mocks
  const getMocks = () => {
    axios
      .get(`${basurl}/mock/all`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setMocks(result.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        Swal.fire({
          position: "center",
          icon: "error",
          title: err.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  //* get quiz list
  const getQuizList = () => {
    axios
      .get(`${basurl}/quiz/all`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setQuizes(result.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        Swal.fire({
          position: "center",
          icon: "error",
          title: err.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // *  onChangeCourse
  const onChangeCourse = (value) => {
    console.log("selected values>>", value);
    const res = courses.filter((item) => value.includes(item._id));
    const newArr = res.map((re) => ({
      course: {
        _id: re._id,
        title: re.title,
      },
      course_validity: re.course_validity,
    }));
    setSelectedCourses(newArr);
  };

  // *  onChangeMock
  const onChangeMock = (value) => {
    const res = mocks.filter((item) => value.includes(item._id));
    const newArr = res.map((re) => ({
      mock: re._id,
      expiryDate: re.expiryDate,
    }));
    setSelectedMocks(newArr);
  };

  // *  onChangeQuiz
  const onChangeQuiz = (value) => {
    const res = quizes.filter((item) => value.includes(item._id));
    const newArr = res.map((re) => ({
      quiz: re._id,
      expiryDate: re.expiryDate,
    }));
    setSelectedQuizes(newArr);
  };

  // upload image to aws bucket
  const uploadVideoHandler = (files) => {
    console.log("video file >> ", files[0]);
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setUploadProgress(percent);
        // console.log(
        //   "progress video upload :: ",
        //   Math.round((evt.loaded / evt.total) * 100)
        // );
      })
      .on("success", (evt) => {
        // console.log("mujhe khusi hogi >>>", evt.request.params);
        setFeaturedImages(
          `https://lms-videos-bucket-prepzone-new.s3.ap-south-1.amazonaws.com/${evt.request.params.Key}`
        );
        setUploadProgress(0);
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // console.log("error >> ", err);
        } else {
          // console.log("aljkskdasd", data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  // handle remove selected image
  // const handleRemoveImage = () => {
  //   setImage(null);
  //   setSelectedImage(null);
  // };

  //onChangeDate
  const onChangeDate = (date, dateString) => {
    // console.log(moment(dateString).toDate());
    setExpiryDate(moment(dateString).toDate());
  };

  const slugify = (text) => {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    const to = "aaaaaeeeeeiiiiooooouuuunc------";

    const newText = text
      .split("")
      .map((letter, i) =>
        letter.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
      );

    return newText
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/&/g, "-y-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-"); // Replace multiple - with single -
  };
  //   submit form
  const onSubmit = (data) => {
    if (
      selectedMocks.length + selectedQuizes.length + selectedCourses.length ===
      0
    ) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "please select atlease one product",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      setIsLoading(true);
      axios
        .put(
          `${basurl}/bundle/update/${id}`,
          {
            courses: selectedCourses,
            mocks: selectedMocks,
            quizes: selectedQuizes,
            expiryDate: expiryDate,
            title: data.title,
            image: featuredImages,
            description: description,
            price: data.price,
            slug: slugify(data.title),
            enrollment: enrollment,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your bundle updated successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            props.history.push("/bundles");
          }
          console.log("Result>>", result);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };
  const { id } = useParams();
  // get bundle details
  const getBundleDetails = () => {
    axios.get(`${basurl}/bundle/details/${id}`).then((res) => {
      const result = res.data;
      if (result.success) {
        setFeaturedImages(result.data.image);
        setValue("title", result.data.title);
        setDescription(result.data.description);
        setValue("price", result.data.price);
        setExpiryDate(result.data.expiryDate);
        setSelectedCourses(result.data.courses);
        setEnrollment(result.data.enrollment);
        getCourses();
        getMocks();
        getQuizList();
      }
    });
  };

  useEffect(() => {
    getBundleDetails();
  }, [id]);

  // console.log("selectedCourses>>>>", selectedCourses);

  return (
    <MainContainer>
      {isLoading && <Loader />}
      <Link to="/bundles" className="sm-header-title">
        <i className="ri-arrow-left-line"></i>{" "}
        <span style={{ position: "relative", left: 5 }}>Back to bundles</span>
      </Link>
      <div className={"card mt-3"}>
        <div className="card-header">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="mb-0">Edit Bundle</h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-8">
                <div className="mb-2">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Title"
                    ref={register({ required: true })}
                    name="title"
                  />
                  {errors.title && (
                    <span className="isError">Title is required</span>
                  )}
                </div>
                <div className="mb-2">
                  <label>Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={{
                      allowedContent: true,
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                      ],
                    }}
                    onReady={(editor) => {
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />
                  {descriptionError && (
                    <span className="isError">Description is required</span>
                  )}
                </div>
                <h4 className="section_title">Bundle Content</h4>
                {courses.length > 0 && (
                  <div className="mb-3">
                    <label>Select Courses </label>
                    <Select
                      showSearch
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select courses"
                      onChange={onChangeCourse}
                      className="w-full"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      defaultValue={selectedCourses.map(
                        (crs) => crs?.course?._id
                      )}
                    >
                      {courses.map((item, index) => (
                        <Option value={item._id} key={item._id}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
                {mocks.length > 0 && (
                  <div className="mb-3">
                    <label>Select Mocks </label>
                    <Select
                      showSearch
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select mocks"
                      onChange={onChangeMock}
                      className="w-full"
                    >
                      {mocks.map((item, index) => (
                        <Option value={item._id} key={item._id}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
                {quizes.length > 0 && (
                  <div className="mb-3">
                    <label>Select Quiz </label>
                    <Select
                      showSearch
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select quiz"
                      onChange={onChangeQuiz}
                      className="w-full"
                    >
                      {quizes.map((item, index) => (
                        <Option value={item._id} key={item._id}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label>Bundle Image</label>
                <div className="mb-2">
                  {featuredImages ? (
                    <div className="course_image_uploader">
                      <span
                        className="close_icon"
                        onClick={() => setFeaturedImages(null)}
                      >
                        <i className="ri-close-line"></i>
                      </span>
                      <img
                        src={featuredImages}
                        alt={"feature image"}
                        className="img-fluid"
                      />
                    </div>
                  ) : (
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        uploadVideoHandler(acceptedFiles)
                      }
                      accept={"image/*"}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="course_image_uploader">
                          {uploadProgress > 1 && (
                            <div className="upload_progress">
                              <div
                                className="inner"
                                style={{
                                  width: `${uploadProgress}%`,
                                  height: "100%",
                                  backgroundColor: "#4CAF50",
                                  transition: "all 0.4s ease-in-out",
                                  borderRadius: "20px",
                                }}
                              ></div>
                            </div>
                          )}

                          <div {...getRootProps()} className={"dropZone"}>
                            <input {...getInputProps()} />
                            {/* <i className="ri-image-line uploader_icon"></i> */}
                            <span className="close_icon">
                              <i className="ri-pencil-fill"></i>
                            </span>
                            <img
                              src={require("../../assets/coursepic.png")}
                              alt="Mock demo image"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </div>
                <div className="mb-2 mt-4">
                  <label>Expiry Date</label>
                  {expiryDate && (
                    <DatePicker
                      onChange={onChangeDate}
                      defaultValue={moment(expiryDate)}
                      disabledDate={(d) => !d || d.isBefore()}
                      className="form-control"
                      size="large"
                    />
                  )}
                </div>
                <div className="mb-2">
                  <label>Price</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Price"
                    ref={register({ required: true })}
                    name="price"
                  />
                  {errors.price && (
                    <span className="isError">Price is required</span>
                  )}
                </div>
                <div className="mb-2">
                  <span>Enrollment for this bundle</span>
                  <div className="mt-2">
                    <Switch checked={enrollment} onChange={onChangeSwitch} />
                  </div>
                </div>
              </div>
            </div>
            <div className={"d-flex mt-2"}>
              <button
                type="submit"
                className="btn btn-success"
                style={{ marginRight: 15 }}
              >
                Save bundle
              </button>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditBundle);
