import React, { useState, useEffect } from "react";
import moment from "moment";

function VideoListItem({ item, handleLink, index }) {
  //   console.log("Vikas babu>>>>>> ", playbackInfo, "typeof", typeof playbackInfo);

  return (
    <>
      {item.isSelect && (
        <div className="component_selected">
          <span className="checked_icon">
            <i className="ri-checkbox-circle-fill"></i>
          </span>
        </div>
      )}
      <div className="row" onClick={() => handleLink(item.id, index)}>
        <div className="col-sm-3">
          <div className="media_thumb">
            <img src={item.poster} alt={item.title} className="img-fluid" />
          </div>
        </div>
        <div className="col-sm-9">
          <div className="media_details">
            <h4 className="media_title">{item.title}</h4>
            <p className="text-muted mb-0">
              Uploaded date:{" "}
              {moment.unix(item.upload_time).format("MMMM Do YYYY, h:mm:ss a")}
            </p>
            <p className="text-muted mb-0">
              Video Status:{" "}
              {item.status === "ready" ? (
                <span className="text-success">
                  <i className="fa fa-check-circle"></i> Ready
                </span>
              ) : (
                <span className="text-warning">
                  <i className="fa fa-spinner fa-spin"></i> Processing
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoListItem;
