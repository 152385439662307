import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import Swal from "sweetalert2";
import axios from "axios";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import { Label, Input } from "reactstrap";
import { userLogout } from "../../redux/actions/UserActions";

// import logo images
import RazorPayLogo from "../../assets/images/razorpay-logo.svg";
import InstamojoLogo from "../../assets/images/instamojo.png";

const Popup = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [razorpay, setRazorpay] = useState(false);
  const [instamozo, setInstamozo] = useState(false);
  const [data, setData] = useState(null);
  const [title, setTitle] = useState("");
  const [btnText, setBtnText] = useState("");
  const [btnLink, setBtnLink] = useState("");
  const basurl = process.env.REACT_APP_URL;
  const imgUrls = process.env.REACT_APP_BASE_URL;
  const userToken = props.user.userToken;

  // handleInsta
  const handleInsta = () => {
    setInstamozo(true);
    setRazorpay(false);
  };

  // handleRazorpay
  const handleRazorpay = () => {
    setRazorpay(true);
    setInstamozo(false);
  };

  // get setting details
  const getSettingDetails = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/popup/details`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setData(result.data);
          setTitle(result.data ? result.data[0].title : "");
          setBtnText(result.data ? result.data[0].cta_title : "");
          setBtnLink(result.data ? result.data[0].cta_link : "");
        }
        console.log("result >>", result);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          // props.history.push("/");
        }
        console.log(err);
      });
  };

  useEffect(() => {
    getSettingDetails();
  }, []);

  // handleForm
  const handleForm = () => {
    setIsLoading(true);
    axios
      .post(
        `${basurl}/popup/${data === null ? "create" : `update/${data[0]._id}`}`,
        {
          title: title,
          description: null,
          cta_title: btnText,
          cta_link: btnLink,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        console.log("result ", result);
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your information updated successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);
  return (
    <MainContainer>
      <div className={"card"}>
        {isLoading && <Loader />}
        <div className={"card-header"}>
          <div className="row">
            <div className="col-sm-6">
              <h4>Manage Popup</h4>
            </div>
          </div>
        </div>
        <div className={"card-body"}>
          <div className="block-row">
            <div className="mb-4">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter popup content"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-4">
                  <label>CTA Text</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter CTA text"
                    value={btnText}
                    onChange={(e) => setBtnText(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                  <label>CTA Link</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter CTA link"
                    value={btnLink}
                    onChange={(e) => setBtnLink(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className={"d-flex mt-3"}>
            <button
              type="button"
              className="btn btn-success"
              style={{ marginRight: 15 }}
              onClick={handleForm}
            >
              Save Settings
            </button>
            <button
              type={"button"}
              className="btn btn-primary"
              onClick={() => props.history.goBack()}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Popup);
