const INITIAL_STATE = {
  totalIncome: 0,
  totalCourese: 0,
  totalStudent: 0,
  totalBundles: 0,
  totalMocks: 0,
  totalQuiz: 0,
  totalIncomeData: [],
  totalTransactions: null,
  bill: null,
  billStatus: null,
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_DASHBOARD":
      return {
        ...state,
        totalIncome: action.payload.totalIncome,
        totalCourese: action.payload.totalCourses,
        totalStudent: action.payload.totalStudents,
        totalIncomeData: action.payload.totalIncomeData,
        totalTransactions: action.payload.totalTransactions,
        totalBundles: action.payload.totalBundles,
        totalMocks: action.payload.totalMocks,
        totalQuiz: action.payload.totalQuiz,
      };
    case "UPDATE_BILL":
      return {
        ...state,
        bill: action.payload,
      };
    case "UPDATE_BILL_STATUS":
      return {
        ...state,
        billStatus: action.payload,
      };

    default:
      return state;
  }
};
export default dashboardReducer;
