import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import { Table, DatePicker } from "antd";
import "antd/dist/antd.css";
import { userLogout } from "../../redux/actions/UserActions";
import { ExportCSV } from "../../components/ExportCSV";

const ResultsList = (props) => {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [exportData, setExportData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const role = props.user?.user?.role;

  // get blogs categories list
  const getCategories = () => {
    axios
      .get(`${basurl}/results/all-results`)
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data);
          const newArr = result.data.map((item, index) => ({
            "S. No": index + 1,
            "Students's Name": item.name,
            "Percentile/INstitutes": item.percentile,
            "Passing Year": item.year,
            "Added Date": moment(item.createdAt).format("DD-MM-YYYY"),
          }));
          setExportData(newArr);
        }
        setIsLoading(false);
        // console.table(result.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // Columns
  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Image",
      render: (value, item, index) => (
        <span>
          <img
            src={item.image}
            alt={item.name}
            width={60}
            height={60}
            style={{ borderRadius: 60 }}
          />
        </span>
      ),
    },
    {
      title: "Student Name",
      render: (value, item, index) => <span>{item.name}</span>,
    },
    {
      title: "Percentile",
      render: (value, item, index) => <span>{item.percentile}%ile</span>,
    },
    {
      title: "Passing Year",
      render: (value, item, index) => <span>{item.year}</span>,
    },
    {
      title: "Added Date",
      render: (value, item, index) => (
        <span>{moment(item.createdAt).format("MMMM DD, YYYY")}</span>
      ),
    },
    {
      title: "Action",
      render: (value, item, index) => (
        <div data-tag="allowRowEvents">
          <Link
            className={"btn btn-success"}
            to={`/results/edit/${item._id}`}
            style={{ marginRight: "10px", minWidth: 100 }}
          >
            <i className={"ri-pencil-line"}></i>
          </Link>
          {role == "admin" && (
            <button
            className={"btn btn-danger"}
            onClick={() => handleDelete(item._id)}
            style={{ minWidth: 100 }}
          >
            <i className={"ri-delete-bin-line"}></i>
          </button>
          )}
          
        </div>
      ),
    },
  ];

  // handleRange
  const handleRange = (date) => {
    if (date !== null) {
      let date1 = moment(date[0].toDate()).toDate().getTime();
      let date2 = moment(date[1].toDate()).toDate().getTime();
      // console.log("Vikas >>>>", moment(list[1].createdAt).toDate().getTime());
      const newArr = filteredData.filter((item) => {
        return (
          moment(item.updatedAt).toDate().getTime() <= date2 &&
          moment(item.updatedAt).toDate().getTime() >= date1
        );
      });
      setList(newArr);
    } else {
      getCategories();
    }

    // console.log("get date from range >>>", newArr)
  };

  // Delete banner slider
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this result ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${basurl}/results/delete-result/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const deleteStatus = res.data;
            if (deleteStatus.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: deleteStatus.message,
                showConfirmButton: false,
                timer: 2500,
              });
              getCategories();
            }
            // console.log("delete status > ", deleteStatus);
          })
          .catch((err) => {
            if (err.response.data === "Invalid token") {
              props.logoutUser();
              localStorage.removeItem("lmsUserInfo");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your token has been expired!, Please login again",
                showConfirmButton: false,
                timer: 1500,
              });
              props.history.push("/");
            }
          });
      }
    });
  };

  function onChange(pagination, filters, sorter, extra) {
    // console.log("params", sorter);
    setPagination({
      pageSize: pagination.pageSize,
    });
  }

  // for search filter
  const filteredData = list.filter((val) => {
    if (searchInput === "") {
      return val;
    } else if (val.name.toLowerCase().includes(searchInput.toLowerCase())) {
      return val;
    }
  });
  return (
    <MainContainer>
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Results
            </h4>
            <Link to={"/results/create"} className={"btn btn-primary"}>
              Add new result
            </Link>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className="card-body">
          <div className={"filters mb-2"}>
            <div className={"row"}>
              <div className={"col-lg-4"}>
                <div className={"search_filter"}>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder={"Search by name..."}
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  {searchInput !== "" && (
                    <span
                      className={"clear_search_text"}
                      onClick={() => setSearchInput("")}
                    >
                      <i className={"la la-times"}></i>
                    </span>
                  )}
                </div>
              </div>
              <div className={"col-lg-4"}>
                <RangePicker
                  format={dateFormat}
                  className={"form-control custom_range_picker"}
                  onChange={handleRange}
                />
              </div>
              <div className={"col-lg-4"}>
                <div className={"row"}>
                  <div className={"col-sm-6"}>
                    <select
                      className={"form-control"}
                      onChange={(e) =>
                        setPagination({
                          pageSize: e.target.value,
                        })
                      }
                    >
                      <option value={10}>10 Rows</option>
                      <option value={20}>20 Rows</option>
                      <option value={50}>50 Rows</option>
                      <option value={100}>100 Rows</option>
                    </select>
                  </div>
                  <div className={"col-sm-6"}>
                    <ExportCSV csvData={exportData} fileName={"Results"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={filteredData}
            bordered={true}
            loading={isLoading}
            pagination={pagination}
            onChange={onChange}
          />
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResultsList);
