import React, { useState, useEffect } from "react";
import MainContainer from "../../../common/MainContainer";
import axios from "axios";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import Loader from "../../../common/Loader";
import { userLogout } from "../../../redux/actions/UserActions";

const EditTopper = (props) => {
  let history = useHistory();
  const { id } = props.match.params;
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const imgUrls = process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [type, setType] = useState("");
  const { register, handleSubmit, watch, errors, setValue } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .put(
        `${basurl}/frontend/toppers/edit/${id}`,
        {
          image: selectedImage,
          name: data.name,
          institute: data.institute,
          percentage: data.percentage,
          category: data.category,
          yearExam: data.examYear,
          type: data.type,
        },
        {
          headers: {
            "auth-token": props.user.userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your topper updated successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
          props.history.push("/frontend/toppers");
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: err.Message,
        });
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          history.push("/");
        }
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    if (e.target.value > 100) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Value shouldn't be greater than 100",
      });
      setValue("percentage", 90);
    }
  };

  // get categories
  const getAllCategories = () => {
    axios
      .get(`${basurl}/frontend/toppers/category/all`)
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  // handle Image upload
  const handleImage = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    // setSelectedImage(event.target.files[0])
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    axios({
      method: "post",
      url: `${basurl}/upload/topper`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const imgRes = res.data;
      if (imgRes.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Image uploaded successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      setSelectedImage(imgRes.data);
      // console.log("image response >> ", imgRes);
    });
  };

  // handle remove selected image
  const handleRemoveImage = () => {
    setImage(null);
    setSelectedImage(null);
  };

  //   handleChangeYear
  const handleChangeYear = (e) => {
    const value = e.target.value;
    if (value.length > 4) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Enter a valid year",
      });
      setValue("examYear", "");
    }
  };

  //   get topper details
  const getTopperDetails = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/frontend/toppers/view/${id}`)
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setValue("name", result.data.name);
          setValue("category", result.data.category);
          setValue("institute", result.data.institute);
          setValue("percentage", result.data.percentage);
          setValue("examYear", result.data.yearExam);
          setValue("type", result.data.type);
          setImage(imgUrls + result.data.image);
          setSelectedImage(result.data.image);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getAllCategories();
    getTopperDetails();
  }, [id]);
  console.log("typoe", type);
  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="card">
        <div className="card-header">
          <div className={"row"}>
            <div className="col-md-6">
              <h4 className={"header-title"}>Add Topper</h4>
            </div>
          </div>
        </div>
        <div className={"card-body"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Choose Exam</label>
                  <select
                    className="form-control"
                    ref={register({ required: true })}
                    name="category"
                  >
                    <option value="">Choose Exam</option>
                    {list.length > 0 && (
                      <>
                        {list.map((value, index) => (
                          <option value={value._id} key={index}>
                            {value.title}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  {errors.category && (
                    <span className="isError">Exam is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Full Name</label>
                  <input
                    type="text"
                    placeholder="Enter full name"
                    className="form-control"
                    ref={register({ required: true })}
                    name="name"
                  />
                  {errors.name && (
                    <span className="isError">Name is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Institute</label>
                  <input
                    type="text"
                    placeholder="Enter institute name"
                    className="form-control"
                    ref={register({ required: true })}
                    name="institute"
                  />
                  {errors.institute && (
                    <span className="isError">Institute Name is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Topper Image</label>
                  <div className={"image-uploader"}>
                    {image !== null ? (
                      <div className={"img_upld_wrapper"}>
                        <img src={image} className={"image_upload"} />
                        <span
                          className={"upload_img_close"}
                          onClick={() => handleRemoveImage()}
                        >
                          <i className="ri-close-line"></i>
                        </span>
                      </div>
                    ) : (
                      <div className={"image_upload_placeholder"}>
                        <i className={"ri-add-box-line"}></i>
                        <span className={"image_upload_titler"}>
                          Browse Topper Image
                        </span>
                        <input
                          type="file"
                          className={"image_upload_file"}
                          onChange={(e) => handleImage(e)}
                          accept="image/*"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Percentage</label>
                  <input
                    type="text"
                    placeholder="Percentage"
                    pattern="^\d*(\.\d{0,2})?$"
                    className="form-control"
                    ref={register({ required: true })}
                    name="percentage"
                    onChange={handleChange}
                  />
                  {errors.percentage && (
                    <span className="isError">Percentage is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <label>Percentage in</label>
                <select
                  className="form-control"
                  ref={register({ required: true })}
                  name="type"
                >
                  <option value="percentage">Percentage</option>
                  <option value="percentile">Percentile</option>
                </select>
                {errors.type && (
                  <span className="isError">Percentage in is required</span>
                )}
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Year of exam</label>
                  <input
                    type="number"
                    placeholder="YYYY"
                    className="form-control"
                    ref={register({ required: true })}
                    name="examYear"
                    onChange={handleChangeYear}
                  />
                  {errors.examYear && (
                    <span className="isError">Exam Year is required</span>
                  )}
                </div>
              </div>
            </div>

            <div className={"d-flex"}>
              <button
                type="submit"
                className="btn btn-success"
                style={{ marginRight: 15 }}
              >
                Save changes
              </button>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditTopper);
