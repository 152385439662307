import React from "react";
import { Container, Row, Col } from "reactstrap";
const MainContainer = (props) => {
  return (
    <>
      <div className="page-content">
        <Container fluid>{props.children}</Container>
      </div>
      {/* <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">{new Date().getFullYear()} © LMS.</div>
            <div className="col-sm-6">
              <div className="text-sm-right d-none d-sm-block">
                Developed <i className="mdi mdi-heart text-danger" /> by{" "}
                <a href="https://pixlplot.com" target="_blank">
                  Pixlplot
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
    </>
  );
};
export default MainContainer;
