import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import axios from "axios";
import Pagination from "react-js-pagination";
import moment from "moment";
import { DatePicker } from "antd";
import discountImg from "../../assets/discount.png";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const MockList = (props) => {
  const [list, setList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mockTitle, setMockTitle] = useState("");
  const [titleError, setTitleError] = useState(false);

  const basurl = process.env.REACT_APP_URL;
  const user = useSelector((state) => state.user);
  const userToken = user.userToken;

  // get mock list
  const getMockList = () => {
    axios
      .get(`${basurl}/mock/all`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data);
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        Swal.fire({
          position: "center",
          icon: "error",
          title: err.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  useEffect(() => {
    getMockList();
  }, []);

  // toggle modal
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  //handleMock
  const handleMock = () => {
    if (mockTitle.length > 0) {
      axios
        .post(
          `${basurl}/mock/add-mock`,
          {
            title: mockTitle,
          },
          {
            headers: {
              "auth-token": user.userToken,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            setMockTitle("");
            setIsOpenModal(false);
            Swal.fire({
              position: "center",
              icon: "success",
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
          props.history.push(`/mock/create/${res.data.data._id}`);
        });
    } else {
      setTitleError(true);
    }
  };
  return (
    <MainContainer>
      <Modal centered isOpen={isOpenModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Create new Mock</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label>Mock Title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter mock title"
              onChange={(e) => {
                setMockTitle(e.target.value);
                setTitleError(false);
              }}
              value={mockTitle}
            />
            {titleError && (
              <span className="text-danger">Mock title is required.</span>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={handleMock}>
            Next
          </button>
        </ModalFooter>
      </Modal>
      <div className="cards">
        <div className="card-headers mb-3">
          <div className="row">
            <div className="col-sm-6">
              <div className="d-flex align-items-center">
                <h4 className="mt-2" style={{ marginRight: 30 }}>
                  Mocks
                </h4>
                <button
                  className="btn btn-primary"
                  onClick={() => setIsOpenModal(true)}
                >
                  <i className="fas fa-plus"></i> Create new mock
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-bodys">
          <div className="courses_wrapper">
            {list.length > 0 ? (
              <div className="course_column_wrapper">
                {list.map((item) => (
                  <div className="course_column" key={item._id}>
                    <div className="courseWrap">
                      <div className="course_image">
                        <Link to={`/mock/create/${item._id}`}>
                          {item.image ? (
                            <img src={item.image} />
                          ) : (
                            <img src={require(`../../assets/coursepic.png`)} />
                          )}
                        </Link>
                      </div>
                      <div className="course_content">
                        <Link
                          to={`/mock/create/${item._id}`}
                          className={"courseTitle"}
                        >
                          {item.title}
                        </Link>
                        <div className={"sections_details_link"}>
                          {item.questions.length} questions
                        </div>
                        <div className={"text-right"}>
                          <span className="course_price">
                            {"₹" + item.price}
                          </span>
                        </div>
                        <div className="course_content_footer">
                          <Link
                            to={`/mock/create/${item._id}`}
                            className="footer_link_11"
                          >
                            <i class="ri-edit-2-line"></i>
                          </Link>
                          <Link
                            to={`/mock/create/${item._id}`}
                            className="footer_link_11"
                          >
                            <i className="ri-settings-2-line"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
export default MockList;
