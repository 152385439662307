import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import { Table } from "antd";
import "antd/dist/antd.css";
import { userLogout } from "../../redux/actions/UserActions";

const Categories = (props) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const role = props.user?.user?.role;

  // get blogs categories list
  const getCategories = () => {
    axios
      .get(`${basurl}/quiz-new/view-categories`)
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data);
        }
        setIsLoading(false);
        console.table(result.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getCategories();
  }, []);

  // Columns
  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Title",
      render: (value, item, index) => <span>{item.title}</span>,
    },
    {
      title: "Category Added Date",
      render: (value, item, index) => (
        <span>{moment(item.createdAt).format("MMMM DD, YYYY")}</span>
      ),
    },
    {
      title: "Action",
      render: (value, item, index) => (
        <div data-tag="allowRowEvents">
          <Link
            className={"btn btn-success"}
            to={`/quiz/categories/edit/${item._id}`}
            style={{ marginRight: "10px", minWidth: 100 }}
          >
            <i className={"ri-pencil-line"}></i>
          </Link>
          {role === "Admin" && (
            <button
              className={"btn btn-danger"}
              onClick={() => handleDelete(item._id)}
              style={{ minWidth: 100 }}
            >
              <i className={"ri-delete-bin-line"}></i>
            </button>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // Delete banner slider
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this category ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${basurl}/quiz-new/delete-category/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const deleteStatus = res.data;
            if (deleteStatus.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: deleteStatus.message,
                showConfirmButton: false,
                timer: 2500,
              });
              getCategories();
            }
            // console.log("delete status > ", deleteStatus);
          })
          .catch((err) => {
            if (err.response.data === "Invalid token") {
              props.logoutUser();
              localStorage.removeItem("lmsUserInfo");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your token has been expired!, Please login again",
                showConfirmButton: false,
                timer: 1500,
              });
              props.history.push("/");
            }
          });
      }
    });
  };
  return (
    <MainContainer>
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Quiz Categories
            </h4>
            <Link to={"/quiz/categories/create"} className={"btn btn-primary"}>
              Add new Category
            </Link>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className="card-body">
          <Table
            columns={columns}
            dataSource={list}
            bordered={true}
            loading={isLoading}
          />
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Categories);
