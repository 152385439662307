import React, { useState, useEffect } from "react";
import moment from "moment";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { itemShow } from "../../components/itemShow";
import { useForm } from "react-hook-form";
import { Table } from "antd";
import "antd/dist/antd.css";
import { userLogout } from "../../redux/actions/UserActions";
import copy from "copy-to-clipboard";
import ReactTooltip from "react-tooltip";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../common/Loader";

const Documents = (props) => {
  const [list, setList] = useState(null);
  const [documentUploader, setDocumentUploader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageLoad, setPageLoad] = useState(false);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const { register, handleSubmit, errors } = useForm();

  // ! get document lists
  const getDocumentsList = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/uploadDocs/docs`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const docList = res.data;
        if (docList.success) {
          setList(docList.data);
        }
        // console.log("videoList>> ", videoList);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        console.log(err);
      });
  };

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  // Handle Submit
  const onSubmit = (data) => {
    setPageLoad(true);
    const mainFile = data.file[0].name;
    var ext = mainFile.substr(mainFile.lastIndexOf(".") + 1);
    const formData = new FormData();
    formData.append("name", data.title);
    formData.append("type", ext);
    formData.append("doc", data.file[0]);
    formData.append("size", data.file[0].size);
    axios
      .post(`${basurl}/uploadDocs`, formData)
      .then((res) => {
        const uploadResponse = res.data;
        // console.log("response >> ", uploadResponse);
        if (uploadResponse.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "File Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          setDocumentUploader(false);
          getDocumentsList();
          setPageLoad(false);
        }
      })
      .catch((err) => {
        setPageLoad(false);
        console.log(err);
      });
    // console.log("submit data >> ", ext)
  };

  useEffect(() => {
    getDocumentsList();
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this file ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setPageLoad(true);
        axios
          .delete(`${basurl}/uploadDocs/delete/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const deleteStatus = res.data;
            if (deleteStatus.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: deleteStatus.message,
                showConfirmButton: false,
                timer: 2500,
              });
              getDocumentsList();
              setPageLoad(false);
            }
            // console.log("delete status > ", deleteStatus);
          })
          .catch((err) => {
            setPageLoad(false);
            console.log(err);
          });
      }
    });
  };

  // Columns
  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Title",
      render: (value, item, index) => <span>{item.name}</span>,
    },
    {
      title: "Url",
      render: (value, item, index) => (
        <span>
          <ReactTooltip place="top" type="success" />
          <Toaster />
          {process.env.REACT_APP_BASE_URL + item.url} {"  "}{" "}
          <span
            data-tip="Click here to copy url"
            onClick={() => copyUrl(process.env.REACT_APP_BASE_URL + item.url)}
          >
            <i
              className="ri-file-copy-line"
              style={{
                position: "relative",
                top: 3,
                left: 5,
                display: "inline-block",
                padding: "5px 10px",
                backgroundColor: "#f1f1f1",
                borderRadius: 5,
              }}
            />
          </span>
        </span>
      ),
    },

    {
      title: "Type",
      render: (value, item, index) => <span>{itemShow(item.type)}</span>,
    },
    {
      title: "Size",
      render: (value, item, index) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {item.size === 0 ? "NA" : bytesToSize(item.size)}
        </span>
      ),
    },
    {
      title: "Uploaded Date",
      render: (value, item, index) => (
        <span>{moment(item.createdAt).format("DD-MM-YYYY")}</span>
      ),
    },

    {
      title: "Action",
      render: (value, item, index) => (
        <div>
          <button
            className="btn btn-danger "
            onClick={() => handleDelete(item._id)}
            style={{ minWidth: 100 }}
          >
            <i className={"ri-delete-bin-line"}></i>
          </button>
        </div>
      ),
    },
  ];

  // for copy url
  const copyUrl = (value) => {
    copy(value, {
      message: "Copied",
    });
    toast.success("Copied");
  };

  return (
    <MainContainer>
      <div className="card">
        {pageLoad && <Loader />}
        <div className="card-header">
          <div className="row">
            <div className="col-sm-6">
              <h4>Document Library</h4>
            </div>
            <div className="col-sm-6">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary "
                  onClick={() => setDocumentUploader(!documentUploader)}
                >
                  <i className="fas fa-plus"></i> Add Document
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          {documentUploader && (
            <div className={"document-uploader-wrapper"}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label>Title</label>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder={"Title"}
                    ref={register({ required: true })}
                    name={"title"}
                  />
                  {errors.title && (
                    <span className="isError">Title is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Browse File</label>
                  <input
                    type="file"
                    className={"form-control"}
                    ref={register({ required: true })}
                    name={"file"}
                  />
                  {errors.title && (
                    <span className="isError">File is required</span>
                  )}
                </div>

                <button type="submit" className="btn btn-success">
                  <i className={"ri-upload-line"}></i> Upload
                </button>
              </form>
            </div>
          )}
          <Table
            columns={columns}
            dataSource={list}
            rowKey={(item) => item._id}
            bordered={true}
            loading={isLoading}
          />
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Documents);
