import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { userLogout } from "../../../store/actions/UserActions";
import { connect } from "react-redux";

//i18n
import { withNamespaces } from "react-i18next";

// users
import avatar2 from "../../../assets/images/users/man.png";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }
  handleLogout = () => {
    localStorage.removeItem("lmsUserInfo");
    this.props.logoutUser();
    this.props.history.push("/login");
  };

  render() {
    let username = "Admin";
    if (localStorage.getItem("lmsUserInfo")) {
      const obj = JSON.parse(localStorage.getItem("lmsUserInfo"));
      const uNm = obj.userInfo.email.split("@")[0];
      username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
    }
    // console.log("available props>>>", this.props.bill);

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            <img
              className="rounded-circle header-profile-user mr-1"
              src={
                this.props.user?.user?.profile_picture === null
                  ? avatar2
                  : this.props.user?.user?.profile_picture
              }
              alt={username}
            />
            <span className="d-none d-xl-inline-block ml-1 text-transform">
              {username}
            </span>
            <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu right>
            <Link className="dropdown-item" to="/admin/edit-profile">
              <i className="ri-user-line align-middle mr-1"></i>{" "}
              {this.props.t("Profile")}
            </Link>
            <Link className="dropdown-item" to="/admin/change-password">
              <i className="ri-lock-unlock-line align-middle mr-1"></i>{" "}
              {this.props.t("Change Password")}
            </Link>
            {/* <DropdownItem className="d-block" href="#">
              <span className="badge badge-success float-right mt-1">11</span>
              <i className="ri-settings-2-line align-middle mr-1"></i>{" "}
              {this.props.t("Settings")}
            </DropdownItem>
            <DropdownItem href="#">
              <i className="ri-lock-unlock-line align-middle mr-1"></i>{" "}
              {this.props.t("Lock screen")}
            </DropdownItem> */}
            <DropdownItem divider />
            <DropdownItem
              className="text-danger"
              onClick={() => this.handleLogout()}
            >
              <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "}
              {this.props.t("Logout")}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(ProfileMenu));
