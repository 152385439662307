import React, { useState } from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody, Button } from "reactstrap";
import FileViewer from "react-file-viewer";
import moment from "moment";
import ReactAudioPlayer from "react-audio-player";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import copy from "copy-to-clipboard";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";

import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

const ThumbnailComponent = ({ data, videoListName, handleDelete }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropOpen, setIsDropOpen] = useState(false);
  const extention = data.Key.split(".").pop();
  const role = useSelector((state) => state.user?.user?.role);

  // toggle modal
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  // render icon and images
  const renderImage = (ext, path) => {
    if (ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "gif") {
      return <img src={path} alt={ext} />;
    } else if (ext === "mp3") {
      return (
        <div className="box_icon_media">
          <i className="ri-file-music-line ff_big_icon"></i>
        </div>
      );
    } else if (ext === "mp4") {
      return (
        <div className="box_icon_media">
          <i className="ri-video-line ff_big_icon"></i>
        </div>
      );
    } else if (
      ext === "pdf" ||
      ext === "svg" ||
      ext === "docx" ||
      ext === "doc" ||
      ext === "xlsx" ||
      ext === "xls" ||
      ext === "pptx" ||
      ext === "ppt"
    ) {
      return (
        <div className="box_icon_media">
          <i className="ri-file-4-line ff_big_icon"></i>
        </div>
      );
    } else {
      return null;
    }
  };

  // choose player
  const playerChoose = () => {
    if (extention === "jpg" || extention === "png" || extention === "gif") {
      return (
        <img
          src={`https://${videoListName}.s3.ap-south-1.amazonaws.com/${data.Key}`}
          alt="image"
          style={{ width: "100%" }}
        />
      );
    } else if (extention === "mp3") {
      return (
        <ReactAudioPlayer
          src={`https://${videoListName}.s3.ap-south-1.amazonaws.com/${data.Key}`}
          autoPlay
          controls
        />
      );
    } else if (extention === "pdf") {
      return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
          <div style={{ height: "550px" }}>
            <Viewer
              fileUrl={`https://${videoListName}.s3.ap-south-1.amazonaws.com/${data.Key}`}
            />
          </div>
        </Worker>
      );
    } else if (extention === "xlsx" || extention === "xls") {
      return (
        <div className="text-center pt-4">
          <a
            className="btn btn-success"
            href={`https://${videoListName}.s3.ap-south-1.amazonaws.com/${data.Key}`}
          >
            <i class="ri-file-download-line"></i> Download
          </a>
        </div>
      );
    } else {
      return (
        <FileViewer
          fileType={extention}
          filePath={`https://${videoListName}.s3.ap-south-1.amazonaws.com/${data.Key}`}
          errorComponent={() => console.log("error component")}
          onError={() => console.log("error")}
          style={{ width: "100%" }}
        />
      );
    }
  };

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };
  return (
    <>
      <Toaster />
      <Modal isOpen={isOpenModal} toggle={toggleModal} size="xl">
        <ModalHeader toggle={toggleModal}>Details</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-7">{playerChoose()}</div>
            <div className="col-md-5">
              <div
                style={{
                  backgroundColor: "#F0F0F1",
                  padding: "20px 15px",
                  height: "100%",
                }}
              >
                <div className="mb-3">
                  <label className="d-block">
                    <strong className="ssdd">Uploaded on: </strong>
                    {moment(data.LastModified).format("MMMM Do YYYY")}
                  </label>
                  <label className="d-block">
                    <strong className="ssdd">File type: </strong>
                    {extention}
                  </label>
                  <label className="d-block">
                    <strong className="ssdd">File size: </strong>
                    {bytesToSize(data.Size)}
                  </label>
                  <label className="d-block">
                    <strong className="ssdd">File name: </strong>
                    {data.Key}
                  </label>
                  <hr />
                  <div className="d-flex align-items-center">
                    <strong
                      className="ssdd mr-2"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      File url:{" "}
                    </strong>
                    <input
                      type="text"
                      className="form-control"
                      value={`https://${videoListName}.s3.ap-south-1.amazonaws.com/${data.Key}`}
                    />
                    <button
                      className="custom-btn btn-outline-secondary"
                      onClick={() => {
                        copy(
                          `https://${videoListName}.s3.ap-south-1.amazonaws.com/${data.Key}`
                        );
                        toast("Copied", {
                          position: "top-center",
                          duration: 2000,
                        });
                      }}
                    >
                      <i className="ri-file-copy-line"></i>
                    </button>
                  </div>

                  <hr />
                </div>
                <div className="text-right">
                  {role === "Admin" ? (
                    <button
                      className="btn btn-link text-danger"
                      type="button"
                      onClick={() => {
                        handleDelete(data.Key);
                        setIsOpenModal(false);
                      }}
                    >
                      Delete this item
                    </button>
                  ) : (
                    <button
                      className="btn btn-link text-danger"
                      type="button"
                      onClick={() =>
                        Swal.fire({
                          title: "Oops!!",
                          text: "Sorry, you can't delete this item.",
                          icon: "error",
                          confirmButtonText: "Okay, Got it!",
                        })
                      }
                    >
                      Delete this item
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <div className="list_component_wrapper">
        <span
          className="more_button"
          onClick={() => setIsDropOpen(!isDropOpen)}
        >
          <i className="ri-more-2-line"></i>
        </span>
        {isDropOpen && (
          <div className="custom_new_drop_down">
            {extention === "xlsx" || extention === "xls" ? (
              <a
                className="dropdown-item"
                href={`https://${videoListName}.s3.ap-south-1.amazonaws.com/${data.Key}`}
              >
                <i className="ri-eye-line"></i>
                <span>View</span>
              </a>
            ) : (
              <div className="dropdown-item" onClick={toggleModal}>
                <i className="ri-eye-line"></i>
                <span>View</span>
              </div>
            )}
            {role === "Admin" && (
              <div
                className="dropdown-item"
                onClick={() => handleDelete(data.Key)}
              >
                <i className="ri-delete-bin-line"></i>
                <span>Delete</span>
              </div>
            )}
          </div>
        )}
        <div className="component_header box_icon_media" onClick={toggleModal}>
          {renderImage(
            extention,
            `https://${videoListName}.s3.ap-south-1.amazonaws.com/${data.Key}`
          )}
        </div>
        <div className="component_body" onClick={toggleModal}>
          <h6 className="component_body_title">{data.Key}</h6>
        </div>
      </div>
    </>
  );
};
export default ThumbnailComponent;
