import React, { Component } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import { Link } from "react-router-dom";

const SalesAnalytics = (props) => {
  // console.log("result>>", props.data.totalTransactions.transactions);
  return (
    <React.Fragment>
      <div className="col-xl-12 col-lg-12 col-12">
        <div className="card card-shadow">
          <div className="card-header card-header-transparent py-20">
            <h6>Total Orders (12 Months)</h6>
            <div className="d-flex justify-content-between">
              <span className="text-muted">
                Success: {props.data.totalTransactions.successTransactions}
              </span>
              <span className="text-muted">
                Failed: {props.data.totalTransactions.faildTransactions}
              </span>
            </div>
          </div>
          <div className="card-body dashboard_table" style={{ minHeight: 545 }}>
            {props.data.totalTransactions.transactions.length > 0 ? (
              <table className="table table-hover">
                {props.data.totalTransactions.transactions.map(
                  (item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.product.title}</td>
                      <td>₹{item.price}</td>
                    </tr>
                  )
                )}
              </table>
            ) : (
              <p className="text-center">data not found</p>
            )}

            {props.data.totalTransactions.transactions.length > 0 && (
              <div className="dashboard_section_bottom_button">
                <Link className="btn btn-link" to="/reports/transactions">
                  View all
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SalesAnalytics;
