import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import moment from "moment";
import "antd/dist/antd.css";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import { userLogout } from "../../redux/actions/UserActions";

import { useSelector } from "react-redux";

import UserImg from "../../assets/user.png";

const Index = (props) => {
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const role = useSelector((state) => state.user?.user?.role);

  // get all courses
  const getCourses = () => {
    axios
      .get(`${basurl}/course-discussion/all`)
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getCourses();
  }, []);

  const handleItem = (val) => {
    setSelectedItem(val);
  };

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);
  // console.log("list here>>>>", selectedItem);

  if (role !== "Admin") {
    return <Redirect to="/" />;
  }
  return (
    <MainContainer>
      {isLoading && <Loader />}

      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Discussion
            </h4>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="discusstion_courses_wrapper">
                    {list.length > 0 && (
                      <div className="discussion_list">
                        {list.map((item, index) => {
                          return (
                            <div
                              key={`discussion_${index * 2}`}
                              className={`discussion_item ${
                                item._id === selectedItem?._id ? "active" : ""
                              }`}
                              onClick={() => handleItem(item)}
                            >
                              {item.course?.title} ({item.comments.length || 0})
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-8">
                  {selectedItem && (
                    <div className="discussion_details">
                      <div className="course_details_wrapper">
                        <div className="course_image">
                          <img
                            src={selectedItem?.course?.course_image}
                            alt={selectedItem?.course?.title}
                          />
                        </div>
                        <div className="course_title">
                          <h4>{selectedItem?.course?.title}</h4>
                        </div>
                      </div>
                      <div className="discussions">
                        <h5 className="mt-4 mb-4">
                          Discussion: ({selectedItem.comments.length})
                        </h5>

                        <h6
                          className="mb-4"
                          dangerouslySetInnerHTML={{
                            __html: selectedItem.description,
                          }}
                        ></h6>

                        {selectedItem.comments.length > 0 && (
                          <div className="pl-4">
                            {selectedItem.comments.map((it, idx) => {
                              return (
                                <div
                                  className="comment_wrapper"
                                  key={idx * 233}
                                >
                                  <div className="comment_user">
                                    <div className="comment_user_image">
                                      {it.user.profile_picture ? (
                                        <img
                                          src={it.user.profile_picture}
                                          alt={it.user.name}
                                        />
                                      ) : (
                                        <img src={UserImg} alt={it.user.name} />
                                      )}
                                    </div>
                                    <div className="comment_user_details">
                                      <h5 className="ml-3">{it.user.name}</h5>
                                    </div>
                                  </div>
                                  <div className="comment">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: it.comment,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
