import React, { useState, useEffect, useRef } from "react";
import MainContainer from "../../common/MainContainer";
import { useHistory } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../common/Loader";

const EditProfile = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageError, setIsImageError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [mobile, setMobile] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [featuredImages, setFeaturedImages] = useState(null);
  const [designation, setDesignation] = useState("");
  const [bio, setBio] = useState("");

  let history = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const basurl = process.env.REACT_APP_URL;
  const imgUrls = process.env.REACT_APP_BASE_URL;
  const userToken = user.userToken;

  // AWS configurations
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new/users" },
    region: "ap-south-1",
  });

  // upload image to aws bucket
  const uploadVideoHandler = (files) => {
    console.log("video file >> ", files[0]);
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setUploadProgress(percent);
        // console.log(
        //   "progress video upload :: ",
        //   Math.round((evt.loaded / evt.total) * 100)
        // );
      })
      .on("success", (evt) => {
        // console.log("mujhe khusi hogi >>>", evt.request.params);
        setFeaturedImages(
          `https://lms-videos-bucket-prepzone-new.s3.ap-south-1.amazonaws.com/${evt.request.params.Key}`
        );
        setUploadProgress(0);
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // console.log("error >> ", err);
        } else {
          // console.log("aljkskdasd", data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  // get user details
  const getUserDetails = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/users/user/${user.user.id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setName(result.data.name);
          setEmail(result.data.email);
          setRole(result.data.role);
          setMobile(result.data.mobile);
          setFeaturedImages(result.data.profile_picture);
          setBio(result.data.bio);
          setDesignation(result.data.designation);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data === "Invalid token") {
          dispatch({
            type: "USER_LOGOUT",
            payload: false,
            token: null,
            info: null,
          });
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  // submit form
  const submitForm = (e) => {
    setIsLoading(true);
    e.preventDefault();
    axios
      .put(
        `${basurl}/users/update/${user.user.id}`,
        {
          profile_picture: featuredImages,
          mobile,
          name,
          designation,
          bio,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const savedUser = res.data;
        if (savedUser.success) {
          dispatch({
            type: "USER_INFO_UPDATE",
            payload: savedUser.data,
          });
          const oldData = JSON.parse(localStorage.getItem("lmsUserInfo"));
          const newData = {
            status: true,
            token: oldData.token,
            userInfo: savedUser.data,
          };
          localStorage.setItem("lmsUserInfo", JSON.stringify(newData));
          console.log("asdasd", oldData);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your information has been saved successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          dispatch({
            type: "USER_LOGOUT",
            payload: false,
            token: null,
            info: null,
          });
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        console.log(err);
      });
  };
  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Edit Profile{" "}
            </h4>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className={"card-body"}>
          <div className={"row"}>
            <div className={"col-lg-3"}>
              {featuredImages ? (
                <div
                  className="course_image_uploader"
                  style={{
                    background: "transparent",
                    boxShadow: "none",
                    height: 250,
                    width: 250,
                    borderRadius: "50%",
                  }}
                >
                  <div
                    className="image_options_upload"
                    onClick={() => setFeaturedImages(null)}
                  >
                    <div className="upload_inner">
                      <div className="upload_inner_icon">
                        <i className="ri-delete-bin-line"></i>
                      </div>
                      <div className="upload_inner_content">
                        REMOVE <br /> PROFILE PHOTO
                      </div>
                    </div>
                  </div>
                  <img
                    src={featuredImages}
                    alt={"feature image"}
                    className="img-fluid"
                  />
                </div>
              ) : (
                <Dropzone
                  onDrop={(acceptedFiles) => uploadVideoHandler(acceptedFiles)}
                  accept={"image/*"}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className="course_image_uploader"
                      style={{
                        background: "transparent",
                        boxShadow: "none",
                        height: 250,
                      }}
                    >
                      {uploadProgress > 1 && (
                        <div className="upload_progress">
                          <div
                            className="inner"
                            style={{
                              width: `${uploadProgress}%`,
                              height: "100%",
                              backgroundColor: "#4CAF50",
                              transition: "all 0.4s ease-in-out",
                              borderRadius: "20px",
                            }}
                          ></div>
                        </div>
                      )}

                      <div {...getRootProps()} className={"dropZone"}>
                        <input {...getInputProps()} />
                        {/* <i className="ri-image-line uploader_icon"></i> */}
                        <div className="image_options_upload">
                          <div className="upload_inner">
                            <div className="upload_inner_icon">
                              <i className="ri-camera-line"></i>
                            </div>
                            <div className="upload_inner_content">
                              CHANGE <br /> PROFILE PHOTO
                            </div>
                          </div>
                        </div>
                        <img
                          src={require("../../assets/user.png")}
                          alt="Mock demo image"
                          className="img-fluid"
                          style={{
                            maxHeight: "100%",
                            width: "auto !important",
                            height: 250,
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}
            </div>
            <div className={"col-lg-8"}>
              <div className={"mb-2"}>
                <label>Name</label>
                <input
                  type="text"
                  className={"form-control"}
                  placeholder={"Name"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className={"mb-2"}>
                <label>Email</label>
                <input
                  type="email"
                  className={"form-control"}
                  placeholder={"Email Address"}
                  value={email}
                  disabled
                />
              </div>
              <div className={"mb-2"}>
                <label>Mobile</label>
                <input
                  type="text"
                  className={"form-control"}
                  placeholder={"Mobile No."}
                  value={mobile}
                  maxLength="10"
                  onChange={(e) => setMobile(e.target.value.replace(/\D/g, ""))}
                />
              </div>
              <div className={"mb-2"}>
                <label>Role</label>
                <input
                  type="text"
                  className={"form-control"}
                  placeholder={"role"}
                  value={role}
                  disabled
                />
              </div>
              <div className={"mb-2"}>
                <label>Designation</label>
                <input
                  type="text"
                  className={"form-control"}
                  placeholder={"Designation"}
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                />
              </div>
              <div className={"mb-2"}>
                <label>Bio</label>
                <textarea
                  className={"form-control"}
                  placeholder={"Bio"}
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                />
              </div>
              <div className={"d-flex"}>
                <button
                  className={"btn btn-primary"}
                  style={{ marginRight: 15 }}
                  onClick={submitForm}
                >
                  Save Profile
                </button>
                <button
                  className={"btn btn-primary"}
                  onClick={() => history.goBack()}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
export default EditProfile;
