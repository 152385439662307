import React, { useState, useEffect } from "react";
import MainContainer from "../../../common/MainContainer";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Loader from "../../../common/Loader";
import { userLogout } from "../../../redux/actions/UserActions";

const AddPartner = (props) => {
  let history = useHistory();
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.set("title", data.title);
    formData.append("image", data.image[0]);
    axios({
      method: "post",
      url: `${basurl}/frontend/partners/add`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "auth-token": props.user.userToken,
      },
    })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your logo added successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
          props.history.push("/frontend/partners");
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: err.Message,
          showConfirmButton: false,
          timer: 1500,
        });
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        setIsLoading(false);
      });
  };

  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="card">
        <div className="card-header">
          <div className={"row"}>
            <div className="col-md-6">
              <h4 className={"header-title"}>Add Partners Logo</h4>
            </div>
          </div>
        </div>
        <div className={"card-body"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                placeholder="Title"
                className="form-control"
                ref={register({ required: true })}
                name="title"
              />
              {errors.title && (
                <span className="isError">Title is required</span>
              )}
            </div>
            <div className="form-group">
              <label>Logo Image</label>
              <input
                type="file"
                placeholder="Image"
                className="form-control"
                ref={register({ required: true })}
                name="image"
              />
              {errors.image && (
                <span className="isError">Logo Image is required</span>
              )}
            </div>
            <div className={"d-flex"}>
              <button
                type="submit"
                className="btn btn-success"
                style={{ marginRight: 15 }}
              >
                Add Partner Logo
              </button>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddPartner);
