import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ActivatedUserModal = ({ getUserDetails }) => {
  const { user } = useSelector((state) => state.user);
  const isActiveUser = user.isActive;

  return (
    <div className="activatedUserModal">
      <div className="container">
        <div className="card">
          <div className="card-body">
            <h2 className="text-center mb-3">Hello, {user.name}!</h2>
            <p className="text-center">
              You've been invited by Shubham Ranka to join as a{" "}
              <strong>{user.role}</strong> in MyPrepZone.
            </p>
            <div className="text-center">
              <button
                className="btn btn-outline-primary"
                onClick={getUserDetails}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivatedUserModal;
