import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { DatePicker, Space, Switch } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";
import Swal from "sweetalert2";
import BuildMock from "./BuildMock";

const CreateMock = (props) => {
  const { id } = useParams();
  const { register, handleSubmit, errors, setValue } = useForm();
  const [details, setDetails] = useState(null);
  const [categories, setCategories] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [featuredImages, setFeaturedImages] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [builder, setBuilder] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNegativeMarking, setIsNegativeMarking] = useState(false);
  const basurl = process.env.REACT_APP_URL;
  const user = useSelector((state) => state.user);
  const userToken = user.userToken;

  // AWS configurations
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new/mock" },
    region: "ap-south-1",
  });

  // upload image to aws bucket
  const uploadVideoHandler = (files) => {
    console.log("video file >> ", files[0]);
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setUploadProgress(percent);
        // console.log(
        //   "progress video upload :: ",
        //   Math.round((evt.loaded / evt.total) * 100)
        // );
      })
      .on("success", (evt) => {
        // console.log("mujhe khusi hogi >>>", evt.request.params);
        setFeaturedImages(
          `https://lms-videos-bucket-prepzone-new.s3.ap-south-1.amazonaws.com/${evt.request.params.Key}`
        );
        setUploadProgress(0);
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // console.log("error >> ", err);
        } else {
          // console.log("aljkskdasd", data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  useEffect(() => {
    if (builder) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [builder]);

  // get mock details
  const getMockDetails = () => {
    axios
      .get(`${basurl}/mock/view/${id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        setDetails(result.data);
        setValue("title", result.data.title);
        setValue("description", result.data.instructions);
        setValue("category", result.data.category._id);
        setValue("price", result.data.price);
        setExpiryDate(moment(result.data.expiryDate));
        setFeaturedImages(result.data.image);
        setIsNegativeMarking(result.data.isNegativeMarking);
        setValue("totalMarks", result.data.totalMarks);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get mock categories
  const getMockCategories = () => {
    axios.get(`${basurl}/mock/categories`).then((res) => {
      const list = res.data;
      if (list.success) {
        setCategories(list.data);
      }
    });
  };

  useEffect(() => {
    getMockDetails();
    getMockCategories();
  }, [id]);

  // onSubmit
  const onSubmit = (data) => {
    console.log("data >> ", data);
    axios
      .put(
        `${basurl}/mock/edit/${id}`,
        {
          title: data.title,
          price: data.price,
          instructions: data.description,
          category: data.category,
          image: featuredImages,
          expiryDate: expiryDate,
          isNegativeMarking: isNegativeMarking,
          totalMarks: data.totalMarks,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Mock Updated Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // OnChange function for date DatePicker
  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setExpiryDate(date);
  };

  return (
    <MainContainer>
      <h4>{details?.title}</h4>
      <div className="back_btn">
        <Link to="/mock">
          <i className="ri-arrow-left-line"></i>{" "}
          <span style={{ position: "relative", left: 5 }}>Back to Mocks</span>
        </Link>
      </div>
      <div className="card mt-3">
        <div className="card-body course_form_wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-8">
                <h5 className="section_title_page">Basic Information</h5>
                <div className="mb-2">
                  <span>Mock title</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter mock title"
                    ref={register({ required: true })}
                    name={"title"}
                  />
                  {errors.title && (
                    <span className="isError">Title is required</span>
                  )}
                </div>
                <div className="mb-2">
                  <span>Mock category</span>
                  <select
                    className="form-control"
                    ref={register({ required: true })}
                    name={"category"}
                  >
                    <option value="">Select category</option>
                    {categories.map((cat) => (
                      <option key={cat._id} value={cat._id}>
                        {cat.title}
                      </option>
                    ))}
                  </select>
                  {errors.category && (
                    <span className="isError">Mock category is required</span>
                  )}
                </div>
                <div className="mb-2">
                  <span>Mock Description for website</span>
                  <textarea
                    className="form-control"
                    ref={register({ required: true })}
                    name={"description"}
                    style={{ minHeight: "265px", resize: "none" }}
                  />
                  {errors.description && (
                    <span className="isError">
                      Mock description is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="mb-2">
                  {featuredImages ? (
                    <div className="course_image_uploader">
                      <span
                        className="close_icon"
                        onClick={() => setFeaturedImages(null)}
                      >
                        <i className="ri-close-line"></i>
                      </span>
                      <img
                        src={featuredImages}
                        alt={"feature image"}
                        className="img-fluid"
                      />
                    </div>
                  ) : (
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        uploadVideoHandler(acceptedFiles)
                      }
                      accept={"image/*"}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="course_image_uploader">
                          {uploadProgress > 1 && (
                            <div className="upload_progress">
                              <div
                                className="inner"
                                style={{
                                  width: `${uploadProgress}%`,
                                  height: "100%",
                                  backgroundColor: "#4CAF50",
                                  transition: "all 0.4s ease-in-out",
                                  borderRadius: "20px",
                                }}
                              ></div>
                            </div>
                          )}

                          <div {...getRootProps()} className={"dropZone"}>
                            <input {...getInputProps()} />
                            {/* <i className="ri-image-line uploader_icon"></i> */}
                            <span className="close_icon">
                              <i className="ri-pencil-fill"></i>
                            </span>
                            <img
                              src={require("../../assets/coursepic.png")}
                              alt="Mock demo image"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  <span className="d-block">Mock Image</span>
                </div>
                <div className="mb-2" style={{ marginTop: "2rem" }}>
                  <span>Validity</span>
                  <Space direction="vertical">
                    {details?.expiryDate === null ? (
                      <DatePicker
                        onChange={onChange}
                        className="form-control"
                        // defaultValue={moment()}
                        style={{ borderRadius: 10, display: "block" }}
                        format="DD-MM-YYYY"
                      />
                    ) : (
                      <>
                        {expiryDate && (
                          <DatePicker
                            onChange={onChange}
                            className="form-control"
                            defaultValue={expiryDate}
                            style={{ borderRadius: 10, display: "block" }}
                            format="DD-MM-YYYY"
                          />
                        )}
                      </>
                    )}
                  </Space>
                </div>
                <div className="mb-2">
                  <span>Price</span>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter quiz price"
                    ref={register({ required: true, valueAsNumber: true })}
                    name={"price"}
                  />
                  {errors.price && (
                    <span className="isError">Price is required</span>
                  )}
                </div>
                <div className="mb-2">
                  <span>Total Marks</span>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter total marks"
                    ref={register({ required: true, valueAsNumber: true })}
                    name={"totalMarks"}
                  />
                  {errors.totalMarks && (
                    <span className="isError">Total marks is required</span>
                  )}
                </div>
                <div className="mb-2 mt-4">
                  <span>Negative marking</span>
                  <div>
                    <Switch
                      //   defaultChecked={isNegativeMarking}
                      onChange={(checked) => setIsNegativeMarking(checked)}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={isNegativeMarking}
                    />
                  </div>
                </div>
                <button
                  className="btn btn-link btn-block mb-4"
                  type="button"
                  onClick={() => props.history.push("/mock")}
                >
                  Cancel
                </button>
                {isLoading ? (
                  <button
                    className="btn btn-primary btn-block mb-4"
                    type="button"
                    style={{ opacity: 0.6 }}
                  >
                    wait...
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-block mb-4"
                    type="submit"
                  >
                    Save edits
                  </button>
                )}
                <button
                  className="btn btn-success btn-block"
                  type="button"
                  onClick={() => setBuilder(true)}
                >
                  Build Mock
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {builder && (
        <BuildMock
          data={details}
          handleBuilder={() => setBuilder(false)}
          reloadMock={() => getMockDetails()}
        />
      )}
    </MainContainer>
  );
};

export default CreateMock;
