import React, { useEffect, useState } from "react";
import axios from "axios";
import MainContainer from "../../../../common/MainContainer";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Switch from "react-switch";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "../../../../common/Loader";
import { userLogout } from "../../../../redux/actions/UserActions";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AWS from "aws-sdk";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";

const AddBanner = (props) => {
  let history = useHistory();
  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [imageError, setImageError] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [featuredImages, setFeaturedImages] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [isImageError, setIsImageError] = useState("");
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const { register, handleSubmit, watch, errors } = useForm();
  const [progress, setProgress] = useState(0);

    // AWS configurations
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    });
    const myBucket = new AWS.S3({
      params: { Bucket: "lms-videos-bucket-prepzone-new/banners" },
      region: "ap-south-1",
    });

    // upload image to aws bucket
  const uploadVideoHandler = (files) => {
    console.log("video file >> ", files[0]);
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setUploadProgress(percent);
      })
      .on("success", (evt) => {
        // console.log("mujhe khusi hogi >>>", evt.request.params);
        setFeaturedImages(
          `https://lms-videos-bucket-prepzone-new.s3.ap-south-1.amazonaws.com/${evt.request.params.Key}`
        );
        setUploadProgress(0);
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };
  

  // handle Image upload
  const handleImage = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setSelectedImage(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    axios
      .post(`${basurl}/upload/banner`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((res) => {
        const imgRes = res.data;
        console.log("imgresponse >> ", imgRes);
        if (imgRes.success) {
          setSelectedImage(imgRes.data);
          setProgress(0);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "image uploaded successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }).catch = (err) => {
      setProgress(0);
      console.log(err);
    };
  };
  // remove selected image
  const handleRemoveImage = () => {
    setImage(null);
    setSelectedImage(null);
  };

  // add banner function
  const onSubmit = (data) => {
    if (featuredImages === null) {
      setImageError("Please Choose Banner Image.");
    } else {
      setIsloading(true);
      axios
        .post(
          `${basurl}/frontend/banners/create`,
          {
            image: featuredImages,
            heading: data.heading,
            subheading: data.subheading,
            link: data.link,
            isActive: isActive,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((res) => {
          const response = res.data;
          if (response.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Banner added successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
            props.history.push("/frontend/banners");
          }
          setIsloading(true);
        })
        .catch((err) => {
          setIsloading(false);
          if (err.response.data === "Invalid token") {
            props.logoutUser();
            localStorage.removeItem("lmsUserInfo");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your token has been expired!, Please login again",
              showConfirmButton: false,
              timer: 1500,
            });
            props.history.push("/");
          }
          console.log(err);
        });
    }
  };

  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="card">
        <div className="card-header">
          <h4 className={"header-title"}>Add Banner</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  <label>
                    Banner Heading <span className="starImportnat">*</span>
                  </label>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder={"Enter Banner Heading"}
                    name={"heading"}
                    ref={register({ required: true })}
                  />
                  {errors.heading && (
                    <span className="isError">Banner heading is required.</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Banner Subheading</label>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder={"Enter Banner Subheading"}
                    name={"subheading"}
                    ref={register}
                  />
                </div>
                <div className="form-group">
                  <label>Banner link</label>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder={"Enter Banner link (not full link like /link)"}
                    name={"link"}
                    ref={register}
                  />
                </div>
                <div className="form-group">
                  <label className={"d-block"}>Banner Active</label>
                  <Switch
                    onChange={() => setIsActive(!isActive)}
                    checked={isActive}
                  />
                </div>
                <div className={"d-flex"}>
                  <button
                    type="submit"
                    className="btn btn-success"
                    style={{ marginRight: 15 }}
                  >
                    Add Banner
                  </button>
                  <button
                    type={"button"}
                    className="btn btn-primary"
                    onClick={() => history.goBack()}
                  >
                    Go Back
                  </button>
                </div>
              </div>
              <div className="col-md-4">
              <div className="form-group">
                  <label>Banner Image</label>
                  {featuredImages ? (
                    <div className="course_image_uploader">
                      <span
                        className="close_icon"
                        onClick={() => setFeaturedImages(null)}
                      >
                        <i className="ri-close-line"></i>
                      </span>
                      <img
                        src={featuredImages}
                        alt={"feature image"}
                        className="img-fluid"
                      />
                    </div>
                  ) : (
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        uploadVideoHandler(acceptedFiles)
                      }
                      accept={"image/*"}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="course_image_uploader">
                          {uploadProgress > 1 && (
                            <div className="upload_progress">
                              <div
                                className="inner"
                                style={{
                                  width: `${uploadProgress}%`,
                                  height: "100%",
                                  backgroundColor: "#4CAF50",
                                  transition: "all 0.4s ease-in-out",
                                  borderRadius: "20px",
                                }}
                              ></div>
                            </div>
                          )}

                          <div {...getRootProps()} className={"dropZone"}>
                            <input {...getInputProps()} />
                            {/* <i className="ri-image-line uploader_icon"></i> */}
                            <span className="close_icon">
                              <i className="ri-pencil-fill"></i>
                            </span>
                            <img
                              src={require("../../../../assets/coursepic.png")}
                              alt="Mock demo image"
                              className="img-fluid"
                              height={"100%"}
                            />
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  {isImageError !== "" ? (
                    <span className="isError">{isImageError}</span>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddBanner);
