import React, { useState, useEffect } from "react";
import MainContainer from "../../../common/MainContainer";
import Swal from "sweetalert2";
import axios from "axios";
import { connect } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { userLogout } from "../../../redux/actions/UserActions";

const AboutUs = (props) => {
  // define stats
  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [heading, setHeading] = useState("");
  const [subheading, setSubheading] = useState("");
  const [about_author, setAbout_author] = useState("");
  const [content, setContent] = useState("");
  const [meta_description, setMeta_description] = useState("");
  const [meta_title, setMeta_title] = useState("");
  const [allDetails, setAllDetails] = useState(null);
  const basurl = process.env.REACT_APP_URL;
  const basurlHome = process.env.REACT_APP_BASE_URL;
  const userToken = props.user.userToken;

  // upload image handler
  const uploadImage = (e) => {
    const file = e.target.files[0];
    var _size = file.size;
    var fSExt = new Array("Bytes", "KB", "MB", "GB"),
      i = 0;
    while (_size > 900) {
      _size /= 1024;
      i++;
    }
    var mainSize = Math.round(_size * 100) / 100;
    var exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
    // console.log('FILE SIZE = ',mainSize);
    if (mainSize > 200) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "File Size too large!",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      setSelectedImage(URL.createObjectURL(file));
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      axios({
        method: "post",
        url: `${basurl}/upload/user`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Image uploaded successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setImage(result.data);
        }
      });
    }
    // console.log("get Image >> ", file)
  };

  // handle remove image
  const handleRemoveImage = () => {
    setImage(null);
    setSelectedImage(null);
  };

  // handle Submit form
  const handleSubmitFrom = (e) => {
    e.preventDefault();
    axios
      .post(
        `${basurl}/frontend/aboutus/add`,
        {
          image,
          heading,
          subheading,
          about_author,
          content,
          meta_description,
          meta_title,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "About Us Page info saved successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  // get about us details
  const getAboutUsDetails = () => {
    axios.get(`${basurl}/frontend/aboutus`).then((res) => {
      const details = res.data;
      if (details.success) {
        if (details.data) {
          setAbout_author(details.data.about_author);
          setContent(details.data.content);
          setHeading(details.data.heading);
          setSubheading(details.data.subheading);
          setMeta_title(details.data.meta_title);
          setMeta_description(details.data.meta_description);
          setImage(details.data.author_image);
          setSelectedImage(basurlHome + details.data.author_image);
          setAllDetails(details.data);
        }
      }
    });
  };

  // Update About us page
  const updateInfo = (e) => {
    e.preventDefault();
    axios
      .put(
        `${basurl}/frontend/aboutus/edit/${allDetails._id}`,
        {
          image,
          heading,
          subheading,
          about_author,
          content,
          meta_description,
          meta_title,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "About Us Page Updated successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          getAboutUsDetails();
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  useEffect(() => {
    getAboutUsDetails();
  }, []);
  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <h4 className={"header-title"}>Frontend About Us Page</h4>
        </div>
        <div className="card-body">
          <form onSubmit={allDetails !== null ? updateInfo : handleSubmitFrom}>
            <div className="form-group">
              <label>Author Image</label>
              <div className={"image-uploader"}>
                {selectedImage !== null ? (
                  <div className={"img_upld_wrapper"}>
                    <img src={selectedImage} className={"image_upload"} />
                    <span
                      className={"upload_img_close"}
                      onClick={() => handleRemoveImage()}
                    >
                      <i className="ri-close-line"></i>
                    </span>
                  </div>
                ) : (
                  <div className={"image_upload_placeholder"}>
                    <i className={"ri-add-box-line"}></i>
                    <span className={"image_upload_titler"}>
                      Browse Course Image
                    </span>
                    <input
                      type="file"
                      className={"image_upload_file"}
                      onChange={(e) => uploadImage(e)}
                      accept="image/*"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>About Us Page Heading</label>
              <input
                type="text"
                placeholder={"Page Heading"}
                value={heading}
                className="form-control"
                onChange={(e) => setHeading(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>About Us Page Sub-heading</label>
              <input
                type="text"
                placeholder={"Page Sub-heading"}
                value={subheading}
                className="form-control"
                onChange={(e) => setSubheading(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>About Author</label>
              <CKEditor
                editor={ClassicEditor}
                data={about_author}
                config={{ allowedContent: true }}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setAbout_author(data);
                }}
              />
            </div>
            <div className="form-group">
              <label>About Us Page Main Content</label>
              <CKEditor
                editor={ClassicEditor}
                data={content}
                config={{ allowedContent: true }}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContent(data);
                }}
              />
            </div>
            <br />
            <br />
            <h4 className={"section_title"}>Seo Section</h4>
            <div className="form-group">
              <label>Meta Title</label>
              <input
                type="text"
                placeholder={"Meta Title"}
                className="form-control"
                value={meta_title}
                onChange={(e) => setMeta_title(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Meta Description</label>
              <input
                type="text"
                placeholder={"Meta Description"}
                className="form-control"
                value={meta_description}
                onChange={(e) => setMeta_description(e.target.value)}
              />
            </div>
            <button type={"submit"} className="btn btn-success">
              Save About Us Page Info
            </button>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
