import React, { useState, useEffect } from "react";
import MainContainer from "../../../../common/MainContainer";
import { Link } from "react-router-dom";
import axios from "axios";
import { Table } from "antd";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { userLogout } from "../../../../redux/actions/UserActions";

const FrontBannerSlider = (props) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  //get slider banner list
  const bannerList = () => {
    axios
      .get(`${basurl}/frontend/banners/`)
      .then((res) => {
        const sliders = res.data;
        if (sliders.success) {
          setList(sliders.data);
        }
        setIsLoading(false);
        // console.log("slider list >> ", sliders)
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  // Delete banner slider
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this banner ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${basurl}/frontend/banners/delete/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const deleteStatus = res.data;
            if (deleteStatus.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: deleteStatus.message,
                showConfirmButton: false,
                timer: 2500,
              });
              bannerList();
            }
            // console.log("delete status > ", deleteStatus);
          })
          .catch((err) => {
            if (err.response.data === "Invalid token") {
              props.logoutUser();
              localStorage.removeItem("lmsUserInfo");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your token has been expired!, Please login again",
                showConfirmButton: false,
                timer: 1500,
              });
              props.history.push("/");
            }
          });
      }
    });
  };

  useEffect(() => {
    bannerList();
  }, []);

  // Columns
  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (value, item, index) => (
        <div className={"table_image"}>
          <img
            src={item.image}
            style={{ width: "150px" }}
          />
        </div>
      ),
    },
    {
      title: "Heading",
      render: (value, item, index) => <span>{item.heading}</span>,
    },
    {
      title: "Subheading",
      render: (value, item, index) => <span>{item.subheading}</span>,
    },
    {
      title: "Link",
      render: (value, item, index) => <span>{item.link}</span>,
    },
    {
      title: "Status",
      render: (value, item, index) => (
        <div data-tag="allowRowEvents">
          {item.isActive ? (
            <span className={"text-success "}>Active</span>
          ) : (
            <span className={"text-danger "}>Deactivated</span>
          )}
        </div>
      ),
    },

    {
      title: "Action",
      render: (value, item, index) => (
        <div data-tag="allowRowEvents">
          <Link
            className={"btn btn-success "}
            to={`/frontend/banners/edit/${item._id}`}
            style={{ marginRight: "10px", minWidth: 100 }}
          >
            <i className={"ri-pencil-line"}></i>
          </Link>
          <button
            className={"btn btn-danger "}
            onClick={() => handleDelete(item._id)}
            style={{ minWidth: 100 }}
          >
            <i className={"ri-delete-bin-line"}></i>
          </button>
        </div>
      ),
    },
  ];

  console.log("banner list>> ", list);
  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <div className={"row"}>
            <div className="col-md-6">
              <h4 className={"header-title"}>Home Page Slider Banners</h4>
            </div>
            <div className="col-md-6 text-right">
              <Link className={"btn btn-primary"} to={"/frontend/banners/add"}>
                Add Slider Banner
              </Link>
            </div>
          </div>
        </div>
        <div className={"card-body"}>
          <Table
            columns={columns}
            dataSource={list}
            bordered={true}
            loading={isLoading}
          />
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FrontBannerSlider);
