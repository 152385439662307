import React from "react";
import { Route, Redirect } from "react-router-dom";
const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isLoggedIn,
  exact,
  billStatus,
  ...rest
}) => (
  <Route
    exact
    {...rest}
    render={(props) => {
      return (
        <Layout>
          <Component {...props} billStatus={billStatus} />
        </Layout>
      );
    }}
  />
);

export default AppRoute;
