export const updateDashboard = (data) => {
  // console.log("dashboard data", data);
  return {
    type: "UPDATE_DASHBOARD",
    payload: data,
  };
};
export const updateBill = (data) => {
  // console.log("dashboard data", data);
  return {
    type: "UPDATE_BILL",
    payload: data,
  };
};
export const updateBillStatus = (data) => {
  // console.log("dashboard data", data);
  return {
    type: "UPDATE_BILL_STATUS",
    payload: data,
  };
};
