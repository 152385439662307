import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Switch from "react-switch";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Loader from "../../common/Loader";
import { useHistory } from "react-router-dom";
import { userLogout } from "../../redux/actions/UserActions";
import moment from "moment";

const AddCoupon = (props) => {
  let history = useHistory();
  const [date, setDate] = useState(new Date());
  const [courses, setCourses] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [type, setType] = useState("");
  const { register, handleSubmit, watch, errors, setValue } = useForm();
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post(
        `${basurl}/coupon/add`,
        {
          code: data.couponCode.toUpperCase(),
          expiryDate: date,
          course: data.course,
          bundle: data.bundle,
          amountType: data.amountType,
          amount: data.amount,
          quantity: data.quantity,
          qtyType: data.qtyType,
          isActive: isActive,
          isGlobal: type === "global" ? true : false,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const resposne = res.data;
        if (resposne.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Coupon code added successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push(`/coupons`);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: resposne.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: err.message,
          showConfirmButton: false,
          timer: 1500,
        });
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          history.push("/");
        }
      });
    setIsLoading(false);
  };

  // get all courses
  const getAllCourses = () => {
    axios.get(`${basurl}/course/all`).then((res) => {
      const result = res.data;
      if (result.success) {
        setCourses(result.data);
      }
    });
  };

  // get all bundles
  const getAllbundles = () => {
    axios.get(`${basurl}/bundle/list`).then((res) => {
      const result = res.data;
      if (result.success) {
        setBundles(result.data);
      }
    });
  };

  const coupongenerator = () => {
    var coupon = "";
    var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 10; i++) {
      coupon += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    setValue("couponCode", "MPZ" + coupon);
    // console.log("generated coupon>>", coupon);
  };

  useEffect(() => {
    getAllbundles();
    getAllCourses();
  }, []);

  // console.log("date", moment(date).format("DD/MM/YYYY, h:mm:ss a"));
  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="card">
        <div className="card-header">
          <h4 className={"header-title"}>Add Coupon Code</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Coupon Code</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Coupon Code"
                    ref={register({ required: true })}
                    name={"couponCode"}
                    style={{ textTransform: "uppercase" }}
                  />
                  {errors.couponCode && (
                    <span className="isError">Coupon code is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <label className={"d-block"}>Generate Coupon Code</label>
                <button
                  type={"button"}
                  className="btn btn-primary"
                  onClick={() => coupongenerator()}
                >
                  <i className={"la la-refresh"}></i> Generate coupon code
                </button>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Type</label>
                  <select
                    className="form-control"
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="">Select Type(course/bundle)</option>
                    <option value="global">Global</option>
                    <option value="course">Course</option>
                    <option value="bundle">Bundle</option>
                  </select>
                </div>
              </div>
              {type === "course" && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Course</label>
                    <select
                      className="form-control"
                      name={"course"}
                      ref={register({ required: true })}
                    >
                      <option value="">Please select course</option>
                      {courses.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                    {errors.course && (
                      <span className="isError">Course is required.</span>
                    )}
                  </div>
                </div>
              )}
              {type === "bundle" && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Bundle</label>
                    <select
                      className="form-control"
                      name={"bundle"}
                      ref={register({ required: true })}
                    >
                      <option value="">Please select bundle</option>
                      {bundles.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                    {errors.bundle && (
                      <span className="isError">Bundle is required.</span>
                    )}
                  </div>
                </div>
              )}

              <div className="col-md-6">
                <div className="form-group">
                  <label>Expiry Date</label>
                  <DatePicker
                    selected={date}
                    minDate={new Date() - 1}
                    style={{ width: "100%", display: "block" }}
                    onChange={(date) => {
                      date.setHours(24, 0, -1, 0);
                      setDate(date);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Amount Type</label>
                  <select
                    className="form-control"
                    name={"amountType"}
                    ref={register({ required: true })}
                  >
                    <option value="">Please select Amount Type</option>
                    <option value="percentage">Percentage</option>
                    <option value="digits">Digits</option>
                  </select>
                  {errors.amountType && (
                    <span className="isError">Amount Type is required.</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <label>Amount</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Amount"
                  ref={register({ required: true })}
                  name={"amount"}
                />
                {errors.amount && (
                  <span className="isError">Amount is required.</span>
                )}
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Quantity Type</label>
                  <select
                    className="form-control"
                    name={"qtyType"}
                    ref={register({ required: true })}
                  >
                    <option value="">Please select quantity type</option>
                    <option value="real">Real</option>
                    <option value="fake">Fake</option>
                  </select>
                  {errors.qtyType && (
                    <span className="isError">Quantity type is required.</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Quantity</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Quantity"
                    ref={register({ required: true })}
                    name={"quantity"}
                  />
                  {errors.quantity && (
                    <span className="isError">Quantity is required.</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className={"d-block"}>Active</label>
                  <Switch
                    onChange={() => setIsActive(!isActive)}
                    checked={isActive}
                  />
                </div>
              </div>
            </div>

            <div className={"d-flex"}>
              <button
                type="submit"
                className="btn btn-success"
                style={{ marginRight: 15 }}
              >
                Add Coupon Code
              </button>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCoupon);
