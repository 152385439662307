import React from "react";
const BasicInfo = (props) => {
  const { data } = props;
  return (
    <div>
      <h4 className="section_title">Personal Information:</h4>
      <table
        className="table table-hover table-striped form-group"
        style={{ borderTopWidth: 0 }}
      >
        <tbody>
          <tr>
            <th>Name: </th>
            <td>{data.name}</td>
          </tr>
          <tr>
            <th>Email: </th>
            <td>
              <a href={`mailto:${data.email}`}>{data.email}</a>
            </td>
          </tr>
          <tr>
            <th>Mobile: </th>
            <td>
              <a href={`tel:${data.mobile}`}>{data.mobile}</a>
            </td>
          </tr>
          <tr>
            <th>Address: </th>
            <td>
              {data.address1 !== null ? data.address1 : "not available"}{" "}
              {data.address2 !== null ? ", " + data.address2 : ""}
            </td>
          </tr>
          <tr>
            <th>City: </th>
            <td>{data.city !== null ? data.city : "not available"}</td>
          </tr>
          <tr>
            <th>State: </th>
            <td>{data.state !== null ? data.state : "not available"}</td>
          </tr>
          <tr>
            <th>Country: </th>
            <td>{data.country !== null ? data.country : "not available"}</td>
          </tr>
        </tbody>
      </table>
      <h4 className="section_title mt-4">Social Media Information:</h4>
      <table
        className="table table-hover table-striped form-group"
        style={{ borderTopWidth: 0 }}
      >
        <tbody>
          <tr>
            <th className="bg-facebook text-white" width="100">
              <i
                className={"la la-facebook"}
                style={{ position: "relative", top: "3px" }}
              />{" "}
              Facebook:{" "}
            </th>
            <td>
              {data.facebook !== null ? (
                <a href={data.facebook} target="_blank">
                  {data.facebook}
                </a>
              ) : (
                "not available"
              )}
            </td>
          </tr>
          <tr>
            <th className="bg-twitter text-white" width="100">
              <i
                className={"la la-twitter"}
                style={{ position: "relative", top: "3px" }}
              />{" "}
              Twitter:{" "}
            </th>
            <td>
              {data.twitter !== null ? (
                <a href={data.twitter} target="_blank">
                  {data.twitter}
                </a>
              ) : (
                "not available"
              )}
            </td>
          </tr>
          <tr>
            <th className="bg-instagram text-white" width="100">
              <i
                className={"la la-instagram"}
                style={{ position: "relative", top: "3px" }}
              />{" "}
              Instagram:{" "}
            </th>
            <td>
              {data.instagram !== null ? (
                <a href={data.instagram} target="_blank">
                  {data.instagram}
                </a>
              ) : (
                "not available"
              )}
            </td>
          </tr>
          <tr>
            <th className="bg-linkedin text-white" width="100">
              <i
                className={"la la-linkedin"}
                style={{ position: "relative", top: "3px" }}
              />{" "}
              Linkedin:{" "}
            </th>
            <td>
              {data.linkedin !== null ? (
                <a href={data.linkedin} target="_blank">
                  {data.linkedin}
                </a>
              ) : (
                "not available"
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BasicInfo;
