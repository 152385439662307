import React, { useState, useEffect } from "react";
import MainContainer from "../../../common/MainContainer";
import axios from "axios";
import Swal from "sweetalert2";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { itemShow } from "../../../components/itemShow";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Dropzone from "react-dropzone";
import Switch from "react-switch";
import AWS from "aws-sdk";
import { useForm } from "react-hook-form";
import Loader from "../../../common/Loader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const AddCourseContent = (props) => {
  const { id } = props.match.params;
  // states
  const [videoList, setVideoList] = useState([]);
  const [addType, setAddType] = useState(null);
  const [videoUploader, setVideoUploader] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [documentUploader, setDocumentUploader] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(null);
  const [sectionItems, setSectionItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // ! section details
  const [sectionDetails, setSectionDetails] = useState(null);
  const [isLoadItem, setIsLoadItem] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const userToken = props.user.userToken;
  const basurl = process.env.REACT_APP_URL;
  const { register, handleSubmit, watch, errors } = useForm();

  // aws bucket configs
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new" },
    region: "ap-south-1",
  });

  // get video list
  const getVideosList = () => {
    axios
      .get(`${basurl}/video-list`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const videoList = res.data;
        if (videoList.success) {
          setVideoList(videoList.data);
          setAddType("video");
        }
        // console.log("videoList>> ", videoList);
      });
  };
  console.log("section items >>", sectionItems);

  // handle add type
  const handleAddType = (value) => {
    setAddType(value);
  };

  // Video Uploader
  const uploadVideoHandler = (files) => {
    // console.log("video file >> ", files[0]);
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setVideoProgress(percent);
        console.log(
          "progress video upload :: ",
          Math.round((evt.loaded / evt.total) * 100)
        );
      })
      .send((err, data) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Video Uploaded Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        getVideosList();
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          console.log("error >> ", err);
        }
      });
  };

  // get documents list
  const getDocumentsList = () => {
    axios
      .get(`${basurl}/uploadDocs/docs`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const docList = res.data;
        if (docList.success) {
          setDocuments(docList.data);
        }
        // console.log("docs list >> ", docList);
      });
  };

  // Handle Submit
  const onSubmit = (data) => {
    const mainFile = data.file[0].name;
    var ext = mainFile.substr(mainFile.lastIndexOf(".") + 1);
    const formData = new FormData();
    formData.append("name", data.title);
    formData.append("type", ext);
    formData.append("doc", data.file[0]);
    axios.post(`${basurl}/uploadDocs`, formData).then((res) => {
      const uploadResponse = res.data;
      // console.log("response >> ", uploadResponse);
      if (uploadResponse.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "File Uploaded Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        setDocumentUploader(false);
        getDocumentsList();
      }
    });
    // console.log("submit data >> ", ext)
  };
  useEffect(() => {
    getVideosList();
    getDocumentsList();
  }, [id]);

  //* handleSubmitSection
  const handleSubmitSection = () => {
    if (title === "") {
      setTitleError("Title is required.");
    } else {
      setIsLoading(true);
      axios
        .post(
          `${basurl}/course/add-section`,
          {
            title: title,
            course: id,
            status: false,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            setIsLoading(false);
            setSectionDetails(result.data);
            // props.history.push(`/courses/create-step2/${id}`);
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };
  //* handleUpdateSection
  const handleUpdateSection = () => {
    if (title === "") {
      setTitleError("Title is required.");
    } else {
      setIsLoading(true);
      axios
        .put(
          `${basurl}/course/edit-section/${sectionDetails._id}`,
          {
            title: title,
            course: id,
            status: false,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            setIsLoading(false);
            setSectionDetails(result.data);
            props.history.push(`/courses/create-step2/${id}`);
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  //getVideoDuration
  const getVideoDuration = (event, id) => {
    var elSpan = document.getElementById(id);
    const duration = event.currentTarget.duration;
    var min = duration / 60;
    elSpan.innerHTML = min.toFixed(2) + " Min";
  };

  // * * create section item
  const createSectionItem = (name, type, url, isPriview) => {
    setIsLoading(true);
    axios
      .post(
        `${basurl}/course/add-course-item`,
        {
          section: sectionDetails._id,
          course: id,
          name,
          type,
          url,
          isPriview,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setSectionItems([...sectionItems, result.data]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err>>", err);
      });
  };

  // add Video to section item
  const addVideoToSection = (url, index) => {
    Swal.fire({
      title: "Title",
      text: "Please enter title for this video",
      input: "text",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        createSectionItem(result.value, "video", url, false);
      }
    });
  };
  // add document to section item
  const addDocument = (index, items) => {
    const name = items.name;
    const url = process.env.REACT_APP_BASE_URL + items.url;
    createSectionItem(name, items.type, url, false);
  };

  //handlePreviewVideo
  const handlePreviewVideo = (index, value, itemid) => {
    setCurrentItem(index);
    setIsLoadItem(true);
    axios
      .put(
        `${basurl}/course/edit-course-item/${itemid}`,
        {
          isPriview: value === true ? false : true,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          refreshSectionItems();
        }
        setIsLoadItem(false);
      })
      .catch((err) => {
        console.log("err>>", err);
        setIsLoadItem(false);
      });
  };

  // * * * refresh section items
  const refreshSectionItems = () => {
    axios
      .get(`${basurl}/course/get-course-items/${sectionDetails._id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setSectionItems(result.data);
        }
      })
      .catch((err) => {
        console.log("err>>", err);
      });
  };

  //removeItem
  const removeItem = (value) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this section ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoadItem(true);
        axios
          .delete(`${basurl}/course/delete-course-item/${value}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const result = res.data;
            if (result.success) {
              refreshSectionItems();
              Swal.fire({
                position: "center",
                icon: "success",
                title: result.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
            setIsLoadItem(false);
          })
          .catch((err) => {
            console.log("err>>", err);
            setIsLoadItem(false);
          });
      }
    });
  };

  const onDragEndHandle = (result) => {
    console.log("get resulyt for draggable event >> ", result);
    // handle
    if (!result.destination) return;
    const items = Array.from(sectionItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSectionItems(items);
  };

  console.log("all section details>>", sectionDetails);
  return (
    <MainContainer>
      {isLoading && <Loader />}
      <DragDropContext onDragEnd={(result) => onDragEndHandle(result)}>
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-md-6">
                <h4 className={"header-title"}>
                  <Link
                    to={`/courses/create-step2/${id}`}
                    className="backBtn_link"
                  >
                    <i className="la la-angle-left"></i>
                  </Link>
                  Add Course Content
                </h4>
              </div>
              <div className="col-md-6">
                <div className="text-right">
                  {sectionDetails !== null ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleUpdateSection}
                    >
                      Save Section
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSubmitSection}
                    >
                      Add Section
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="form-group">
              <label>Section Title</label>
              <input
                type="text"
                placeholder="Section Title"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {titleError !== null && title === "" && (
                <span className="isError">{titleError}</span>
              )}
            </div>

            {sectionDetails !== null && (
              <>
                <h4 className={"section_title"}>Section Content</h4>
                <Droppable droppableId="sectionItems_wrapper">
                  {(provided) => (
                    <div
                      className="sectionItems_wrapper"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {sectionItems.length > 0 ? (
                        <div>
                          {sectionItems.map((item, index) => (
                            <Draggable
                              key={index}
                              draggableId={"dd" + index}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className={"section_item_row"}
                                  style={{
                                    paddingRight:
                                      item.type === "video" ? "180px" : "10px",
                                  }}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <div className="section_joint">
                                    <span className="row_icon">
                                      {itemShow(item.type)}
                                    </span>
                                    <span className="row_url">{item.name}</span>
                                  </div>
                                  {currentItem === index && isLoadItem && (
                                    <div className="section_loading">
                                      <i className="ri-refresh-line spin"></i>
                                    </div>
                                  )}

                                  {item.type === "video" && (
                                    <div className={"item_preview_wrapper"}>
                                      <label>Preview</label>
                                      <Switch
                                        onChange={() =>
                                          handlePreviewVideo(
                                            index,
                                            item.isPriview,
                                            item._id
                                          )
                                        }
                                        onColor={"#FF394F"}
                                        checked={item.isPriview}
                                      />
                                    </div>
                                  )}
                                  <span
                                    className={"section_remove"}
                                    onClick={() => removeItem(item._id)}
                                  >
                                    <i className={"ri-delete-bin-line"}></i>
                                  </span>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      ) : (
                        <div className="text-center">
                          <span className="text-muted">
                            No content added here. Please add or upload content
                            from gallery{" "}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </Droppable>
                <h4 className={"section_title"}>
                  <i className="ri-add-box-line"></i> Content add from gallery
                  (Video/Document/Quiz)
                </h4>
                <div className={"form-group"}>
                  <div className={"add_options_wrapper"}>
                    <div className="row form-group">
                      <div className="col-md-4">
                        <button
                          type="button"
                          className={`btn btn-default btn-block ${
                            addType === "video" ? "active" : null
                          }`}
                          onClick={() => handleAddType("video")}
                        >
                          <i className={"la la-file-video-o"}></i> Add Video
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          className={`btn btn-default btn-block ${
                            addType === "document" ? "active" : null
                          }`}
                          onClick={() => handleAddType("document")}
                        >
                          <i className={"la la-file-pdf-o"}></i> Add Document
                        </button>
                      </div>
                      <div className="col-md-4">
                        {/* <button
                      type="button"
                      className={`btn btn-default btn-block ${
                        addType === "quiz" ? "active" : null
                      }`}
                      onClick={() => handleAddType("quiz")}
                    >
                      <i className={"la la-question-circle"}></i> Add Quiz
                    </button> */}
                      </div>
                    </div>
                    {addType === "video" && (
                      <div className="video_list_wrapper">
                        <div className="card">
                          <div
                            className="card-header bg-primary"
                            style={{ padding: "10px 15px" }}
                          >
                            <div className="row">
                              <div className="col-md-8">
                                <h5
                                  style={{
                                    margin: 0,
                                    color: "#ffffff",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  Select From Video Gallery
                                </h5>
                              </div>
                              <div className="col-md-4 text-right">
                                <button
                                  type={"button"}
                                  className={"btn btn-success "}
                                  onClick={() =>
                                    setVideoUploader(!videoUploader)
                                  }
                                >
                                  <i className={"ri-upload-line"}></i>
                                  <span
                                    style={{
                                      position: "relative",
                                      top: "-2px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    Upload Video
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            {videoProgress > 0 && videoProgress < 99 ? (
                              <div
                                className={"progress_wrapper"}
                                style={{ marginBottom: "10px" }}
                              >
                                <div className="progress_wrap">
                                  <CircularProgressbar
                                    value={videoProgress}
                                    strokeWidth={50}
                                    styles={buildStyles({
                                      strokeLinecap: "butt",
                                      pathColor: "#33b3f2",
                                    })}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {videoUploader && (
                              <div className={"section_video_uploader"}>
                                <Dropzone
                                  onDrop={(acceptedFiles) =>
                                    uploadVideoHandler(acceptedFiles)
                                  }
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section className={"dropzone_wrapper"}>
                                      <div
                                        {...getRootProps()}
                                        className={"dropZone"}
                                      >
                                        <input {...getInputProps()} />
                                        <p>Drag or Browse Video File</p>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                            )}
                            <div className="table-responsive">
                              {videoList.Contents.length > 0 ? (
                                <table
                                  className={
                                    "table table-hover table-bordered table-hover-"
                                  }
                                >
                                  <thead>
                                    <tr>
                                      <th>S. No.</th>
                                      <th>Video Name</th>
                                      <th>Preview</th>
                                      <th>Duration</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {videoList.Contents.reverse().map(
                                      (video, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                              <ReactPlayer
                                                controls={true}
                                                width={100}
                                                height={70}
                                                url={`https://${videoList.Name}.s3.ap-south-1.amazonaws.com/${video.Key}`}
                                              />
                                            </td>
                                            <td>{video.Key}</td>
                                            <td>
                                              <video
                                                width={0}
                                                height={0}
                                                controls
                                                onLoadedMetadata={(event) =>
                                                  getVideoDuration(
                                                    event,
                                                    "video" + index
                                                  )
                                                }
                                              >
                                                <source
                                                  src={`https://${videoList.Name}.s3.ap-south-1.amazonaws.com/${video.Key}`}
                                                  type="video/mp4"
                                                />
                                              </video>
                                              <span id={"video" + index}></span>
                                            </td>
                                            <td>
                                              <button
                                                onClick={() =>
                                                  addVideoToSection(
                                                    `https://${videoList.Name}.s3.ap-south-1.amazonaws.com/${video.Key}`,
                                                    index
                                                  )
                                                }
                                                className={"btn  btn-success"}
                                              >
                                                <i
                                                  className={"ri-add-box-line"}
                                                ></i>
                                              </button>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              ) : (
                                <span className="alert alert-info">
                                  No video found!
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {addType === "document" && (
                      <div className={""}>
                        <div className="card">
                          <div
                            className="card-header bg-primary"
                            style={{ padding: "10px 15px" }}
                          >
                            <div className="row">
                              <div className="col-md-8">
                                <h5
                                  style={{
                                    margin: 0,
                                    color: "#ffffff",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  Select From Doc Library
                                </h5>
                              </div>
                              <div className="col-md-4 text-right">
                                <button
                                  type={"button"}
                                  className={"btn btn-default "}
                                  onClick={() =>
                                    setDocumentUploader(!documentUploader)
                                  }
                                >
                                  <i className={"ri-upload-line"}></i>
                                  <span
                                    style={{
                                      position: "relative",
                                      top: "-2px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    Upload Document
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            {documentUploader && (
                              <div className={"document-uploader-wrapper"}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="form-group">
                                    <label>Title</label>
                                    <input
                                      type="text"
                                      className={"form-control"}
                                      placeholder={"Title"}
                                      ref={register({ required: true })}
                                      name={"title"}
                                    />
                                    {errors.title && (
                                      <span className="isError">
                                        Title is required
                                      </span>
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <label>Browse File</label>
                                    <input
                                      type="file"
                                      className={"form-control"}
                                      ref={register({ required: true })}
                                      name={"file"}
                                    />
                                    {errors.title && (
                                      <span className="isError">
                                        File is required
                                      </span>
                                    )}
                                  </div>

                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    <i className={"ri-upload-line"}></i> Upload
                                  </button>
                                </form>
                              </div>
                            )}
                            {documents.length > 0 && (
                              <div className="table-responsive">
                                <table
                                  className={"table table-bordered table-hover"}
                                >
                                  <thead>
                                    <tr>
                                      <th>S. No.</th>
                                      <th>Title</th>
                                      <th>Type</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {documents.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{itemShow(item.type)}</td>
                                        <td>
                                          <button
                                            onClick={() =>
                                              addDocument(index, item)
                                            }
                                            className={"btn  btn-success"}
                                          >
                                            <i
                                              className={"ri-add-box-line"}
                                            ></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </DragDropContext>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(AddCourseContent);
