import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import moment from "moment";
import { Table, DatePicker, Select } from "antd";
import "antd/dist/antd.css";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ExportCSV } from "../../components/ExportCSV";
import Loader from "../../common/Loader";
import { userLogout } from "../../redux/actions/UserActions";
import Swal from "sweetalert2";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const Index = (props) => {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";
  const monthFormat = "YYYY/MM";
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const [students, setStudents] = useState([]);
  const [list, setList] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [exportData, setExportData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [permissions, setPermissions] = useState(null);
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [facultyId, setFacultyId] = useState(null);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [permissionType, setPermissionType] = useState(null);
  const role = useSelector((state) => state.user?.user?.role);
  const { Option } = Select;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // toggle modal
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };
  // toggle permission modal
  const togglePermissionModal = () => {
    setPermissionModal(!permissionModal);
  };

  // get user list
  const getUsersList = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/users/admin-faculty-listing`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const list = res.data;
        if (list.success) {
          const index = list.data.findIndex(
            (item) => item.email === "help@lmsplus.in"
          );
          list.data.splice(index, 1);
          setStudents(list.data);
          const newArr = list.data.map((item, index) => ({
            "S. No": index + 1,
            "User's Name": item.name,
            "Email Address": item.email,
            "Mobile No.": item.mobile,
            Role: item.role,
            Status: item.isActive ? "Confirm" : "Pending",
            "Role Assign Date": moment(item.date).format("DD-MM-YYYY"),
          }));
          setExportData(newArr);
        } else {
          setError("There are no users");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  // get all courses
  const getCourses = () => {
    axios
      .get(`${basurl}/course/front-all`)
      .then((res) => {
        const courseList = res.data;
        if (courseList.success) {
          setCourses(courseList.data.reverse());
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getUsersList();
    getCourses();
  }, []);

  console.log("facultyId>>>>>>>>>>>>", facultyId);

  // create role permissions
  const createRolePermissions = () => {
    const courseArr = selectedCourses.map((item) => item._id);
    axios
      .post(
        `${basurl}/users/create-role-permission`,
        {
          user: facultyId,
          role: "Faculty",
          courses: courseArr,
          quizzes: [],
          mocks: [],
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Permission given successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setPermissions(null);
          setSelectedCourses([]);
          setPermissionType(null);
          setPermissionModal(false);
          setFacultyId(null);
          getUsersList();
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // update role permissions
  const updateRolePermissions = () => {
    const courseArr = selectedCourses.map((item) => item._id);
    axios
      .put(
        `${basurl}/users/update-permission/${permissions._id}`,
        {
          user: facultyId,
          role: "Faculty",
          courses: courseArr,
          quizzes: [],
          mocks: [],
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Permission updated successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          setPermissions(null);
          setSelectedCourses([]);
          setPermissionType(null);
          setPermissionModal(false);
          setFacultyId(null);
          getUsersList();
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //handlePermission
  const handlePermission = (record, type) => {
    console.log("asasdasd", record, type);
    setPermissions(null);
    setSelectedCourses([]);
    setPermissionType(null);
    setFacultyId(null);
    if (record.permission !== null) {
      setPermissions(record.permission);
      setSelectedCourses(record.permission.courses);
    }
    setPermissionType(type);
    setFacultyId(record._id);
    setPermissionModal(true);
  };

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // Columns
  const columns = [
    {
      title: "S. No.",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Full Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, index) => <span>{record.name}</span>,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text, record, index) => <span>{record.email}</span>,
    },
    {
      title: "Mobile No.",
      render: (text, record, index) => (
        <span>{record.mobile ? record.mobile : "NA"}</span>
      ),
    },
    {
      title: "Role",
      render: (text, record, index) => <span>{record.role}</span>,
    },
    {
      title: "Permission",
      render: (text, record, index) => (
        <span>
          {record.role === "Admin" ? (
            "Full Access"
          ) : (
            <>
              {record.permission === null ? (
                <span
                  className="permission_chip"
                  onClick={() => handlePermission(record, "post")}
                >
                  <span className="permission_chip_text">Give Permission</span>
                </span>
              ) : (
                <>
                  <span
                    className="permission_chip"
                    onClick={() => handlePermission(record, "put")}
                  >
                    <span className="permission_chip_text">
                      {record.permission.courses.length} Courses
                    </span>
                    <span>
                      <i className="ri-pencil-line"></i>
                    </span>
                  </span>
                </>
              )}
            </>
          )}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      sorter: (a, b) => a.isActive - b.isActive,
      render: (text, record, index) => (
        <span>{record.isActive ? "Confirm" : "Pending"}</span>
      ),
    },
    {
      title: "Role Assign Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      render: (text, record, index) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {moment(record.date).format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      title: "Action",
      // dataIndex: "date",
      // sorter: (a, b) => a.date.localeCompare(b.date),
      render: (text, record, index) => (
        <>
          {record.email !== "help@lmsplus.in" && (
            <>
              {record._id === props.user.user?.id ? (
                <button
                  className="btn btn-danger"
                  style={{ opacity: 0.7 }}
                  onClick={() => {
                    Swal.fire({
                      title: "You can't delete this user",
                      text: "You can't delete your own account",
                      icon: "warning",
                      showCancelButton: false,
                    });
                  }}
                >
                  <i className="ri-delete-bin-line"></i>
                </button>
              ) : (
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(record._id)}
                >
                  <i className="ri-delete-bin-line"></i>
                </button>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    // console.log("params", sorter);
    setPagination({
      pageSize: pagination.pageSize,
    });
  }
  // console.log("pagination, ", pagination);

  // for search filter
  const filteredData = students.filter((val) => {
    if (searchInput === "") {
      return val;
    } else if (
      val.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      val.email.toLowerCase().includes(searchInput.toLowerCase())
    ) {
      return val;
    }
  });

  // handleRange
  const handleRange = (date) => {
    if (date !== null) {
      let date1 = date[0].toDate();
      let date2 = date[1].toDate();
      const newArr = students.filter((item) => {
        return (
          moment(item.date).toDate() <= date2 &&
          moment(item.date).toDate() >= date1
        );
      });
      setStudents(newArr);
    } else {
      setStudents(list);
    }

    // console.log("get date from range >>>", newArr)
  };

  // handle data submit
  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post(
        `${basurl}/users/register-faculty`,
        {
          name: data.name,
          email: data.email,
          mobile: data.mobile,
          role: data.role,
          password: data.password,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "User added successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsOpenModal(false);
          getUsersList();
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "User already exists",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        Swal.fire({
          position: "center",
          icon: "warning",
          title: err.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
        // console.log("error", err.response.data.message);
      });
  };

  // handle delete
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        axios
          .delete(`${basurl}/users/delete-user/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            console.log(res);
            if (res.data.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "User deleted successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              getUsersList();
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Something went wrong",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            if (err.response.data === "Invalid token") {
              props.logoutUser();
              localStorage.removeItem("lmsUserInfo");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your token has been expired!, Please login again",
                showConfirmButton: false,
                timer: 1500,
              });
              props.history.push("/");
            }
          });
      }
    });
  };

  // *  onChangeCourse
  const onChangeCourse = (value) => {
    console.log("value", value);
    const res = courses.filter((item) => value.includes(item._id));
    const newArr = res.map((re) => ({
      _id: re._id,
      title: re.title,
    }));
    setSelectedCourses(newArr);
  };

  console.log("permissions=========", selectedCourses);

  if (role !== "Admin") {
    return <Redirect to="/" />;
  }
  return (
    <MainContainer>
      {isLoading && <Loader />}

      {/* Create custom role modal */}
      <Modal isOpen={isOpenModal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add Faculty or Admin</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label>Full Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Full Name"
                ref={register({ required: true })}
                name={"name"}
              />
              {errors.name && (
                <div className="text-danger">Full Name is required</div>
              )}
            </div>
            <div className="mb-3">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email Address"
                ref={register({ required: true })}
                name={"email"}
              />
              {errors.email && (
                <div className="text-danger">Email is required</div>
              )}
            </div>
            <div className="mb-3">
              <label>Role</label>
              <select
                type="password"
                className="form-control"
                ref={register({ required: true })}
                name={"role"}
              >
                <option value="">Select Role</option>
                <option value="Admin">Admin</option>
                <option value="Faculty">Faculty</option>
              </select>
              {errors.role && (
                <div className="text-danger">Role is required</div>
              )}
            </div>
            <div className="mb-3">
              <label>Mobile No.</label>
              <input
                type="text"
                className="form-control"
                placeholder="Mobile No."
                ref={register({ required: true })}
                name={"mobile"}
                maxLength={10}
              />
              {errors.mobile && (
                <div className="text-danger">Mobile is required</div>
              )}
            </div>
            <div className="mb-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                ref={register({
                  required: "You must specify a password",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                  pattern: {
                    value:
                      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}/,
                    message:
                      "Please use atleast 1 special character, and 1 capital letter in the password.",
                  },
                })}
                name={"password"}
              />
              {errors.password && (
                <div className="text-danger">{errors.password.message}</div>
              )}
            </div>
            <hr />
            {isLoading ? (
              <button
                type="button"
                className="btn btn-primary"
                style={{ opacity: 0.8 }}
              >
                Loading...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            )}
          </form>
        </ModalBody>
      </Modal>
      {/* End Create custom role modal */}
      {/* Create custom role modal */}
      <Modal isOpen={permissionModal} toggle={togglePermissionModal} size="lg">
        <ModalHeader toggle={togglePermissionModal}>
          Give permission
        </ModalHeader>
        <ModalBody>
          {courses.length > 0 && (
            <div className="mb-3">
              <label>Select Courses </label>
              <Select
                showSearch
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select courses"
                onChange={onChangeCourse}
                className="w-full"
                defaultValue={selectedCourses.map((crs) => crs?._id)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {courses.map((item, index) => (
                  <Option value={item._id} key={item._id}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() =>
              permissionType === "post"
                ? createRolePermissions()
                : updateRolePermissions()
            }
          >
            Save
          </button>
        </ModalFooter>
      </Modal>
      {/* End Create custom role modal */}
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Roles
            </h4>
            <button
              onClick={() => setIsOpenModal(true)}
              className="btn btn-primary"
            >
              Add Faculty / Admin
            </button>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className={"filters mb-2"}>
                <div className={"row"}>
                  <div className={"col-lg-4"}>
                    <div className={"search_filter"}>
                      <input
                        type="text"
                        className={"form-control"}
                        placeholder={"Search by name..."}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                      {searchInput !== "" && (
                        <span
                          className={"clear_search_text"}
                          onClick={() => setSearchInput("")}
                        >
                          <i className={"la la-times"}></i>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className={"col-lg-4"}>
                    <RangePicker
                      format={dateFormat}
                      className={"form-control custom_range_picker"}
                      onChange={handleRange}
                    />
                  </div>
                  <div className={"col-lg-4"}>
                    <div className={"row"}>
                      <div className={"col-sm-6"}>
                        <select
                          className={"form-control"}
                          onChange={(e) =>
                            setPagination({
                              pageSize: e.target.value,
                            })
                          }
                        >
                          <option value={10}>10 Rows</option>
                          <option value={20}>20 Rows</option>
                          <option value={50}>50 Rows</option>
                          <option value={100}>100 Rows</option>
                        </select>
                      </div>
                      <div className={"col-sm-6"}>
                        <ExportCSV csvData={exportData} fileName={"Roles"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Table
                columns={columns}
                dataSource={filteredData}
                bordered={true}
                rowKey={(item) => item._id}
                loading={isLoading}
                pagination={pagination}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
