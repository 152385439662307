import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Input } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Create = (props) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [time, setTime] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(0);

  const basurl = process.env.REACT_APP_URL;
  const user = useSelector((state) => state.user);
  const userToken = user.userToken;

  const slugify = (text) => {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    const to = "aaaaaeeeeeiiiiooooouuuunc------";

    const newText = text
      .split("")
      .map((letter, i) =>
        letter.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
      );

    return newText
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/&/g, "-y-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-"); // Replace multiple - with single -
  };

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // handleSubmit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (title === "") {
      Swal.fire({
        title: "Oops!",
        text: "Please enter a title",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      axios
        .post(
          `${basurl}/quiz-new/create`,
          {
            title,
            slug: slugify(title),
            category: selectedCategory,
            description: content,
            time,
            index,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((result) => {
          const res = result.data;
          if (res.success) {
            Swal.fire({
              title: "Success!",
              text: "Quiz created successfully",
              icon: "success",
              // confirmButtonText: "Ok",
            });
            props.history.push(`/quiz/edit/${res.data._id}`);
          }
        });
    }
  };

  // get all categories
  const getCategories = () => {
    axios
      .get(`${basurl}/quiz-new/view-categories`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((result) => {
        const res = result.data;
        if (res.success) {
          setCategories(res.data);
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.userLogout();
        }
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <MainContainer>
      <div className="cards">
        <div className="card-headers mb-3">
          <div className="row">
            <div className="col-sm-6">
              <div className="">
                <h4 className="mt-2" style={{ marginRight: 30 }}>
                  Create New Quiz
                </h4>
                <Link className="sm-header-title" to={`/quiz`}>
                  <i className="ri-arrow-left-line" />{" "}
                  <span style={{ position: "relative", left: 5 }}>
                    Back to quiz
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="card-bodys">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className={"row"}>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>Title</label>
                      <Input
                        type="text"
                        name="title"
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>Time</label>
                      <Input
                        type="number"
                        name="time"
                        placeholder="0:00 in minutes"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>Category</label>
                      <select
                        className="form-control"
                        onChange={(e) => setSelectedCategory(e.target.value)}
                      >
                        <option>Select Category</option>
                        {categories.map((category) => (
                          <option key={category._id} value={category._id}>
                            {category.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>Index</label>
                      <Input
                        type="number"
                        name="index"
                        placeholder="index"
                        value={index}
                        onChange={(e) => setIndex(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label>Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={content}
                    style={{ minHeight: "130px" }}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setContent(data);
                      // console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
export default Create;
