import React from "react";
import LogoImg from "../assets/images/logo-dark.png";

const Loader = () => {
  return (
    <div className={"main_loader_container"}>
      <div className="loadingio-spinner-spinner-bql8n33rdvh">
        <div className="ldio-hpfsdhpdhyw">
          <img src={LogoImg} alt="Logo" className="loader_logo" />
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <span className={"loading_title"}>Loading...</span>
    </div>
  );
};

export default Loader;
