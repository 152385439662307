import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import MainContainer from "../../common/MainContainer";
import { connect } from "react-redux";
import { userLogout } from "../../redux/actions/UserActions";
import Loader from "../../common/Loader";
import Swal from "sweetalert2";
import BundleCard from "./components/BundleCard";

const BundleList = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const basurl = process.env.REACT_APP_URL;

  const userToken = props.user.userToken;
  const role = props.user?.user?.role;

  //   get bundle list
  const getBundList = () => {
    axios
      .get(`${basurl}/bundle/list-all`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data);
        }
        // console.log("all bundles", result);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        console.log(err);
      });
  };

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  useEffect(() => {
    getBundList();
  }, []);

  // !handlePuilish
  const handlePuilish = (id, value) => {
    setIsLoading(true);
    axios
      .put(
        `${basurl}/bundle/update/${id}`,
        {
          isPublished: value === true ? false : true,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your bundle updated successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          getBundList();
        }
        console.log("updated value >>", result);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);

        setIsLoading(false);
      });
  };

  // !handleDelete
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this bundle?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        axios
          .delete(`${basurl}/bundle/delete/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const result = res.data;
            if (result.success) {
              getBundList();
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Bundle deleted successfully",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Bundles
            </h4>
            <Link to="/bundles/add" className="btn btn-primary">
              Add Bundle
            </Link>
          </div>
        </div>
      </div>
      <div className={"card mt-4"}>
        <div className="card-body">
          {list.length > 0 ? (
            <div className="row">
              {list.map((item, index) => (
                <div className="col-lg-4" key={index}>
                  <BundleCard
                    data={item}
                    handlePuilish={handlePuilish}
                    handleDelete={handleDelete}
                  />
                </div>
              ))}
            </div>
          ) : (
            <>
              {!isLoading && (
                <div className="alert alert-secondary">bo bundle found</div>
              )}
            </>
          )}
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BundleList);
