import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import axios from "axios";
import Pagination from "react-js-pagination";
import moment from "moment";
import { Table } from "antd";
import "antd/dist/antd.css";
import discountImg from "../../assets/discount.png";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Loader from "./../../common/Loader";

const Index = (props) => {
  const [list, setList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [quizTitle, setQuizTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });

  const basurl = process.env.REACT_APP_URL;
  const user = useSelector((state) => state.user);
  const userToken = user.userToken;
  const role = user?.user?.role;

  const onChange = (pagination, filters, sorter, extra) => {
    setPagination({
      pageSize: pagination.pageSize,
    });
  };

  // get quiz list
  const getQuizList = () => {
    axios
      .get(`${basurl}/quiz-new/get-all`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          // data filtered by index
          var filteredData =
            result.data.length > 0
              ? result.data.sort((a, b) => a.index - b.index)
              : [];
          setList(filteredData);
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        Swal.fire({
          position: "center",
          icon: "error",
          title: err.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  useEffect(() => {
    getQuizList();
  }, []);

  // toggle modal
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  // handle delete quiz
  const handleDeleteQuiz = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${basurl}/quiz-new/delete/${id}`, {
            headers: {
              "auth-token": user.userToken,
            },
          })
          .then((res) => {
            if (res.data.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              getQuizList();
            }
          });
      }
    });
  };

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // Columns
  const columns = [
    {
      title: "S. No.",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record, index) => <span>{record.title}</span>,
    },
    {
      title: "Category",
      sorter: (a, b) => a.category.title.localeCompare(b.category.title),
      render: (text, record, index) => (
        <span>{record.category ? record.category.title : "NA"}</span>
      ),
    },
    {
      title: "Index",
      render: (text, record, index) => <span>{record.index}</span>,
    },
    {
      title: "Questions",
      render: (text, record, index) => <span>{record.questions.length}</span>,
    },
    {
      title: "Time",
      render: (text, record, index) => <span>{record.time} minutes</span>,
    },
    {
      title: "Published Date",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record, index) => (
        <span>{moment(record.createdAt).format("MMM DD, YYYY")}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (text, record, index) => (
        <>
          {record.isActive ? (
            <span className="text-success">Active</span>
          ) : (
            <span className="text-danger">Archived</span>
          )}
        </>
      ),
    },
    {
      title: "Action",
      render: (text, record, index) => (
        <div className="d-flex">
          {record.isActive && (
            <Link
              to={`/quiz/edit/${record._id}`}
              className="btn btn-success btn-sm"
              style={{ marginLeft: 10 }}
            >
              <i className="mdi mdi-pencil"></i>
            </Link>
          )}
          {role === "Admin" && (
            <button
            className="btn btn-danger btn-sm"
            style={{ marginLeft: 10 }}
            onClick={() => handleDeleteQuiz(record._id)}
          >
            <i className="mdi mdi-delete"></i>
          </button>
          )}
          

          {/* {record.isActive ? (
            <button
              className="btn btn-danger btn-sm"
              style={{ marginLeft: 10 }}
              onClick={() =>
                handleToggleUserStatus(record._id, record.isActive)
              }
            >
              <i className="mdi mdi-trash-can"></i>
            </button>
          ) : (
            <button
              className="btn btn-success btn-sm"
              style={{ marginLeft: 10 }}
              onClick={() =>
                handleToggleUserStatus(record._id, record.isActive)
              }
            >
              <i className="mdi mdi-check"></i>
            </button>
          )} */}
        </div>
      ),
    },
  ];
  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="cards">
        <div className="card-headers mb-3">
          <div className="row">
            <div className="col-sm-6">
              <div className="d-flex align-items-center">
                <h4 className="mt-2" style={{ marginRight: 30 }}>
                  Quiz
                </h4>
                <Link to="/quiz/create" className="btn btn-primary">
                  <i className="fas fa-plus"></i> Create new quiz
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="card-bodys">
          <Table
            columns={columns}
            dataSource={list}
            bordered={true}
            rowKey={(item) => item._id}
            loading={isLoading}
            pagination={pagination}
            onChange={onChange}
          />
        </div>
      </div>
    </MainContainer>
  );
};
export default Index;
