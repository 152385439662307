import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { DatePicker, Space, Switch } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";
import Swal from "sweetalert2";
import discountImg from "../../assets/discount.png";
import BuildCourse from "./BuildCourse";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CreateCourse = (props) => {
  const { id } = useParams();
  const { register, handleSubmit, errors, setValue } = useForm();
  const [details, setDetails] = useState(null);
  const [categories, setCategories] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [featuredImages, setFeaturedImages] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [builder, setBuilder] = useState(false);
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState(" ");
  const [enrollment, setEnrollment] = useState(true);
  const [faculty, setFaculty] = useState([]);
  const basurl = process.env.REACT_APP_URL;
  const user = useSelector((state) => state.user);
  const userToken = user.userToken;
  const role = user?.user?.role;
  // console.log("user enrollment>>>>", sections);

  const onChangeSwitch = (checked) => {
    setEnrollment(checked);
  };

  // AWS configurations
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new/courses" },
    region: "ap-south-1",
  });

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // upload image to aws bucket
  const uploadVideoHandler = (files) => {
    // console.log("video file >> ", files[0]);
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setUploadProgress(percent);
        // console.log(
        //   "progress video upload :: ",
        //   Math.round((evt.loaded / evt.total) * 100)
        // );
      })
      .on("success", (evt) => {
        console.log("mujhe khusi hogi >>>", evt.request.params);
        setFeaturedImages(
          `https://lms-videos-bucket-prepzone-new.s3.ap-south-1.amazonaws.com/${evt.request.params.Key}`
        );
        setUploadProgress(0);
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // console.log("error >> ", err);
        } else {
          // console.log("aljkskdasd", data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  // get course Details
  const courseDetails = () => {
    axios
      .get(`${basurl}/course/view/${id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        setDetails(result.data);
        setValue("title", result.data.title);
        setDescription(result.data.description || " ");
        // setValue("description", result.data.description);
        setValue("category", result.data.category._id);
        setValue("price", result.data.price);
        setValue("instructor", result.data.instructor);
        setExpiryDate(moment(result.data.course_validity));
        setValue("slug", result.data.course_url);
        setFeaturedImages(result.data.course_image);
        setEnrollment(result.data.enrollment);
        // console.log(moment(result.data.course_validity));
        const list = result.data.course_sections;
        let newArray = list.map((item, index) => {
          item.isOpened = false;
          return { ...item };
        });
        setSections(newArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get course categories
  const getCourseCategories = () => {
    axios.get(`${basurl}/coursecat`).then((res) => {
      const list = res.data;
      if (list.success) {
        setCategories(list.data);
      }
    });
  };

  // get all faculty
  const getFaculty = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/users/admin-faculty-listing`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const list = res.data;
        if (list.success) {
          const index = list.data.findIndex(
            (item) => item.email === "help@lmsplus.in"
          );
          list.data.splice(index, 1);
          setFaculty(list.data);
        } else {
          alert("There are no users");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  useEffect(() => {
    getCourseCategories();
    getFaculty();
    courseDetails();
  }, [id]);

  const slugify = (text) => {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    const to = "aaaaaeeeeeiiiiooooouuuunc------";

    const newText = text
      .split("")
      .map((letter, i) =>
        letter.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
      );

    return newText
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/&/g, "-y-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-"); // Replace multiple - with single -
  };

  // onSubmit
  const onSubmit = (data) => {
    console.log("all data", data);
    setIsLoading(true);
    const url = data.slug.split(" ").join("-");
    axios
      .put(
        `${basurl}/course/edit/${id}`,
        {
          title: data.title,
          course_url:
            data.slug === "" ? slugify(data.title) : slugify(data.slug),
          description: description,
          course_image: featuredImages,
          category: data.category,
          course_validity: expiryDate,
          price: data.price,
          enrollment: enrollment,
          instructor: data.instructor,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Course Updated Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  // handlePublish
  const handlePublish = (courseid, value) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${value ? "Achive" : "Publish"} this course ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#000099",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${value ? "Achive" : "Publish"} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        axios
          .put(
            `${basurl}/course/publish/${id}`,
            {
              isPublished: value === true ? false : true,
            },
            {
              headers: {
                "auth-token": userToken,
              },
            }
          )
          .then((res) => {
            const result = res.data;
            if (result.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Course status updated successfully!",
                showConfirmButton: false,
                timer: 1500,
              });
              setIsLoading(false);
              document.body.style.overflow = "auto";
              props.history.push("/courses");
            } else {
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      }
    });
  };

  // OnChange function for date DatePicker
  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setExpiryDate(date);
  };

  // handleSectionOpen
  const handleSectionOpen = (ind) => {
    let arr = sections.map((item, index) => {
      if (ind == index) {
        item.isOpened = !item.isOpened;
      } else {
        item.isOpened = false;
      }
      return { ...item };
    });
    setSections(arr);
  };

  // section delete handle
  const handleSectionDelete = (sid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this section ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#000099",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        axios
          .delete(`${basurl}/course/delete-section/${sid}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const deleteStatus = res.data;
            if (deleteStatus.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: deleteStatus.message,
                showConfirmButton: false,
                timer: 2500,
              });
              courseDetails();
              setIsLoading(false);
            } else {
              setIsLoading(false);
              Swal.fire({
                position: "center",
                icon: "warning",
                title: deleteStatus.message,
                showConfirmButton: false,
                timer: 2500,
              });
            }
            // console.log("delete status > ", deleteStatus);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("err>> ", err);
          });
      }
    });
  };

  //reloadDetails
  const reloadDetails = (val) => {
    if (val) {
      setIsLoading(true);
      courseDetails();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (builder) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [builder]);

  // add course item to section array
  const addCourseItemToSection = (sectionItem) => {
    // console.log("addCourseItemToSection", sectionItem);
    let arr = sections.map((item) => {
      if (item._id == sectionItem.section) {
        item.section_item.push(sectionItem);
      }
      return { ...item };
    });
    setSections(arr);
  };

  // delete course item from section array
  const deleteCourseItemFromSection = (sectionItem) => {
    // console.log("deleteCourseItemFromSection", sectionItem);
    let arr = sections.map((item) => {
      if (item._id == sectionItem.section) {
        item.section_item = item.section_item.filter(
          (item) => item._id != sectionItem._id
        );
      }
      return { ...item };
    });
    setSections(arr);
  }; // end of delete course item from section array

  // onDragEndHandle
  const onDragEndHandle = (result) => {
    console.log("onDragEndHandle", result);
    if (!result.destination) return;
    const items = Array.from(sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSections(items);
  }; // end of onDragEndHandle

  return (
    <MainContainer>
      <h4>{details?.title}</h4>
      <Link to="/courses" className="sm-header-title">
        <i className="ri-arrow-left-line"></i>{" "}
        <span style={{ position: "relative", left: 5 }}>Back to courses</span>
      </Link>
      <div className="card mt-3">
        <div className="card-body course_form_wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-8">
                <h5 className="section_title_page">Basic Information</h5>
                <div className="mb-2">
                  <span>Course title</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter course title"
                    ref={register({ required: true })}
                    name={"title"}
                  />
                  {errors.title && (
                    <span className="isError">Title is required</span>
                  )}
                </div>
                <div className="mb-2">
                  <span>Course url(slug)</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter course url"
                    ref={register({ required: false })}
                    name={"slug"}
                  />
                </div>
                <div className="mb-2">
                  <span>Course category</span>
                  <select
                    className="form-control"
                    ref={register({ required: true })}
                    name={"category"}
                  >
                    <option value="">Select category</option>
                    {categories.map((cat) => (
                      <option key={cat._id} value={cat._id}>
                        {cat.title}
                      </option>
                    ))}
                  </select>
                  {errors.category && (
                    <span className="isError">Course category is required</span>
                  )}
                </div>
                <div className="mb-2">
                  <span>Course description</span>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={{
                      allowedContent: true,
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                      ],
                    }}
                    onReady={(editor) => {
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="mb-2">
                  {featuredImages ? (
                    <div className="course_image_uploader">
                      {role === "Admin" && (
                        <span
                          className="close_icon"
                          onClick={() => setFeaturedImages(null)}
                        >
                          <i className="ri-close-line"></i>
                        </span>
                      )}

                      <img
                        src={featuredImages}
                        alt={"feature image"}
                        className="img-fluid"
                      />
                    </div>
                  ) : (
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        uploadVideoHandler(acceptedFiles)
                      }
                      accept={"image/*"}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="course_image_uploader">
                          {uploadProgress > 1 && (
                            <div className="upload_progress">
                              <div
                                className="inner"
                                style={{
                                  width: `${uploadProgress}%`,
                                  height: "100%",
                                  backgroundColor: "#4CAF50",
                                  transition: "all 0.4s ease-in-out",
                                  borderRadius: "20px",
                                }}
                              ></div>
                            </div>
                          )}

                          <div {...getRootProps()} className={"dropZone"}>
                            <input {...getInputProps()} />
                            {/* <i className="ri-image-line uploader_icon"></i> */}
                            <span className="close_icon">
                              <i className="ri-pencil-fill"></i>
                            </span>
                            <img
                              src={require("../../assets/coursepic.png")}
                              alt="course demo image"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  <span className="d-block">Course Image</span>
                </div>
                <div className="mb-2" style={{ marginTop: "2rem" }}>
                  <span>Validity</span>
                  <Space direction="vertical">
                    {details?.course_validity === null ? (
                      <DatePicker
                        onChange={onChange}
                        className="form-control"
                        // defaultValue={moment()}
                        style={{ borderRadius: 10, display: "block" }}
                        format="DD-MM-YYYY"
                      />
                    ) : (
                      <>
                        {expiryDate && (
                          <DatePicker
                            onChange={onChange}
                            className="form-control"
                            defaultValue={expiryDate}
                            style={{ borderRadius: 10, display: "block" }}
                            format="DD-MM-YYYY"
                          />
                        )}
                      </>
                    )}
                  </Space>
                </div>
                <div className="mb-2 mt-4">
                  <span>Price</span>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter course price"
                    ref={register({ required: true, valueAsNumber: true })}
                    name={"price"}
                  />
                  {errors.price && (
                    <span className="isError">Price is required</span>
                  )}
                </div>
                {faculty.length > 0 && (
                  <div className="mb-4 mt-4">
                    <span>Instructor</span>
                    <select
                      className="form-control"
                      ref={register({ required: true })}
                      name={"instructor"}
                    >
                      <option value={""}>Please select instructor</option>
                      {faculty.map((item, index) => (
                        <option
                          value={item._id}
                          key={`instructor-${index * 3}`}
                        >
                          {item.name} ({item.email})
                        </option>
                      ))}
                    </select>

                    {errors.instructor && (
                      <span className="isError">Instructor is required</span>
                    )}
                  </div>
                )}

                <div className="mb-2">
                  <span>Enrollment for this course</span>
                  <div className="mt-2">
                    <Switch checked={enrollment} onChange={onChangeSwitch} />
                  </div>
                </div>
                <button
                  className="btn btn-link btn-block mb-4"
                  type="button"
                  onClick={() => props.history.push("/courses")}
                >
                  Cancel
                </button>
                {role === "Admin" && (
                  <>
                    {isLoading ? (
                      <button
                        className="btn btn-primary btn-block mb-4"
                        type="button"
                        style={{ opacity: 0.6 }}
                      >
                        wait...
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-block mb-4"
                        type="submit"
                      >
                        Save edits
                      </button>
                    )}
                  </>
                )}
                <button
                  className="btn btn-success btn-block"
                  type="button"
                  onClick={() => setBuilder(true)}
                >
                  Build course
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {builder && (
        <BuildCourse
          data={details}
          handleBuilder={() => setBuilder(false)}
          featuredImages={featuredImages}
          sections={sections}
          handleSectionOpen={handleSectionOpen}
          handleSectionDelete={handleSectionDelete}
          reloadDetails={reloadDetails}
          handlePublish={handlePublish}
          addCourseItemToSection={addCourseItemToSection}
          deleteCourseItemFromSection={deleteCourseItemFromSection}
          onDragEndHandle={onDragEndHandle}
        />
      )}
    </MainContainer>
  );
};
export default CreateCourse;
