import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import Swal from "sweetalert2";
import axios from "axios";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import { useForm } from "react-hook-form";
import { Switch } from "antd";
import CKEditor from "ckeditor4-react-advanced";
import { userLogout } from "../../redux/actions/UserActions";

const EditMockQuestion = (props) => {
  const { id, quizId } = props.match.params;
  const [totalTime, setTotalTime] = useState(0);
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [solution_hindi, setSolution_hindi] = useState(null);
  const [solution_english, setSolution_english] = useState(null);
  const [question_details_english, setQuestion_details_english] =
    useState(null);
  const [question_details_hindi, setQuestion_details_hindi] = useState(null);
  const [options, setOptions] = useState([
    {
      text_hindi: "",
      text_english: "",
      isCorrect: false,
    },
  ]);
  const { register, handleSubmit, errors, setValue } = useForm();
  // for api info
  const basurl = process.env.REACT_APP_URL;
  const imgUrls = process.env.REACT_APP_BASE_URL;
  const userToken = props.user.userToken;

  // get question Information
  const getQuestionInfo = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/mock/question/view/${id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setOptions(result.data.options);
          setValue("title_hindi", result.data.title_hindi);
          setValue("title_english", result.data.title_english);
          setSolution_english(result.data.solution_english);
          setSolution_hindi(result.data.solution_hindi);
          setQuestion_details_english(result.data.question_details_english);
          setQuestion_details_hindi(result.data.question_details_hindi);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("err>>", err);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        setIsLoading(false);
      });
  };

  // submit form
  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .put(
        `${basurl}/mock/question/edit/${id}`,
        {
          title_hindi: data.title_hindi,
          title_english: data.title_english,
          mock: id,
          solution_english,
          solution_hindi,
          question_details_english,
          question_details_hindi,
          options,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        setIsLoading(false);
        if (result.success) {
          props.history.goBack();
          // quizId
        }
        console.log(result);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  // toggle
  const toggleCheckbox = (it, ind) => {
    let arr = options.map((item, index) => {
      if (ind == index) {
        item.isCorrect = !item.isCorrect;
      } else {
        item.isCorrect = false;
      }
      return { ...item };
    });
    setOptions(arr);
  };

  // Add Option
  const addOption = () => {
    setOptions([
      ...options,
      {
        text_hindi: "",
        text_english: "",
        isCorrect: false,
      },
    ]);
  };

  // Remove Option
  const removeOption = (index) => {
    const list = [...options];
    list.splice(index, 1);
    setOptions(list);
  };

  //handleOptionText
  const handleOptionText = (e, index) => {
    const { name, value } = e.target;
    const list = [...options];
    list[index][name] = value;
    setOptions(list);
  };

  useEffect(() => {
    getQuestionInfo();
  }, [id]);

  //   console.log("options update >> ", quizId);
  return (
    <MainContainer>
      <div className={"card"}>
        {isLoading && <Loader />}
        <div className={"card-header"}>
          <h4>Edit Question</h4>
        </div>
        <div className={"card-body"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Question in English</label>
                  <input
                    type="text"
                    placeholder="Title in English"
                    className="form-control"
                    name="title_english"
                    ref={register({ required: true })}
                  />
                  {errors.title_english && (
                    <span className="isError">
                      Title in english is required.
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Question in Hindi</label>
                  <input
                    type="text"
                    placeholder="Title in Hindi"
                    className="form-control"
                    name="title_hindi"
                    ref={register({ required: false })}
                  />
                  {errors.title_hindi && (
                    <span className="isError">Title in Hindi is required.</span>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              {question_details_english && (
                <div className="col-md-6">
                  <div className="mb-2">
                    <label>Question Details in English</label>
                    <CKEditor
                      data={question_details_english}
                      onChange={(evt) =>
                        setQuestion_details_english(evt.editor.getData())
                      }
                      config={{ allowedContent: true, height: "16em" }}
                    />
                  </div>
                </div>
              )}
              {question_details_hindi && (
                <div className="col-md-6">
                  <div className="mb-2">
                    <label>Question Details in Hindi</label>
                    <CKEditor
                      data={question_details_hindi}
                      onChange={(evt) =>
                        setQuestion_details_hindi(evt.editor.getData())
                      }
                      config={{ allowedContent: true, height: "16em" }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="section_title d-flex space-between">
              <h4 className={"mb-0"} style={{ position: "relative", top: 10 }}>
                Options
              </h4>
              <button
                className="btn btn-success "
                type="button"
                onClick={() => addOption()}
              >
                <i className="la la-plus"></i> Add Option
              </button>
            </div>

            {options.map((item, index) => {
              return (
                <div className="row" key={index}>
                  <div className="col-md-5">
                    <div className="mb-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Text in Hindi"
                        name="text_hindi"
                        value={item.text_hindi}
                        onChange={(e) => handleOptionText(e, index)}
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="mb-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Text in English"
                        value={item.text_english}
                        name="text_english"
                        onChange={(e) => handleOptionText(e, index)}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="mb-2 d-flex space-between">
                      <Switch
                        checked={item.isCorrect}
                        checkedChildren="Right"
                        unCheckedChildren="Wrong"
                        onChange={() => toggleCheckbox(item, index)}
                      />
                      <button
                        className="btn btn-danger "
                        type="button"
                        onClick={() => removeOption(index)}
                      >
                        <i className="ri-delete-bin-line"></i>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
            <h4 className={"section_title"}>Solution</h4>
            <div className="row">
              <div className="col-md-6">
                {solution_english && (
                  <div className="mb-2">
                    <label>Solution in English</label>
                    <CKEditor
                      data={solution_english}
                      onChange={(evt) =>
                        setSolution_english(evt.editor.getData())
                      }
                      config={{ allowedContent: true, height: "20em" }}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-6">
                {solution_hindi && (
                  <div className="mb-2">
                    <label>Solution in Hindi</label>
                    <CKEditor
                      data={solution_hindi}
                      onChange={(evt) =>
                        setSolution_hindi(evt.editor.getData())
                      }
                      config={{ allowedContent: true, height: "20em" }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div>
              <button type="submit" className="btn btn-primary">
                Save Question
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditMockQuestion);
