import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import axios from "axios";
import Pagination from "react-js-pagination";
import moment from "moment";
import { DatePicker } from "antd";
import discountImg from "../../assets/discount.png";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CourseList = (props) => {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";
  const [courses, setCourses] = useState([]);
  const [list, setList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isPublished, setIsPublished] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const user = useSelector((state) => state.user);
  const basurl = process.env.REACT_APP_URL;
  const dispatch = useDispatch();

  const role = user?.user?.role;
  // console.log("user>>>>", role);

  // get course list
  const getCourseList = () => {
    if (role === "Admin") {
      setIsLoading(true);
      axios
        .get(`${basurl}/course/all?page=${activePage}&limit=${9}`)
        .then((res) => {
          const courseList = res.data;
          if (courseList.success) {
            setCourses(courseList.data.reverse());
            setTotalCount(courseList.total);
            setList(courseList.data.reverse());
          }
          console.log("Course list >> ", courseList);
          setIsLoading(false);
        }).catch = (err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          dispatch({
            type: "USER_LOGOUT",
            payload: false,
            token: null,
            info: null,
          });
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      };
    } else {
      // setIsLoading(true);
      axios
        .get(`${basurl}/users/get-data-by-roles/${user?.user?.id}`, {
          headers: {
            "auth-token": user?.userToken,
          },
        })
        .then((res) => {
          const courseList = res.data;
          if (courseList.success) {
            setCourses(courseList.data.courses.reverse());
            setList(courseList.data.courses.reverse());
            setIsLoading(false);
            setSearchInput("");
          } else {
            setCourses([]);
            setList([]);
            setIsLoading(false);
          }
          setIsLoading(false);
        }).catch = (err) => {
        alert("ASsad");
        console.log("err>>>>>>>>>>>>>", err.response);
        if (err.response.data === "Invalid token") {
          dispatch({
            type: "USER_LOGOUT",
            payload: false,
            token: null,
            info: null,
          });
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        } else {
          setIsLoading(false);
          setCourses([]);
          setList([]);
        }
      };
    }
  };

  // get course list by search
  const getCourseListBySearch = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/course/search/${searchInput}`, {
        headers: {
          "auth-token": user?.userToken,
        },
        params: {
          search: searchInput,
        },
      })
      .then((res) => {
        const courseList = res.data;
        if (courseList.success && courseList.data.length > 0) {
          setSearchResult(courseList.data);
          setCourses(courseList.data);
          setList(courseList.data);
          setTotalCount(courseList.data.length);
        }
        console.log("Course list >> ", courseList);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          dispatch({
            type: "USER_LOGOUT",
            payload: false,
            token: null,
            info: null,
          });
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  useEffect(() => {
    if (searchInput === "") {
      getCourseList();
    } else {
      getCourseListBySearch();
    }
  }, [searchInput]);

  const handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    setActivePage(pageNumber);
  };

  // handle price & discount
  const showPrice = (data) => {
    if (data.discount_price_method === "digit") {
      return (
        <span className={"discounted_price"}>
          <span className={"price-cut"}>₹{data.price}</span> ₹
          {parseInt(data.price) - parseInt(data.discount_price)}
        </span>
      );
    } else if (data.discount_price_method === "percent") {
      return (
        <span className={"discounted_price"}>
          <span className={"price-cut"}>₹{data.price}</span> ₹
          {parseInt(data.price) -
            (parseInt(data.price) * parseInt(data.discount_price)) / 100}
        </span>
      );
    }
  };

  useEffect(() => {
    getCourseList();
  }, [activePage]);

  useEffect(() => {
    if (list.length > 0) {
      const arr = list.filter((item) => item.isPublished === isPublished);
      setCourses(arr);
    }
  }, [isPublished]);

  // for search filter
  const filteredData = courses.filter((val) => {
    if (searchInput === "") {
      return val;
    } else if (val.title.toLowerCase().includes(searchInput.toLowerCase())) {
      return val;
    }
  });

  // toggle modal
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  //handleCourseCreate
  const handleCourseCreate = () => {
    if (courseTitle.length > 0) {
      axios
        .post(
          `${basurl}/course/create-course`,
          {
            title: courseTitle,
          },
          {
            headers: {
              "auth-token": user.userToken,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            setCourseTitle("");
            setIsOpenModal(false);
          }
          props.history.push(`/courses/create/${res.data.data._id}`);
        });
    } else {
      setTitleError(true);
    }
  };
  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // Delete Course
  const deleteCourse = (courseId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger-outline mr-2",
        cancelButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "<Strong>Are you absolutely sure?</Strong>",
        html: "Please type <Strong>DELETE</Strong> to confirm.",
        input: "text",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (result.value === "DELETE") {
            axios
              .delete(`${basurl}/course/delete/${courseId}`, {
                headers: {
                  "auth-token": user.userToken,
                },
              })
              .then((res) => {
                const deleteStatus = res.data;
                if (deleteStatus.success) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: deleteStatus.message,
                    showConfirmButton: false,
                    timer: 2500,
                  });
                  getCourseList();
                }
              });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `Please enter "DELETE" for delete this course`,
              showConfirmButton: true,
            });
          }
        }
      });
  };

  return (
    <MainContainer>
      <Modal centered isOpen={isOpenModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Create course</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label>Course Title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter course title"
              onChange={(e) => {
                setCourseTitle(e.target.value);
                setTitleError(false);
              }}
              value={courseTitle}
            />
            {titleError && (
              <span className="text-danger">Course title is required.</span>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={handleCourseCreate}>
            Next
          </button>
        </ModalFooter>
      </Modal>
      <div className="cards">
        <div className="card-headers mb-3">
          <div
            className="row align-items-center mb-4
          "
          >
            <div className="col-sm-4">
              <div className="d-flex align-items-center">
                <h4 className="mt-2" style={{ marginRight: 30 }}>
                  Courses
                </h4>
                {role === "Admin" && (
                  <button
                    className="btn btn-primary"
                    onClick={() => setIsOpenModal(true)}
                  >
                    <i className="fas fa-plus"></i> Add Course
                  </button>
                )}

                {/* <Link className="btn btn-primary " to="/courses/create">
                  <i className="fas fa-plus"></i> Add Course
                </Link> */}
              </div>
            </div>
            <div className="col-sm-8">
              <div className="search_course_wrapper">
                <label className="form-label">Search</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search courses..."
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                />
                {searchInput.length > 0 && (
                  <div className="indicator">
                    {isLoading ? (
                      <span className="sb_btn spin">
                        <svg
                          viewBox="0 0 1024 1024"
                          fill="currentColor"
                          height="1em"
                          width="1em"
                        >
                          <path d="M512 1024c-69.1 0-136.2-13.5-199.3-40.2C251.7 958 197 921 150 874c-47-47-84-101.7-109.8-162.7C13.5 648.2 0 581.1 0 512c0-19.9 16.1-36 36-36s36 16.1 36 36c0 59.4 11.6 117 34.6 171.3 22.2 52.4 53.9 99.5 94.3 139.9 40.4 40.4 87.5 72.2 139.9 94.3C395 940.4 452.6 952 512 952c59.4 0 117-11.6 171.3-34.6 52.4-22.2 99.5-53.9 139.9-94.3 40.4-40.4 72.2-87.5 94.3-139.9C940.4 629 952 571.4 952 512c0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.2C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3s-13.5 136.2-40.2 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7-63.1 26.8-130.2 40.3-199.3 40.3z" />
                        </svg>
                      </span>
                    ) : (
                      <span className="sb_btn" onClick={() => setSearchInput("")}>
                        <svg
                          fill="none"
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                        >
                          <path
                            fill="currentColor"
                            d="M6.225 4.811a1 1 0 00-1.414 1.414L10.586 12 4.81 17.775a1 1 0 101.414 1.414L12 13.414l5.775 5.775a1 1 0 001.414-1.414L13.414 12l5.775-5.775a1 1 0 00-1.414-1.414L12 10.586 6.225 4.81z"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-bodys">
          <div className="courses_wrapper">
            {filteredData.length > 0 ? (
              <div className="course_column_wrapper">
                {filteredData.map((item, index) => {
                  const totlaLessons =
                    item?.course_sections?.length > 0
                      ? item?.course_sections?.reduce((prev, cur) => {
                          return prev + parseFloat(cur.section_item.length);
                        }, 0)
                      : 0;

                  return (
                    <div className={"course_column"} key={index}>
                      <div className={"courseWrap"}>
                        <div className={"course_image"}>
                          <Link to={`/courses/create/${item._id}`}>
                            {item.course_image ? (
                              <img src={item.course_image} />
                            ) : (
                              <img
                                src={require(`../../assets/coursepic.png`)}
                              />
                            )}
                          </Link>
                          <div
                            className={`course_status ${
                              item.isPublished ? "published" : "unPublished"
                            }`}
                          >
                            {item.isPublished ? (
                              <i className={"la la-check-circle-o"}></i>
                            ) : (
                              <i className={"la la-warning"}></i>
                            )}{" "}
                            {item.isPublished ? "Published" : "Unpublished"}
                          </div>
                          {/* <div
                              className={`course_status ${
                                item.isPublished ? "published" : "unPublished"
                              }`}
                            >
                              {item.isPublished ? (
                                <i className={"la la-check-circle-o"}></i>
                              ) : (
                                <i className={"la la-warning"}></i>
                              )}{" "}
                              {item.isPublished ? "Published" : "Unpublished"}
                            </div>
                            {item.isDiscounted && (
                              <div
                                className={"discount_tag"}
                                style={{ backgroundImage: `url(${discountImg})` }}
                              >
                                {item.discount_price_method === "digit" && "₹"}
                                {item.discount_price}
                                {item.discount_price_method === "percent" &&
                                  "%"}{" "}
                                <br />
                                OFF
                              </div>
                            )} */}
                        </div>
                        <div className="course_content">
                          <Link
                            to={`/courses/create/${item._id}`}
                            className={"courseTitle"}
                          >
                            {item.title}
                          </Link>
                          <div className={"sections_details_link"}>
                            {totlaLessons}{" "}
                            {totlaLessons > 1 ? "Videos" : "Video"}
                          </div>
                          <div className="course_validity_link">
                            <span>Validity: </span>
                            {item.course_validity
                              ? moment(item.course_validity).format(
                                  "MMMM Do YYYY"
                                )
                              : "Not mentioned"}
                          </div>
                          <div className={"text-right"}>
                            <span className="course_price">
                              {"₹" + item.price}
                            </span>
                          </div>
                          <div className="course_content_footer">
                            <Link
                              to={`/courses/create/${item._id}`}
                              className="footer_link_11"
                            >
                              <i className="ri-edit-2-line"></i>
                            </Link>
                            {role === "Admin" && (
                              <span
                                onClick={() => deleteCourse(item._id)}
                                className="footer_link_11 text-danger pointer"
                              >
                                <i className="ri-delete-bin-line"></i>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                {isLoading ? (
                  <div className="course_column_wrapper">
                    <div className="course_column">
                      <Skeleton height={"20vh"} style={{ marginBottom: 10 }} />
                      <Skeleton style={{ marginBottom: 10, width: "100%" }} />
                      <Skeleton style={{ width: "60%" }} />
                    </div>
                    <div className="course_column">
                      <Skeleton height={"20vh"} style={{ marginBottom: 10 }} />
                      <Skeleton style={{ marginBottom: 10, width: "100%" }} />
                      <Skeleton style={{ width: "60%" }} />
                    </div>
                    <div className="course_column">
                      <Skeleton height={"20vh"} style={{ marginBottom: 10 }} />
                      <Skeleton style={{ marginBottom: 10, width: "100%" }} />
                      <Skeleton style={{ width: "60%" }} />
                    </div>
                  </div>
                ) : (
                  <div className={"alert alert-secondary text-center"}>
                    No Course Available
                  </div>
                )}
              </>
            )}
            {searchInput === "" && (
              <div className={"justify-flex-end"}>
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={9}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={9}
                  onChange={handlePageChange}
                  hideDisabled
                  // prevPageText={<span></span>}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="card">
        <div className="card-body">
           <div className={"filters mb-2"}>
            <div className={"row"}>
              <div className={"col-lg-4"}>
                <div className={"search_filter"}>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder={"Search by name..."}
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  {searchInput !== "" && (
                    <span
                      className={"clear_search_text"}
                      onClick={() => setSearchInput("")}
                    >
                      <i className={"la la-times"}></i>
                    </span>
                  )}
                </div>
              </div>
              <div className={"col-lg-4"}>
                <RangePicker
                  format={dateFormat}
                  className={"form-control custom_range_picker"}
                  onChange={handleRange}
                />
              </div>
              <div className={"col-lg-4"}>
                <div className={"custom_switch_course"}>
                  <span
                    className={`customButton ${isPublished ? "active" : ""}`}
                    onClick={() => setIsPublished(true)}
                  >
                    Published
                  </span>
                  <span
                    className={`customButton ${!isPublished ? "active" : ""}`}
                    onClick={() => setIsPublished(false)}
                  >
                    Unpublished
                  </span>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div> */}
    </MainContainer>
  );
};
export default CourseList;
