import React, { useEffect, useState } from "react";
import axios from "axios";
import MainContainer from "../../../../common/MainContainer";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "../../../../common/Loader";
import { IKContext, IKUpload } from "imagekitio-react";
import { userLogout } from "../../../../redux/actions/UserActions";
import toast, { Toaster } from "react-hot-toast";
import PlaceholderImage from "../../../../assets/placeholder.svg";
import { Progress } from "reactstrap";

const CreateMetaData = (props) => {
  let history = useHistory();
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsloading] = useState(false);
  const [thumb, setThumb] = useState("");
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const { register, handleSubmit, watch, errors } = useForm();

  const publicKey = "public_HjiUmwMXfcVANdDad6n0ek26fsc=";
  let urlEndpoint = "https://ik.imagekit.io/lmsplus";
  const authenticationEndpoint = "https://server.myprepzone.com/auth";

  const metatitleLength = watch("metaTitle", "").length;
  const metadescriptionLength = watch("metaDescription", "").length;

  const authenticator = async () => {
    console.log("authenticating...");
    return await fetch(authenticationEndpoint)
      .then((response) => response.json())
      .then((data) => data);
  };

  // add banner function
  const onSubmit = (data) => {
    setIsloading(true);
    axios
      .post(
        `${basurl}/frontend/metadata`,
        {
          ogImage: thumb ? thumb : null,
          slug: data.slug,
          metaTitle: data.metaTitle,
          metaDescription: data.metaDescription,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const response = res.data;
        if (response.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Metadata added successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/frontend/seo/metadata");
        }
        setIsloading(true);
      })
      .catch((err) => {
        setIsloading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        console.log(err);
      });
  };

  const onUploadProgress = (progressEvent) => {
    // console.log("Progress", progressEvent);
    let percentComplete = progressEvent.loaded / progressEvent.total;
    percentComplete = parseInt(percentComplete * 100);
    setProgress(percentComplete);
    setIsloading(false);
    setTimeout(() => setProgress(0), 2000);
  };

  const onError = (err) => {
    console.log("Error", err);
  };

  const onSuccess = (res) => {
    setThumb(res.url);
    toast.success("Image Uploaded successfully!!");
    console.log("Success", res);
  };

  return (
    <MainContainer>
      {isLoading && <Loader />}
      <Toaster />
      <div className="card">
        <div className="card-header">
          <h4 className={"header-title"}>Page Meta Data</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label>
                      Meta Title <span className="starImportnat">*</span>
                    </label>
                    <span>
                      <span>Characters:</span>{" "}
                      <span className="isError"> 70 / {metatitleLength}</span>
                    </span>
                  </div>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder={"Enter Meta title"}
                    name={"metaTitle"}
                    maxLength={70}
                    ref={register({ required: true })}
                  />
                  <span>
                    {metatitleLength > 69 && (
                      <span className="isError">
                        Meta Title should be less than 70 characters
                      </span>
                    )}
                  </span>
                  {errors.metaTitle && (
                    <span className="isError">Meta Title is required.</span>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Page Slug <span className="starImportnat">*</span>{" "}
                    <small className="text-muted">
                      https://myprepzone.com/
                      <span
                        style={{
                          display: "inline-block",
                          padding: "2px 5px",
                          background: "rgba(224, 30, 90, 0.1)",
                          borderRadius: "5px",
                          color: "rgb(224, 30, 90)",
                          fontSize: "11px",
                        }}
                      >
                        pagename
                      </span>
                      , for home page, use "home"
                    </small>
                  </label>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder={"Enter Meta title"}
                    name={"slug"}
                    ref={register({ required: true })}
                  />
                  {errors.slug && (
                    <span className="isError">Meta Title is required.</span>
                  )}
                </div>
                <div className="form-group">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label>
                      <label>Meta Description</label>
                    </label>
                    <span>
                      <span>Characters:</span>{" "}
                      <span className="isError">
                        {" "}
                        160 / {metadescriptionLength}
                      </span>
                    </span>
                  </div>

                  <textarea
                    className={"form-control"}
                    placeholder={"Enter Meta Description"}
                    name={"metaDescription"}
                    maxLength={160}
                    ref={register}
                  />
                  <span>
                    {metadescriptionLength > 159 && (
                      <span className="isError">
                        Meta Description should be less than 160 characters.
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="image_upload_wrapper_square">
                  <label>
                    Featured Image{" "}
                    <small className="text-muted" style={{ marginLeft: 10 }}>
                      Recommended Size:{" "}
                      <span
                        style={{
                          display: "inline-block",
                          padding: "2px 5px",
                          background: "rgba(224, 30, 90, 0.1)",
                          borderRadius: "5px",
                          color: "rgb(224, 30, 90)",
                          fontSize: "11px",
                        }}
                      >
                        1200X630
                      </span>
                    </small>
                  </label>
                  {progress !== 0 && (
                    <div style={{ width: "300px" }}>
                      <Progress
                        animated
                        bar
                        color="success"
                        style={{
                          height: "10px",
                        }}
                        value={progress}
                      />
                    </div>
                  )}
                  <IKContext
                    publicKey={publicKey}
                    urlEndpoint={urlEndpoint}
                    authenticator={authenticator}
                  >
                    {/* <IKImage path={thumb} /> */}
                    <IKUpload
                      folder="metadata"
                      onUploadProgress={onUploadProgress}
                      useUniqueFileName={false}
                      onError={onError}
                      onSuccess={onSuccess}
                    />

                    {thumb !== "" ? (
                      <div className="img_wrapper_square">
                        <span className="close">
                          <i
                            className="ri-close-line"
                            onClick={() => setThumb("")}
                          />
                        </span>
                        <img src={thumb} alt={"image"} />
                      </div>
                    ) : (
                      <div className="img_wrapper_square">
                        <img src={PlaceholderImage} alt={"image"} />
                      </div>
                    )}
                  </IKContext>
                </div>
              </div>
            </div>
            <hr />
            <div className={"d-flex"}>
              <button
                type="submit"
                className="btn btn-success"
                style={{ marginRight: 15 }}
              >
                Add Meta Data
              </button>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateMetaData);
