import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import Swal from "sweetalert2";
import { Table } from "antd";
import "antd/dist/antd.css";
import { connect } from "react-redux";

const AllOrders = (props) => {
  const [list, setList] = useState([]);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;

  // get all Orders
  const getAllOrders = () => {
    axios
      .get(`${basurl}/orders/all-orders`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data);
        }
        // console.log("all orders", result);
      });
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <h4>All Orders</h4>
        </div>
        <div className="card-body">
          {/* <Table columns={columns} dataSource={list} /> */}
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(AllOrders);
