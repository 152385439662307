import React, { useState, useEffect } from "react";
import MainContainer from "../../../common/MainContainer";
import { userLogout } from "../../../redux/actions/UserActions";
import { Link } from "react-router-dom";
import axios from "axios";
import { Table } from "antd";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import Swal from "sweetalert2";

const Partners = (props) => {
  const [list, setList] = useState([]);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const [isLoading, setIsLoading] = useState(true);
  //get slider banner list
  const getAllPartners = () => {
    axios
      .get(`${basurl}/frontend/partners/all`)
      .then((res) => {
        const coupons = res.data;
        if (coupons.success) {
          setList(coupons.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // Delete banner slider
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this item ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${basurl}/frontend/partners/delete/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const deleteStatus = res.data;
            if (deleteStatus.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: deleteStatus.message,
                showConfirmButton: false,
                timer: 2500,
              });
              getAllPartners();
            }
            // console.log("delete status > ", deleteStatus);
          })
          .catch((err) => {
            if (err.response.data === "Invalid token") {
              props.logoutUser();
              localStorage.removeItem("lmsUserInfo");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your token has been expired!, Please login again",
                showConfirmButton: false,
                timer: 1500,
              });
              props.history.push("/");
            }
          });
      }
    });
  };

  useEffect(() => {
    getAllPartners();
  }, []);

  // Columns
  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (value, item, index) => <span>{item.title}</span>,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (value, item, index) => (
        <img src={process.env.REACT_APP_BASE_URL + item.image} width="100px" />
      ),
    },
    {
      title: "Action",
      render: (value, item, index) => (
        <div>
          <button
            className="btn  btn-danger"
            style={{ marginLeft: "10px", minWidth: 100 }}
            onClick={() => handleDelete(item._id)}
          >
            <i className="ri-delete-bin-line"></i>
          </button>
        </div>
      ),
    },
  ];
  // console.log("banner list>> ", list);
  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <div className={"row"}>
            <div className="col-md-6">
              <h4 className={"header-title"}>Partners Logo List</h4>
            </div>
            <div className="col-md-6 text-right">
              <Link className={"btn btn-primary"} to={"/frontend/partners/add"}>
                Add New Partner
              </Link>
            </div>
          </div>
        </div>
        <div className={"card-body"}>
          <Table
            columns={columns}
            dataSource={list}
            bordered={true}
            loading={isLoading}
          />
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Partners);
