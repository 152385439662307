import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import Swal from "sweetalert2";
import { Table } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userLogout } from "../../redux/actions/UserActions";
import { DatePicker } from "antd";
import { ExportCSV } from "../../components/ExportCSV";

const Transactions = (props) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [exportData, setExportData] = useState([]);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;

  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";

  // get all Orders
  const getAllTrasactions = () => {
    axios
      .get(`${basurl}/orders/all-transactions`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data.reverse());
          const newArr = result.data.map((item, index) => ({
            "S. No": index + 1,
            User: item.name,
            Mobile: item.mobile,
            Email: item.email,
            Product: item.product.title,
            Price: item.price,
            Date: moment(item.createdAt).format("DD/MM/YYYY"),
            Status: item.success ? "Success" : "Failed",
          }));
          setExportData(newArr);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
        // console.log("all orders", result);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        console.log("error>> ", err);
      });
  };

  // handleRange
  const handleRange = (date) => {
    if (date !== null) {
      let date1 = date[0].toDate();
      let date2 = date[1].toDate();
      const newArr = list.filter((item) => {
        return (
          moment(item.createdAt).toDate() <= date2 &&
          moment(item.createdAt).toDate() >= date1
        );
      });
      setList(newArr);
    } else {
      getAllTrasactions();
    }

    // console.log("get date from range >>>", newArr)
  };

  // for search filter
  const filteredData = list.filter((val) => {
    // console.log("val.order_id", val);
    if (searchInput === "") {
      return val;
    } else if (
      val.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      val.email.toLowerCase().includes(searchInput.toLowerCase())
    ) {
      return val;
    }
  });

  useEffect(() => {
    getAllTrasactions();
  }, []);

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // Columns
  const columns = [
    {
      title: "S. No.",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "User",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, index) => <span>{record.name}</span>,
    },
    {
      title: "Mobile",
      sorter: (a, b) => a.mobile.localeCompare(b.mobile),
      render: (text, record, index) => <span>{record.mobile}</span>,
    },
    {
      title: "Email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text, record, index) => <span>{record.email}</span>,
    },
    {
      title: "Product",
      render: (text, record, index) => <span>{record.product?.title}</span>,
    },
    {
      title: "Payment Mode",
      sorter: (a, b) =>
        a.isOffline.toString().localeCompare(b.isOffline.toString()),
      render: (text, record, index) => (
        <span>{record.isOffline ? "Offline" : "Online"}</span>
      ),
    },
    {
      title: "Price",
      render: (text, record, index) => <span>₹{record.price}</span>,
      //sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      sorter: (a, b) =>
        a.updatedAt.toString().localeCompare(b.updatedAt.toString()),
      key: "updatedAt",
      render: (item) => (
        <span>{moment(item).format("MMMM Do YYYY, h:mm:ss")}</span>
      ),
      //sorter: (a, b) => a.updatedAt - b.updatedAt,
    },
    {
      title: "Status",
      dataIndex: "success",
      key: "success",
      render: (item) => (
        <span>
          {item ? (
            <span className="text-success">Success</span>
          ) : (
            <span className="text-danger">Failed</span>
          )}
        </span>
      ),
      //sorter: (a, b) => a.success - b.success,
    },
  ];
  return (
    <MainContainer>
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Transactions
            </h4>
          </div>
        </div>
      </div>
      <div className="filter_options">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-sm-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
              <div className="col-sm-5">
                <RangePicker
                  format={dateFormat}
                  className={"form-control custom_range_picker"}
                  onChange={handleRange}
                />
              </div>
              <div className="col-sm-2">
                <ExportCSV csvData={exportData} fileName={"Transactions"} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className="card-body">
          <Table
            columns={columns}
            dataSource={filteredData}
            bordered={true}
            rowKey={(item) => item._id}
            loading={isLoading}
          />
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
