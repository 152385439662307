import MainContainer from "../../../common/MainContainer";
import React, { useState, useEffect } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import axios from "axios";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { userLogout } from "../../../redux/actions/UserActions";

const basurl = process.env.REACT_APP_URL;
const basurlHome = process.env.REACT_APP_BASE_URL;

const General = (props) => {
  const [details, setDetails] = useState(null);
  const [logo, setLogo] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  // for social media links
  const [facebook, setFacebook] = useState("");
  const [youtube, setYouTube] = useState("");
  const [telegram, setTelegram] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedIn] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  // for footer
  const [footerText, setFooterText] = useState("");
  const [androidAppLink, setAndroidAppLink] = useState("");
  const [iosAppLink, setIosAppLink] = useState("");

  // for blog page
  const [blogTitle, setBlogTitle] = useState("");
  const [blogSubtitle, setBlogSubtitle] = useState("");
  const [blogString, setBlogString] = useState([]);

  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;

  // get information
  const getDetails = () => {
    axios.get(`${basurl}/frontend/general`).then((res) => {
      const result = res.data;
      if (result.success) {
        if (result.data.length > 0) {
          const details = result.data[0];
          setDetails(details);
          setLogo(
            details.logo !== null
              ? process.env.REACT_APP_BASE_URL + details.logo
              : null
          );
          setSelectedLogo(details.logo);
          setTitle(details.title);
          setDescription(details.description);
          setAndroidAppLink(details.androidAppLink);
          setFacebook(details.facebook);
          setFooterText(details.footerText);
          setTwitter(details.twitter);
          setIosAppLink(details.iosAppLink);
          setTelegram(details.telegram);
          setTwitter(details.twitter);
          setWhatsapp(details.whatsapp);
          setYouTube(details.youtube);
          setLinkedIn(details.linkedin);
          setInstagram(details.instagram);
          setBlogTitle(details.blogTitle);
          setBlogSubtitle(details.blogSubtitle);
          setBlogString(details.blogString);
        }
      }
    });
  };

  useEffect(() => {
    getDetails();
  }, []);

  // handle remove selected image
  const handleRemoveImage = () => {
    setLogo(null);
    setSelectedLogo(null);
  };

  // handle Image upload
  const handleImage = (event) => {
    setLogo(URL.createObjectURL(event.target.files[0]));
    setSelectedLogo(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    axios({
      method: "post",
      url: `${basurl}/upload/img`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const imgRes = res.data;
      console.log("imgresponse >> ", imgRes);
      if (imgRes.success) {
        setSelectedLogo(imgRes.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Logo uploaded successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  // handle submit information
  const handleSubmit = () => {
    axios
      .post(
        `${basurl}/frontend/general/create`,
        {
          logo: selectedLogo,
          title: title,
          description: description,
          facebook: facebook,
          youtube: youtube,
          instagram: instagram,
          telegram: telegram,
          whatsapp: whatsapp,
          twitter: twitter,
          footerText: footerText,
          androidAppLink: androidAppLink,
          iosApplink: iosAppLink,
          linkedin: linkedin,
          blogSubtitle: blogSubtitle,
          blogTitle: blogTitle,
          blogString: blogString,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "info saved successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          getDetails();
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  // handle Update
  const handleUpdate = () => {
    axios
      .put(
        `${basurl}/frontend/general/update/${details._id}`,
        {
          logo: selectedLogo,
          title: title,
          description: description,
          facebook: facebook,
          youtube: youtube,
          instagram: instagram,
          telegram: telegram,
          whatsapp: whatsapp,
          twitter: twitter,
          footerText: footerText,
          androidAppLink: androidAppLink,
          iosApplink: iosAppLink,
          linkedin: linkedin,
          blogSubtitle: blogSubtitle,
          blogTitle: blogTitle,
          blogString: blogString,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "info updated successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          getDetails();
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };
  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <h4 className={"header-title"}>Front Website General info</h4>
        </div>
        <div className="card-body">
          <h4 className={"section_title"}>Website Identification</h4>
          <div className="form-group">
            <label>Company Logo</label>
            <div className={"image-uploader"}>
              {logo !== null ? (
                <div className={"img_upld_wrapper"}>
                  <img src={logo} className={"image_upload"} />
                  <span
                    className={"upload_img_close"}
                    onClick={() => handleRemoveImage()}
                  >
                    <i className="ri-close-line"></i>
                  </span>
                </div>
              ) : (
                <div className={"image_upload_placeholder"}>
                  <i className={"ri-add-box-line"}></i>
                  <span className={"image_upload_titler"}>
                    Browse Company Logo
                  </span>
                  <input
                    type="file"
                    className={"image_upload_file"}
                    onChange={(e) => handleImage(e)}
                    accept="image/*"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <label>Website Title</label>
            <input
              type="text"
              placeholder={"Enter Website's Title"}
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Website Description</label>
            <textarea
              className={"form-control"}
              value={description}
              placeholder={"Enter Website's Description"}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <h4 className={"section_title"} style={{ marginTop: "30px" }}>
            Social Media Links
          </h4>
          <div className="form-group form_row">
            <span className={"icon_wrap facebook"}>
              <i className={"fab fa-facebook"}></i>
            </span>
            <input
              type="text"
              className={"form-control"}
              placeholder={"Facebook link"}
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
            />
          </div>
          <div className="form-group form_row">
            <span className={"icon_wrap twitter"}>
              <i className={"fab fa-twitter"}></i>
            </span>
            <input
              type="text"
              className={"form-control"}
              placeholder={"Twitter link"}
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
            />
          </div>
          <div className="form-group form_row">
            <span className={"icon_wrap instagram"}>
              <i className={"fab fa-instagram"}></i>
            </span>
            <input
              type="text"
              className={"form-control"}
              placeholder={"Instagram link"}
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
            />
          </div>
          <div className="form-group form_row">
            <span className={"icon_wrap youtube"}>
              <i className={"fab fa-youtube"}></i>
            </span>
            <input
              type="text"
              className={"form-control"}
              placeholder={"Youtube link"}
              value={youtube}
              onChange={(e) => setYouTube(e.target.value)}
            />
          </div>
          <div className="form-group form_row">
            <span className={"icon_wrap linkedin"}>
              <i className={"fab fa-linkedin"}></i>
            </span>
            <input
              type="text"
              className={"form-control"}
              placeholder={"Linkedin link"}
              value={linkedin}
              onChange={(e) => setLinkedIn(e.target.value)}
            />
          </div>
          <div className="form-group form_row">
            <span className={"icon_wrap whatsapp"}>
              <i className={"fab fa-whatsapp"}></i>
            </span>
            <input
              type="tel"
              className={"form-control"}
              placeholder={"Whatsapp No."}
              value={whatsapp}
              maxLength={13}
              onChange={(e) => setWhatsapp(e.target.value)}
            />
          </div>
          <div className="form-group form_row">
            <span className={"icon_wrap telegram"}>
              <i className={"fab fa-telegram-plane"}></i>
            </span>
            <input
              type="text"
              className={"form-control"}
              placeholder={"Telegram link"}
              value={telegram}
              onChange={(e) => setTelegram(e.target.value)}
            />
          </div>
          <h4 className={"section_title"} style={{ marginTop: "30px" }}>
            Mobile Apps Links
          </h4>
          <div className="form-group">
            <label>Android App</label>
            <input
              type="text"
              placeholder={"Android App Link"}
              className="form-control"
              value={androidAppLink}
              onChange={(e) => setAndroidAppLink(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>IOS App</label>
            <input
              type="text"
              placeholder={"IOS App Link"}
              className="form-control"
              value={iosAppLink}
              onChange={(e) => setIosAppLink(e.target.value)}
            />
          </div>
          <h4 className={"section_title"} style={{ marginTop: "30px" }}>
            Website Footer
          </h4>
          <div className="form-group">
            <label>Footer Text</label>
            <textarea
              className={"form-control"}
              placeholder={"Footer About Text"}
              value={footerText}
              onChange={(e) => setFooterText(e.target.value)}
            />
          </div>
          <h4 className={"section_title mt-3"}>Blog Page Header Info</h4>
          <div className="form-group">
            <label>Blog Page Title</label>
            <input
              type="text"
              placeholder="Enter Blog Title"
              value={blogTitle}
              className="form-control"
              onChange={(e) => setBlogTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Blog Page Subtitle</label>
            <input
              type="text"
              placeholder="Enter Blog Subtitle"
              value={blogSubtitle}
              className="form-control"
              onChange={(e) => setBlogSubtitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Blog Strings</label>
            <TagsInput
              value={blogString}
              onChange={(string) => setBlogString(string)}
              inputProps={{ placeholder: "Add Blog Page Strings" }}
            />
          </div>
          <button
            className={"btn btn-success"}
            onClick={details === null ? handleSubmit : handleUpdate}
          >
            Save Details
          </button>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(General);
