import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import { useForm } from "react-hook-form";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";
import moment from "moment";
import Loader from "../../common/Loader";
import { useHistory } from "react-router-dom";
import { userLogout } from "../../redux/actions/UserActions";

const AddAnnouncement = (props) => {
  let history = useHistory();
  const { register, handleSubmit, errors, setValue } = useForm();
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [uploadProgress, setUploadProgress] = useState(0);
  const [featuredImages, setFeaturedImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const basurl = process.env.REACT_APP_URL;

  // onSubmit
  const onSubmit = (data) => {
    setIsLoading(true);
    axios({
      method: "post",
      url: `${basurl}/announcements/create`,
      data: {
        title: data.title,
        expiryDate: expiryDate,
        image: featuredImages,
        description: data.description,
      },
      headers: {
        "auth-token": props.user.userToken,
      },
    })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your announcement added successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
          props.history.push("/announcements");
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: err.Message,
          showConfirmButton: false,
          timer: 1500,
        });
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        setIsLoading(false);
      });
  };
  const onChange = (date, dateString) => {
    // console.log(date, dateString);
    setExpiryDate(date);
  };

  // AWS configurations
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new/announcements" },
    region: "ap-south-1",
  });

  // upload image to aws bucket
  const uploadVideoHandler = (files) => {
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setUploadProgress(percent);
      })
      .on("success", (evt) => {
        setFeaturedImages(
          `https://lms-videos-bucket-prepzone-new.s3.ap-south-1.amazonaws.com/${evt.request.params.Key}`
        );
        setUploadProgress(0);
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="card">
        <div className="card-header">
          <h4 className={"header-title"}>Add Announcement</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-8">
                <div className="mb-2">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Title"
                    ref={register({ required: true })}
                    name={"title"}
                  />
                  {errors.title && (
                    <span className="isError">Title is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    placeholder="Description"
                    ref={register({ required: true })}
                    name={"description"}
                    style={{ minHeight: "200px" }}
                  />
                  {errors.description && (
                    <span className="isError">Description is required</span>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-2">
                  <label>Image</label>
                  {featuredImages ? (
                    <div className="course_image_uploader">
                      <span
                        className="close_icon"
                        onClick={() => setFeaturedImages(null)}
                      >
                        <i className="ri-close-line"></i>
                      </span>
                      <img
                        src={featuredImages}
                        alt={"feature image"}
                        className="img-fluid"
                      />
                    </div>
                  ) : (
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        uploadVideoHandler(acceptedFiles)
                      }
                      accept={"image/*"}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="course_image_uploader">
                          {uploadProgress > 1 && (
                            <div className="upload_progress">
                              <div
                                className="inner"
                                style={{
                                  width: `${uploadProgress}%`,
                                  height: "100%",
                                  backgroundColor: "#4CAF50",
                                  transition: "all 0.4s ease-in-out",
                                  borderRadius: "20px",
                                }}
                              ></div>
                            </div>
                          )}

                          <div {...getRootProps()} className={"dropZone"}>
                            <input {...getInputProps()} />
                            {/* <i className="ri-image-line uploader_icon"></i> */}
                            <span className="close_icon">
                              <i className="ri-pencil-fill"></i>
                            </span>
                            <img
                              src={require("../../assets/coursepic.png")}
                              alt="Mock demo image"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </div>
                <div className="mb-2">
                  <label>Expiry Date</label>
                  <DatePicker
                    onChange={onChange}
                    defaultValue={moment(expiryDate)}
                    className="form-control"
                    style={{ height: 40, borderRadius: 4 }}
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>
            </div>

            <div className={"d-flex"}>
              <button
                type="submit"
                className="btn btn-success"
                style={{ marginRight: 15 }}
              >
                Add Announcement
              </button>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddAnnouncement);
