import React, { useState } from "react";
import moment from "moment";
import { Menu, Dropdown, Modal, DatePicker, Space } from "antd";
import { connect } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
var Tooltip = require("rc-tooltip");

const CoursesList = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [exDate, setExDate] = useState(new Date());
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [user, setUser] = useState(null);
  const { data } = props;
  const basurl = process.env.REACT_APP_URL;
  const userInfo = useSelector((state) => state.user);

  // console.log("available props>> ", props);

  const menu = () => {
    return (
      <Menu>
        <Menu.Item key="0">
          <span onClick={showModal}>Change Expiry Date</span>
        </Menu.Item>
      </Menu>
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onChange = (date, dateString) => {
    console.log(date._d, dateString);
    setExDate(date._d);
  };

  const handleModalClick = (e, date, id, user) => {
    e.preventDefault();
    setExDate(date);
    setSelectedCourse(id);
    setUser(user);
    // console.log("course find >>", id);
  };

  // handle Submit
  const handleSubmit = () => {
    axios
      .put(
        `${basurl}/course-validity/update/${props.userId}/${selectedCourse}`,
        {
          validity: exDate,
        },
        {
          headers: {
            "auth-token": props.user.userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result.message,
            timer: 1500,
          });
          props.refetchList();
          setIsModalVisible(false);
          // window.location.reload();
        }
      })
      .catch((err) => {
        console.log("find error >> ", err);
      });
  };
  //Todo:  handleRevokeAccess
  const handleRevokeAccess = (courseId) => {
    console.log("course id>>>", courseId);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger-outline mr-2",
        cancelButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "<Strong>Are you absolutely sure?</Strong>",
        html: "Please type <Strong>REVOKE</Strong> to confirm.",
        input: "text",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Remove Access.",
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (result.value === "REVOKE") {
            axios
              .post(
                `${basurl}/users/revoke-access`,
                {
                  user: props.userId,
                  course: courseId,
                },
                {
                  headers: {
                    "auth-token": props.user.userToken,
                  },
                }
              )
              .then((res) => {
                const result = res.data;
                if (result.success) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: result.message,
                    timer: 1500,
                  });
                  props.refetchList();
                  setIsModalVisible(false);
                }
              })
              .catch((err) => {
                console.log("find error >> ", err);
              });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `Please enter "REVOKE" for revoke access.`,
              showConfirmButton: true,
            });
          }
        }
      });
  };
  const dateFormat = "YYYY/MM/DD";
  // console.log("expirey date >> ", props.user);
  return (
    <div>
      {data.length > 0 ? (
        <div>
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Image</th>
                <th>Name</th>
                <th>Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              {data.map((course, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      src={
                        course.course.course_image
                          ? course.course.course_image
                          : require("../../../assets/coursepic.png")
                      }
                      style={{ width: "100px" }}
                      alt={course.course.title}
                    />
                  </td>
                  <td>
                    <span>{course.course.title}</span>
                  </td>
                  <td>
                    {moment(course.validity).toDate() >=
                    moment(new Date()).toDate() ? (
                      <>
                        Expires on{" "}
                        {moment(course.validity).format("MMMM Do YYYY")}
                      </>
                    ) : (
                      <span>
                        <span className="isError">Expired on</span>{" "}
                        <strong>
                          {moment(course.validity).format("MMMM Do YYYY")}
                        </strong>
                      </span>
                    )}

                    <Dropdown overlay={menu} trigger={["click"]}>
                      <a
                        className="btn btn-xs btn-link"
                        onClick={(e) =>
                          handleModalClick(
                            e,
                            course.validity,
                            course.course._id,
                            course.user
                          )
                        }
                      >
                        <i className="ri-pencil-line"></i>
                      </a>
                    </Dropdown>

                    <button
                      className="btn btn-sm btn-danger"
                      onClick={(e) => handleRevokeAccess(course.course._id)}
                    >
                      <i className="ri-delete-bin-line"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal
            title="Change Expiry Date"
            visible={isModalVisible}
            onOk={handleSubmit}
            onCancel={handleCancel}
          >
            <Space direction="vertical">
              <DatePicker
                onChange={onChange}
                defaultValue={moment(exDate)}
                format={dateFormat}
              />
            </Space>
          </Modal>
        </div>
      ) : (
        <div className="alert alert-warning">No course found</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(CoursesList);
