import React, { useState, useEffect } from "react";
import MainContainer from "../../../common/MainContainer";
import Swal from "sweetalert2";
import axios from "axios";
import { connect } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm } from "react-hook-form";
import { userLogout } from "../../../redux/actions/UserActions";

const TermsAndConditions = (props) => {
  const [content, setContent] = useState("");
  const [allDetails, setAllDetails] = useState(null);
  const { register, handleSubmit, watch, errors, setValue } = useForm();
  const basurl = process.env.REACT_APP_URL;
  const basurlHome = process.env.REACT_APP_BASE_URL;
  const userToken = props.user.userToken;
  const onSubmit = (data) => {
    axios
      .post(
        `${basurl}/frontend/terms/add`,
        {
          title: data.title,
          content: content,
          meta_description: data.meta_description,
          meta_title: data.meta_title,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
      });
  };

  // get Terms on page start
  const getTerms = () => {
    axios.get(`${basurl}/frontend/terms`).then((res) => {
      const result = res.data;
      if (result.success) {
        if (result.data) {
          setAllDetails(result.data);
          setContent(result.data.content);
          setValue("title", result.data.title);
          setValue("meta_description", result.data.meta_description);
          setValue("meta_title", result.data.meta_title);
        }
      }
    });
  };

  // Update Terms Info
  const UpdateInfoHandler = (data) => {
    axios
      .put(
        `${basurl}/frontend/terms/edit/${allDetails._id}`,
        {
          title: data.title,
          content: content,
          meta_description: data.meta_description,
          meta_title: data.meta_title,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Terms & Privacy Policy Page Updated successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          getTerms();
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  useEffect(() => {
    getTerms();
  }, []);
  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <h4 className={"header-title"}>
            Frontend Terms & Privacy Policy Page
          </h4>
        </div>
        <div className="card-body">
          <form
            onSubmit={handleSubmit(
              allDetails !== null ? UpdateInfoHandler : onSubmit
            )}
          >
            <div className="form-group">
              <label>Page Heading</label>
              <input
                type="text"
                placeholder={"Page Heading"}
                className="form-control"
                ref={register({ required: true })}
                name={"title"}
              />
              {errors.title && (
                <span className="isError">Page Heading is required.</span>
              )}
            </div>
            <div className="form-group">
              <label>Terms & Privacy Policy Content</label>
              <CKEditor
                editor={ClassicEditor}
                data={content}
                config={{ allowedContent: true }}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContent(data);
                }}
              />
            </div>
            <h4 className={"section_title"}>Seo Section</h4>
            <div className="form-group">
              <label>Meta Title</label>
              <input
                type="text"
                placeholder={"Meta Title"}
                className="form-control"
                ref={register}
                name={"meta_title"}
              />
            </div>
            <div className="form-group">
              <label>Meta Description</label>
              <input
                type="text"
                placeholder={"Meta Description"}
                className="form-control"
                ref={register}
                name={"meta_description"}
              />
            </div>
            <button type="submit" className={"btn btn-success"}>
              Save Terms & Privacy Policy
            </button>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
