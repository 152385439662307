import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import Swal from "sweetalert2";
import { Table } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userLogout } from "../../redux/actions/UserActions";

const MockList = (props) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;

  // get all Orders
  const getAllCategories = () => {
    axios
      .get(`${basurl}/quiz/categories`)
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
        // console.log("all orders", result);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("error>> ", err);
      });
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  //handleDelete
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${basurl}/quiz/categories/delete/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const dl = res.data;
            if (dl.success) {
              Swal.fire(
                "Deleted!",
                "Your Category has been deleted.",
                "success"
              );
              getAllCategories();
            }
          })
          .catch((err) => {
            if (err.response.data === "Invalid token") {
              props.logoutUser();
              localStorage.removeItem("lmsUserInfo");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your token has been expired!, Please login again",
                showConfirmButton: false,
                timer: 1500,
              });
              props.history.push("/");
            }
          });
      }
    });
  };

  // Columns
  const columns = [
    {
      title: "S. No.",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   render: (item) =>
    //     item !== null ? (
    //       <img src={baseUrlImages + "/" + item} width={100} />
    //     ) : (
    //       "NA"
    //     ),
    // },
    {
      title: "Category Name",
      dataIndex: "title",
      key: "title",
      render: (item) => <span>{item}</span>,
    },
    {
      title: "Added on",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (item) => (
        <span>{moment(item.updatedAt).format("Do MMMM YYYY")}</span>
      ),
      //sorter: (a, b) => a.price - b.price,
    },

    {
      title: "Action",
      render: (text, record, index) => (
        <div className="d-flex">
          <Link
            to={`/quizcategories/edit/${record._id}`}
            className="btn  btn-success"
            style={{ minWidth: 100 }}
          >
            <i className="ri-pencil-line"></i>
          </Link>
          <button
            type="button"
            className="btn  btn-danger"
            style={{ marginLeft: 10, minWidth: 100 }}
            onClick={() => handleDelete(record._id)}
          >
            <i className="ri-delete-bin-line"></i>
          </button>
        </div>
      ),
    },
  ];
  return (
    <MainContainer>
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Quiz Categories
            </h4>
            <Link to={"/quizcategories/create"} className="btn btn-primary">
              Add Quiz Category
            </Link>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className="card-body">
          <Table
            columns={columns}
            dataSource={list}
            bordered={true}
            loading={isLoading}
          />
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MockList);
