import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import BlogCard from "./components/BlogCard";
import { DatePicker } from "antd";
import moment from "moment";

const Blogs = (props) => {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [isPublished, setIsPublished] = useState(true);
  const [blogData, setBlogData] = useState([]);
  // for api info
  const basurl = process.env.REACT_APP_URL;
  const imgUrls = process.env.REACT_APP_BASE_URL;
  const userToken = props.user.userToken;

  // get blog listing
  const getBlogListing = () => {
    axios
      .get(`${basurl}/blogs/all-for-admin`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data);
          setBlogData(result.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        Swal.fire({
          position: "center",
          icon: "error",
          title: err.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  // console.log("blog listing >> ", list);
  useEffect(() => {
    getBlogListing();
  }, []);

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  useEffect(() => {
    if (blogData.length > 0) {
      const arr = blogData.filter((item) => item.isPublished === isPublished);
      setList(arr);
    }
  }, [isPublished]);
  // handleRange
  const handleRange = (date) => {
    if (date !== null) {
      let date1 = date[0].toDate();
      let date2 = date[1].toDate();
      const newArr = list.filter((item) => {
        return (
          moment(item.created_at).toDate() <= date2 &&
          moment(item.created_at).toDate() >= date1
        );
      });
      setList(newArr);
    } else {
      setList(blogData);
    }
  };

  // for search filter
  const filteredData = list.filter((val) => {
    if (searchInput === "") {
      return val;
    } else if (val.title.toLowerCase().includes(searchInput.toLowerCase())) {
      return val;
    }
  });
  return (
    <MainContainer>
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Blogs
            </h4>
            <Link to={"/blogs/create"} className={"btn btn-primary"}>
              Create Blog
            </Link>
          </div>
        </div>
      </div>
      <div className={"card mt-4"}>
        {isLoading && <Loader />}
        <div className={"card-body"}>
          <div className={"filters mb-2"}>
            <div className={"row"}>
              <div className={"col-lg-4"}>
                <div className={"search_filter"}>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder={"Search by name..."}
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  {searchInput !== "" && (
                    <span
                      className={"clear_search_text"}
                      onClick={() => setSearchInput("")}
                    >
                      <i className={"la la-times"}></i>
                    </span>
                  )}
                </div>
              </div>
              <div className={"col-lg-4"}>
                <RangePicker
                  format={dateFormat}
                  className={"form-control custom_range_picker"}
                  onChange={handleRange}
                />
              </div>
              <div className={"col-lg-4"}>
                <div className={"custom_switch_course"}>
                  <span
                    className={`customButton ${isPublished ? "active" : ""}`}
                    onClick={() => setIsPublished(true)}
                  >
                    Published
                  </span>
                  <span
                    className={`customButton ${!isPublished ? "active" : ""}`}
                    onClick={() => setIsPublished(false)}
                  >
                    Unpublished
                  </span>
                </div>
              </div>
            </div>
          </div>
          {filteredData.length > 0 ? (
            <div className={"row"}>
              {filteredData.map((item, index) => (
                <div key={index} className={"col-md-4"}>
                  <BlogCard
                    image={item.image}
                    title={item.title}
                    content={item.content}
                    publishDate={item.publish_date}
                    tags={item.tags}
                    isPublished={item.isPublished}
                    views={item.views}
                    id={item._id}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className={"alert alert-info text-center"}>No blogs found</div>
          )}
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(Blogs);
