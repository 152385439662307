const INITIAL_STATE = {
  user: null,
  isLoggedin: false,
  userToken: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "USER_LOGGEDIN":
      return {
        ...state,
        isLoggedin: action.payload,
        user: action.info,
        userToken: action.token,
      };
    case "USER_LOGOUT":
      return {
        ...state,
        isLoggedin: action.payload,
        user: action.info,
        userToken: action.token,
      };
      case "USER_INFO_UPDATE":
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
export default userReducer;
