import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { userLoggedIn } from "../../store/actions/UserActions";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import logodark from "../../assets/images/logo-dark.png";

const ForgetPasswordPage = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();
  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_URL}/users/forgot-password-admin`, {
        email: data.email,
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result.message,
            showConfirmButton: false,
            timer: 2500,
          });
          history.push(`/forgot-password-otp/${data.email}`);
        } else {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
        setIsLoading(false);
        // console.log("Login result >> ", result);
      })
      .catch(function (error) {
        setIsLoading(false);
        // handle error
        console.log("error", error);
      });
  };

  // console.log("vikas",pwdRef.current["password"])
  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <Row className="no-gutters">
          <Col lg={5}>
            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div className="w-100">
                <Row
                  className="justify-content-center"
                  style={{ marginTop: -10 }}
                >
                  <Col lg={9}>
                    <div>
                      <div className="text-left">
                        <div>
                          <Link to="/" className="logo">
                            <img src={logodark} height="50" alt="logo" />
                          </Link>
                        </div>

                        <h4 className="login_title mt-4 text-left">
                          Forgot password?
                        </h4>
                        <div className="mt-4">
                          <strong>
                            Enter the email address you used to create your
                            MyPrepZone account. We’ll send you an OTP on email.
                          </strong>
                        </div>
                      </div>
                      <div className="mt-4">
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <FormGroup className="mb-4">
                            <Label htmlFor="username">Email address</Label>
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder=""
                              name="email"
                              style={{
                                borderColor: errors.password ? "red" : "",
                              }}
                              ref={register({
                                required: "Email address is required.",
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: "invalid email address",
                                },
                              })}
                            />
                            {errors.email && (
                              <div className="text-danger mb-2" role="alert">
                                {errors.email.message}
                              </div>
                            )}
                          </FormGroup>

                          <div className="mt-4 text-center">
                            {isLoading ? (
                              <Button
                                color="primary"
                                block
                                className="w-md waves-effect waves-light"
                                type="button"
                              >
                                Loading...
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                block
                                className="w-md waves-effect waves-light"
                                type="submit"
                              >
                                Send OTP
                              </Button>
                            )}
                          </div>
                          <div className="mt-4 text-left">
                            <span>
                              Take me back to{" "}
                              <Link className="" to="/login">
                                <strong>Login</strong>
                              </Link>
                            </span>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={7}>
            <div className="authentication-bg">
              <div className="bg-overlay"></div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggedinUser: (values) => {
      dispatch(userLoggedIn(values));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordPage);
