import React from "react";

const ThumbnailComponent = ({
  data,
  videoListName,
  handleLink,
  index,
  selectedLink,
}) => {
  const extention = data.Key.split(".").pop();

  // render icon and images
  const renderImage = (ext, path) => {
    if (ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "gif") {
      return <img src={path} alt={ext} />;
    } else if (ext === "mp3") {
      return (
        <div className="box_icon_media">
          <i className="ri-file-music-line ff_big_icon"></i>
        </div>
      );
    } else if (ext === "mp4") {
      return (
        <div className="box_icon_media">
          <i className="ri-video-line ff_big_icon"></i>
        </div>
      );
    } else if (ext === "pdf" || ext === "svg") {
      return (
        <div className="box_icon_media">
          <i className="ri-file-4-line ff_big_icon"></i>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div
        className="list_component_wrapper"
        onClick={() =>
          handleLink(
            `https://${videoListName}.s3.ap-south-1.amazonaws.com/${data.Key}`,
            index
          )
        }
      >
        <div className="component_header box_icon_media">
          {renderImage(
            extention,
            `https://${videoListName}.s3.ap-south-1.amazonaws.com/${data.Key}`
          )}
        </div>
        <div className="component_body">
          <h6 className="component_body_title">{data.Key}</h6>
        </div>
        {data.isSelect ||
          (selectedLink ==
            `https://${videoListName}.s3.ap-south-1.amazonaws.com/${data.Key}` && (
            <div className="component_selected">
              <span className="checked_icon">
                <i className="ri-checkbox-circle-fill"></i>
              </span>
            </div>
          ))}
      </div>
    </>
  );
};
export default ThumbnailComponent;
