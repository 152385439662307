import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import Swal from "sweetalert2";
import axios from "axios";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import { Label, Input } from "reactstrap";
import { userLogout } from "../../redux/actions/UserActions";

// import logo images
import RazorPayLogo from "../../assets/images/razorpay-logo.svg";
import InstamojoLogo from "../../assets/images/instamojo.png";

const Settings = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [razorpay, setRazorpay] = useState(false);
  const [instamozo, setInstamozo] = useState(false);
  const [data, setData] = useState(null);
  const basurl = process.env.REACT_APP_URL;
  const imgUrls = process.env.REACT_APP_BASE_URL;
  const userToken = props.user.userToken;

  // handleInsta
  const handleInsta = () => {
    setInstamozo(true);
    setRazorpay(false);
  };

  // handleRazorpay
  const handleRazorpay = () => {
    setRazorpay(true);
    setInstamozo(false);
  };

  // get setting details
  const getSettingDetails = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/setting/details`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setData(result.data);
          setInstamozo(
            result.data[0].paymentMethod === "instamojo" ? true : false
          );
          setRazorpay(
            result.data[0].paymentMethod === "razorpay" ? true : false
          );
        }
        console.log("result >>", result);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          // props.history.push("/");
        }
        console.log(err);
      });
  };

  useEffect(() => {
    getSettingDetails();
  }, []);

  // handleForm
  const handleForm = () => {
    setIsLoading(true);
    axios
      .post(
        `${basurl}/setting/${
          data === null ? "create" : `update/${data[0]._id}`
        }`,
        {
          paymentMethod: razorpay ? "razorpay" : "instamojo",
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        console.log("result ", result);
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your payment method updated successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  return (
    <MainContainer>
      <div className={"card"}>
        {isLoading && <Loader />}
        <div className={"card-header"}>
          <div className="row">
            <div className="col-sm-6">
              <h4>Settings</h4>
            </div>
          </div>
        </div>
        <div className={"card-body"}>
          <div className="block-row">
            <h4 className="section_title">Payment Options:</h4>
            <div className="logo_wrappers">
              <div className="logo_box">
                <div
                  className="custom-control custom-radio mb-2"
                  onClick={handleRazorpay}
                >
                  <Input
                    type="radio"
                    id="razorpay"
                    name="positions"
                    className="custom-control-input"
                    value={razorpay}
                    checked={razorpay ? "checked" : ""}
                  />
                  <Label className="custom-control-label" for="razorpay">
                    <img src={RazorPayLogo} alt="Razorpay" width={120} />
                  </Label>
                </div>
              </div>
              <div className="logo_box">
                <div
                  className="custom-control custom-radio mb-2"
                  onClick={handleInsta}
                >
                  <Input
                    type="radio"
                    id="instamozo"
                    name="positions"
                    className="custom-control-input"
                    value={instamozo}
                    checked={instamozo ? "checked" : ""}
                  />
                  <Label className="custom-control-label" for="instamozo">
                    <img src={InstamojoLogo} alt="Razorpay" width={120} />
                  </Label>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className={"d-flex mt-3"}>
            <button
              type="button"
              className="btn btn-success"
              style={{ marginRight: 15 }}
              onClick={handleForm}
            >
              Save Settings
            </button>
            <button
              type={"button"}
              className="btn btn-primary"
              onClick={() => props.history.goBack()}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
