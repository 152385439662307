import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { Table } from "antd";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { userLogout } from "../../redux/actions/UserActions";

const CourseReviews = (props) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;

  // get all Orders
  const getAllReviews = () => {
    axios
      .get(`${basurl}/course-reviews/all-admin`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
        // console.log("all reviews", result);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        console.log("error>> ", err);
      });
  };

  // unPublish review
  const unPublishNow = (item) => {
    console.log("", item);
    setIsLoading(true);
    axios
      .put(
        `${basurl}/course-reviews/unpublish/${item._id}`,
        {},
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;

        if (result.success) {
          getAllReviews();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "The review is unpublished",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
        console.log("resut data >>", result);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("error >> ", err);
      });
  };
  // Publish review
  const publishNow = (item) => {
    console.log("", item);
    setIsLoading(true);
    axios
      .put(
        `${basurl}/course-reviews/publish-review/${item._id}`,
        {},
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          getAllReviews();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "The review is published",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
        console.log("resut data >>", result);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("error >> ", err);
      });
  };

  //handleDelete
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        setIsLoading(true);
        axios
          .delete(`${basurl}/course-reviews/delete/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const result = res.data;
            if (result.success) {
              getAllReviews();
              Swal.fire({
                position: "center",
                icon: "success",
                title: "The review is deleted",
                showConfirmButton: false,
                timer: 1500,
              });
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
            console.log("resut data >>", result);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("error >> ", err);
          });
      }
    });
  };

  useEffect(() => {
    getAllReviews();
  }, []);

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  //   console.log("all reviews", list);

  // Columns
  const columns = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (item) => (
        <span>
          {item ? <Link to={`/student/${item._id}`}>{item.name}</Link> : "NA"}
        </span>
      ),
    },
    {
      title: "Course",
      dataIndex: "course",
      key: "title",
      render: (item) => <span>{item.title}</span>,
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      render: (item) => <span>{item}</span>,
      //sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (item) => <span>{item}</span>,
      //sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Comment on",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (item) => <span>{moment(item).format("MMMM Do YYYY")}</span>,
      //sorter: (a, b) => a.updatedAt - b.updatedAt,
    },
    {
      title: "Status",
      dataIndex: "isPublish",
      key: "isPublish",
      render: (item) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {item ? (
            <span className="text-success">Published</span>
          ) : (
            <span className="text-danger">Unpublished</span>
          )}
        </span>
      ),
      //sorter: (a, b) => a.success - b.success,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (item) => (
        <div style={{ whiteSpace: "nowrap" }}>
          <span>
            {item.isPublish ? (
              <button
                className="btn btn-danger"
                onClick={() => unPublishNow(item)}
              >
                Unpublish now
              </button>
            ) : (
              <button
                className="btn btn-success"
                onClick={() => publishNow(item)}
              >
                Publish now
              </button>
            )}
          </span>
          <span className="ml-2">
            <button
              className="btn btn-danger"
              onClick={() => handleDelete(item._id)}
            >
              <i className="ri-delete-bin-line"></i>
            </button>
          </span>
        </div>
      ),
    },
  ];
  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-sm-6">
              <h4>All Course Reviews</h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="courses_wrapper">
            <Table
              columns={columns}
              dataSource={list}
              bordered={true}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CourseReviews);
