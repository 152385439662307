import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  Input,
  Button,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import { browserName, isMobile, osName } from "react-device-detect";
import Swal from "sweetalert2";
import { userLoggedIn } from "../../store/actions/UserActions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import logodark from "../../assets/images/logo-dark.png";

const Login = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [ip, setIP] = useState("");
  const [browsersName, setBrowserName] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [os, setOs] = useState("");
  const pwdRef = useRef("");
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    setIP(res.data);
  };

  useEffect(() => {
    getData();
    setBrowserName(browserName);
    setDeviceName(isMobile ? "Mobile" : "Desktop");
    setOs(osName);
  }, []);
  console.log("asd", ip);
  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_URL}/users/loginforAdmin`, {
        email: data.userName,
        password: data.password,
        device: deviceName.toString(),
        browser: browsersName.toString(),
        ipAddress: ip?.IPv4,
        lat: ip?.latitude,
        long: ip?.longitude,
        country: ip?.country_name,
        state: ip?.state,
        city: ip?.city,
        os: os.toString(),
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          // alert("you are loggedin successfully");
          const values = {
            userInfo: result.user,
            token: result.token,
            status: true,
          };
          localStorage.setItem("lmsUserInfo", JSON.stringify(values));
          props.loggedinUser(values);
          props.history.push("/dashboard");
          setIsLoading(false);
        } else {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        // console.log("Login result >> ", result);
      })
      .catch(function (error) {
        // handle error
        setIsLoading(false);
        console.log("error", error);
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>LMSPlus: Login</title>
        <meta name="description" content="Myprepzone meta description" />
      </Helmet>
      <Container fluid className="p-0">
        <Row className="no-gutters">
          <Col lg={5}>
            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div className="w-100">
                <Row
                  className="justify-content-center"
                  style={{ marginTop: -30 }}
                >
                  <Col lg={9}>
                    <div>
                      <div className="text-left">
                        <div className="">
                          <Link to="/" className="logo">
                            <img src={logodark} height="50" alt="logo" />
                          </Link>
                        </div>
                        <h4 className="login_title mt-4 text-left">
                          Hi, welcome back
                        </h4>
                      </div>
                      <div className="mt-4">
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmit)}
                          ref={pwdRef}
                        >
                          <FormGroup className="mb-4">
                            <Label htmlFor="username">Email address</Label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              placeholder=""
                              name="userName"
                              style={{
                                borderColor: errors.password ? "red" : "",
                              }}
                              ref={register({
                                required: "Email address is required.",
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: "invalid email address",
                                },
                              })}
                            />
                            {errors.userName && (
                              <div className="text-danger mb-2" role="alert">
                                {errors.userName.message}
                              </div>
                            )}
                          </FormGroup>
                          <FormGroup className="mb-4 position-relative">
                            <Label htmlFor="userpassword">Password</Label>
                            <input
                              type={!isPasswordOpen ? "password" : "text"}
                              className="form-control"
                              id="user-password"
                              placeholder=""
                              name="password"
                              style={{
                                borderColor: errors.password ? "red" : "",
                              }}
                              ref={register({
                                required: "You must specify a password",
                                minLength: {
                                  value: 8,
                                  message:
                                    "Password must have at least 8 characters",
                                },
                                pattern: {
                                  value:
                                    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}/,
                                  message:
                                    "Please use atleast 1 special character, and 1 capital letter in the password.",
                                },
                              })}
                            />
                            <span
                              className={"eye_button"}
                              onClick={() => setIsPasswordOpen(!isPasswordOpen)}
                            >
                              {isPasswordOpen ? (
                                <i className="mdi mdi-eye"></i>
                              ) : (
                                <i className="mdi mdi-eye-off"></i>
                              )}
                            </span>
                            {errors.password && (
                              <div className="text-danger mb-2" role="alert">
                                {errors.password.message}
                              </div>
                            )}
                          </FormGroup>
                          <div className="mt-4 text-center">
                            {isLoading ? (
                              <Button
                                color="primary"
                                block
                                className="w-md waves-effect waves-light"
                                type="button"
                              >
                                Loading...
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                block
                                className="w-md waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </Button>
                            )}
                          </div>
                          <div className="mt-4 text-left">
                            <Link className="" to="/forgot-password">
                              <i className="mdi mdi-lock mr-1" />{" "}
                              <strong>Forgot your password?</strong>
                            </Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={7}>
            <div className="authentication-bg">
              <div className="bg-overlay"></div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggedinUser: (values) => {
      dispatch(userLoggedIn(values));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
