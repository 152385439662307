import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    const role = this.props.user?.role;
    // console.log("user>>>", role);
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{this.props.t("Dashboard")}</li> */}

            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ri-home-4-line"></i>
                {/* <span className="badge badge-pill badge-success float-right">
                  3
                </span> */}
                <span className="ml-1">{this.props.t("Dashboard")}</span>
              </Link>
            </li>
            {role === "Admin" && (
              <>
                <li>
                  <Link to="/students" className=" waves-effect">
                    <i className="ri-file-user-line"></i>
                    <span className="ml-1">{this.props.t("Students")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/popup" className=" waves-effect">
                    <i className="ri-chat-quote-line"></i>
                    <span className="ml-1">{this.props.t("Manage Popup")}</span>
                  </Link>
                </li>
              </>
            )}
            {role === "Admin" && (
              <li>
                <Link to="/courseReviews" className=" waves-effect">
                  <i className="ri-user-star-line"></i>
                  <span className="ml-1">{this.props.t("Reviews")}</span>
                </Link>
              </li>
            )}
            <li>
              <Link to="/discussion" className=" waves-effect">
                <i className="ri-message-2-line"></i>
                <span className="ml-1">{this.props.t("Discussion")}</span>
              </Link>
            </li>

            {role === "Admin" && (
              <li>
                <Link to="/testimonials" className=" waves-effect">
                  <i className="ri-user-star-line"></i>
                  <span className="ml-1">{this.props.t("Testimonials")}</span>
                </Link>
              </li>
            )}

            {/* <li className="menu-title">{this.props.t("Gallery")}</li> */}
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-movie-line"></i>
                <span className="ml-1">{this.props.t("Gallery")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/media">{this.props.t("Media")}</Link>
                </li>

                <li>
                  <Link to="/video-list">{this.props.t("Videos")}</Link>
                </li>
              </ul>
            </li>

            {role === "Admin" && (
              <li>
                <Link to="/roles" className=" waves-effect">
                  <i className="ri-shield-user-line"></i>
                  <span className="ml-1">{this.props.t("Roles")}</span>
                </Link>
              </li>
            )}
            {role === "Admin" && (
              <li>
                <Link to="/predictors" className=" waves-effect">
                  <i className="ri-magic-line"></i>
                  <span className="ml-1">
                    {this.props.t("College Predictor")}
                  </span>
                </Link>
              </li>
            )}
            {role === "Admin" && (
              <li>
                <Link to="/frontend/articles" className=" waves-effect">
                  <i className="ri-article-line"></i>
                  <span className="ml-1">{this.props.t("Daily Reads")}</span>
                </Link>
              </li>
            )}

            {/* <li>
              <Link to="/documents" className=" waves-effect">
                <i className="ri-file-line"></i>
                <span className="ml-1">{this.props.t("Documents")}</span>
              </Link>
            </li> */}

            {/* <li className="menu-title">{this.props.t("Content")}</li> */}
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-book-open-line"></i>
                <span className="ml-1">{this.props.t("Courses")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {role === "Admin" && (
                  <li>
                    <Link to="/categories">{this.props.t("Categories")}</Link>
                  </li>
                )}

                <li>
                  <Link to="/courses">{this.props.t("Courses")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-file-list-3-line"></i>
                <span className="ml-1">{this.props.t("Results")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/results/categories">
                    {this.props.t("Categories")}
                  </Link>
                </li>
                <li>
                  <Link to="/results">{this.props.t("Results")}</Link>
                </li>
              </ul>
            </li>
            {/*<li>*/}
            {/*  <Link to="/testimonials" className=" waves-effect">*/}
            {/*    <i className="ri-file-user-line"></i>*/}
            {/*    <span className="ml-1">{this.props.t("Testimonials")}</span>*/}
            {/*  </Link>*/}
            {/*</li>*/}
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-article-line"></i>
                <span className="ml-1">{this.props.t("Blogs")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/blogs/blogcategory">
                    {this.props.t("Categories")}
                  </Link>
                </li>
                <li>
                  <Link to="/blogs">{this.props.t("Blogs")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-question-line"></i>
                <span className="ml-1">{this.props.t("Quiz")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/quiz/categories">
                    {this.props.t("Categories")}
                  </Link>
                </li>
                <li>
                  <Link to="/quiz">{this.props.t("Quiz List")}</Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-macbook-line"></i>
                <span className="ml-1">{this.props.t("Mocks")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/mockcategories">{this.props.t("Categories")}</Link>
                </li>
                <li>
                  <Link to="/mock">{this.props.t("Mocks List")}</Link>
                </li>
              </ul>
            </li> */}
            {role === "Admin" && (
              <li>
                <Link to="/bundles" className="waves-effect">
                  <i className="ri-server-line"></i>
                  <span className="ml-1">{this.props.t("Bundles")}</span>
                </Link>
              </li>
            )}

              <li>
                <Link to="/coupons" className=" waves-effect">
                  <i className="ri-coupon-2-line"></i>
                  <span className="ml-1">{this.props.t("Coupons")}</span>
                </Link>
              </li>

            {/* <li>
              <Link to="/announcements" className=" waves-effect">
                <i className="ri-volume-up-line"></i>
                <span className="ml-1">{this.props.t("Announcements")}</span>
              </Link>
            </li> */}
            {role === "Admin" && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-line-chart-line"></i>
                  <span className="ml-1">{this.props.t("Reports")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {/* <li>
                  <Link to="/reports/all-orders">{this.props.t("All Orders")}</Link>
                </li> */}
                  <li>
                    <Link to="/reports/transactions">
                      {this.props.t("Transactions")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {role === "Admin" && (
              <>
                <li className="menu-title">{this.props.t("Front Website")}</li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-computer-line"></i>
                    <span className="ml-1">{this.props.t("Website")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <Link to="/frontend/HomePage">
                        {this.props.t("Home Page")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/frontend/topperscategory">
                        {this.props.t("Topper Categories")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/frontend/toppers">
                        {this.props.t("Toppers")}
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/frontend/banners">
                        {this.props.t("Home Page Slider")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/announcements">
                        {this.props.t("Announcements")}
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/frontend/general">
                        {this.props.t("General Info")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/frontend/partners">
                        {this.props.t("Partner Logos")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/frontend/aboutus">
                        {this.props.t("About Us Page")}
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/frontend/terms-privacy-policy">
                        {this.props.t("Terms & Conditions")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/frontend/contact-us">
                        {this.props.t("Contact Us Page")}
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/frontend/contacts">
                        {this.props.t("Contact Us Enquiries")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-pages-line"></i>
                    <span className="ml-1">{this.props.t("SEO")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    
                    <li>
                      <Link to="/frontend/seo/metadata">
                        {this.props.t("Meta Data")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            <>
              <li className="menu-title">{this.props.t("Admin")}</li>
              <li>
                <Link to="/email" className="waves-effect">
                  <i className="ri-mail-send-line"></i>

                  <span className="ml-1">{this.props.t("Email")}</span>
                </Link>
              </li>
            </>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-settings-2-line"></i>
                <span className="ml-1">{this.props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {role === "Admin" && (
                  <li>
                    <Link to="/payment">{this.props.t("Payment Method")}</Link>
                  </li>
                )}
                <li>
                  <Link to="/admin/change-password">
                    {this.props.t("Change Password")}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/edit-profile">
                    {this.props.t("Edit Profile")}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/bill">{this.props.t("Billing Info")}</Link>
                </li>
              </ul>
            </li>
          </ul>
          {/* <div style={{ paddingRight: 10, paddingLeft: 10 }}>
            <a
              href="https://console.aws.amazon.com/console/home"
              target="_blank"
              class="btn btn-warning btn-block"
            >
              Login AWS Console
            </a>
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout, user: state.user?.user };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
