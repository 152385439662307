import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { updateDashboard } from "../../store/actions/DashboardActions";
import { userLogout, userLoggedIn } from "../../store/actions/UserActions";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/celebration";

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import ActivatedUserModal from "./../../components/ActivatedUserModal";
import Loader from "../../common/Loader";
import { useHistory } from "react-router-dom";

const Dashboard = (props) => {
  const basurl = process.env.REACT_APP_URL;
  const [modalOpen, setModalOpen] = useState(false);
  const [isOpenLottify, setIsOpenLottify] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const role = useSelector((state) => state.user?.user?.role);
  const user = useSelector((state) => state.user?.user);
  const isActiveUser = user?.isActive;
  const history = useHistory();
  // console.log("Dashboard>>", props.dashboard);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // console.log("props.user", props.user);

  useEffect(() => {
    if (props.user?.user?.role == "Faculty") {
      setIsLoading(false);
    }
  }, [props.user]);

  useEffect(() => {
    if (props.billStatus == "over") {
      history.push("/admin/bill");
    }
  }, [props.billStatus]);

  const getDashboardData = () => {
    axios
      .get(`${basurl}/dashboard/`, {
        headers: {
          "auth-token": props.user.userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        // console.log(result);
        if (result.success) {
          props.sendDataDashboard(result.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        setIsLoading(false);
        // console.log("aayo re", props);
      });
  };

  const toggle = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    if (props.user.user) {
      setModalOpen(props.user.user.welcomeNote);
    }
    if (role === "Admin") {
      getDashboardData();
    }
  }, []);

  const handleWelcomeNote = () => {
    setModalOpen(false);
    axios
      .post(
        `${basurl}/users/welcome-note-disable`,
        {
          user: props.user.user.id,
        },
        {
          headers: {
            "auth-token": props.user.userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          const values = {
            userInfo: result.user,
            token: result.token,
            status: true,
          };
          localStorage.setItem("lmsUserInfo", JSON.stringify(values));
          props.loggedinUser(values);
        }
        console.log("result>>", result);
      });
  };

  const getUserDetails = () => {
    axios
      .put(
        `${basurl}/users/update/${props.user?.user?.id}`,
        {
          isActive: true,
        },
        {
          headers: {
            "auth-token": props.user.userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          const values = {
            userInfo: result.data,
            token: result.token,
            status: true,
          };
          setIsOpenLottify(true);

          setTimeout(() => {
            localStorage.setItem("lmsUserInfo", JSON.stringify(values));
            props.loggedinUser(values);
          }, 2000);
          setTimeout(() => {
            setIsOpenLottify(false);
          }, 3000);
        }
        // console.log("result>>", result);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading && <Loader />}
        {isOpenLottify && (
          <div className="lottiefy_wrapper">
            <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
          </div>
        )}
        {!isActiveUser && (
          <ActivatedUserModal getUserDetails={getUserDetails} />
        )}
        <Modal
          isOpen={modalOpen}
          toggle={toggle}
          // returnFocusAfterClose={this.state.focusAfterClose}
          centered={true}
          size={"lg"}
        >
          <ModalHeader toggle={handleWelcomeNote}>
            Hey {props.user.user.name}!
          </ModalHeader>
          <ModalBody className="text-center">
            <h5>
              Thanks for signing up. Your Myprepzone account is ready to go!🎉
            </h5>
            <p>
              Your account includes adding videos, creating mocks & quizzes,
              third party integration services, and a lot more. If you ever need
              more features, you can always upgrade to our add-on services.
            </p>
            <p>
              Feel free to shoot your queries [at]{" "}
              <a href="mailto:sales@lmsplus.in">sales@lmsplus.in</a>
            </p>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="primary" onClick={handleWelcomeNote}>
              Ok, Let's roll the class
            </Button>
          </ModalFooter>
        </Modal>
        <Container fluid>
          {role === "Admin" ? (
            <Row>
              <Col xl={8}>
                <Row>
                  <MiniWidgets
                    title="Total Sales"
                    value={props.dashboard.totalIncome}
                    icon="ri-money-dollar-circle-line"
                    rupee
                  />
                  <MiniWidgets
                    title="Total Courses"
                    value={props.dashboard.totalCourese}
                    icon="ri-book-open-line"
                  />
                  <MiniWidgets
                    title="Total Students"
                    value={props.dashboard.totalStudent}
                    icon="ri-file-user-line"
                  />
                </Row>
                <Row>
                  <MiniWidgets
                    title="Total Mocks"
                    value={props.dashboard.totalMocks}
                    icon="ri-book-3-line"
                  />
                  <MiniWidgets
                    title="Total Quizes"
                    value={props.dashboard.totalQuiz}
                    icon="ri-book-3-line"
                  />
                  <MiniWidgets
                    title="Total Bundles"
                    value={props.dashboard.totalBundles}
                    icon="ri-book-3-line"
                  />
                </Row>

                {/* revenue Analytics */}
                {props.dashboard.totalIncomeData.length > 0 && (
                  <RevenueAnalytics data={props.dashboard.totalIncomeData} />
                )}
              </Col>

              <Col xl={4}>
                {/* sales Analytics */}
                {props.dashboard.totalIncomeData.length > 0 && (
                  <SalesAnalytics data={props.dashboard} />
                )}

                {/* earning reports */}
                {/* <EarningReports /> */}
              </Col>
            </Row>
          ) : (
            <div className="card">
              <div className="card-header">
                <h4>Dashboard</h4>
              </div>
              <div className="card-body">
                <h5>Welcome {user.name}</h5>
              </div>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    dashboard: state.dashboard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendDataDashboard: (data) => {
      dispatch(updateDashboard(data));
    },
    loggedinUser: (values) => {
      dispatch(userLoggedIn(values));
    },
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
