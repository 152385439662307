import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { Tooltip } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../common/Loader";
import QRCode from "../../assets/ankitmishra.png";
import moment from "moment";

const BillPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  let history = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const basurl = process.env.REACT_APP_URL;
  const imgUrls = process.env.REACT_APP_BASE_URL;
  const userToken = user.userToken;

  // get user details
  const getUserDetails = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/bill/getinfo`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setDetails(result.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data === "Invalid token") {
          dispatch({
            type: "USER_LOGOUT",
            payload: false,
            token: null,
            info: null,
          });
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const getPaymentStatus = (expiryDate, paymentDate) => {
    // Get the last day of the month of the expiry date.
    const lastDayOfMonth = new Date(
      expiryDate.getFullYear(),
      expiryDate.getMonth() + 1,
      0
    ).getDate();

    // current date
    const currentDate = new Date();

    // Calculate the due date, which is 7 days before the last day of the month.
    const dueDate = new Date(
      expiryDate.getFullYear(),
      expiryDate.getMonth(),
      expiryDate.getDate() - 6
    );

    // Check if the payment date is already past the expiry date.
    if (currentDate > expiryDate) {
      return <label className="badge badge-pill badge-danger">Over Due</label>;
    }

    // Check if the payment date is already past the due date.
    if (currentDate >= dueDate && currentDate <= expiryDate) {
      return <label className="badge badge-pill badge-warning">Due</label>;
    }

    // Otherwise, the payment is still on time.
    return <label className="badge badge-pill badge-success">Paid</label>;
  };

  //   console.log(details);

  // Payment Verify email
  const paymentVerify = async () => {
    setIsLoading(true);
    axios
      .post(`${basurl}/bill/payment-verify`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          alert(result.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data === "Invalid token") {
          dispatch({
            type: "USER_LOGOUT",
            payload: false,
            token: null,
            info: null,
          });
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        setIsLoading(false);
      });
  };

  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Bill Information
            </h4>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        {details !== null ? (
          <div className={"card-body"}>
            <div className="row">
              <div className="col-md-8">
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="">Payment Status:</label>
                  </div>
                  <div className="col-sm-8">
                    <p>
                      {getPaymentStatus(
                        new Date(details?.endDate),
                        new Date(details?.startDate)
                      )}
                    </p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="">Due Date:</label>
                  </div>
                  <div className="col-sm-8">
                    <p>{moment(details?.endDate).format("MMMM Do YYYY")}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label>For payment</label>
                  <div className="mb-3" style={{ maxWidth: 250 }}>
                    <img
                      src={QRCode}
                      alt="Ankit Mishra"
                      className="img-fluid"
                    />
                  </div>
                  <div className="mb-3">
                    <label>
                      Pay via QR and after successfull payment click below
                      button "Verify Payment"{" "}
                      <Tooltip
                        // {...args}
                        isOpen={tooltipOpen}
                        target="TooltipExample"
                        toggle={toggle}
                      >
                        For Payment Verification, click on Verify Payment, a
                        confirmation email goes to Super Admin.
                      </Tooltip>
                      <i
                        className="ri-information-line"
                        id="TooltipExample"
                        style={{ fontSize: 20, position: "relative", top: 4 }}
                      ></i>
                    </label>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => paymentVerify()}
                  >
                    Verify Payment
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </MainContainer>
  );
};
export default BillPage;
