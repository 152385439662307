import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import Swal from "sweetalert2";
import { Table } from "antd";
import "antd/dist/antd.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";
import { useSelector } from "react-redux";
import ListComponent from "./components/ListComponent";
import ThumbnailComponent from "./components/ThumbnailComponent";
import moment from "moment";

const MediaScreen = (props) => {
  const [videoList, setVideoList] = useState(null);
  const [originalVideoList, setOriginalVideoList] = useState(null);
  const [videoUploader, setVideoUploader] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGridView, setIsGridView] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [selectedType, setSelectedType] = useState(0);
  const basurl = process.env.REACT_APP_URL;
  const user = useSelector((state) => state.user);
  const userToken = user.userToken;
  //   console.log("base url >> ", basurl);
  const getVideosList = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/video-list`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const videoList = res.data;
        if (videoList.success) {
          setVideoList(videoList.data);
          setOriginalVideoList(videoList.data);
          const newArray = videoList.data.Contents.map((item) => {
            return {
              ...item,
              isSelect: false,
            };
          });
          if (newArray.length > 0) {
            const finalArr = newArray.sort((a, b) => {
              return (
                moment(a.LastModified).toDate() -
                moment(b.LastModified).toDate()
              );
            });
            setList(finalArr.reverse());
          } else {
            setList([]);
          }
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        console.log(err);
      });
  };

  useEffect(() => {
    getVideosList();
  }, []);

  // console.log("main media list>>>", list);

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new" },
    region: "ap-south-1",
  });

  const handleDelete = (key) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this file ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const paramm = {
          Bucket: "lms-videos-bucket-prepzone-new",
          Key: key,
        };
        myBucket.deleteObject(paramm, (err, data) => {
          if (err) {
            console.log(err);
          } else {
            getVideosList();
          }
        });
      }
    });
  };

  const uploadVideoHandler = (files) => {
    console.log("video file >> ", files[0]);
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setVideoProgress(percent);
        // console.log(
        //   "progress video upload :: ",
        //   Math.round((evt.loaded / evt.total) * 100)
        // );
      })
      .on("success", (evt) => {
        // console.log("mujhe khusi hogi >>>", evt);
        getVideosList();
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // console.log("error >> ", err);
        } else {
          setVideoUploader(false);
          // console.log("aljkskdasd", data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "File Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  // handleTypes
  const handleTypes = (evt) => {
    const type = evt.target.value;
    setSelectedType(type);
    console.log("type >> ", type);
  };

  // for search filter
  const filteredData = list.filter((val) => {
    if (searchInput === "") {
      return val;
    } else if (val.Key.toLowerCase().includes(searchInput.toLowerCase())) {
      return val;
    }
  });

  useEffect(() => {
    if (selectedType == 1) {
      const videos = videoList?.Contents.filter((video) => {
        const extention = video.Key.split(".").pop();
        return (
          extention === "mp4" ||
          extention === "avi" ||
          extention === "mkv" ||
          extention === "flv"
        );
      });

      setList(videos);
    } else if (selectedType == 3) {
      const images = videoList?.Contents.filter((imgs) => {
        const extention = imgs.Key.split(".").pop();
        return (
          extention === "jpg" ||
          extention === "png" ||
          extention === "gif" ||
          extention === "svg"
        );
      });

      setList(images);
    } else if (selectedType == 2) {
      const pdfs = videoList?.Contents.filter((pdf) => {
        const extention = pdf.Key.split(".").pop();
        return extention === "pdf";
      });

      setList(pdfs);
    } else if (selectedType == 4) {
      const audios = videoList?.Contents.filter((audio) => {
        const extention = audio.Key.split(".").pop();
        return extention === "mp3";
      });

      setList(audios);
    } else {
      getVideosList();
    }
  }, [selectedType]);

  //handleLink
  const handleLink = (value, ind) => {
    const oldArrayItem = list;
    const newArray = oldArrayItem.map((item, index) => {
      if (index === ind) {
        return {
          ...item,
          isSelect: !item.isSelect,
        };
      } else {
        return {
          ...item,
          isSelect: false,
        };
      }
    });
    setList(newArray);
    props.mediaLink(value);
  };

  // console.log("list items>>", list);

  return (
    <>
      {videoProgress > 0 && videoProgress < 99 ? (
        <div className={"progress_wrapper"} style={{ marginBottom: "10px" }}>
          <div className="progress_wrap">
            <CircularProgressbar
              value={videoProgress}
              strokeWidth={50}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathColor: "#33b3f2",
              })}
            />
          </div>
        </div>
      ) : null}

      <div className="filter_options">
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-sm-5">
                <select
                  className="form-control"
                  onChange={(e) => handleTypes(e)}
                  value={selectedType}
                >
                  <option value={0}>All Media</option>
                  {/* <option value={1}>Videos</option> */}
                  <option value={2}>PDF</option>
                  <option value={3}>Images</option>
                  {/* <option value={4}>Audio & Music</option> */}
                </select>
              </div>
              <div className="col-sm-2">
                <span
                  className="layout_change_icon"
                  onClick={() => setIsGridView(!isGridView)}
                >
                  {isGridView ? (
                    <i className="ri-layout-grid-line"></i>
                  ) : (
                    <i className="ri-list-check-2"></i>
                  )}
                </span>
              </div>
              <div className="col-sm-5">
                <button
                  className="btn btn-primary"
                  onClick={() => setVideoUploader(!videoUploader)}
                >
                  <i className="fas fa-plus"></i> Add Media
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row justify-content-end">
              <div className="col-lg-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {videoUploader && (
        <div className={"section_video_uploader"}>
          <Dropzone
            onDrop={(acceptedFiles) => uploadVideoHandler(acceptedFiles)}
            // accept={"video/mp4, video/avi, video/mkv, video/flv"}
          >
            {({ getRootProps, getInputProps }) => (
              <section className={"dropzone_wrapper"}>
                <span
                  className="close_icon_dropzone"
                  onClick={() => setVideoUploader(false)}
                >
                  <i className="ri-close-line"></i>
                </span>
                <div {...getRootProps()} className={"dropZone"}>
                  <input {...getInputProps()} />
                  <div className="d-flex flex-column">
                    <p>Drop files to upload</p>
                    <p className="text-center mt-2 mb-2">or</p>
                    <button type="button" className="btn btn-primary">
                      <i className="ri-upload-line"></i> Upload
                    </button>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      )}
      <div
        className={props.short ? "short_list" : ""}
        style={{ maxHeight: props.short ? 300 : "auto", overflow: "auto" }}
      >
        {videoList !== null ? (
          <>
            {isGridView ? (
              <div className="layout_thumbnail_view">
                {filteredData.map((item, index) => (
                  <ThumbnailComponent
                    data={item}
                    videoListName={videoList.Name}
                    handleLink={handleLink}
                    key={item.Key}
                    index={index}
                    selectedLink={props.selectedLink}
                  />
                ))}
              </div>
            ) : (
              <div className="layout_list_view">
                {filteredData.map((item, index) => (
                  <ListComponent
                    data={item}
                    videoListName={videoList.Name}
                    handleLink={handleLink}
                    key={item.Key}
                    index={index}
                    selectedLink={props.selectedLink}
                  />
                ))}
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};
export default MediaScreen;
