import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { Switch, DatePicker } from "antd";
import { useSelector } from "react-redux";
import FileViewer from "react-file-viewer";
import ReactAudioPlayer from "react-audio-player";
// import Switch from "react-switch";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import Tooltip from "react-tooltip";
import moment from "moment";

//Import Logos
import logoSmLight from "../../assets/images/logo-sm-light.png";
import logoLight from "../../assets/images/logo-light.png";
import { Link } from "react-router-dom";
import ProfileMenu from "../../components/CommonForBoth/TopbarDropdown/ProfileMenu";
import Swal from "sweetalert2";
import MediaScreen from "./MediaScreen";
import ReactPlayer from "react-player";
import VideoScreen from "./VideoScreen";
import VideoChipherPlayer from "./components/VideoChipherPlayer";

const BuildCourse = ({
  data,
  handleBuilder,
  sections,
  handleSectionOpen,
  handleSectionDelete,
  reloadDetails,
  handlePublish,
  featuredImages,
  addCourseItemToSection,
  deleteCourseItemFromSection,
  onDragEndHandle,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [urlModal, setUrlModal] = useState(false);
  const [chapterModal, setChapterModal] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [chapterTitle, setChapterTitle] = useState("");
  const [selectedSection, setSelectedSection] = useState(null);
  const [titleError, setTitleError] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [selectedItemTitle, setSelectedItemTitle] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [isChooseLink, setIsChooseLink] = useState(true);
  const [isSchedule, setIsSchedule] = useState(false);
  const [additionalUrl, setAdditionalUrl] = useState("");
  const [isEditItem, setIsEditItem] = useState(false);
  const [scheduleTime, setScheduleTime] = useState(new Date());
  const [selectedChaper, setSelectedChaper] = useState(null);
  // console.log("all sections", selectedSection);
  const basurl = process.env.REACT_APP_URL;
  const user = useSelector((state) => state.user);
  const role = user?.user?.role;
  const userToken = user.userToken;

  // toggle urlmodal
  const toggleUrlModal = () => {
    setUrlModal(!urlModal);
  };
  // toggle modal
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };
  // videoModalToggle
  const videoModalToggle = () => {
    setVideoModalOpen(!videoModalOpen);
  };
  // toggle modal chapter
  const toggleModalChapter = () => {
    setChapterModal(!chapterModal);
  };

  // handleSubmitChapter
  const handleSubmitChapter = (e) => {
    e.preventDefault();
    if (chapterTitle === "") {
      setTitleError(true);
    } else if(selectedChaper !== null){
      axios
        .put(
          `${basurl}/course/update-section/${selectedChaper._id}`,
          {
            title: chapterTitle,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Chapter updated successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            reloadDetails(true);
            setChapterModal(false);
            setChapterTitle("");
            setSelectedChaper(null);
          }
        });


    } else {
      axios
        .post(
          `${basurl}/course/add-section`,
          {
            title: chapterTitle,
            course: data._id,
            status: false,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Chapter added successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            reloadDetails(true);
            setChapterModal(false);
            setChapterTitle("");
          }
        });
    }
  };

  // handleEditSection
  const handleEditSection = (section) => {
    // get section from sections by id 
    const selected = sections.find((item) => item._id === section);
    setSelectedChaper(selected);
    toggleModalChapter();
    setChapterTitle(selected.title);

  }
  // get media link
  const mediaLink = (media) => {
    // console.log("get media link>>>", media);
    setSelectedLink(media);
  };

  console.log("selectedLink", selectedLink);

  // handleAddElement
  const handleAddElement = () => {
    if (selectedItemTitle === "") {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please enter title",
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      const type = selectedLink.split(".").pop();
      if (isEditItem) {
        UpdateSectionItem(
          selectedItemTitle,
          type,
          selectedLink,
          false,
          selectedItem._id
        );
      } else {
        createSectionItem(selectedItemTitle, type, selectedLink, false);
      }
      //   console.log("selected link>>>>", selectedItemTitle, selectedLink);

      //   console.log("adasdasdasds", type);
    }
  };
  // handleAddElement
  const handleAddElementLink = () => {
    if (selectedItemTitle === "") {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please enter title",
        showConfirmButton: false,
        timer: 1000,
      });
    } else if (additionalUrl === "") {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please enter url",
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      //   console.log("selected link>>>>", selectedItemTitle, selectedLink);
      if (isEditItem) {
        UpdateSectionItem(
          selectedItemTitle,
          "mp4",
          additionalUrl,
          false,
          selectedItem._id
        );
      } else {
        createSectionItem(selectedItemTitle, "mp4", additionalUrl, false);
      }

      //   console.log("adasdasdasds", type);
    }
  };
  // handleAddElement
  const handleAddElementVideo = () => {
    // console.log("selected link>>>>", isEditItem);
    if (selectedItemTitle === "") {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please enter title",
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      if (isEditItem) {
        UpdateSectionItem(
          selectedItemTitle,
          "video",
          selectedLink,
          false,
          selectedItem._id
        );
      } else {
        createSectionItem(selectedItemTitle, "video", selectedLink, false);
      }
    }
  };

  // * * create section item
  const createSectionItem = (name, type, url, isPriview) => {
    axios
      .post(
        `${basurl}/course/add-course-item`,
        {
          section: selectedSection,
          course: data._id,
          name,
          type,
          url,
          isPriview,
          isScheduled: isSchedule,
          scheduleDate: scheduleTime,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setIsOpenModal(false);
          setUrlModal(false);
          setVideoModalOpen(false);
          // reloadDetails(true);
          setIsEditItem(false);
          setSelectedItemTitle("");
          addCourseItemToSection(result.data);
          setAdditionalUrl("");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Element added successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log("err>>", err);
      });
  };
  // * * update section item
  const UpdateSectionItem = (name, type, url, isPriview, itemId) => {
    axios
      .put(
        `${basurl}/course/edit-course-item/${itemId}`,
        {
          section: selectedSection,
          course: data._id,
          name,
          type,
          url,
          isPriview,
          isScheduled: isSchedule,
          scheduleDate: scheduleTime,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setIsOpenModal(false);
          setUrlModal(false);
          setVideoModalOpen(false);
          reloadDetails(true);
          setIsEditItem(false);
          setSelectedItemTitle("");
          setAdditionalUrl("");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Element updated successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log("err>>", err);
      });
  };

  // render icon
  const renderIcon = (type) => {
    if (
      type === "mp4" ||
      type === "avi" ||
      type === "mkv" ||
      type === "wmv" ||
      type === "videoChipher" ||
      type === "video"
    ) {
      return <i className="ri-video-line"></i>;
    } else if (type === "pdf") {
      return <i className="ri-file-pdf-fill"></i>;
    } else if (
      type === "png" ||
      type === "jpg" ||
      type === "jpeg" ||
      type === "gif"
    ) {
      return <i className="ri-image-line"></i>;
    } else if (type === "mp3" || type === "wav" || type === "ogg") {
      return <i className="ri-file-music-line"></i>;
    } else {
      return <i className="ri-file-line"></i>;
    }
  };

  // choose player
  const playerChoose = (extention, link) => {
    if (extention === "jpg" || extention === "png" || extention === "gif") {
      return <img src={link} alt="image" style={{ width: "100%" }} />;
    } else if (extention === "mp3") {
      return <ReactAudioPlayer src={link} autoPlay controls />;
    } else if (extention === "mp4") {
      return <ReactPlayer url={link} controls width="100%" />;
    } else if (extention === "pdf") {
      return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
          <div style={{ height: "450px" }}>
            <Viewer fileUrl={link} />
          </div>
        </Worker>
      );
    } else if (extention === "videoChipher") {
      return <VideoChipherPlayer id={link} />;
    } else if (extention === "video") {
      return <VideoChipherPlayer id={link} />;
    } else {
      return (
        <FileViewer
          fileType={extention}
          filePath={link}
          errorComponent={() => console.log("error component")}
          onError={() => console.log("error")}
          style={{ width: "100%" }}
        />
      );
    }
  };

  //removeItem
  const removeItem = (value, sectionId, sectionItem) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this item ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCourseItemFromSection(sectionItem);
        axios
          .delete(`${basurl}/course/delete-course-item/${value}/${sectionId}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const result = res.data;
            if (result.success) {
              // reloadDetails(true);
              Swal.fire({
                position: "center",
                icon: "success",
                title: result.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            console.log("err>>", err);
          });
      }
    });
  };

  //handlePreviewVideo
  const handlePreviewVideo = (value, itemid) => {
    axios
      .put(
        `${basurl}/course/edit-course-item/${itemid}`,
        {
          isPriview: value === true ? false : true,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          reloadDetails(true);
          Swal.fire({
            position: "center",
            icon: "success",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log("err>>", err);
      });
  };

  const onChangeSwitch = (checked) => {
    setIsSchedule(checked);
  };

  const onChangeDateSchedule = (value) => {
    // console.log("schedule date>>>", value);
    setScheduleTime(value);
  };

  return (
    <>
      {/* add ask url */}
      <Modal
        centered
        isOpen={urlModal}
        toggle={toggleUrlModal}
        zIndex={9999}
        size="xl"
      >
        <ModalHeader toggle={toggleUrlModal}>Add element from</ModalHeader>
        <ModalBody>
          {/* {isChooseLink} */}
          <div className="row">
            <div className="col-md-4">
              <button
                type="button"
                className="btn btn-outline-primary btn-block"
                onClick={() => {
                  setIsOpenModal(true);
                  setUrlModal(false);
                }}
              >
                From Media
              </button>
            </div>
            <div className="col-md-4">
              <button
                type="button"
                className="btn btn-outline-primary btn-block"
                onClick={() => {
                  setVideoModalOpen(true);
                }}
              >
                From Videos
              </button>
            </div>
            <div className="col-md-4">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={() => {
                  setIsChooseLink(true);
                }}
              >
                From Link (Youtube, Vimeo, etc.)
              </button>
            </div>
          </div>
          {isChooseLink && (
            <>
              <div className="mt-3">
                <div className="mb-3">
                  <label>Element title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter element title"
                    value={selectedItemTitle}
                    onChange={(e) => setSelectedItemTitle(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label>Link</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter url https://youtube...."
                    value={additionalUrl}
                    onChange={(e) => setAdditionalUrl(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
          <div className="schedule_element_wrapper">
            <div className="row">
              <div className="col-md-6">
                <h4>Schedule Element</h4>
              </div>
              <div className="col-md-6">
                <div className="text-right">
                  <Switch checked={isSchedule} onChange={onChangeSwitch} />
                </div>
              </div>
            </div>
            {isSchedule ? (
              <>
                <div className="schedule_element_elwrap">
                  {/* <DatePicker
                    onChange={onChangeDateSchedule}
                    open
                    style={{ position: "relative" }}
                  /> */}
                  <DateTimePicker
                    onChange={onChangeDateSchedule}
                    value={scheduleTime}
                  />
                </div>
              </>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={handleAddElementLink}>
            Save element
          </button>
        </ModalFooter>
      </Modal>
      {/* end ask url */}

      {/* add Video from video section */}
      <Modal
        centered
        isOpen={videoModalOpen}
        toggle={videoModalToggle}
        zIndex={9999}
        size="xl"
      >
        <ModalHeader toggle={videoModalToggle}>Add elements</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label>Element title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter element title"
              value={selectedItemTitle}
              onChange={(e) => setSelectedItemTitle(e.target.value)}
            />
          </div>
          <VideoScreen mediaLink={mediaLink} />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={handleAddElementVideo}>
            Save element
          </button>
        </ModalFooter>
      </Modal>
      {/* End add Video from video section */}

      {/* add element modal */}
      <Modal
        centered
        isOpen={isOpenModal}
        toggle={toggleModal}
        zIndex={9999}
        size="xl"
      >
        <ModalHeader toggle={toggleModal}>Add elements</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label>Element title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter element title"
              value={selectedItemTitle}
              onChange={(e) => setSelectedItemTitle(e.target.value)}
            />
          </div>
          <MediaScreen
            mediaLink={mediaLink}
            short={true}
            selectedLink={selectedLink}
          />
          <div className="schedule_element_wrapper">
            <div className="row">
              <div className="col-md-6">
                <h4>Schedule Element</h4>
              </div>
              <div className="col-md-6">
                <div className="text-right">
                  <Switch checked={isSchedule} onChange={onChangeSwitch} />
                </div>
              </div>
            </div>
            {isSchedule ? (
              <>
                <div className="schedule_element_elwrap">
                  {/* <DatePicker
                    onChange={onChangeDateSchedule}
                    open
                    style={{ position: "relative" }}
                  /> */}
                  <DateTimePicker
                    onChange={onChangeDateSchedule}
                    value={scheduleTime}
                  />
                </div>
              </>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={handleAddElement}>
            Save element
          </button>
        </ModalFooter>
      </Modal>
      {/* end add element modal */}
      {/* add chapter modal */}
      <Modal
        centered
        isOpen={chapterModal}
        toggle={toggleModalChapter}
        zIndex={9999}
      >
        <form onSubmit={(e) => handleSubmitChapter(e)}>
          <ModalHeader toggle={toggleModalChapter}>{selectedChaper?.title || "Add Chapter"}</ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <label>Chapter title</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter chapter title"
                value={chapterTitle}
                onChange={(e) => {
                  setChapterTitle(e.target.value);
                  setTitleError(false);
                }}
              />
              {titleError && (
                <span className="text-danger">Chapter title is required</span>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </ModalFooter>
        </form>
      </Modal>
      {/* end add chapter modal */}
      <div className="build_course_wrapper">
        <div className="left_section">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoSmLight} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoLight} alt="" height="50" />
              </span>
            </Link>
          </div>
          {/* Inner div */}
          <div className="inner_content_sidebar">
            <div className="featured_image">
              <img
                src={featuredImages}
                alt="course image"
                className="img-fluid"
              />
            </div>
            {/* Chapter list */}
            {sections.length > 0 && (
              <div className="chapter_list">
                <DragDropContext
                  onDragEnd={(result) => onDragEndHandle(result)}
                >
                  {sections.map((section, index) => (
                    <div className="chapter_list_item" key={section._id}>
                      <div className="chapter_item_header d-flex justify-content-between">
                        <div
                          className="d-flex"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSectionOpen(index)}
                        >
                          {section.isOpened ? (
                            <i className="ri-arrow-down-s-line"></i>
                          ) : (
                            <i className="ri-arrow-right-s-line"></i>
                          )}{" "}
                          <span style={{ marginLeft: 5 }}>{section.title}</span>
                        </div>
                        <span>
                        <span
                            className="delete_section mr-2"
                            onClick={() => handleEditSection(section._id)}
                          >
                            <i className="ri-pencil-line" style={{ cursor: "pointer", color: "green" }}></i>
                          </span>
                        {role === "Admin" && (
                          <span
                            className="delete_section"
                            onClick={() => handleSectionDelete(section._id)}
                          >
                            <i className="ri-delete-bin-line"></i>
                          </span>
                        )}
                        </span>
                      </div>
                      {section.isOpened && (
                        <div className="chapter_item_body">
                          {section.section_item.length > 0 ? (
                            <>
                              <Droppable droppableId="sectionItems_wrapper">
                                {(provided) => (
                                  <div
                                    className="sectionItems_wrapper"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    {section.section_item.map((item, indx) => (
                                      <Draggable
                                        key={indx}
                                        draggableId={"dd" + indx}
                                        index={indx}
                                      >
                                        {(provided) => (
                                          <div
                                            className="chapter_item_body_item"
                                            key={item._id}
                                          >
                                            <div
                                              className="cib_name"
                                              onClick={() =>
                                                setSelectedItem(item)
                                              }
                                            >
                                              <span className="sch_icon">
                                                {item.isScheduled ? (
                                                  <i
                                                    className="ri-calendar-event-line schedule"
                                                    title={
                                                      item.isScheduled
                                                        ? "Schedule for " +
                                                          moment(
                                                            item.scheduleDate
                                                          ).format(
                                                            "MMMM Do YYYY, h:mm:ss a"
                                                          )
                                                        : ""
                                                    }
                                                  ></i>
                                                ) : (
                                                  <i className="ri-checkbox-circle-line"></i>
                                                )}
                                              </span>
                                              <span className="rr_icon">
                                                {renderIcon(item.type)}
                                              </span>
                                              <span>{item.name}</span>
                                            </div>

                                            <div className="d-flex">
                                              {/* handlePreviewVideo */}
                                              <div
                                                className="cib_icon mr-2"
                                                onClick={() => {
                                                  setSelectedItem(item);
                                                  setIsEditItem(true);
                                                  setSelectedItemTitle(
                                                    item.name
                                                  );
                                                  setScheduleTime(
                                                    new Date(item.scheduleDate)
                                                  );
                                                  setIsSchedule(
                                                    item.isScheduled
                                                  );
                                                  setAdditionalUrl(item.url);
                                                  setSelectedSection(
                                                    section._id
                                                  );
                                                  if (item.type == "mp4") {
                                                    toggleUrlModal(true);
                                                  } else {
                                                    setIsOpenModal(true);
                                                    setUrlModal(false);
                                                    setSelectedLink(item.url);
                                                  }
                                                }}
                                              >
                                                <i className="ri-pencil-line"></i>
                                              </div>
                                              {role === "Admin" && (
                                                <>
                                                  <div
                                                    className="cib_icon mr-2"
                                                    onClick={() =>
                                                      handlePreviewVideo(
                                                        item.isPriview,
                                                        item._id
                                                      )
                                                    }
                                                  >
                                                    {item.isPriview ? (
                                                      <i
                                                        className="ri-eye-line"
                                                        title={
                                                          "publicly visible"
                                                        }
                                                      ></i>
                                                    ) : (
                                                      <i
                                                        className="ri-eye-off-line"
                                                        title={
                                                          "publicly not visible"
                                                        }
                                                      ></i>
                                                    )}
                                                  </div>
                                                  <div
                                                    className="cib_icon"
                                                    onClick={() =>
                                                      removeItem(
                                                        item._id,
                                                        section._id,
                                                        item
                                                      )
                                                    }
                                                  >
                                                    <i className="ri-delete-bin-line"></i>
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  </div>
                                )}
                              </Droppable>
                            </>
                          ) : (
                            <span>There are no items in this section</span>
                          )}
                          <span
                            className="add_element_trigger small_trigger"
                            onClick={() => {
                              setSelectedSection(section._id);
                              toggleUrlModal(true);
                              setSelectedItemTitle("");
                              setAdditionalUrl("");
                              setIsSchedule(false);
                              setScheduleTime(new Date());
                            }}
                          >
                            <i className="ri-add-circle-line"></i>
                            Add new element
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </DragDropContext>
              </div>
            )}

            {/* end Chapter list */}
            <span
              className="add_element_trigger"
              onClick={() => setChapterModal(true)}
            >
              <i className="ri-add-circle-line"></i>
              Add new chapter
            </span>
          </div>
        </div>
        <div className="right_section">
          <div className="d-flex justify-content-end">
            <ProfileMenu />
          </div>
          <div className="right_part_inner">
            <div className="sm-header-title">
              Edit course structure for{" "}
              <span className="dark_text">{data?.title}</span>
            </div>
            <div>
              <span className="sm-header-title pointer" onClick={handleBuilder}>
                <i className="ri-arrow-left-line"></i>{" "}
                <span style={{ position: "relative", left: 5 }}>
                  Back to courses
                </span>
              </span>
            </div>
            {!selectedItem ? (
              <div className="section_chapter_add_big">
                <div className="d-flex flex-column">
                  <p>
                    Click on the + icon 👇 to add a chapter, and under each
                    chapter, you can add content.
                  </p>
                  <button
                    className="btn-link-chapter"
                    onClick={() => setChapterModal(true)}
                  >
                    <i className="ri-add-circle-line"></i>
                  </button>
                </div>
              </div>
            ) : (
              <div className="selected_file_viewer">
                <div className="row">
                  <div className="col-lg-8">
                    {playerChoose(selectedItem.type, selectedItem.url)}
                  </div>
                  <div
                    className="col-lg-4"
                    style={{ backgroundColor: "#f7f7f7", padding: "20px" }}
                  >
                    <div className="mb-3">
                      <label className="d-block">File name:</label>
                      <span>{selectedItem.name}</span>
                      <hr />
                    </div>
                    <div className="mb-3">
                      <label className="d-block">File type:</label>
                      <span>{selectedItem.type}</span>
                      <hr />
                    </div>
                    {role === "Admin" && (
                      <div className="mb-3">
                        <label className="d-block">File url:</label>
                        <span>{selectedItem.url}</span>
                        <hr />
                      </div>
                    )}

                    <div className="mb-3">
                      <label className="d-block">File Preview:</label>
                      <span>
                        {selectedItem.isPriview
                          ? "Previewable"
                          : "Not Previewable"}
                      </span>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => handlePublish(data._id, data.isPublished)}
                  >
                    {data.isPublished ? "Unpublish course" : "Publish course"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BuildCourse;
