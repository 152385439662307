import React, { Component } from "react";
import { Col, Card, CardBody, Media } from "reactstrap";

const MiniWidgets = (props) => {
  return (
    <React.Fragment>
      <Col md={4}>
        <Card>
          <CardBody>
            <Media>
              <Media body className="overflow-hidden">
                <p className="text-truncate font-size-14 mb-2">{props.title}</p>
                <h4
                  className={`mb-0 ${
                    props.value > 99999 ? "small_tittle" : ""
                  }`}
                >
                  {props.rupee && "₹"}
                  {props.value.toFixed(0)}
                </h4>
              </Media>
              <div className="text-primary">
                <i className={props.icon + " font-size-24"}></i>
              </div>
            </Media>
          </CardBody>

          {/* <CardBody className="border-top py-3">
            <div className="text-truncate">
              <span className="badge badge-soft-success font-size-11 mr-1">
                <i className="mdi mdi-menu-up"> </i> {props.rate}
              </span>
              <span className="text-muted ml-2">{props.desc}</span>
            </div>
          </CardBody> */}
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MiniWidgets;
