import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

const BlogCard = (props) => {
  return (
    <div className="blog_card_wrapper">
      <div className={"blog_card_image"}>
        <img src={props.image} alt={props.title} />
        <div className={"card_image_overlay"}>
          <div className={"card_overlay_inner"}>
            <Link to={`/blogs/edit/${props.id}`} className={"btn btn-outline"}>
              <i className="ri-pencil-line"></i> Edit this blog
            </Link>
          </div>
        </div>
        <div
          className={`blog_status ${
            props.isPublished ? "published" : "unPublished"
          }`}
        >
          {props.isPublished ? (
            <i className={"la la-check-circle-o"}></i>
          ) : (
            <i className={"la la-warning"}></i>
          )}{" "}
          {props.isPublished ? "Published" : "Unpublished"}
        </div>
      </div>
      <div className={"blog_card_content"}>
        <Link to={`/blogs/edit/${props.id}`} className={"blog_card_title"}>
          {props.title.length > 27
            ? props.title.slice(0, 27) + "..."
            : props.title}
        </Link>
        <div className={"blog_card_description"}>
          {ReactHtmlParser(
            props.content.length > 120
              ? props.content.slice(0, 120) + "..."
              : props.content
          )}
        </div>
        <div className={"card_publish_date"}>
          <strong>
            <i
              className={"la la-calendar-check-o"}
              style={{ position: "relative", top: "3px", marginRight: "5px" }}
            />
            {"  "}Publish Date:
          </strong>{" "}
          {moment(props.publishDate).format("DD/MM/YYYY")}
        </div>
        <div className={"blog_total_view"}>
          <strong>
            <i
              className={"la la-eye"}
              style={{ position: "relative", top: "3px", marginRight: "5px" }}
            />
            {"  "}Total Views:
          </strong>{" "}
          {props.views}
        </div>
        <div className={"blog_card_tags"}>
          <strong style={{ marginRight: "10px" }}>
            <i
              className={"la la-tags"}
              style={{ position: "relative", top: "3px", marginRight: "5px" }}
            />{" "}
            {"  "} Tags:
          </strong>
          {props.tags.length > 0 && (
            <>
              {props.tags.map((tag, index) => (
                <span className={"chips"} key={index}>
                  {tag}
                </span>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default BlogCard;
