import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import moment from "moment";
import { Table, DatePicker } from "antd";
import "antd/dist/antd.css";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ExportCSV } from "../../components/ExportCSV";
import Loader from "../../common/Loader";
import { userLogout } from "../../redux/actions/UserActions";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const Index = (props) => {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const [students, setStudents] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [coreStudents, setCoreStudents] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [pagination, setPagination] = useState({
    pageSize: 10,
  });

  const role = useSelector((state) => state.user?.user?.role);

  // console.log("asasdasdasdsdasdasd", exportData);

  // get code students list
  const getCoreStudents = () => {
    axios
      .get(`${basurl}/users/students`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const list = res.data;
        if (list.success) {
          setCoreStudents(list.data);
        }
        getUsersList();
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  // get user list
  const getUsersList = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/predictor/get-all`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const list = res.data;
        if (list.success) {
          setStudents(list.data);
          const newArr = list.data.map((item, index) => ({
            "S. No": index + 1,
            "User's Name": item.firstName + " " + item.lastName,
            "Email Address": item.email,
            Student: coreStudentsNew(item),
            "Mobile No.": item.mobile || "NA",
            Gender: item.gender,
            Category: item.category,
            "10th %": item.class_10_percentage + "%",
            "12th %": item.class_12_percentage + "%",
            "12th Stream": item.discipline,
            "Graduation %": item.graduation_percentage,
            "Under Grad Stream": item.is_engineer ? "Engineer" : "Non-Engineer",
            "Work Experience": item.work_ex,
            "Professional Qualification": item.is_professional_qualification
              ? "Yes"
              : "No",
            "Overall Percentile": item.ptotal,
            "VARC %ile": item.pvrc,
            "++ VARC scaled score": item.mvrc !== null ? item.mvrc : "NA",
            "QA %ile": item.pqa,
            "QA scaled score": item.mqa !== null ? item.mqa : "NA",
            "DILR %ile": item.pdilr,
            "LRDI scaled score": item.mdilr !== null ? item.mdilr : "NA",
            date: moment(item.createdAt).format("MMMM Do YYYY, h:mm a") || "",
            Result: item.result?.length > 0 ? item.result.toString() : "NA",
          }));
          // console.log("export data", newArr);
          if (newArr.length > 0) {
            setExportData(newArr);
            // console.log("export data", newArr);
          } else {
            setExportData([]);
          }
        } else {
          setError("There are no users");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  useEffect(() => {
    getCoreStudents();
    // getUsersList();
  }, []);

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // check is student or not
  const isStudent = (value) => {
    const rr = coreStudents.some(
      (item) => item.email === value && item.courses.length > 0
    );
    if (rr) {
      return "active_column";
    } else {
      return "not-active";
    }
  };
  // check is student or not
  const coreStudentsNew = (value) => {
    // console.log("vikassssss", value);
    if (coreStudents.length > 0) {
      const ff = coreStudents.some(
        (item) => item.email === value.email && item.courses.length > 0
      );
      if (ff) {
        return "Yes";
      } else {
        return "No";
      }
    }
  };

  //handleDelete
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        setIsLoading(true);
        axios
          .delete(`${basurl}/predictor/delete/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const result = res.data;
            if (result.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Deleted successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              getUsersList();
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Something went wrong!",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            if (err.response.data === "Invalid token") {
              props.logoutUser();
              localStorage.removeItem("lmsUserInfo");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your token has been expired!, Please login again",
                showConfirmButton: false,
                timer: 1500,
              });
              props.history.push("/");
            }
            console.log("error>> ", err);
          });
      }
    });
  };

  // Columns
  const columns = [
    {
      title: "S. No.",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Full Name",
      dataIndex: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (text, record, index) => (
        <span>{record.firstName + " " + record.lastName}</span>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text, record, index) => (
        <span>
          {record.email}{" "}
          <button
            className="btn-danger btn-sm"
            style={{ border: 0 }}
            onClick={() => handleDelete(record._id)}
          >
            <i className="ri-delete-bin-line"></i>
          </button>
        </span>
      ),
    },
    {
      title: "Mobile No.",
      render: (text, record, index) => (
        <span>{record.mobile ? record.mobile : "NA"}</span>
      ),
    },
    {
      title: "Gender",
      sorter: (a, b) => a.gender.localeCompare(b.gender),
      render: (text, record, index) => <span>{record.gender}</span>,
    },
    {
      title: "Category",
      sorter: (a, b) => a.category.localeCompare(b.category),
      render: (text, record, index) => <span>{record.category}</span>,
    },
    {
      title: "10th %",
      sorter: (a, b) =>
        a.class_10_percentage.localeCompare(b.class_10_percentage),
      render: (text, record, index) => (
        <span>{record.class_10_percentage}</span>
      ),
    },
    {
      title: "12th %",
      sorter: (a, b) =>
        a.class_12_percentage.localeCompare(b.class_12_percentage),
      render: (text, record, index) => (
        <span>{record.class_12_percentage}</span>
      ),
    },
    {
      title: "12th Stream",
      sorter: (a, b) => a.discipline.localeCompare(b.discipline),
      render: (text, record, index) => <span>{record.discipline}</span>,
    },
    {
      title: "Graduation %",
      sorter: (a, b) =>
        a.graduation_percentage.localeCompare(b.graduation_percentage),
      render: (text, record, index) => (
        <span>{record.graduation_percentage}</span>
      ),
    },
    {
      title: "Under Grad Stream",
      render: (text, record, index) => (
        <span>{record.is_engineer ? "Engineer" : "Non-Engineer"}</span>
      ),
    },
    {
      title: "Work Experience",
      sorter: (a, b) => a.work_ex.localeCompare(b.work_ex),
      render: (text, record, index) => <span>{record.work_ex}</span>,
    },
    {
      title: "Professional Qualification",
      // sorter: (a, b) => a.work_ex.localeCompare(b.work_ex),
      render: (text, record, index) => (
        <span>{record.is_professional_qualification ? "Yes" : "No"}</span>
      ),
    },
    {
      title: "Overall Percentile",
      sorter: (a, b) => a.ptotal.localeCompare(b.ptotal),
      render: (text, record, index) => <span>{record.ptotal}</span>,
    },
    {
      title: "VARC %ile",
      sorter: (a, b) => a.pvrc.localeCompare(b.pvrc),
      render: (text, record, index) => <span>{record.pvrc}</span>,
    },
    {
      title: "++ VARC scaled score",
      sorter: (a, b) => a.mvrc.localeCompare(b.mvrc),
      render: (text, record, index) => <span>{record.mvrc}</span>,
    },
    {
      title: "QA %ile",
      sorter: (a, b) => a.pqa.localeCompare(b.pqa),
      render: (text, record, index) => <span>{record.pqa}</span>,
    },
    {
      title: "QA scaled score",
      sorter: (a, b) => a.mqa.localeCompare(b.mqa),
      render: (text, record, index) => <span>{record.mqa}</span>,
    },
    {
      title: "DILR %ile",
      sorter: (a, b) => a.pdilr.localeCompare(b.pdilr),
      render: (text, record, index) => <span>{record.pdilr}</span>,
    },
    {
      title: "LRDI scaled score",
      sorter: (a, b) => a.mdilr.localeCompare(b.mdilr),
      render: (text, record, index) => <span>{record.mdilr}</span>,
    },
    {
      title: "Date",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record, index) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {moment(record.createdAt).format("lll")}
        </span>
      ),
    },
    {
      title: "Colleges",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record, index) => (
        <div>
          {record.result.length > 0 ? (
            <>
              {record.result.map((item, index) => (
                <div
                  style={{ whiteSpace: "nowrap" }}
                  key={`${record.email + Math.round(index)}`}
                >
                  {item}
                </div>
              ))}
            </>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    // console.log("params", sorter);
    setPagination({
      pageSize: pagination.pageSize,
    });
  }
  // console.log("pagination, ", pagination);

  // for search filter
  const filteredData = students.filter((val) => {
    if (searchInput === "") {
      return val;
    } else if (
      val.firstName.toLowerCase().includes(searchInput.toLowerCase()) ||
      val.email.toLowerCase().includes(searchInput.toLowerCase())
    ) {
      return val;
    }
  });

  // handleRange
  const handleRange = (date) => {
    if (date !== null) {
      let date1 = date[0].toDate();
      let date2 = date[1].toDate();
      const newArr = students.filter((item) => {
        return (
          moment(item.createdAt).toDate() <= date2 &&
          moment(item.createdAt).toDate() >= date1
        );
      });
      setStudents(newArr);
    } else {
      getUsersList();
    }
  };

  //   console.log("permissions=========", selectedCourses);

  if (role !== "Admin") {
    return <Redirect to="/" />;
  }
  return (
    <MainContainer>
      {isLoading && <Loader />}

      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2">College Predictor</h4>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className={"filters mb-2"}>
                <div className={"row"}>
                  <div className={"col-lg-4"}>
                    <div className={"search_filter"}>
                      <input
                        type="text"
                        className={"form-control"}
                        placeholder={"Search by name..."}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                      {searchInput !== "" && (
                        <span
                          className={"clear_search_text"}
                          onClick={() => setSearchInput("")}
                        >
                          <i className={"la la-times"}></i>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className={"col-lg-4"}>
                    <RangePicker
                      format={dateFormat}
                      className={"form-control custom_range_picker"}
                      onChange={handleRange}
                    />
                  </div>
                  <div className={"col-lg-4"}>
                    <div className={"row"}>
                      <div className={"col-sm-6"}>
                        <select
                          className={"form-control"}
                          onChange={(e) =>
                            setPagination({
                              pageSize: e.target.value,
                            })
                          }
                        >
                          <option value={10}>10 Rows</option>
                          <option value={20}>20 Rows</option>
                          <option value={50}>50 Rows</option>
                          <option value={100}>100 Rows</option>
                        </select>
                      </div>
                      <div className={"col-sm-6"}>
                        {exportData.length > 0 && (
                          <ExportCSV
                            csvData={exportData}
                            fileName={"predictors"}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Table
                columns={columns}
                dataSource={filteredData}
                bordered={true}
                rowKey={(item) => item._id}
                rowClassName={(record) => isStudent(record.email)}
                loading={isLoading}
                pagination={pagination}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
