import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import { userLogout } from "../../redux/actions/UserActions";
import AWS from "aws-sdk";
import Switch from "react-switch";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";

const Create = (props) => {
  const history = useHistory();
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [featuredImages, setFeaturedImages] = useState(null);
  const [not_percentile, setNot_percentile] = useState(false);

  const getCategories = () => {
    axios
      .get(`${basurl}/results/all-categories`)
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setCategories(result.data);
        }
        setIsLoading(false);
        console.table(result.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getCategories();
  }, []);

  const { register, handleSubmit, errors, reset, setValue } = useForm();
  const onSubmit = (data, e) => {
    setIsLoading(true);
    axios
      .post(
        `${basurl}/results/create-result`,
        {
          name: data.name,
          percentile: data.percentile,
          image: featuredImages,
          category: selectedCategory,
          year: data.year,
          not_percentile,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Result added successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          e.target.reset();
          props.history.push("/results");
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        console.log("result >> ", result);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  // AWS configurations
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new/results" },
    region: "ap-south-1",
  });

  // upload image to aws bucket
  const uploadVideoHandler = (files) => {
    // console.log("video file >> ", files[0]);
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setUploadProgress(percent);
        // console.log(
        //   "progress video upload :: ",
        //   Math.round((evt.loaded / evt.total) * 100)
        // );
      })
      .on("success", (evt) => {
        // console.log("mujhe khusi hogi >>>", evt.request.params);
        setFeaturedImages(
          `https://lms-videos-bucket-prepzone-new.s3.ap-south-1.amazonaws.com/${evt.request.params.Key}`
        );
        setUploadProgress(0);
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // console.log("error >> ", err);
        } else {
          // console.log("aljkskdasd", data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  console.log("categories >> ", selectedCategory);
  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="card">
        <div className={"card-header"}>
          <div className="row">
            <div className="col-sm-6">
              <h4>Add Result</h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-9">
                <div className="form-group">
                  <label>Student Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={"Student Name"}
                    name="name"
                    ref={register({ required: true })}
                  />
                  {errors.name && (
                    <span className="isError">Student name is required.</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Category</label>
                  <select
                    className="form-control"
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Passing Year</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Year"}
                        name="year"
                        ref={register({ required: false })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>
                          {not_percentile ? "Institutes" : "Percentile"}
                        </label>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <label
                            style={{
                              display: "inline-block",
                              marginRight: 10,
                              position: "relative",
                              top: 2,
                            }}
                          >
                            Institute
                          </label>
                          <Switch
                            onChange={() => {
                              setNot_percentile(!not_percentile);
                              setValue("percentile", "");
                            }}
                            checked={not_percentile}
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          not_percentile ? "Institutes" : "Percentile"
                        }
                        name="percentile"
                        ref={register({ required: false })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <label>Student Image Image</label>
                  {featuredImages ? (
                    <div
                      className="course_image_uploader"
                      style={{ minHeight: "240px" }}
                    >
                      <span
                        className="close_icon"
                        onClick={() => setFeaturedImages(null)}
                      >
                        <i className="ri-close-line"></i>
                      </span>
                      <img
                        src={featuredImages}
                        alt={"feature image"}
                        className="img-fluid"
                      />
                    </div>
                  ) : (
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        uploadVideoHandler(acceptedFiles)
                      }
                      accept={"image/*"}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className="course_image_uploader"
                          style={{ minHeight: "240px" }}
                        >
                          {uploadProgress > 1 && (
                            <div className="upload_progress">
                              <div
                                className="inner"
                                style={{
                                  width: `${uploadProgress}%`,
                                  height: "100%",
                                  backgroundColor: "#4CAF50",
                                  transition: "all 0.4s ease-in-out",
                                  borderRadius: "20px",
                                }}
                              ></div>
                            </div>
                          )}

                          <div {...getRootProps()} className={"dropZone"}>
                            <input {...getInputProps()} />
                            {/* <i className="ri-image-line uploader_icon"></i> */}
                            <span className="close_icon">
                              <i className="ri-pencil-fill"></i>
                            </span>
                            <img
                              src={require("../../assets/coursepic.png")}
                              alt="demo image"
                              className="img-fluid"
                              height={"100%"}
                              style={{ minHeight: 240 }}
                            />
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </div>
              </div>
            </div>

            <div className={"d-flex"}>
              <button
                type="submit"
                className="btn btn-success"
                style={{ marginRight: 15 }}
              >
                Add Result
              </button>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Create);
