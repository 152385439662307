import React from "react";
export const itemShow = (type) => {
  if (type === "video") {
    return <i className={"la la-play-circle"} />;
  } else if (
    type === "word" ||
    type === "odt" ||
    type === "doc" ||
    type === "docx"
  ) {
    return <i className={"la la-file-word-o"} />;
  } else if (type === "ppt" || type === "pptx") {
    return <i className={"la la-file-powerpoint-o"} />;
  } else if (type === "pdf") {
    return <i className={"la la-file-pdf-o"} />;
  } else if (
    type === "excel" ||
    type === "xls" ||
    type === "xlsx" ||
    type === "csv"
  ) {
    return <i className={"la la-file-excel-o"} />;
  } else if (type === "audio" || type === "mp3" || type === "aac") {
    return <i className={"la la-file-sound-o"} />;
  }
};
