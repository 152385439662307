import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import moment from "moment";
import { Link } from "react-router-dom";
import axios from "axios";
import { Table } from "antd";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { userLogout } from "../../redux/actions/UserActions";
import { useSelector } from "react-redux";

const Announcements = (props) => {
  const [list, setList] = useState([]);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const [isLoading, setIsLoading] = useState(true);
  const role = useSelector((state) => state.user?.user?.role);
  //get slider banner list
  const getAllAnnouncements = () => {
    axios
      .get(`${basurl}/announcements/all`)
      .then((res) => {
        const coupons = res.data;
        if (coupons.success) {
          setList(coupons.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // Delete banner slider
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this item ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${basurl}/announcements/delete/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const deleteStatus = res.data;
            if (deleteStatus.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: deleteStatus.message,
                showConfirmButton: false,
                timer: 2500,
              });
              getAllAnnouncements();
            }
            // console.log("delete status > ", deleteStatus);
          })
          .catch((err) => {
            if (err.response.data === "Invalid token") {
              props.logoutUser();
              localStorage.removeItem("lmsUserInfo");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your token has been expired!, Please login again",
                showConfirmButton: false,
                timer: 1500,
              });
              props.history.push("/");
            }
          });
      }
    });
  };

  useEffect(() => {
    getAllAnnouncements();
  }, []);

  // Columns
  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (value, item, index) => <span>{item.title}</span>,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (value, item, index) => <img src={item.image} width="100px" />,
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (value, item, index) => <span>{item.description}</span>,
    },
    {
      title: "Expiry Date",
      render: (value, item, index) => (
        <span>{moment(item.expiryDate).format("DD-MM-YYYY")}</span>
      ),
    },

    {
      title: "Action",
      render: (value, item, index) => (
        <div>
          {/* <Link
            to={`/announcements/edit/${item._id}`}
            className="btn btn-primary"
          >
            <i className="ri-pencil-line"></i>
          </Link> */}
          {role === "Admin" ? (
            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px", minWidth: 100 }}
              onClick={() => handleDelete(item._id)}
            >
              <i className="ri-delete-bin-line"></i>
            </button>
          ) : (
            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px", minWidth: 100, opacity: "0.5" }}
              onClick={() =>
                Swal.fire({
                  title: "Opps!",
                  text: "You are not allowed to delete this item",
                  icon: "error",
                  confirmButtonText: "Ok",
                })
              }
            >
              <i className="ri-delete-bin-line"></i>
            </button>
          )}
        </div>
      ),
    },
  ];
  // console.log("banner list>> ", list);
  return (
    <MainContainer>
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Announcements
            </h4>
            <Link className={"btn btn-primary"} to={"/announcements/add"}>
              Add New Announcement
            </Link>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className={"card-body"}>
          <Table
            columns={columns}
            dataSource={list}
            bordered={true}
            rowKey={(item) => item._id}
            loading={isLoading}
          />
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
