import React, { useState } from "react";
import { Switch } from "antd";
import { Link } from "react-router-dom";

const QuestionCard = (props) => {
  const { data, index } = props;
  //   console.log("card data >>", data)
  return (
    <div className="question_card_new">
      <div className="card_header">
        <Link
          to={`/quiz/edit-question/${props.data._id}/${props.data.quiz}`}
          className="qc_icon mr-2"
          style={{ marginLeft: 10 }}
        >
          <i className="mdi mdi-pencil"></i>
        </Link>
        <span
          className="qc_icon text-danger"
          style={{ cursor: "pointer" }}
          onClick={props.handleDeleteQuestion}
        >
          <i className="mdi mdi-delete"></i>
        </span>
      </div>
      <div className="card_question">
        <strong>Question: </strong> <p>{props.data.title}</p>
      </div>
      <div className="card_options">
        {data.options.map((que, ind) => {
          return (
            <div
              className={`que_row ${que.isCorrect ? "active" : ""}`}
              key={ind}
            >
              {que.text}
              {que.isCorrect && (
                <span className="currect_icon">
                  <i className="mdi mdi-check"></i>
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default QuestionCard;
