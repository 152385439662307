import React, { useState, useEffect } from "react";
import MainContainer from "../../../common/MainContainer";
import axios from "axios";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import { Table } from "antd";
import "antd/dist/antd.css";
import { userLogout } from "../../../redux/actions/UserActions";

const Contact = (props) => {
  const [list, setList] = useState([]);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const [isLoading, setIsLoading] = useState(true);

  //get slider banner list
  const getContactList = () => {
    axios
      .get(`${basurl}/frontend/contact/contacts`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const list = res.data;
        console.log("list >> ", list);
        if (list.success) {
          setList(list.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  useEffect(() => {
    getContactList();
  }, []);

  // Columns
  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "User Name",
      render: (value, item, index) => <span>{item.name}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value, item, index) => <span>{item.email}</span>,
    },
    {
      title: "Message",
      render: (value, item, index) => <span>{item.message}</span>,
    },
    {
      title: "Mobile",
      render: (value, item, index) => <span>{item.mobile}</span>,
    },
    {
      title: "Inquiry Date",
      render: (value, item, index) => (
        <span>{moment(item.createdAt).format("MMMM Do YYYY")}</span>
      ),
    },
  ];

  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <h4 className={"header-title"}>Frontend Contact Form Queries</h4>
        </div>
        <div className="card-body">
          <Table
            columns={columns}
            dataSource={list}
            bordered={true}
            loading={isLoading}
          />
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Contact);
