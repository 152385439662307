import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
  InputGroup,
} from "reactstrap";
import { Switch } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditQuestion = (props) => {
  const id = props.match.params.id;
  const quiz = props.match.params.quizId;

  // states
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState([
    {
      text: "",
      isCorrect: false,
    },
  ]);

  const basurl = process.env.REACT_APP_URL;
  const user = useSelector((state) => state.user);
  const userToken = user.userToken;

  // get question Details
  const getQuestionDetails = () => {
    axios
      .get(`${basurl}/quiz-new/view-question/${id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((result) => {
        const res = result.data;
        if (res.success) {
          setTitle(res.data.title);
          setOptions(res.data.options);
          setContent(res.data.solution);
        }
      });
  };

  useEffect(() => {
    getQuestionDetails();
  }, [id]);

  // toggle
  const toggleCheckbox = (it, ind) => {
    let arr = options.map((item, index) => {
      if (ind == index) {
        item.isCorrect = !item.isCorrect;
      } else {
        item.isCorrect = false;
      }
      return { ...item };
    });
    setOptions(arr);
  };

  // Add Option
  const addOption = () => {
    setOptions([
      ...options,
      {
        text: "",
        isCorrect: false,
      },
    ]);
  };

  // Remove Option
  const removeOption = (index) => {
    const list = [...options];
    list.splice(index, 1);
    setOptions(list);
  };

  //handleOptionText
  const handleOptionText = (e, index) => {
    const { name, value } = e.target;
    const list = [...options];
    list[index][name] = value;
    setOptions(list);
  };

  // handleSubmit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (title === "") {
      Swal.fire({
        title: "Oops!",
        text: "Please enter a title",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else if (options.length < 2) {
      Swal.fire({
        title: "Oops!",
        text: "Please add options at least 3",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      axios
        .put(
          `${basurl}/quiz-new/edit-question/${id}`,
          {
            title,
            options,
            quiz: quiz,
            solution: content,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((result) => {
          const res = result.data;
          if (res.success) {
            Swal.fire({
              title: "Success!",
              text: "Question updated successfully",
              icon: "success",
              // confirmButtonText: "Ok",
            });
            props.history.goBack();
          }
        });
    }
  };

  return (
    <MainContainer>
      <div className="cards">
        <div className="card-headers mb-3">
          <div className="row">
            <div className="col-sm-6">
              <div className="">
                <h4 className="mt-2" style={{ marginRight: 30 }}>
                  Update Question
                </h4>
                <Link className="sm-header-title" to={`/quiz/edit/${quiz}`}>
                  <i className="ri-arrow-left-line" />{" "}
                  <span style={{ position: "relative", left: 5 }}>
                    Back to questions
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="card-bodys">
          <div className="card">
            <div className="card-body course_form_wrapper">
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <label>Title</label>
                      <Input
                        type="text"
                        name="title"
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <h5>Options</h5>
                      <button
                        className="btn btn-success "
                        type="button"
                        onClick={() => addOption()}
                      >
                        <i className="la la-plus"></i> Add Option
                      </button>
                    </div>
                    <hr />
                    {options.map((item, index) => {
                      return (
                        <div className="row" key={index}>
                          <div className="col-md-10">
                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`Option ${index + 1}`}
                                value={item.text}
                                name="text"
                                onChange={(e) => handleOptionText(e, index)}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="mb-2 d-flex justify-content-between align-items-center">
                              <Switch
                                checked={item.isCorrect}
                                checkedChildren="Right"
                                unCheckedChildren="Wrong"
                                onChange={() => toggleCheckbox(item, index)}
                              />
                              <button
                                className="btn btn-danger ml-2"
                                type="button"
                                onClick={() => removeOption(index)}
                              >
                                <i className="mdi mdi-delete"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="mt-4 mb-4">
                      <h4>Solution</h4>
                      <CKEditor
                        editor={ClassicEditor}
                        data={content}
                        style={{ minHeight: "130px" }}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContent(data);
                          // console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
export default EditQuestion;
