import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import copy from "copy-to-clipboard";

const EmailDetails = (props) => {
  const { id } = props.match.params;
  // console.log("available props>>", props);
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState(null);
  const [emails, setEmails] = useState([]);
  const [emailBtnText, setEmailBtnText] = useState("Copy Emails");

  const userToken = props.user.userToken;
  const basurl = process.env.REACT_APP_URL;

  const getEmaildetails = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/emails/get-email-details/${id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setDetails(result.data);
          setEmails(result.data.emails.split(","));
        }
        // console.log("email list", result);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getEmaildetails();
  }, [id]);

  const copyEmails = () => {
    const emls = details ? details.emails.split(",").join("\n") : "";
    copy(emls, {
      debug: true,
      message: "Emails copied",
    });
    setEmailBtnText("Emails copied!!");

    setTimeout(() => {
      setEmailBtnText("Copy Emails");
    }, [2000]);
  };

  return (
    <MainContainer>
      <div className="card">
        {isLoading && <Loader />}
        {details && (
          <>
            <div className="card-header">
              <div className="row">
                <div className="col-sm-8">
                  <h4>Subject: {details.subject}</h4>
                </div>
                <div className="col-sm-4 text-right">
                  {moment(details.createdAt).format("MMMM D")}
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="text-right mb-4">
                <button className="btn btn-primary" onClick={copyEmails}>
                  {emailBtnText}
                </button>
              </div>
              <div className="email_sent">
                <strong>Mail sent to: </strong>
                {emails.map((email, index) => (
                  <span className="email-chip" key={index}>
                    {email}
                  </span>
                ))}
              </div>
              <div className="email_message">
                <div>
                  <strong>Email Body: </strong>
                </div>
                {ReactHtmlParser(details.message)}
              </div>
              {details?.attachments.length > 0 ? (
                <div>
                  <hr />
                  <div style={{ marginBottom: 20 }}>
                    <strong>Attachment:({details?.attachments.length})</strong>
                  </div>
                  {details?.attachments.map((itm, indx) => {
                    return (
                      <div
                        key={indx}
                        className="card"
                        style={{ marginBottom: 15 }}
                      >
                        <div
                          className="card-body"
                          style={{
                            padding: 10,
                            paddingLeft: 30,
                            paddingRight: 30,
                          }}
                        >
                          <a href={itm.path || itm.href} target="_blank">
                            {itm.filename}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(EmailDetails);
