import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import BundleDemo from "../../../assets/demo/bundle.svg";

const BundleCard = ({ data, handlePuilish, handleDelete }) => {
  const imgUrls = process.env.REACT_APP_BASE_URL;
  const cardImage = data.image ? imgUrls + data.image : BundleDemo;
  const { user } = useSelector((state) => state.user);
  const role = user?.role;
  return (
    <div
      className={
        data.isPublished
          ? "bundle-card-wrapper"
          : "bundle-card-wrapper disabled"
      }
    >
      <div className="image">
        <img
          src={data.image ? data.image : BundleDemo}
          alt={data.title}
          className="card_image"
        />
        {/* {data.isPublished && } */}
        <div
          className={`publish_tag ${data.isPublished ? "active" : "disabled"}`}
        >
          {data.isPublished ? "Published" : "Unpublished"}
        </div>
        <div className="card_image_overlay">
          <div className="card_image_float">
            <button
              className="btn btn-sm btn-outline-light mb-2"
              onClick={() => handlePuilish(data._id, data.isPublished)}
            >
              <i
                className={data.isPublished ? "ri-eye-line" : "ri-eye-off-line"}
              ></i>
            </button>
            {role === "Admin" && (
              <button
                className="btn btn-sm btn-outline-light"
                onClick={() => handleDelete(data._id)}
              >
                <i className="ri-delete-bin-line"></i>
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="card_content">
        <div>
          <Link to={`/bundles/edit/${data._id}`}>
            <h5 className="card-title">
              {data.title.length > 35
                ? data.title.slice(0, 35) + "..."
                : data.title}
            </h5>
          </Link>
        </div>
        <h4 className="text-success">
          <strong>₹{data.price}</strong>
        </h4>
        <div>
          <i
            className="ri-book-2-line"
            style={{ position: "relative", top: 2 }}
          ></i>{" "}
          {data.courses.length + data.mocks.length + data.quizes.length}
        </div>
        <div>
          <i
            className="ri-calendar-line text-danger"
            style={{ position: "relative", top: 2 }}
          ></i>{" "}
          {moment(data.expiryDate).format("MMMM Do YYYY")}
        </div>
      </div>
    </div>
  );
};

export default BundleCard;
