import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
  InputGroup,
} from "reactstrap";
import QuestionCard from "./components/QuestionCard";

const Edit = (props) => {
  const id = props.match.params.id;
  const [title, setTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const [content, setContent] = useState("");
  const [time, setTime] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(0);

  const basurl = process.env.REACT_APP_URL;
  const user = useSelector((state) => state.user);
  const userToken = user.userToken;

  // get all categories
  const getCategories = () => {
    axios
      .get(`${basurl}/quiz-new/view-categories`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((result) => {
        const res = result.data;
        if (res.success) {
          setCategories(res.data);
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.userLogout();
        }
      });
  };

  // get quiz details
  const getQuizDetails = () => {
    axios
      .get(`${basurl}/quiz-new/view/${id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((result) => {
        const res = result.data;
        if (res.success) {
          setTitle(res.data.title);
          setQuestions(res.data.questions);
          setContent(res.data.description);
          setTime(res.data.time);
          setSelectedCategory(res.data.category ? res.data.category._id : null);
          setIndex(res.data.index);
        }
      });
  };

  useEffect(() => {
    getCategories();
    getQuizDetails();
  }, [id]);

  const slugify = (text) => {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    const to = "aaaaaeeeeeiiiiooooouuuunc------";

    const newText = text
      .split("")
      .map((letter, i) =>
        letter.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
      );

    return newText
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/&/g, "-y-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-"); // Replace multiple - with single -
  };

  // handleSubmit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (title === "") {
      Swal.fire({
        title: "Oops!",
        text: "Please enter a title",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      axios
        .put(
          `${basurl}/quiz-new/edit/${id}`,
          {
            title,
            slug: slugify(title),
            description: content,
            time,
            id,
            index,
            category: selectedCategory,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((result) => {
          const res = result.data;
          if (res.success) {
            Swal.fire({
              title: "Success!",
              text: "Quiz updated successfully",
              icon: "success",
            });
          }
        });
    }
  };

  // handleDeleteQuestion
  const handleDeleteQuestion = (value) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${basurl}/quiz-new/delete-question/${value}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((result) => {
            const res = result.data;
            if (res.success) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              getQuizDetails();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
    // axios
    //   .delete(`${basurl}/quiz-new/delete-question/${value}`, {
    //     headers: {
    //       "auth-token": userToken,
    //     },
    //   })
    //   .then((result) => {
    //     const res = result.data;
    //     if (res.success) {
    //       Swal.fire({
    //         title: "Success!",
    //         text: res.message,
    //         icon: "success",
    //         // confirmButtonText: "Ok",
    //       });
    //       getQuizDetails();
    //     }
    //   })
    //   .catch((err) => {
    //     Swal.fire({
    //       title: "Oops!",
    //       text: err.message,
    //       icon: "error",
    //       confirmButtonText: "Ok",
    //     });
    //   });
  };

  // console.log("token,", userToken);

  return (
    <MainContainer>
      <div className="cards">
        <div className="card-headers mb-3">
          <div className="row">
            <div className="col-sm-6">
              <div className="">
                <h4 className="mt-2" style={{ marginRight: 30 }}>
                  Update Quiz
                </h4>
                <Link className="sm-header-title" to={`/quiz`}>
                  <i className="ri-arrow-left-line" />{" "}
                  <span style={{ position: "relative", left: 5 }}>
                    Back to quiz
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="card-bodys">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md="12">
                    <div className={"row"}>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label>Title</label>
                          <Input
                            type="text"
                            name="title"
                            placeholder="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label>Time</label>
                          <Input
                            type="number"
                            name="time"
                            placeholder="0:00 in minutes"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label>Category</label>
                          <select
                            className="form-control"
                            value={selectedCategory}
                            onChange={(e) =>
                              setSelectedCategory(e.target.value)
                            }
                          >
                            <option>Select Category</option>
                            {categories.map((category) => (
                              <option key={category._id} value={category._id}>
                                {category.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label>Index</label>
                          <Input
                            type="number"
                            name="index"
                            placeholder="index"
                            value={index}
                            onChange={(e) => setIndex(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label>Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={content}
                        style={{ minHeight: "130px" }}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContent(data);
                          // console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h4>Questions ({questions.length > 0 ? questions.length : 0})</h4>
            </div>
            <div className="col-md-6">
              <div className="d-flex justify-content-end">
                <Link
                  to={`/quiz/add-question/${id}`}
                  className="btn btn-primary"
                >
                  Add Question
                </Link>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            {questions.map((item) => (
              <div className="col-md-4" key={item._id}>
                <QuestionCard
                  data={item}
                  handleDeleteQuestion={() => handleDeleteQuestion(item._id)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
export default Edit;
