import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import Swal from "sweetalert2";
import axios from "axios";
import { Button, ButtonGroup } from "reactstrap";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import { userLogout } from "../../redux/actions/UserActions";

const PaymentGateway = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rSelected, setRSelected] = useState(1);
  const [upi, setUpi] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [authKey, setAuthKey] = useState("");
  const basurl = process.env.REACT_APP_URL;
  const imgUrls = process.env.REACT_APP_BASE_URL;
  const userToken = props.user.userToken;

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .put(
        `${basurl}/payment-option/edit`,
        {
          upi: rSelected === 1 ? upi : null,
          type: rSelected === 1 ? "QR" : "Instamojo",
          apiKey: rSelected === 1 ? null : apiKey,
          authKey: rSelected === 1 ? null : authKey,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.message,
          showConfirmButton: false,
          timer: 1500,
        });
        // console.log("response >>>>>", result);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data === "Invalid token") {
          // dispatch({
          //   type: "USER_LOGOUT",
          //   payload: false,
          //   token: null,
          //   info: null,
          // });
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        setIsLoading(false);
      });
  };

  const getPaymentOption = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/payment-option/full-info`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          //   console.log("get payment option ifull info", result.data);
          setRSelected(result.data.type == "QR" ? 1 : 2);
          setUpi(result.data?.upi ? result.data.upi : "");
          setApiKey(result.data?.apiKey ? result.data.apiKey : "");
          setAuthKey(result.data?.authKey ? result.data.authKey : "");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data === "Invalid token") {
          // dispatch({
          //   type: "USER_LOGOUT",
          //   payload: false,
          //   token: null,
          //   info: null,
          // });
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPaymentOption();
  }, []);

  return (
    <MainContainer>
      <div className={"card"}>
        {isLoading && <Loader />}
        <div className={"card-header"}>
          <div className="row">
            <div className="col-sm-6">
              <h4>Payment Settings</h4>
            </div>
          </div>
        </div>
        <div className={"card-body"}>
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <div className="row">
                <div className="col-sm-3">
                  <label>Payment Type:</label>
                </div>
                <div className="col-sm-8">
                  <ButtonGroup>
                    <Button
                      color="primary"
                      outline
                      onClick={() => setRSelected(1)}
                      active={rSelected === 1}
                    >
                      QR Code
                    </Button>
                    <Button
                      color="primary"
                      outline
                      onClick={() => setRSelected(2)}
                      active={rSelected === 2}
                    >
                      Instamojo
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
            {rSelected === 1 ? (
              <div className="mb-2">
                <label>UPI ID</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Please enter your UPI ID"
                  value={upi}
                  onChange={(e) => setUpi(e.target.value)}
                  name={"upi"}
                />
              </div>
            ) : (
              <>
                <div className="mb-2">
                  <label>API Key</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="API Key"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    name={"apiKey"}
                  />
                </div>
                <div className="mb-2">
                  <label>Auth Key</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Auth Key"
                    value={authKey}
                    onChange={(e) => setAuthKey(e.target.value)}
                    name={"authKey"}
                  />
                </div>
              </>
            )}

            <hr />
            <div className={"d-flex mt-3"}>
              <button
                type="submit"
                className="btn btn-success"
                style={{ marginRight: 15 }}
              >
                Save Details
              </button>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => props.history.goBack()}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(PaymentGateway);
