import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

function VideoListItem({ item, refreshList }) {
  const [modal, setModal] = useState(false);
  const [otp, setOtp] = useState(null);
  const [playbackInfo, setPlaybackInfo] = useState(null);
  const basurl = process.env.REACT_APP_URL;
  const { user } = useSelector((state) => state.user);
  const role = user?.role;

  const toggle = () => setModal(!modal);
  const VdoPlayer = window.VdoPlayer;

  const getOTPVideo = () => {
    axios
      .post(`${basurl}/video-list/get-video-otp`, {
        id: item.id,
      })
      .then((res) => {
        const videoList = res.data;
        if (videoList.success) {
          setOtp(videoList.data.otp);
          setPlaybackInfo(videoList.data.playbackInfo);
          var video = new VdoPlayer({
            otp: videoList.data.otp,
            playbackInfo: videoList.data.playbackInfo,
            theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
            // the container can be any DOM element on website
            container: document.querySelector("#embedBox"),
          });
        }

        // console.log("Vikas babu>>>>>> ", videoList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //handleDelete
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((ress) => {
      if (ress) {
        axios
          .delete(`${basurl}/video-list/delete-video/${id}`)
          .then((res) => {
            const result = res.data;
            if (result.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Video Deleted Successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              setModal(false);
              refreshList();
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Something went wrong!",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleModal = () => {
    setModal(true);
    getOTPVideo();
  };

  //   console.log("Vikas babu>>>>>> ", playbackInfo, "typeof", typeof playbackInfo);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Video: {item.title}</ModalHeader>
        <ModalBody>{otp && <div id="embedBox"></div>}</ModalBody>
        <ModalFooter>
          {role === "Admin" ? (
            <Button color="danger" onClick={() => handleDelete(item.id)}>
              Delete
            </Button>
          ) : (
            <Button
              color="danger"
              onClick={() => {
                Swal.fire({
                  title: "Oops!!",
                  text: "Sorry, you can't delete this item.",
                  icon: "error",
                  confirmButtonText: "Okay, Got it!",
                });
              }}
            >
              Delete
            </Button>
          )}{" "}
          <Button onClick={() => setModal(false)}>Close</Button>
        </ModalFooter>
      </Modal>
      <div className="row" onClick={() => handleModal()}>
        <div className="col-sm-3">
          <div className="media_thumb">
            <img src={item.poster} alt={item.title} className="img-fluid" />
          </div>
        </div>
        <div className="col-sm-9">
          <div className="media_details">
            <h4 className="media_title">{item.title}</h4>
            <p className="text-muted mb-0">
              Uploaded date:{" "}
              {moment.unix(item.upload_time).format("MMMM Do YYYY, h:mm:ss a")}
            </p>
            <p className="text-muted mb-0">
              Video Status:{" "}
              {item.status === "ready" ? (
                <span className="text-success">
                  <i className="fa fa-check-circle"></i> Ready
                </span>
              ) : (
                <span className="text-warning">
                  <i className="fa fa-spinner fa-spin"></i> Processing
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoListItem;
