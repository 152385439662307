import React, { useEffect, useState, useRef } from "react";
import MainContainer from "../../common/MainContainer";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import { Select, Switch, DatePicker } from "antd";
import "antd/dist/antd.css";
import "react-tagsinput/react-tagsinput.css";
import TagsInput from "react-tagsinput";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import AWS from "aws-sdk";

//Import Editor
import CKEditor from "ckeditor4-react-advanced";
import {
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Swal from "sweetalert2";
import moment from "moment";

const CreateEmail = (props) => {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [emails, setEmails] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [courses, setCourses] = useState([]);
  const [predictorList, setPredictrList] = useState([]);
  const [customEmails, setCustomEmails] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedOption, setSelectedOption] = useState("all");
  const [isSchedule, setIsSchedule] = useState(false);
  const [scheduleTime, setScheduleTime] = useState(new Date());
  const [additionalImages, setAdditionalImages] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [verifiedStudents, setVerifiedStudents] = useState([]);
  const [allCollege, setAllCollege] = useState([]);
  const [selectDate, setSelectDate] = useState(null);

  // Date range ref
  const dateRef = useRef();

  // Date range picker
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";

  const userToken = props.user.userToken;
  const basurl = process.env.REACT_APP_URL;

  const { Option } = Select;

  // toggle modal
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  // console.log("all quiz categories >> ", selectedOption);

  // handleRange
  const handleRange = (date) => {
    // console.log("all dates>", date);
    if (date !== null) {
      let date1 = date[0].toDate();
      let date2 = date[1].toDate();
      setSelectDate([moment(date1), moment(date2)]);

      if (selectedOption == "all") {
        const filtered = verifiedStudents.filter((item) => {
          return (
            moment(item.createdAt).toDate() <= date2 &&
            moment(item.createdAt).toDate() >= date1
          );
        });
        const newArr = filtered.map((item) => {
          return (item = item.email);
        });
        setEmails(newArr);
      } else if (selectedOption == "college") {
        const filteredData = allCollege.filter((item) => {
          return (
            moment(item.createdAt).toDate() <= date2 &&
            moment(item.createdAt).toDate() >= date1
          );
        });
        const newArr = filteredData.map((item) => {
          return (item = item.email);
        });
        setEmails(newArr);
      }
      // const newArr = students.filter((item) => {
      //   return (
      //     moment(item.date).toDate() <= date2 &&
      //     moment(item.date).toDate() >= date1
      //   );
      // });
      // setStudents(newArr);
    } else {
      if (selectedOption == "all") {
        getStudentList();
      } else if (selectedOption == "college") {
        getPredicorList();
      }
      // setStudents(list);
    }

    // console.log("get date from range >>>", newArr)
  };

  // AWS configurations
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new/email" },
    region: "ap-south-1",
  });

  const onChangeSwitch = (checked) => {
    setIsSchedule(checked);
  };
  const onChangeDateSchedule = (date) => {
    setScheduleTime(date);
    // console.log("schedule date>>>", date);
  };

  const getPredicorList = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/predictor/get-all`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const list = res.data;
        if (list.success) {
          setAllCollege(list.data);
          const newArr = list.data.map((item) => {
            return (item = item.email);
          });
          setPredictrList(newArr);
          setEmails(newArr);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };
  // * * * * Get student list
  const getStudentList = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/users/students`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          const verifiedStudents = result.data.filter(
            (item) => item.emailVerified
          );
          setVerifiedStudents(verifiedStudents);
          const newArr = verifiedStudents.map((item) => {
            return (item = item.email);
          });
          setEmails(newArr);
          // console.log("newArr", newArr);
        }
        // console.log(result);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  };

  useEffect(() => {
    setSelectedOption("all");
    getStudentList();
  }, []);
  useEffect(() => {
    if (selectedOption === "all") {
      getStudentList();
    }
  }, [selectedOption]);

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // * * * * send emails
  const handleSubmit = (e) => {
    var attachments = [];
    if (additionalImages.length > 0) {
      attachments = additionalImages.map((item) => {
        var filename = item.replace(/^.*[\\\/]/, "");
        return {
          filename: filename,
          path: item,
        };
      });
    } else {
      attachments = [];
    }

    e.preventDefault();
    if (subject === "") {
      Swal.fire("Error!", "Please mention your email subject", "warning");
    } else if (message === "") {
      Swal.fire("Error!", "Please enter messgae", "warning");
    } else if (emails.length <= 0) {
      Swal.fire("Error!", "Please select right course", "warning");
    } else {
      var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      for (var i = 0; i < emails.length; i++) {
        if (!regex.test(emails[i])) {
          return Swal.fire("Error!", "Please enter valid email", "warning");
        }
      }
      setIsLoading(true);
      axios
        .post(
          `${basurl}/emails/sendemails`,
          {
            email: emails,
            subject,
            message,
            isScheduled: isSchedule,
            scheduledTime: scheduleTime,
            attachments: attachments,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result.message,
              showConfirmButton: false,
              timer: 1500,
            });
            history.goBack();
          }
          console.log(result);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  // get course list for choose course
  const getCourseList = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/bundle/front-all`)
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setCourses(result.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // * * * * handleChange
  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    if (value === "course") {
      setEmails([]);
      getCourseList();
    } else if (value === "all") {
      setSelectDate(null);
      setCourses([]);
    } else if (value === "college") {
      setSelectDate(null);
      setCourses([]);
      getPredicorList();
    } else if (value === "custom") {
      setCourses([]);
      setEmails([]);
    }
  };

  const getEmailsByCourse = () => {
    // console.log("selectedCourse", selectedCourse);
    setIsLoading(true);
    axios
      .post(
        `${basurl}/bundle/getUsers`,
        {
          ids: selectedCourse,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          const emailList = result.data.map((item) => item.email);
          if (emailList.length <= 0) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                "no student found in this bundle, Please choose another bundle",
              showConfirmButton: false,
              timer: 1500,
            });
            setSelectedCourse([]);
          }
          setEmails(emailList);
          // console.log("get course details", emailList);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  // * * * *  ========= functions for select searchable ======== * * * *
  function onChangeSearch(value) {
    setSelectedCourse(value);
  }

  useEffect(() => {
    // console.log("selectedCourse", selectedCourse.length);
    if (selectedCourse.length > 0) {
      getEmailsByCourse();
    }
  }, [selectedCourse]);

  const handleCustomEmails = (string) => {
    const emailArray = string.match(/[^\r\n]+/g);
    console.log("string", emailArray);
    setEmails(emailArray);
  };

  // Upload image
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    const params = {
      ACL: "public-read",
      Key: file.name,
      ContentType: file.type,
      Body: file,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setUploadProgress(percent);
        // console.log(
        //   "progress video upload :: ",
        //   Math.round((evt.loaded / evt.total) * 100)
        // );
      })
      .on("success", (evt) => {
        // console.log("mujhe khusi hogi >>>", evt.request.params);
        setAdditionalImages([
          ...additionalImages,
          `https://lms-videos-bucket-prepzone-new.s3.ap-south-1.amazonaws.com/${evt.request.params.Key}`,
        ]);
        setUploadProgress(0);
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // console.log("error >> ", err);
        } else {
          // console.log("aljkskdasd", data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  // Todo: handleRemoveAttechment
  const handleRemoveAttechment = (idx) => {
    const newArticles = [...additionalImages];
    newArticles.splice(idx, 1);
    setAdditionalImages(newArticles);
  };

  console.log("all selected emails ==>>", emails);

  return (
    <MainContainer>
      <Modal
        centered
        isOpen={isOpenModal}
        toggle={toggleModal}
        zIndex={9999}
        size="xl"
      >
        <ModalHeader toggle={toggleModal}>
          Email list {emails !== null ? `(${emails.length})` : `(0)`}
        </ModalHeader>
        <ModalBody>
          {emails !== null && emails.length > 0 ? (
            <div style={{ maxHeight: 300, overflow: "auto" }}>
              {emails.map((item, index) => (
                <span key={`email--${index + 3}`} className="chips">
                  {item}
                </span>
              ))}
            </div>
          ) : (
            "No emails found"
          )}
        </ModalBody>
      </Modal>
      <div className="card">
        {isLoading && <Loader />}
        <div className="card-header">
          <div className="row">
            <div className="col-md-6">
              <h4>Compose mail</h4>
            </div>
            <div className="col-md-6">
              <h4 className="text-right">
                Send Emails To 👉🏻
                {emails !== null ? `(${emails.length})` : `(0)`}{" "}
                {emails !== null && emails.length > 0 ? (
                  <span
                    className="btn-link"
                    style={{
                      cursor: "pointer",
                      fontSize: 14,
                      letterSpacing: 0,
                      fontWeight: "bold",
                    }}
                    onClick={toggleModal}
                  >
                    View Email List
                  </span>
                ) : null}
              </h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <div className="row">
                <div className="col-md-6">
                  <label>To:</label>
                  <select className="form-control" onChange={handleChange}>
                    <option value="all">All Students</option>
                    <option value="course">Bundle wise</option>
                    <option value="college">College Predictor </option>
                    <option value="custom">Custom</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label>Filter by Date:</label>
                  <RangePicker
                    format={dateFormat}
                    className={"form-control custom_range_picker"}
                    onChange={handleRange}
                    value={selectDate}
                    ref={dateRef}
                    disabled={
                      selectedOption == "course"
                        ? true
                        : false || selectedOption == "custom"
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </div>
            {courses.length > 0 && (
              <div className="mb-2">
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  mode="multiple"
                  placeholder="Choose a bundle..."
                  optionFilterProp="children"
                  // className="custom_selector"
                  onChange={onChangeSearch}
                  // onFocus={onFocusSearch}
                  // onBlur={onBlurSearch}
                  // onSearch={onSearchSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {courses.map((item, index) => (
                    <Option
                      value={item._id}
                      key={"course" + index}
                      select={selectedCourse === item._id ? true : false}
                    >
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {selectedOption === "custom" && (
              <div className="mb-2 custom_emails">
                <textarea
                  className="form-control"
                  onChange={(e) => handleCustomEmails(e.target.value)}
                  style={{ minHeight: "100px" }}
                ></textarea>
              </div>
            )}
            <div className="mb-2">
              <input
                type="text"
                className="form-control"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>

            <FormGroup>
              <CKEditor
                data={message}
                onChange={(evt) => setMessage(evt.editor.getData())}
                config={{ allowedContent: true, height: "25em" }}
              />
            </FormGroup>
            <FormGroup>
              <div className="form-group">
                <button className={"btn btn-primary image_upload_button"}>
                  <i className={"ri-attachment-line"}></i> Add Attachment
                  <input
                    type="file"
                    className={"btn_upload_file"}
                    onChange={(e) => handleImageUpload(e)}
                    accept="image/*"
                  />
                </button>
              </div>
            </FormGroup>

            {additionalImages.length > 0 && (
              <FormGroup>
                <div className={"add_images_wrapper"}>
                  {additionalImages.map((item, index) => (
                    <div className={"img_row"} key={index}>
                      <span>{item}</span>
                      <span
                        className={"copy"}
                        onClick={() => handleRemoveAttechment(index)}
                        style={{ color: "#ffffff" }}
                      >
                        <i className={"ri-close-line"}></i>
                      </span>
                    </div>
                  ))}
                </div>
              </FormGroup>
            )}
            <FormGroup
              style={{
                background: "#f7f7f7",
                padding: "15px",
                borderRadius: 10,
              }}
            >
              <div className="row">
                <div className="col-md-5">
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 20 }}
                  >
                    <h4 style={{ marginBottom: 0 }}>Schedule</h4>
                    <div className="">
                      <Switch checked={isSchedule} onChange={onChangeSwitch} />
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  {isSchedule ? (
                    <>
                      <div className="schedule_element_elwrap">
                        <DateTimePicker
                          onChange={onChangeDateSchedule}
                          value={scheduleTime}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </FormGroup>

            <div className="d-flex">
              <button
                type="submit"
                className="btn btn-success"
                style={{ marginRight: 15 }}
              >
                {isSchedule ? "Schedule Email" : "Send Email"}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(CreateEmail);
