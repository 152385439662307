import React, { useState } from "react";
import MainContainer from "../../common/MainContainer";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import Loader from "../../common/Loader";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";
import { useHistory } from "react-router-dom";
import { userLogout } from "../../redux/actions/UserActions";
import { useDispatch } from "react-redux";

const AddCategory = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [featuredImages, setFeaturedImages] = useState(null);
  const [desc, setDesc] = useState("");
  const [isImageError, setIsImageError] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const { register, handleSubmit, watch, errors } = useForm();
  const basurl = process.env.REACT_APP_URL;
  const [isLoading, setIsLoading] = useState(false);

  // AWS configurations
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new/categories" },
    region: "ap-south-1",
  });

  // upload image to aws bucket
  const uploadVideoHandler = (files) => {
    console.log("video file >> ", files[0]);
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setUploadProgress(percent);
        // console.log(
        //   "progress video upload :: ",
        //   Math.round((evt.loaded / evt.total) * 100)
        // );
      })
      .on("success", (evt) => {
        // console.log("mujhe khusi hogi >>>", evt.request.params);
        setFeaturedImages(
          `https://lms-videos-bucket-prepzone-new.s3.ap-south-1.amazonaws.com/${evt.request.params.Key}`
        );
        setUploadProgress(0);
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // console.log("error >> ", err);
        } else {
          // console.log("aljkskdasd", data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  const onSubmit = (data) => {
    if (featuredImages === null) {
      setIsImageError("Please Choose Category Image");
    } else {
      setIsLoading(true);
      axios({
        method: "post",
        url: `${basurl}/coursecat/create`,
        data: {
          image: featuredImages,
          title: data.title,
          description: desc,
          sortOrder: data.sortOrder,
        },
      })
        .then((res) => {
          const result = res.data;
          if (result.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Course Category Added successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
            props.history.push("/categories");
          }
          setIsLoading(false);
          console.log("added category:>>", result);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.data === "Invalid token") {
            dispatch({
              type: "USER_LOGOUT",
              payload: false,
              token: null,
              info: null,
            });
            localStorage.removeItem("lmsUserInfo");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your token has been expired!, Please login again",
              showConfirmButton: false,
              timer: 1500,
            });
            props.history.push("/");
          }
        });
    }
  };

  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="card">
        <div className="card-header">
          <h4>Add Course Category</h4>
        </div>
        <div className={"card-body"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <div className="form-group">
                    <label>Category Image</label>
                    {featuredImages ? (
                      <div className="course_image_uploader">
                        <span
                          className="close_icon"
                          onClick={() => setFeaturedImages(null)}
                        >
                          <i className="ri-close-line"></i>
                        </span>
                        <img
                          src={featuredImages}
                          alt={"feature image"}
                          className="img-fluid"
                        />
                      </div>
                    ) : (
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          uploadVideoHandler(acceptedFiles)
                        }
                        accept={"image/*"}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="course_image_uploader">
                            {uploadProgress > 1 && (
                              <div className="upload_progress">
                                <div
                                  className="inner"
                                  style={{
                                    width: `${uploadProgress}%`,
                                    height: "100%",
                                    backgroundColor: "#4CAF50",
                                    transition: "all 0.4s ease-in-out",
                                    borderRadius: "20px",
                                  }}
                                ></div>
                              </div>
                            )}

                            <div {...getRootProps()} className={"dropZone"}>
                              <input {...getInputProps()} />
                              {/* <i className="ri-image-line uploader_icon"></i> */}
                              <span className="close_icon">
                                <i className="ri-pencil-fill"></i>
                              </span>
                              <img
                                src={require("../../assets/coursepic.png")}
                                alt="Mock demo image"
                                className="img-fluid"
                                height={"100%"}
                              />
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    )}
                    {isImageError !== "" ? (
                      <span className="isError">{isImageError}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Category Name</label>
              <input
                type="text"
                name={"title"}
                placeholder={"Please Enter Title"}
                className="form-control"
                ref={register({ required: true })}
              />
              {errors.title && (
                <span className="isError">Title is required</span>
              )}
            </div>
            <div className="form-group">
              <label>Sort Order</label>
              <input
                type="number"
                name={"sortOrder"}
                placeholder={"0"}
                className="form-control"
                ref={register({ required: true })}
              />
              {errors.sortOrder && (
                <span className="isError">Sort Order is required</span>
              )}
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea
                className="form-control"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                placeholder={"Description"}
                maxLength={500}
              />
            </div>

            <div className={"d-flex"}>
              <button
                type="submit"
                className="btn btn-success"
                style={{ marginRight: 15 }}
              >
                Add Category
              </button>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};
export default AddCategory;
