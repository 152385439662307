import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import Swal from "sweetalert2";
import axios from "axios";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { itemShow } from "../../components/itemShow";
import Switch from "react-switch";
import "react-circular-progressbar/dist/styles.css";
import AWS from "aws-sdk";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { Link } from "react-router-dom";
import Loader from "../../common/Loader";
import { userLogout } from "../../redux/actions/UserActions";

const CreateCourseStep2 = (props) => {
  const userToken = props.user.userToken;
  const basurl = process.env.REACT_APP_URL;
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm();
  const { id } = props.match.params;
  const [videoList, setVideoList] = useState([]);
  const [addType, setAddType] = useState(null);
  const [sectionItems, setSectionItems] = useState([]);
  const [videoUploader, setVideoUploader] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [documentUploader, setDocumentUploader] = useState(false);
  const [tags, setTags] = useState([]);
  const [reviews, setReviews] = useState(false);
  const [publish, setpublish] = useState(false);
  const [sections, setSections] = useState([]);

  // get course details & update details
  const getCourseDetails = () => {
    axios
      .get(`${basurl}/course/view/${id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const details = res.data;
        if (details.success) {
          const list = details.data.course_sections;
          let newArray = list.map((item, index) => {
            item.isOpened = false;
            return { ...item };
          });
          setSections(newArray);
          setTags(details.data.tags);
          setpublish(details.data.isPublished);
        }
        // console.log("course details >> ", details);
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new" },
    region: "ap-south-1",
  });

  // handleSectionOpen
  const handleSectionOpen = (ind) => {
    let arr = sections.map((item, index) => {
      if (ind == index) {
        item.isOpened = !item.isOpened;
      } else {
        item.isOpened = false;
      }
      return { ...item };
    });
    setSections(arr);
  };

  // section delete handle
  const handleSectionDelete = (sid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this section ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        axios
          .delete(`${basurl}/course/delete-section/${sid}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const deleteStatus = res.data;
            if (deleteStatus.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: deleteStatus.message,
                showConfirmButton: false,
                timer: 2500,
              });
              getCourseDetails();
              setIsLoading(false);
            } else {
              setIsLoading(false);
              Swal.fire({
                position: "center",
                icon: "warning",
                title: deleteStatus.message,
                showConfirmButton: false,
                timer: 2500,
              });
            }
            // console.log("delete status > ", deleteStatus);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("err>> ", err);
          });
      }
    });
  };

  const getVideosList = () => {
    axios
      .get(`${basurl}/video-list`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const videoList = res.data;
        if (videoList.success) {
          setVideoList(videoList.data);
        }
        // console.log("videoList>> ", videoList);
      });
  };
  // get documents list
  const getDocumentsList = () => {
    axios
      .get(`${basurl}/uploadDocs/docs`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const docList = res.data;
        if (docList.success) {
          setDocuments(docList.data);
        }
        // console.log("docs list >> ", docList);
      });
  };
  useEffect(() => {
    getCourseDetails();
    getVideosList();
    getDocumentsList();
  }, []);

  // video duration
  const getVideoDuration = (duration, index) => {};

  // handlePreviewVideo
  const handlePreviewVideo = (index, value) => {
    console.log("video preview index >> ", index);
    const list = [...sectionItems];
    list[index]["isPriview"] = value === false ? true : false;
    setSectionItems(list);
  };

  // Video Uploader
  const uploadVideoHandler = (files) => {
    console.log("video file >> ", files[0]);
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setVideoProgress(percent);
        console.log(
          "progress video upload :: ",
          Math.round((evt.loaded / evt.total) * 100)
        );
      })
      .send((err, data) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Video Uploaded Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        getVideosList();
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          console.log("error >> ", err);
        }
      });
  };

  // Handle Submit
  const onSubmit = (data) => {
    const mainFile = data.file[0].name;
    var ext = mainFile.substr(mainFile.lastIndexOf(".") + 1);
    const formData = new FormData();
    formData.append("name", data.title);
    formData.append("type", ext);
    formData.append("doc", data.file[0]);
    formData.append("size", data.file[0].size);
    axios.post(`${basurl}/uploadDocs`, formData).then((res) => {
      const uploadResponse = res.data;
      console.log("response >> ", uploadResponse);
      if (uploadResponse.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "File Uploaded Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        setDocumentUploader(false);
        getDocumentsList();
      }
    });
    // console.log("submit data >> ", ext)
  };
  const handleTags = (tags) => {
    console.log("tags >> ", tags);
    setTags(tags);
  };

  // Save course
  const handleSaveCourse = () => {
    const list = sections.filter((section) => section.title !== null);
    console.log("sections >> ", list);
    axios
      .put(
        `${basurl}/course/edit/${id}`,
        {
          course_sections: list,
          tags: tags,
          isPublished: publish,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Course content saved successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/courses");
        }
        // console.log("result >> ", result)
      });
  };

  // Delete Course
  const deleteCourse = () => {
    Swal.fire({
      title: "Title",
      html: "Please enter <Strong>Delete</Strong> for delete this course",
      input: "text",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value === "Delete") {
          axios
            .delete(`${basurl}/course/delete/${id}`, {
              headers: {
                "auth-token": userToken,
              },
            })
            .then((res) => {
              const deleteStatus = res.data;
              if (deleteStatus.success) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: deleteStatus.message,
                  showConfirmButton: false,
                  timer: 2500,
                });
                props.history.push("/courses");
              }
            });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `Please enter "Delete" for delete this course`,
            showConfirmButton: true,
          });
        }
      }
    });
  };
  // console.log("video list here ", sectionItems);
  return (
    <MainContainer>
      {isLoading && <Loader />}

      <div className="card">
        <div
          className="card-header"
          style={{ borderBottom: "1px solid #eeeeee" }}
        >
          <h4 className={"mb-0"}>Add Course : Course Content</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className={"course_content_sidebar"}>
                <h4 className={"sidebar_title"}>Course Content</h4>
                <div className="sidebar_body">
                  {sections.map((item, index) => (
                    <div key={index} className={"content_section"}>
                      <div className={"section-title"}>
                        Section: {index + 1} - {item.title}
                        {item.isOpened ? (
                          <span
                            className={"circle"}
                            onClick={() => handleSectionOpen(index)}
                          >
                            <i
                              className="la la-chevron-up"
                              aria-hidden="true"
                            ></i>
                          </span>
                        ) : (
                          <span
                            className={"circle"}
                            onClick={() => handleSectionOpen(index)}
                          >
                            <i
                              className="la la-chevron-down"
                              aria-hidden="true"
                            ></i>
                          </span>
                        )}
                        <span
                          className={"delete_section"}
                          onClick={() => handleSectionDelete(item._id)}
                        >
                          <i className={"ri-delete-bin-line"}></i>
                        </span>
                        <Link
                          to={`/courses/edit-course-content/${id}/${item._id}`}
                          className="edit_section"
                        >
                          <i className={"ri-pencil-line"}></i>
                        </Link>
                      </div>
                      {item.isOpened && (
                        <div className={"section-body"}>
                          {item.section_item.map((item, index) => (
                            <div
                              className={"item_row"}
                              key={index}
                              style={{
                                paddingRight: item.isPriview ? "80px" : "0px",
                              }}
                            >
                              {itemShow(item.type)}
                              <span>{item.name}</span>
                              {item.isPriview && (
                                <span className={"btn btn-info  preview_btn"}>
                                  Preview
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                  <div className={"mt-2"}>
                    <Link
                      className="btn btn-primary"
                      to={`/courses/add-course-content/${id}`}
                    >
                      <i className="la la-plus"></i> Add Course Content Section
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"mt-20 card"}>
        <div className="card-header">
          <h4 className={"header-title"} style={{ margin: 0 }}>
            Advance
          </h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Tags</label>
                <TagsInput value={tags} onChange={handleTags} />
              </div>
            </div>

            <div className="col-md-3">
              <div
                className="form-group"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label>Publish/Draft</label>
                <Switch
                  onChange={() => setpublish(!publish)}
                  onColor={"#FF394F"}
                  checked={publish}
                />
              </div>
            </div>
          </div>
          <div className={"course-card-footer"}>
            <div className="row">
              <div className="col-md-6">
                <button
                  className={"btn btn-default delete-button"}
                  onClick={() => deleteCourse()}
                >
                  Distroy / Delete Course
                </button>
              </div>
              <div className="col-md-6 text-right">
                <button
                  className={"btn btn-primary"}
                  onClick={() => handleSaveCourse()}
                >
                  Save Course
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateCourseStep2);
