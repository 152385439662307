import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import Swal from "sweetalert2";
import { Select } from "antd";
import "antd/dist/antd.css";
import { connect, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import moment from "moment";
import { userLogout } from "../../redux/actions/UserActions";
import { Redirect } from "react-router-dom";

const AddStudent = (props) => {
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const [courses, setCourses] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [selectedBundles, setSelectedBundles] = useState(null);
  const [students, setStudents] = useState([]);
  const [productType, setProductType] = useState(null);
  const [isExistStudent, setIsExistStudent] = useState(true);
  const [quiz, setQuiz] = useState([]);
  const [type, setType] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const { Option } = Select;
  const [price1, setPrice1] = useState(0);
  const [studentName, setStudentName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentMobile, setStudentMobile] = useState("");
  const [courseError, setCourseError] = useState(null);
  const [courseError1, setCourseError1] = useState(null);
  const role = useSelector((state) => state.user?.user?.role);

  const logoutFunction = () => {
    props.logoutUser();
    localStorage.removeItem("lmsUserInfo");
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Your token has been expired!, Please login again",
      showConfirmButton: false,
      timer: 1500,
    });
    props.history.push("/");
  };

  // get courses
  const getCourses = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/course/front-all`)
      .then((res) => {
        const courseList = res.data;
        if (courseList.success) {
          setCourses(courseList.data.reverse());
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  // get bundles
  const getBundles = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/bundle/list`)
      .then((res) => {
        const bundleList = res.data;
        if (bundleList.success) {
          setBundles(bundleList.data.reverse());
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  // get students
  const getStudents = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/users/students`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const studentList = res.data;
        if (studentList.success) {
          setStudents(studentList.data.reverse());
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          logoutFunction();
        }
      });
  };

  // get Quiz list
  const getQuizList = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/quiz/all`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const quizList = res.data;
        if (quizList.success) {
          setQuiz(quizList.data.reverse());
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          logoutFunction();
        }
      });
  };

  useEffect(() => {
    getStudents();
  }, []);

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  useEffect(() => {
    if (type === 1) {
      getCourses();
    } else if (type === 2) {
      getQuizList();
    } else if (type === 4) {
      getBundles();
    }
  }, [type]);

  //handleSwitch
  const handleSwitchOn = () => {
    setIsExistStudent(true);
  };
  const handleSwitchOff = () => {
    setIsExistStudent(false);
  };

  //onChangeType
  const onChangeType = (value) => {
    setType(value);
  };

  // selectQuiz
  const selectQuiz = (value) => {
    setSelectedQuiz(value);
  };
  // selectCourse
  const selectCourse = (value) => {
    console.log("selected course >>", value);
    const getCourse = courses.filter((course) => course._id === value);
    if (value === 0) {
      setSelectedCourse(null);
      setProductType(null);
    } else {
      setSelectedCourse(value);
      setProductType("course");
      setPrice1(getCourse[0].price);
    }
  };
  // selectBundle
  const selectBundle = (value) => {
    console.log("selected bundle >>", value);
    const getBundle = bundles.filter((bundle) => bundle._id === value);
    if (value === 0) {
      setSelectedCourse(null);
      setSelectedBundles(null);
      setProductType(null);
    } else {
      setSelectedBundles(value);
      setPrice1(getBundle[0].price);
      setProductType("bundle");
    }
  };
  // onChangeStudent
  const onChangeStudent = (value) => {
    setSelectedStudent(value);
  };

  //handleAddProduct to existing student
  const handleAddProduct = () => {
    const ssCourse = courses.filter((item) => item._id === selectedCourse);
    if (selectedCourse === null) {
      setCourseError("Please assign a course");
    } else {
      setIsLoading(true);
      axios
        .post(
          `${basurl}/addStudent/existing`,
          {
            user: selectedStudent,
            course: selectedCourse,
            bundle: selectedBundles,
            price: price1,
            type: productType,
            expiryDate: ssCourse[0].course_validity,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            props.history.push("/students");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Course successfully assigned to student",
              showConfirmButton: false,
              timer: 2500,
            });
          }
          setIsLoading(false);
          console.log("res >> ", result);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("error >> ", err);
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    }
  };
  //handleAddBundle to existing student
  const handleAddBundle = () => {
    const ssCourse = bundles.filter((item) => item._id === selectedBundles);
    if (selectedBundles === null) {
      setCourseError("Please assign a bundle");
    } else {
      setIsLoading(true);
      axios
        .post(
          `${basurl}/addStudent/existing`,
          {
            user: selectedStudent,
            bundle: selectedBundles,
            price: price1,
            type: productType,
            expiryDate: ssCourse[0].expiryDate,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            props.history.push("/students");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Bundle successfully assigned to student",
              showConfirmButton: false,
              timer: 2500,
            });
          }
          setIsLoading(false);
          console.log("res >> ", result);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("error >> ", err);
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    }
  };

  // console.log("selected course >>", selectedBundles);
  //handleNewStudent to existing student
  const handleNewStudent = (e) => {
    e.preventDefault();
    const ssCourse = courses.filter((item) => item._id === selectedCourse);
    // console.log("selected course >> ", ssCourse);
    if (selectedCourse === null) {
      setCourseError1("Please assign a course");
    } else {
      setIsLoading(true);
      axios
        .post(
          `${basurl}/addStudent/add-new-student`,
          {
            name: studentName,
            email: studentEmail,
            mobile: studentMobile,
            course: selectedCourse,
            price: price1,
            expiryDate: ssCourse[0].course_validity,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            props.history.push("/students");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Student Added successfully",
              showConfirmButton: false,
              timer: 2500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: result.message,
              showConfirmButton: false,
              timer: 2500,
            });
          }
          setIsLoading(false);
          // console.log("res >> ", result);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("error >> ", err.response);
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    }
  };

  if (role !== "Admin") {
    return <Redirect to="/" />;
  }

  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="card">
        <div className="card-header">
          <h4>Add Student</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="tabs-wrapper">
                <div
                  className={`tab ${isExistStudent && "active"}`}
                  onClick={handleSwitchOn}
                >
                  Add Product to Student
                </div>
                {/* <div
                  className={`tab ${!isExistStudent && "active"}`}
                  onClick={handleSwitchOff}
                >
                  Add Student & assign product
                </div> */}
              </div>
              <div className="tabs_body">
                {isExistStudent ? (
                  <div className="tabs_form">
                    {students.length > 0 && (
                      <div className="form-group">
                        <label>Search Student by email id</label>
                        <Select
                          showSearch
                          className="w-full"
                          onChange={onChangeStudent}
                          placeholder="Search Student by email id"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {students.map((item, index) => (
                            <Option value={item._id} key={item._id}>
                              {item.email}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    )}
                    <div className="form-group">
                      <label>Select Product Type</label>
                      <Select
                        showSearch
                        className="w-full"
                        placeholder="Search to Select"
                        onChange={onChangeType}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {/* <Option value={0}>Select Product Type</Option> */}
                        <Option value={1}>Course</Option>
                        <Option value={4}>Bundle</Option>
                        {/* <Option value={3}>Mock Test</Option> */}
                      </Select>
                    </div>
                    {type === 1 && (
                      <div className="form-group">
                        <label
                          style={{ color: courseError !== null ? "red" : "" }}
                        >
                          Select Course
                        </label>
                        <Select
                          showSearch
                          className={`w-full ${
                            courseError !== null ? "isError" : ""
                          }`}
                          onChange={selectCourse}
                          placeholder="Select Course"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA1, optionB1) =>
                            optionA1.children
                              .toLowerCase()
                              .localeCompare(optionB1.children.toLowerCase())
                          }
                        >
                          {courses.map((item, index) => (
                            <Option
                              value={item._id}
                              key={item._id + item.title.toString()}
                            >
                              {item.title}
                            </Option>
                          ))}
                        </Select>
                        {courseError && (
                          <span className={"isError"}>{courseError}</span>
                        )}
                      </div>
                    )}
                    {type === 2 && (
                      <div className="form-group">
                        <label>Select Quiz</label>
                        <Select
                          showSearch
                          className="w-full"
                          onChange={selectQuiz}
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          <Option value={0}>Select Quiz</Option>
                          {quiz.map((item, index) => (
                            <Option value={item._id} key={item._id}>
                              {item.title}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    )}
                    {type === 4 && (
                      <div className="form-group">
                        <label>Select Bundle</label>
                        <Select
                          showSearch
                          className="w-full"
                          onChange={selectBundle}
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {bundles.map((item, index) => (
                            <Option value={item._id} key={item._id}>
                              {item.title}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    )}
                    <div className="form-group">
                      <label>Price</label>
                      <input
                        type="number"
                        placeholder="Price"
                        className="form-control"
                        value={price1}
                        min={0}
                        onChange={(e) => setPrice1(e.target.value)}
                      />
                    </div>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-primary btn-block"
                        onClick={
                          productType === "course"
                            ? handleAddProduct
                            : handleAddBundle
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="tabs_form">
                    <form onSubmit={handleNewStudent}>
                      <div className="form-group">
                        <label>Student Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Name"
                          value={studentName}
                          onChange={(e) => setStudentName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Student Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Student email"
                          value={studentEmail}
                          onChange={(e) => setStudentEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Student Mobile Number</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Student Mobile No."
                          maxLength={10}
                          length={10}
                          value={studentMobile}
                          onChange={(e) => setStudentMobile(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Select Product Type</label>
                        <Select
                          showSearch
                          className="w-full"
                          placeholder="Search to Select"
                          onChange={onChangeType}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          <Option value={1}>Course</Option>
                          {/* <Option value={2}>Quiz</Option> */}
                          {/* <Option value={3}>Mock Test</Option> */}
                        </Select>
                      </div>
                      {type === 1 && (
                        <div className="form-group">
                          <label
                            style={{
                              color: courseError1 !== null ? "red" : "",
                            }}
                          >
                            Select Course
                          </label>
                          <Select
                            showSearch
                            className={`w-full ${
                              courseError1 !== null ? "isError" : ""
                            }`}
                            onChange={selectCourse}
                            placeholder="Select Course"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {courses.map((item, index) => (
                              <Option
                                value={item._id}
                                key={item._id + item.title.toString()}
                              >
                                {item.title}
                              </Option>
                            ))}
                          </Select>
                          {courseError1 && (
                            <span className={"isError"}>{courseError1}</span>
                          )}
                        </div>
                      )}
                      {type === 2 && (
                        <div className="form-group">
                          <label>Select Quiz</label>
                          <Select
                            showSearch
                            className="w-full"
                            onChange={selectQuiz}
                            placeholder="Select Quiz"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {quiz.map((item) => (
                              <Option value={item._id} key={item._id}>
                                {item.title}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      <div className="form-group">
                        <label>Price</label>
                        <input
                          type="number"
                          placeholder="Price"
                          className="form-control"
                          value={price1}
                          min={0}
                          onChange={(e) => setPrice1(e.target.value)}
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddStudent);
