import React, { useState, useEffect } from "react";
import MainContainer from "../../../common/MainContainer";
import axios from "axios";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import Loader from "../../../common/Loader";
import { userLogout } from "../../../redux/actions/UserActions";

const AddTopperCategory = (props) => {
  let history = useHistory();
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post(
        `${basurl}/frontend/toppers/category/add`,
        {
          title: data.name,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your exam added successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
          props.history.push("/frontend/topperscategory");
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: err.Message,
          showConfirmButton: false,
          timer: 1500,
        });
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        setIsLoading(false);
      });
  };

  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="card">
        <div className="card-header">
          <div className={"row"}>
            <div className="col-md-6">
              <h4 className={"header-title"}>Add Exam</h4>
            </div>
          </div>
        </div>
        <div className={"card-body"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label>Exam Name</label>
              <input
                type="text"
                placeholder="Enter Exam Name"
                className="form-control"
                ref={register({ required: true })}
                name="name"
              />
              {errors.name && (
                <span className="isError">Exam Name is required</span>
              )}
            </div>

            <div className={"d-flex"}>
              <button
                type="submit"
                className="btn btn-success"
                style={{ marginRight: 15 }}
              >
                Add Exam
              </button>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTopperCategory);
