import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import Swal from "sweetalert2";
import axios from "axios";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import { useForm } from "react-hook-form";
import { TimePicker, DatePicker, Switch } from "antd";
import moment from "moment";
import QuestionCard from "./components/QuestionCard";
import CKEditor from "ckeditor4-react-advanced";
import { Link } from "react-router-dom";
import { userLogout } from "../../redux/actions/UserActions";

const EditMock = (props) => {
  const { id } = props.match.params;
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [totalTime, setTotalTime] = useState(null);
  const [changedTotalTime, setChangedTotalTime] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [description1_english, setDescription1_english] = useState(null);
  const [description2_english, setDescription2_english] = useState(null);
  const [description1_hindi, setDescription1_hindi] = useState(null);
  const [description2_hindi, setDescription2_hindi] = useState(null);
  const { register, handleSubmit, errors, setValue } = useForm();
  const [isNegativeMarking, setIsNegativeMarking] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  //   console.log("id>>", id);
  // for api info
  const basurl = process.env.REACT_APP_URL;
  const imgUrls = process.env.REACT_APP_BASE_URL;
  const userToken = props.user.userToken;

  // get quiz categories
  const getQuizCategories = () => {
    axios
      .get(`${basurl}/mock/categories`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setCategories(result.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        Swal.fire({
          position: "center",
          icon: "error",
          title: err.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };
  // submit form
  const onSubmit = (data) => {
    // console.log("data ?>>>>", data);
    axios
      .put(
        `${basurl}/mock/edit/${id}`,
        {
          title_hindi: data.title_hindi,
          title_english: data.title_english,
          category: data.category,
          time: totalTime,
          price: parseFloat(data.price),
          expiryDate: expiryDate,
          description1_english,
          description1_hindi,
          description2_english,
          description2_hindi,
          isNegativeMarking,
          totalMarks,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        // console.log(result);
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  // get quiz details
  const getQuizDetails = () => {
    axios
      .get(`${basurl}/mock/view/${id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          // var duration = moment.duration(result.data.time, "seconds");
          // var formatted = moment(duration).format("hh:mm:ss");
          setValue("title_hindi", result.data.title_hindi);
          setValue("title_english", result.data.title_english);
          setValue("category", result.data.category._id);
          setValue("price", result.data.price);
          setQuestions(result.data.questions);
          setTotalTime(result.data.time);
          setChangedTotalTime(result.data.time);
          setExpiryDate(moment(result.data.expiryDate).toDate());
          setDescription1_english(result.data.description1_english);
          setDescription2_english(result.data.description2_english);
          setDescription1_hindi(
            result.data.description1_hindi === null
              ? " "
              : result.data.description1_hindi
          );
          setDescription2_hindi(
            result.data.description2_hindi === null
              ? " "
              : result.data.description2_hindi
          );
          setIsNegativeMarking(result.data.isNegativeMarking);
          setTotalMarks(result.data.totalMarks);
          setSelectedCategory(result.data.category._id);
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };
  useEffect(() => {
    getQuizCategories();
    getQuizDetails();
  }, [id]);

  // console.log("all mock categories >> ", totalTime);

  // on change time picker
  const onChange = (time, timeString) => {
    console.log(moment(timeString).seconds(timeString));
    const tt = moment(timeString, "HH:mm:ss").diff(
      moment().startOf("day"),
      "seconds"
    );
    setChangedTotalTime(timeString);
    setTotalTime(tt);
  };

  //onChangeDate
  const onChangeDate = (date, dateString) => {
    // console.log(moment(dateString).toDate());
    setExpiryDate(moment(dateString).toDate());
  };

  // Delete
  const handleDeleteQuestion = (idz) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${basurl}/mock/question/delete/${idz}/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const dl = res.data;
            if (dl.success) {
              Swal.fire(
                "Deleted!",
                "Your Question has been deleted.",
                "success"
              );
              getQuizDetails();
            }
          });
      }
    });
  };

  // * * * * * * *  Bulk Questions Upload from Excel file * * * * * * * * *
  const bulkUpload = (file) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file.target.files[0]);
    formData.append("mock", id);
    axios({
      method: "post",
      url: `${basurl}/mock/upload-multiple-quiz`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const result = res.data;
      if (result.success) {
        getQuizDetails();
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      setIsLoading(false);
    }).catch = (err) => {
      setIsLoading(false);
      console.log("error on bulk upload questions>>>", err);
    };
  };

  const dateChanged = (value) => {
    const timeNew = new Date(value * 1000).toISOString().substr(11, 8);
    return timeNew;
  };

  return (
    <MainContainer>
      <div className={"card"}>
        {isLoading && <Loader />}
        <div className={"card-header"}>
          <h4>Edit Mock</h4>
        </div>
        <div className={"card-body"}>
          <h4 className="section_title">Mock Info</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                {categories.length > 0 && (
                  <div className="mb-2">
                    <label>Select Category</label>
                    <select
                      name="category"
                      className="form-control"
                      ref={register({ required: true })}
                    >
                      <option value="">Select Category</option>
                      {categories.map((item, index) => {
                        return (
                          <option
                            value={item._id}
                            key={index}
                            selected={
                              selectedCategory === item._id ? true : false
                            }
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                    {errors.category && (
                      <span className="isError">Category is required.</span>
                    )}
                  </div>
                )}
              </div>
              {changedTotalTime !== null && (
                <div className="col-md-6">
                  <label>Total Time</label>
                  <TimePicker
                    onChange={onChange}
                    // value={moment(changedTotalTime, "HH:mm:ss")}
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                    className="form-control"
                    size="large"
                    showNow={false}
                    allowClear={true}
                    value={moment(dateChanged(totalTime), "HH:mm:ss")}
                  />
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Title in English</label>
                  <input
                    type="text"
                    placeholder="Title in English"
                    className="form-control"
                    name="title_english"
                    ref={register({ required: true })}
                  />
                  {errors.title_english && (
                    <span className="isError">
                      Title in english is required.
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Title in Hindi</label>
                  <input
                    type="text"
                    placeholder="Title in Hindi"
                    className="form-control"
                    name="title_hindi"
                    ref={register({ required: false })}
                  />
                  {errors.title_hindi && (
                    <span className="isError">Title in Hindi is required.</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Price</label>
                  <input
                    type="number"
                    placeholder="Price"
                    className="form-control"
                    name="price"
                    ref={register({ required: true })}
                  />
                  {errors.price && (
                    <span className="isError">Price is required.</span>
                  )}
                </div>
              </div>
              {expiryDate && (
                <div className="col-md-6">
                  <div className="mb-2">
                    <label>Expiry Date</label>
                    <DatePicker
                      onChange={onChangeDate}
                      defaultValue={moment(expiryDate)}
                      disabledDate={(d) => !d || d.isBefore()}
                      className="form-control"
                      size="large"
                    />
                  </div>
                </div>
              )}

              <div className="col-md-6 mb-2">
                <div className="mb-0">
                  <label className={"d-block"}>Negative Marking</label>
                </div>
                <Switch
                  checked={isNegativeMarking}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={() => setIsNegativeMarking(!isNegativeMarking)}
                />
              </div>
              <div className="col-md-6 mb-2">
                <div className="">
                  <label className={"d-block"}>Total Marks</label>
                </div>
                <input
                  type="number"
                  placeholder="Total Marks"
                  className="form-control"
                  value={totalMarks}
                  onChange={(e) => setTotalMarks(e.target.value)}
                />
              </div>

              <div className="col-md-6">
                <div className="mb-2">
                  <label>Description1 English</label>
                  {description1_english && (
                    <CKEditor
                      data={description1_english}
                      onChange={(evt) =>
                        setDescription1_english(evt.editor.getData())
                      }
                      config={{ allowedContent: true, height: "16em" }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Description1 Hindi</label>
                  {description1_hindi && (
                    <CKEditor
                      data={description1_hindi}
                      onChange={(evt) =>
                        setDescription1_hindi(evt.editor.getData())
                      }
                      config={{ allowedContent: true, height: "16em" }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Description2 Englsih</label>
                  {description2_english && (
                    <CKEditor
                      data={description2_english}
                      onChange={(evt) =>
                        setDescription2_english(evt.editor.getData())
                      }
                      config={{ allowedContent: true, height: "16em" }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Description2 Hindi</label>
                  {description2_hindi && (
                    <CKEditor
                      data={description2_hindi}
                      onChange={(evt) =>
                        setDescription2_hindi(evt.editor.getData())
                      }
                      config={{ allowedContent: true, height: "16em" }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div>
              <button type="submit" className="btn btn-primary">
                Save Quiz
              </button>
            </div>
          </form>
          <div className="section_title mt-3 d-flex space-between">
            <h4 className="mt-0" style={{ position: "relative", top: 10 }}>
              <strong>Questions: </strong>
            </h4>
            <div>
              <button
                type="button"
                className="btn btn-success mr-3"
                style={{ overflow: "hidden", position: "relative" }}
              >
                <span style={{ position: "relative", marginRight: 5 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-file-earmark-excel"
                    viewBox="0 -1 10 20"
                  >
                    <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                  </svg>
                </span>{" "}
                Bulk Upload
                <input
                  type="file"
                  className={"upload_overlay"}
                  onChange={(e) => bulkUpload(e)}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </button>
              <Link
                to={`/mock/add-question/${id}`}
                className="btn btn-primary "
              >
                <i className="la la-plus"></i> Add Question
              </Link>
            </div>
          </div>

          {questions.length > 0 ? (
            <div className="questions_wrapper">
              <div className="row">
                {questions.map((item, index) => (
                  <div className="col-md-4" key={index}>
                    <QuestionCard
                      data={item}
                      index={index}
                      quizId={id}
                      handleDeleteQuestion={() =>
                        handleDeleteQuestion(item._id)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="text-center">No question found</div>
          )}
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditMock);
