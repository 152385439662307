import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import Swal from "sweetalert2";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import { connect } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Dropzone from "react-dropzone";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { userLogout } from "../../redux/actions/UserActions";
import VideoListItem from "./components/VideoListItem";

const VideoScreen = (props) => {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";
  const monthFormat = "YYYY/MM";
  const [title, setTitle] = useState("");
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGridView, setIsGridView] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [selectedType, setSelectedType] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  //   console.log("base url >> ", basurl);

  const toggle = () => setOpenModal(!openModal);

  // handleRange
  const handleRange = (date) => {
    if (date !== null) {
      let date1 = date[0].toDate();
      let date2 = date[1].toDate();
      const newArr = list.filter((item) => {
        return (
          moment.unix(item.upload_time).toDate() <= date2 &&
          moment.unix(item.upload_time).toDate() >= date1
        );
      });
      setList(newArr);
    } else {
      getVideosListCipher();
    }

    // console.log("get date from range >>>", newArr)
  };

  const getVideosListCipher = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/video-list/get-videos`)
      .then((res) => {
        const videoList = res.data;
        if (videoList.success) {
          const newArray = videoList.data.rows.map((item) => {
            return {
              ...item,
              isSelect: false,
            };
          });
          setList(newArray);
        }

        console.log("videoList>> ", videoList);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        console.log(err);
      });
  };

  useEffect(() => {
    // getVideosList();
    getVideosListCipher();
  }, []);

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  // handleTypes
  const handleTypes = (evt) => {
    const type = evt.target.value;
    setSelectedType(type);
    console.log("type >> ", type);
  };

  // for search filter
  const filteredData = list.filter((val) => {
    if (searchInput === "") {
      return val;
    } else if (val.title.toLowerCase().includes(searchInput.toLowerCase())) {
      return val;
    }
  });

  // uploadVideoHandler
  const uploadVideoHandler = (files) => {
    setUploadFiles(files);
  };

  // handleUploadVideoFile
  const handleUploadVideoFile = () => {
    if (title === "") {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please enter title",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    } else if (uploadFiles.length === 0) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please select video file",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    } else {
      setIsLoading(true);
      axios
        .post(`${basurl}/video-list/upload-video`, {
          title: title,
        })
        .then((res) => {
          const result = res.data;
          if (result.success) {
            const formData = new FormData();
            formData.append("policy", result.data.clientPayload.policy);
            formData.append("key", result.data.clientPayload.key);
            formData.append(
              "x-amz-signature",
              result.data.clientPayload["x-amz-signature"]
            );
            formData.append(
              "x-amz-algorithm",
              result.data.clientPayload["x-amz-algorithm"]
            );
            formData.append(
              "x-amz-date",
              result.data.clientPayload["x-amz-date"]
            );
            formData.append(
              "x-amz-credential",
              result.data.clientPayload["x-amz-credential"]
            );
            formData.append("success_action_status", "201");
            formData.append("success_action_redirect", "");
            formData.append("file", uploadFiles[0]);
            setIsLoading(true);
            axios
              .post(result.data.clientPayload.uploadLink, formData, {})
              .then((response) => {
                const uloadRes = response.data;
                if (response.status === 201) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Video uploaded successfully",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  setTitle("");
                  setUploadFiles([]);
                  setOpenModal(false);
                  getVideosListCipher();
                }
                console.log("upload response >> ", response);
              });
          }
          console.log("result >> ", result);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  //handleLink
  const handleLink = (value, ind) => {
    const oldArrayItem = list;
    const newArray = oldArrayItem.map((item, index) => {
      if (index === ind) {
        return {
          ...item,
          isSelect: !item.isSelect,
        };
      } else {
        return {
          ...item,
          isSelect: false,
        };
      }
    });
    setList(newArray);
    props.mediaLink(value);
  };

  return (
    <MainContainer>
      <Modal isOpen={openModal} toggle={toggle} size="xl" zIndex={9999}>
        <ModalHeader toggle={toggle}>Upload new video</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label>Title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter video title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className={"section_video_uploader"}>
            {uploadFiles.length > 0 ? (
              <div className="video_uploader_container">
                <div className="row">
                  <div className="col-sm-10">
                    <h6 className="mb-0">{uploadFiles[0].name}</h6>
                  </div>
                  <div className="col-sm-2 text-right">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setUploadFiles([])}
                    >
                      <i className="fa fa-times-circle"></i>
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <Dropzone
                onDrop={(acceptedFiles) => uploadVideoHandler(acceptedFiles)}
                accept={"video/mp4, video/avi, video/mkv, video/flv"}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className={"dropzone_wrapper"}>
                    <div {...getRootProps()} className={"dropZone"}>
                      <input {...getInputProps()} />
                      <div className="d-flex flex-column">
                        <p>Drop files to upload</p>
                        <p className="text-center mt-2 mb-2">or</p>
                        <button type="button" className="btn btn-primary">
                          Select files
                        </button>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          {isLoading ? (
            <button
              className="btn btn-primary"
              onClick={() => console.log("Loading...")}
              style={{ opacity: 0.8 }}
            >
              <i className="fa fa-spinner fa-spin"></i> Loading...
            </button>
          ) : (
            <button className="btn btn-primary" onClick={handleUploadVideoFile}>
              Upload file
            </button>
          )}
        </ModalFooter>
      </Modal>
      <div className="cards">
        <div className="card-headers">
          <div className="row">
            <div className="col-sm-6">
              <div className="d-flex align-items-center">
                <h4 className="mt-2" style={{ marginRight: 30 }}>
                  Videos
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="card-bodys">
          <div className="filter_options">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-5">
                    <RangePicker
                      format={dateFormat}
                      className={"form-control custom_range_picker"}
                      onChange={handleRange}
                    />
                  </div>
                  <div className="col-sm-2">
                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => setOpenModal(true)}
                    >
                      <i className="fas fa-plus"></i> Add Video
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            {list.length > 0 ? (
              <div className="card-body">
                {filteredData.map((item, index) => (
                  <div className="course_video_item" key={index}>
                    <VideoListItem
                      item={item}
                      handleLink={handleLink}
                      index={index}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <>
                {!isLoading && (
                  <div className="alert alert-secondary">Data not found</div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoScreen);
