import React, { useState, useEffect } from "react";
import axios from "axios";
import { Radio } from "antd";
import { useSelector } from "react-redux";
import FileViewer from "react-file-viewer";
import ReactAudioPlayer from "react-audio-player";
import Switch from "react-switch";

//Import Logos
import logoSmLight from "../../assets/images/logo-sm-light.png";
import logoLight from "../../assets/images/logo-light.png";
import { Link } from "react-router-dom";
import ProfileMenu from "../../components/CommonForBoth/TopbarDropdown/ProfileMenu";
import Swal from "sweetalert2";

const BuildMock = ({ data, handleBuilder, reloadMock }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([
    {
      title: "",
      isCorrect: true,
    },
  ]);
  const basurl = process.env.REACT_APP_URL;
  const user = useSelector((state) => state.user);
  const userToken = user.userToken;

  // toggle
  const toggleCheckbox = (it, ind) => {
    let arr = options.map((item, index) => {
      if (ind == index) {
        item.isCorrect = !item.isCorrect;
      } else {
        item.isCorrect = false;
      }
      return { ...item };
    });
    setOptions(arr);
  };

  // Add Option
  const addOption = () => {
    setOptions([
      ...options,
      {
        title: "",
        isCorrect: false,
      },
    ]);
  };

  // Remove Option
  const removeOption = (index) => {
    const list = [...options];
    list.splice(index, 1);
    setOptions(list);
  };

  //handleOptionText
  const handleOptionText = (e, index) => {
    const { name, value } = e.target;
    const list = [...options];
    list[index][name] = value;
    setOptions(list);
  };

  // handleAddQuestion
  const handleAddQuestion = () => {
    if (question === "") {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Question is required!",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    } else if (options.length < 2) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "You need to add at least 2 options!",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    } else {
      setIsLoading(true);
      axios
        .post(
          `${basurl}/mock/question/add`,
          {
            title: question,
            mock: data._id,
            options,
            image: null,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            reloadMock();
            setQuestion("");
            setOptions([
              {
                title: "",
                isCorrect: true,
              },
            ]);
          }
          console.log(result);
          setIsLoading(false);
        }).catch = (err) => {
        setIsLoading(false);
      };
    }
  };

  // Delete question
  const handleDeleteQuestion = (idz) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${basurl}/mock/question/delete/${idz}/${data._id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const dl = res.data;
            if (dl.success) {
              Swal.fire(
                "Deleted!",
                "Your Question has been deleted.",
                "success"
              );
              reloadMock();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <>
      <div className="build_course_wrapper">
        <div className="left_section">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoSmLight} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoLight} alt="" height="50" />
              </span>
            </Link>
          </div>
          {/* Inner div */}
          <div className="inner_content_sidebar" style={{ overflow: "auto" }}>
            <div className="featured_image">
              <img src={data?.image} alt="course image" className="img-fluid" />
            </div>
            {/* Chapter list */}
            {data?.questions.length > 0 && (
              <div className="chapter_list">
                {data?.questions.map((section, index) => (
                  <div className="chapter_list_item" key={section._id}>
                    <div className="chapter_item_header d-flex justify-content-between">
                      <div
                        className="d-flex"
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleSectionOpen(index)}
                      >
                        <i className="ri-arrow-right-s-line"></i>{" "}
                        <span style={{ marginLeft: 5 }}>{section.title}</span>
                      </div>
                      <span
                        className="delete_section"
                        onClick={() => handleDeleteQuestion(section._id)}
                      >
                        <i className="ri-delete-bin-line"></i>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* end Chapter list */}
            <span
              className="add_element_trigger"
              //   onClick={() => setChapterModal(true)}
            >
              <i className="ri-add-circle-line"></i>
              Add question
            </span>
          </div>
        </div>
        <div className="right_section" style={{ overflow: "auto" }}>
          <div className="d-flex justify-content-end">
            <ProfileMenu />
          </div>
          <div className="right_part_inner">
            <div className="sm-header-title">
              Add questions in <span className="dark_text">{data?.title}</span>
            </div>
            <div>
              <span
                className="d-block sm-header-title pointer"
                onClick={handleBuilder}
              >
                <i className="ri-arrow-left-line"></i>{" "}
                <span style={{ position: "relative", left: 5 }}>
                  Back to basic mock information
                </span>
              </span>
            </div>
            <div className="card" style={{ marginTop: 40 }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-9">
                    <div className="mb-4">
                      <textarea
                        className="form-control"
                        placeholder="Type your question here"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        style={{
                          minHeight: "200px",
                          resize: "none",
                          borderRadius: 10,
                        }}
                      ></textarea>
                    </div>
                    {options.map((item, index) => (
                      <div className="mb-2" key={index}>
                        <div className="d-flex align-items-center">
                          <Radio
                            checked={item.isCorrect}
                            onChange={() => toggleCheckbox(item, index)}
                          />
                          <input
                            className="form-control"
                            placeholder="option..."
                            value={item.title}
                            name="title"
                            onChange={(e) => handleOptionText(e, index)}
                          />
                          {options.length > 1 && (
                            <span
                              style={{
                                marginLeft: 10,
                                color: "#B71C1C",
                                cursor: "pointer",
                              }}
                              onClick={() => removeOption(index)}
                            >
                              <i className="ri-delete-bin-line"></i>
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                    {options.length > 0 && (
                      <button
                        className="btn btn-link"
                        onClick={() => addOption()}
                      >
                        Add more option
                      </button>
                    )}
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary"
                        onClick={handleAddQuestion}
                      >
                        Add question
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BuildMock;
