import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Loader from "../../common/Loader";
import { Table } from "antd";
import "antd/dist/antd.css";

const CourseCategories = (props) => {
  const basurl = process.env.REACT_APP_URL;
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // get categories
  const getCategories = () => {
    axios
      .get(`${basurl}/coursecat`)
      .then((res) => {
        const list = res.data;
        if (list.success) {
          setList(list.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // Delete
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        setIsLoading(true);
        axios
          .delete(`${basurl}/coursecat/delete/${id}`)
          .then((res) => {
            const dl = res.data;
            if (dl.status === "success") {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              getCategories();
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    });
  };

  // Columns
  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      render: (value, item, index) => <span>{item.title}</span>,
    },
    {
      title: "Sort Order",
      render: (value, item, index) => <span>{item.sortOrder}</span>,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (value, item, index) => <img src={item.image} width="100px" />,
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (value, item, index) => <span>{item.description}</span>,
    },

    {
      title: "Action",
      render: (value, item, index) => (
        <div style={{ whiteSpace: "nowrap" }}>
          <Link
            to={`/categories/edit/${item._id}`}
            className="btn  btn-primary"
            style={{ minWidth: 100 }}
          >
            <i className="ri-pencil-line"></i>
          </Link>
          <button
            className="btn  btn-danger"
            style={{ marginLeft: "10px", minWidth: 100 }}
            onClick={() => handleDelete(item._id)}
          >
            <i className="ri-delete-bin-line"></i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Course Categories
            </h4>
            <Link to={"/categories/create"} className={"btn btn-primary "}>
              <i className={"fa fa-plus"}></i> Add Category
            </Link>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className="card-body">
          <div className={"table-responsive"}>
            <Table
              columns={columns}
              dataSource={list}
              bordered={true}
              rowKey={(item) => item._id}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
export default CourseCategories;
