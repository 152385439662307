import React, { useState, useEffect } from "react";
import axios from "axios";

const VideoChipherPlayer = ({ id }) => {
  const [otp, setOtp] = useState(null);
  const [playbackInfo, setPlaybackInfo] = useState(null);
  const basurl = process.env.REACT_APP_URL;

  const VdoPlayer = window.VdoPlayer;

  const getOTPVideo = () => {
    axios
      .post(`${basurl}/video-list/get-video-otp`, {
        id: id,
      })
      .then((res) => {
        const videoList = res.data;
        if (videoList.success) {
          setOtp(videoList.data.otp);
          setPlaybackInfo(videoList.data.playbackInfo);
          var video = new VdoPlayer({
            otp: videoList.data.otp,
            playbackInfo: videoList.data.playbackInfo,
            theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
            // the container can be any DOM element on website
            container: document.querySelector("#embedBox"),
          });
        }

        // console.log("Vikas babu>>>>>> ", videoList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOTPVideo();
  }, [id]);

  return <div>{otp && <div id="embedBox"></div>}</div>;
};

export default VideoChipherPlayer;
