import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import Switch from "react-switch";
import CKEditor from "ckeditor4-react-advanced";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { VideoPlayer } from "../../components/videoPlayer";
import moment from "moment";
import Loader from "../../common/Loader";
import { userLogout } from "../../redux/actions/UserActions";

const EditCourse = (props) => {
  const [categories, setCategories] = useState([]);
  const [validity, setValidity] = useState(new Date());

  const [isImageError, setIsImageError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState("");
  const basurl = process.env.REACT_APP_URL;
  const basurlHome = process.env.REACT_APP_BASE_URL;
  const [liveClass, setLiveClass] = useState([
    { title: "", url: "", startDate: new Date(), endDate: new Date() },
  ]);
  const [discounted, setDiscounted] = useState(false);
  const [discountMethod, setDiscountMethod] = useState("percent");
  const [discountValue, setDiscountValue] = useState(0);
  const [courseVideo, setCourseVideo] = useState("");
  const [isLiveClass, setIsLiveClass] = useState(false);
  const [courseVideoType, setCourseVideoType] = useState("youtube");
  const [videoProgress, setVideoProgress] = useState(0);
  const { id } = props.match.params;
  const { register, handleSubmit, watch, errors, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(true);

  const userToken = props.user.userToken;
  // console.log("discounted >> ", discounted);
  const onSubmit = (data) => {
    setIsLoading(true);
    const url = data.url.split(" ").join("-");
    axios
      .put(
        `${basurl}/course/edit/${id}`,
        {
          title: data.courseTitle,
          short_description: data.short_description,
          description: content,
          category: data.category,
          course_image: selectedImage,
          course_video: courseVideo,
          course_url: url,
          price: data.price,
          discount_price_method: discountMethod,
          discount_price: discountValue,
          course_validity: validity,
          meta_title: data.meta_title,
          meta_description: data.meta_description,
          live_class: liveClass,
          isDiscounted: discounted,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const courseRes = res.data;
        console.log("course add response ", courseRes);
        if (courseRes.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Course Saved successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push(`/courses/create-step2/${courseRes.data._id}`);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: courseRes.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // handle Image upload
  const handleImage = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setSelectedImage(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    axios({
      method: "post",
      url: `${basurl}/upload/img`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const imgRes = res.data;
      console.log("imgresponse >> ", imgRes);
      if (imgRes.success) {
        setSelectedImage(imgRes.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "images uploaded successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  // handle remove selected image
  const handleRemoveImage = () => {
    setImage(null);
    setSelectedImage(null);
  };

  // get course categories
  const getCourseCategories = () => {
    axios.get(`${basurl}/coursecat`).then((res) => {
      const list = res.data;
      if (list.success) {
        setCategories(list.data);
      }
    });
  };

  //for live classes
  const addMoreClass = () => {
    setLiveClass([
      ...liveClass,
      { title: "", url: "", startDate: new Date(), endDate: new Date() },
    ]);
  };
  const handleLiveClass = (e, index) => {
    const { name, value } = e.target;
    const list = [...liveClass];
    list[index][name] = value;
    setLiveClass(list);
  };
  const handleClassStartDate = (value, name, index) => {
    const list = [...liveClass];
    list[index][name] = value;
    setLiveClass(list);
  };
  const handleRemoveLiveClass = (index) => {
    const list = [...liveClass];
    list.splice(index, 1);
    setLiveClass(list);
  };

  useEffect(() => {
    getCourseCategories();
  }, []);

  const onChangeDiscount = () => {
    setDiscounted(!discounted);
  };
  // handle Discount methiod
  const handleChangeDiscountMethod = (e) => {
    console.log("fount event", e.target.value);
    setDiscountMethod(e.target.value);
  };

  // Handle discount value
  const handleDiscountValue = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setDiscountValue(e.target.value);
    }
  };

  // Show Promo Video Platform icons according condition
  var videoIcons;
  if (courseVideoType === "youtube") {
    videoIcons = (
      <div className={"vIcon_wrap youtube"}>
        <i className={"la la-youtube-play"}></i>
      </div>
    );
  } else if (courseVideoType === "vimeo") {
    videoIcons = (
      <div className={"vIcon_wrap vimeo"}>
        <i className={"la la-vimeo"}></i>
      </div>
    );
  } else if (courseVideoType === "custom") {
    videoIcons = (
      <div className={"vIcon_wrap custom"}>
        <i className={"la la-file-video-o"}></i>
      </div>
    );
  } else {
    videoIcons = (
      <div className={"vIcon_wrap upload"}>
        <i className={"ri-upload-line"}></i>
      </div>
    );
  }

  const uploadVideoHandler = (files) => {
    console.log("video file >> ", files[0]);
    const formData = new FormData();
    formData.append("image", files[0]);
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded / total) * 100);
        console.log("percentage >> ", percent);
        setVideoProgress(percent);
      },
    };
    axios.post(`${basurl}/upload/img`, formData, options).then((res) => {
      const finalResult = res.data;
      console.log("result upload >> ", finalResult);
      if (finalResult.success) {
        setCourseVideoType("custom");
        setCourseVideo(process.env.REACT_APP_BASE_URL + finalResult.data);
      }
    });
  };

  // handle Remove Video
  const handleRemoveVideo = () => {
    setCourseVideo("");
    setCourseVideoType("youtube");
  };

  const getCourseDetails = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/course/view/${id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        console.log("course details here >> ", result);
        if (result.success) {
          setCourseVideoType(result.data.course_video_method);
          setCourseVideo(result.data.course_video);
          setContent(result.data.description);
          setValidity(moment(result.data.course_validity).toDate());
          setLiveClass(result.data.live_class);
          setImage(process.env.REACT_APP_BASE_URL + result.data.course_image);
          setSelectedImage(result.data.course_image);
          setDiscounted(result.data.isDiscounted);
          setDiscountMethod(result.data.discount_price_method);
          setDiscountValue(result.data.discount_price);
          setValue("courseTitle", result.data.title);
          setValue("short_description", result.data.short_description);
          setValue("price", result.data.price);
          setValue("meta_title", result.data.meta_title);
          setValue("meta_description", result.data.meta_description);
          setValue("url", result.data.course_url);
          setValue("category", result.data.category._id);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getCourseDetails();
  }, []);

  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-sm-6">
              <h4>Add Course : Basic Information</h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <h4 className={"section_title"}>Basic Information</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={"row"}>
              <div className={"col-md-9"}>
                <div className="form-group">
                  <label>
                    Course Title <span className="starImportnat">*</span>
                  </label>
                  <input
                    name="courseTitle"
                    className="form-control"
                    placeholder="Please Enter Course Title"
                    ref={register({ required: true })}
                  />
                  {errors.courseTitle && (
                    <span className="isError">Course Title is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Short Description <span className="starImportnat">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="short_description"
                    placeholder="Please Enter Course Short Description"
                    ref={register({ required: true })}
                    style={{ resize: "none" }}
                  />
                  {errors.short_description && (
                    <span className="isError">
                      Short Description is required
                    </span>
                  )}
                </div>
                {content !== "" && (
                  <div className="form-group">
                    <label>
                      Description <span className="starImportnat">*</span>
                    </label>
                    <CKEditor
                      data={content}
                      onChange={(evt) => setContent(evt.editor.getData())}
                      config={{ allowedContent: true, height: "15em" }}
                    />
                    {contentError !== "" ? (
                      <span className="isError">{contentError}</span>
                    ) : null}
                  </div>
                )}

                <div className="form-group">
                  <label>
                    Course Category <span className="starImportnat">*</span>
                  </label>
                  <select
                    name="category"
                    ref={register({ required: true })}
                    className="form-control"
                  >
                    <option value="">Please Choose Course Category</option>
                    {categories.length > 0 ? (
                      <>
                        {categories.map((category, index) => (
                          <option key={index} value={category._id}>
                            {category.title}
                          </option>
                        ))}
                      </>
                    ) : null}
                  </select>
                  {errors.category && (
                    <span className="isError">Category is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Course Url <span className="starImportnat">*</span>
                  </label>
                  <div className={"d-flex"}>
                    <span
                      style={{
                        position: "relative",
                        top: "10px",
                      }}
                    >
                      {process.env.REACT_APP_HOME_PATH}
                    </span>
                    <input
                      type="text"
                      placeholder={"Course Url"}
                      className="form-control"
                      name={"url"}
                      ref={register({ required: true })}
                    />
                  </div>
                  {errors.url && (
                    <span className="isError">Course Url is required.</span>
                  )}
                </div>
              </div>
              <div className={"col-md-3"}>
                <div className="form-group">
                  <label>
                    Course Image <span className="starImportnat">*</span>
                  </label>
                  <div className={"image-uploader"}>
                    {image !== null ? (
                      <div className={"img_upld_wrapper"}>
                        <img src={image} className={"image_upload"} />
                        <span
                          className={"upload_img_close"}
                          onClick={() => handleRemoveImage()}
                        >
                          <i className="ri-close-line"></i>
                        </span>
                      </div>
                    ) : (
                      <div className={"image_upload_placeholder"}>
                        <i className={"ri-add-box-line"}></i>
                        <span className={"image_upload_titler"}>
                          Browse Course Image
                        </span>
                        <input
                          type="file"
                          className={"image_upload_file"}
                          onChange={(e) => handleImage(e)}
                          accept="image/*"
                        />
                      </div>
                    )}
                  </div>
                  {isImageError !== "" ? (
                    <span className="isError">{isImageError}</span>
                  ) : null}
                </div>
                <div className={"form-group"}>
                  <label>Course Promo Video</label>
                  <div className="course_video_wrapper">
                    {courseVideo === "" ? (
                      <div
                        className="course_video_placeholder"
                        data-toggle="modal"
                        data-target="#uploadVideo"
                      >
                        <i className={"la la-file-video-o place_icon"}></i>
                        <span className={"image_upload_titler"}>
                          Add Course Promo Video
                        </span>
                      </div>
                    ) : (
                      <div>
                        {VideoPlayer(courseVideoType, courseVideo)}
                        <div
                          className={"btn btn-secondary btn-block"}
                          onClick={() => handleRemoveVideo()}
                        >
                          Remove Video
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={"form-group"}>
                  <label>
                    Validity <span className="starImportnat">*</span>
                  </label>
                  <DatePicker
                    selected={validity}
                    style={{ width: "100%", display: "block" }}
                    onChange={(date) => setValidity(date)}
                  />
                </div>
                <div className={"form-group"}>
                  <label>
                    Price <span className="starImportnat">*</span>
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder={"Enter Price"}
                    ref={register({
                      required: true,
                    })}
                    name="price"
                  />
                  {errors.price && (
                    <span className="isError">Price is required.</span>
                  )}
                </div>
                <div className={"d-flex form-group"}>
                  <label style={{ marginRight: "10px", marginTop: "3px" }}>
                    Price Discount
                  </label>
                  <Switch onChange={onChangeDiscount} checked={discounted} />
                </div>
                {discounted && (
                  <div className={"form-group"}>
                    <div className="row">
                      <div className="col-sm-6">
                        <select
                          className={"form-control"}
                          value={discountMethod}
                          onChange={handleChangeDiscountMethod}
                        >
                          <option value={"percent"}>Percent-%</option>
                          <option value={"digit"}>Digit-00</option>
                        </select>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="tel"
                          value={discountValue}
                          onChange={handleDiscountValue}
                          placeholder={"0"}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={"mt-2"}>
              <h4 className={"section_title"}>
                Live Classes
                <span
                  style={{
                    position: "relative",
                    top: "5px",
                    marginLeft: "10px",
                  }}
                >
                  <Switch onChange={setIsLiveClass} checked={isLiveClass} />
                </span>
              </h4>
            </div>
            {isLiveClass && (
              <div>
                {liveClass.map((item, index) => {
                  return (
                    <div className={"live_class_wrapper"} key={index}>
                      <div className={"row"}>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Class Tilte</label>
                                <input
                                  type="text"
                                  className={"form-control"}
                                  placeholder={"Class Title"}
                                  value={item.title}
                                  name={"title"}
                                  onChange={(e) => handleLiveClass(e, index)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Class Url</label>
                                <input
                                  type="text"
                                  className={"form-control"}
                                  placeholder={"https://"}
                                  value={item.url}
                                  name={"url"}
                                  onChange={(e) => handleLiveClass(e, index)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Start Date</label>
                                <DatePicker
                                  selected={moment(item.startDate).toDate()}
                                  style={{ width: "100%", display: "block" }}
                                  name={"startDate"}
                                  minDate={new Date()}
                                  showTimeSelect
                                  dateFormat="Pp"
                                  onChange={(date) =>
                                    handleClassStartDate(
                                      date,
                                      "startDate",
                                      index
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>End Date</label>
                                <DatePicker
                                  selected={moment(item.endDate).toDate()}
                                  style={{ width: "100%", display: "block" }}
                                  name={"endDate"}
                                  minDate={new Date()}
                                  showTimeSelect
                                  dateFormat="Pp"
                                  onChange={(date) =>
                                    handleClassStartDate(date, "endDate", index)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {index !== 0 ? (
                          <div className="col-md-2">
                            <button
                              type={"button"}
                              className={"btn btn-danger "}
                              style={{ marginTop: "25px" }}
                              onClick={() => handleRemoveLiveClass(index)}
                            >
                              <i className={"ri-delete-bin-line"}></i>
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
                <div className="form-group">
                  <button
                    type={"button"}
                    className={"btn btn-success"}
                    onClick={() => addMoreClass()}
                  >
                    <i className={"la la-plus"}></i> Add More Class
                  </button>
                </div>
              </div>
            )}

            <div className={"mt-2"}>
              <h4 className={"section_title"}>Seo</h4>
            </div>
            <div className={"form-group"}>
              <label>Meta Title</label>
              <input
                type="text"
                className={"form-control"}
                placeholder={"Title"}
                name="meta_title"
                ref={register({ required: false })}
              />
            </div>
            <div className={"form-group"}>
              <label>Meta Description</label>
              <textarea
                className={"form-control"}
                name="meta_description"
                ref={register({ required: false })}
                placeholder={"Description"}
              />
            </div>
            <hr />
            <div className={"row"}>
              <div className={"col-md-6"}>
                <button type={"submit"} className={"btn btn-primary"}>
                  Submit & Continue
                </button>
              </div>
              <div className={"col-md-6 text-right"}>
                <Link
                  to={`/courses/create-step2/${id}`}
                  className={"btn btn-primary"}
                >
                  Skip to Next Step
                </Link>
              </div>
            </div>
          </form>
          <div
            className="modal fade"
            id="uploadVideo"
            tabIndex="-1"
            aria-labelledby="uploadVideoLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="uploadVideoLabel">
                    Add Promo Video
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label>Video Type:</label>
                    <div className={"d-flex"}>
                      {videoIcons}
                      <select
                        className={"form-control"}
                        value={courseVideoType}
                        onChange={(e) => setCourseVideoType(e.target.value)}
                      >
                        <option value="youtube">Youtube</option>
                        <option value="vimeo">Vimeo</option>
                        <option value="custom">Custom</option>
                        <option value="upload">Upload Video</option>
                      </select>
                    </div>
                  </div>
                  {courseVideoType === "upload" ? (
                    <div>
                      {videoProgress > 0 && videoProgress < 99 ? (
                        <div
                          className={"progress_wrapper"}
                          style={{ marginBottom: "10px" }}
                        >
                          <div className="progress_wrap">
                            <CircularProgressbar
                              value={videoProgress}
                              strokeWidth={50}
                              styles={buildStyles({
                                strokeLinecap: "butt",
                                pathColor: "#33b3f2",
                              })}
                            />
                          </div>
                        </div>
                      ) : null}
                      {videoProgress === 100 && (
                        <div className={"alert alert-success text-center"}>
                          Video Uploaded Successfully!
                        </div>
                      )}
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          uploadVideoHandler(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className={"dropzone_wrapper"}>
                            <div {...getRootProps()} className={"dropZone"}>
                              <input {...getInputProps()} />
                              <p>Drag or Browse Video File</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  ) : (
                    <div className="videoLink_wrapper">
                      <input
                        type="text"
                        placeholder={`https:// enter ${courseVideoType} video link here..`}
                        className={"video_link_input"}
                        value={courseVideo}
                        onChange={(e) => setCourseVideo(e.target.value)}
                      />
                    </div>
                  )}
                  <div className={"mt-2 text-center"}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditCourse);
