import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import axios from "axios";
import { Table } from "antd";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import { userLogout } from "../../redux/actions/UserActions";

const CouponList = (props) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const role = props.user?.user?.role;
  //get slider banner list
  const getCouponList = () => {
    axios
      .get(`${basurl}/coupon/`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const coupons = res.data;
        if (coupons.success) {
          setList(coupons.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  // Delete banner slider
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this banner ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${basurl}/coupon/delete/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const deleteStatus = res.data;
            if (deleteStatus.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: deleteStatus.message,
                showConfirmButton: false,
                timer: 2500,
              });
              getCouponList();
            }
            // console.log("delete status > ", deleteStatus);
          })
          .catch((err) => {
            if (err.response.data === "Invalid token") {
              props.logoutUser();
              localStorage.removeItem("lmsUserInfo");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your token has been expired!, Please login again",
                showConfirmButton: false,
                timer: 1500,
              });
              props.history.push("/");
            }
          });
      }
    });
  };

  useEffect(() => {
    getCouponList();
  }, []);

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  // Columns
  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Coupon Code",
      dataIndex: "title",
      key: "title",
      render: (value, item, index) => <span>{item.code}</span>,
    },
    {
      title: "Expiry Date",
      render: (value, item, index) => (
        <span>{moment(item.expiryDate).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "Course",
      render: (value, item, index) => (
        <span>{item.course ? item.course.title : "NA"}</span>
      ),
    },
    {
      title: "Bundle",
      render: (value, item, index) => (
        <span>{item.bundle ? item.bundle.title : "NA"}</span>
      ),
    },
    {
      title: "Discount",
      render: (value, item, index) => (
        <span>
          {item.amountType === "digits" ? "₹" : null}
          {item.amount}
          {item.amountType === "percentage" ? "%" : null}
        </span>
      ),
    },
    {
      title: "Quanity",
      render: (value, item, index) => <span>{item.quantity}</span>,
    },
    {
      title: "Status",
      render: (value, item, index) => (
        <div data-tag="allowRowEvents">
          {item.isActive ? (
            <span className={"text-success"}>Active</span>
          ) : (
            <sapn className={"text-danger"}>Deactivated</sapn>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      render: (value, item, index) => (
        <div data-tag="allowRowEvents" style={{ whiteSpace: "nowrap" }}>
          <Link
            className={"btn btn-success"}
            to={`/coupons/edit/${item._id}`}
            style={{ marginRight: "10px", minWidth: 100 }}
          >
            <i className={"ri-pencil-line"}></i>
          </Link>
          {role === "Admin" && (
          <button
            className={"btn btn-danger"}
            onClick={() => handleDelete(item._id)}
            style={{ minWidth: 100 }}
          >
            <i className={"ri-delete-bin-line"}></i>
          </button>
          )}
        </div>
      ),
    },
  ];

  // console.log("banner list>> ", list);
  return (
    <MainContainer>
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Coupons
            </h4>
            <Link className={"btn btn-primary"} to={"/coupons/add"}>
              Add New Coupon Code
            </Link>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className={"card-body"}>
          <Table
            columns={columns}
            dataSource={list}
            bordered={true}
            loading={isLoading}
          />
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CouponList);
