import React from "react";
import ReactPlayer from "react-player";

export const VideoPlayer = (type, link) => {
  if (type === "youtube") {
    var video_id = link.split("v=")[1];
    var ampersandPosition = video_id.indexOf("&");
    if (ampersandPosition != -1) {
      video_id = video_id.substring(0, ampersandPosition);
    }

    return (
      <iframe
        width="100%"
        height="180"
        src={`https://www.youtube.com/embed/${video_id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  } else if (type === "vimeo") {
    var video_id = link.split("/").pop();
    return (
      <iframe
        src={`https://player.vimeo.com/video/${video_id}`}
        width="100%"
        height="180"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    );
  } else {
    return (
      <ReactPlayer url={link} width={"100%"} height={180} controls={true} />
    );
  }
};
