export const userLoggedIn = (data) => {
  //   console.log("sent data >> ", data);
  return (dispatch) => {
    dispatch({
      type: "USER_LOGGEDIN",
      payload: data.status,
      token: data.token,
      info: data.userInfo,
    });
  };
};

// logout action
export const userLogout = () => {
  //   console.log("sent data >> ", data);
  return (dispatch) => {
    dispatch({
      type: "USER_LOGOUT",
      payload: false,
      token: null,
      info: null,
    });
  };
};
