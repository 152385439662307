import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import Swal from "sweetalert2";
import axios from "axios";
import CKEditor from "ckeditor4-react-advanced";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import copy from "copy-to-clipboard";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Switch from "react-switch";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import Loader from "../../common/Loader";
import moment from "moment";
import { userLogout } from "../../redux/actions/UserActions";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";

// for date
function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

const EditBlog = (props) => {
  const { id } = props.match.params;
  const [content, setContent] = useState("");
  const [categories, setCategories] = useState([]);
  const [contentError, setContentError] = useState("");
  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageError, setIsImageError] = useState("");
  const { register, handleSubmit, watch, errors, setValue } = useForm();
  const [additionalImages, setAdditionalImages] = useState([]);
  const [blogTags, setBlogTags] = useState([]);
  const [customSlug, setCustomSlug] = useState(false);
  const [publisheDate, setPublisheDate] = useState(new Date());
  const [isPublished, setIsPublished] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [sliderItem, setSliderItem] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [featuredImages, setFeaturedImages] = useState(null);
  const basurl = process.env.REACT_APP_URL;
  const imgUrls = process.env.REACT_APP_BASE_URL;
  const userToken = props.user.userToken;
  const role = props.user?.user?.role;

  // console.log("user token >> ", role);

  // AWS configurations
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new/blogs" },
    region: "ap-south-1",
  });

  // upload image to aws bucket
  const uploadVideoHandler = (files) => {
    console.log("video file >> ", files[0]);
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setUploadProgress(percent);
        // console.log(
        //   "progress video upload :: ",
        //   Math.round((evt.loaded / evt.total) * 100)
        // );
      })
      .on("success", (evt) => {
        // console.log("mujhe khusi hogi >>>", evt.request.params);
        setFeaturedImages(
          `https://lms-videos-bucket-prepzone-new.s3.ap-south-1.amazonaws.com/${evt.request.params.Key}`
        );
        setUploadProgress(0);
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // console.log("error >> ", err);
        } else {
          // console.log("aljkskdasd", data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  // get blogs categories list
  const getCategories = () => {
    axios.get(`${basurl}/blogs/categories/all`).then((res) => {
      const result = res.data;
      if (result.success) {
        setCategories(result.data);
      }
    });
  };

  // get Blog details
  const getBlogDetails = () => {
    axios
      .get(`${basurl}/blogs/view/${id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setValue("title", result.data.title);
          setCustomSlug(true);
          setValue("slug", result.data.slug);
          setValue("meta_title", result.data.meta_title);
          setValue("meta_description", result.data.meta_description);
          setValue("category", result.data.category._id);
          setValue("author", result.data.author._id);
          setContent(result.data.content);
          setBlogTags(result.data.tags);
          setFeaturedImages(result.data.image);
          setIsPublished(result.data.isPublished);
          setPublisheDate(moment(result.data.publish_date).toDate());
          setSliderItem(result.data.sliderItem);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  const slugify = (text) => {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    const to = "aaaaaeeeeeiiiiooooouuuunc------";

    const newText = text
      .split("")
      .map((letter, i) =>
        letter.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
      );

    return newText
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/&/g, "-y-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-"); // Replace multiple - with single -
  };
  const onSubmit = (data) => {
    if (content === "") {
      setContentError("Blog Content is required.");
    } else {
      setIsLoading(true);
      axios
        .put(
          `${basurl}/blogs/edit/${id}`,
          {
            title: data.title,
            slug: customSlug ? slugify(data.slug) : slugify(data.title),
            meta_title: data.meta_title,
            meta_description: data.meta_description,
            category: data.category,
            author: data.author,
            content: content,
            tags: blogTags,
            image: featuredImages,
            publish_date: publisheDate,
            isPublished: isPublished,
            sliderItem: sliderItem,
          },
          {
            headers: {
              "auth-token": userToken,
            },
          }
        )
        .then((res) => {
          setIsLoading(false);
          const result = res.data;
          if (result.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your blog created successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            props.history.push("/blogs");
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: result.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }

          console.log("added blog result >> ", result);
        }).catch = (err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      };
    }
  };

  // Upload image
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    const params = {
      ACL: "public-read",
      Key: file.name,
      ContentType: file.type,
      Body: file,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setUploadProgress(percent);
        // console.log(
        //   "progress video upload :: ",
        //   Math.round((evt.loaded / evt.total) * 100)
        // );
      })
      .on("success", (evt) => {
        // console.log("mujhe khusi hogi >>>", evt.request.params);
        setAdditionalImages([
          ...additionalImages,
          `https://lms-videos-bucket-prepzone-new.s3.ap-south-1.amazonaws.com/${evt.request.params.Key}`,
        ]);
        setUploadProgress(0);
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // console.log("error >> ", err);
        } else {
          // console.log("aljkskdasd", data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };
  // handle handleCopyClipboard
  const handleCopyClipboard = (e, item) => {
    var el = e.target;
    console.log("ee >> ", el);
    el.innerHTML = "Copied";
    el.style.color = "#09ff66";
    copy(item);
    setTimeout(() => {
      el.innerHTML = "Copy";
      el.style.color = "#ffffff";
    }, 2000);
  };
  const FORMAT = "dd/MM/yyyy";

  useEffect(() => {
    getBlogDetails();
    getCategories();
  }, []);

  // Delete Blog
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Blog ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${basurl}/blogs/delete/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const deleteStatus = res.data;
            if (deleteStatus.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: deleteStatus.message,
                showConfirmButton: false,
                timer: 2500,
              });
              props.history.push("/blogs");
            }
            // console.log("delete status > ", deleteStatus);
          })
          .catch((err) => {
            if (err.response.data === "Invalid token") {
              props.logoutUser();
              localStorage.removeItem("lmsUserInfo");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your token has been expired!, Please login again",
                showConfirmButton: false,
                timer: 1500,
              });
              props.history.push("/");
            }
          });
      }
    });
  };
  // handle change date
  const handleDayClick = (day) => {
    setPublisheDate(day);
  };
  return (
    <MainContainer>
      <div className={"card"}>
        {isLoading && <Loader />}
        <div className={"card-header"}>
          <div className="row">
            <div className="col-sm-6">
              <h4>Create Blog</h4>
            </div>
          </div>
        </div>
        <div className={"card-body"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={"row"}>
              <div className="col-lg-9">
                <div className="form-group">
                  <label>Blog Title</label>
                  <input
                    type="text"
                    className={"form-control"}
                    name={"title"}
                    ref={register({ required: true })}
                  />
                  {errors.title && (
                    <span className="isError">Blog Title is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Blog Category</label>
                  <select
                    name={"category"}
                    className={"form-control"}
                    ref={register({
                      required: "Please Select Blog Category",
                    })}
                  >
                    <option value="">Please Choose Blog Category</option>
                    {categories.map((item, index) => (
                      <option
                        value={item._id}
                        key={index}
                        style={{ textTransform: "uppercase" }}
                      >
                        {item.category}
                      </option>
                    ))}
                  </select>
                  {errors.category && (
                    <span className="isError">{errors.category.message}</span>
                  )}
                </div>
                <div className="form-group">
                  <div className={"d-flex"}>
                    <label
                      style={{
                        marginRight: "10px",
                        position: "relative",
                        top: "3px",
                      }}
                    >
                      Custom Slug (url)
                    </label>
                    <Switch
                      onChange={() => setCustomSlug(!customSlug)}
                      checked={customSlug}
                    />
                  </div>
                  {customSlug && (
                    <input
                      type="text"
                      placeholder={"Custom slug"}
                      className="form-control"
                      ref={register({ required: true })}
                      name={"slug"}
                      style={{ marginTop: "10px" }}
                    />
                  )}
                  {errors.slug && (
                    <span className="isError">Slug is required</span>
                  )}
                </div>
                {content !== "" && (
                  <div className="form-group">
                    <label>Blog Content</label>
                    <CKEditor
                      data={content}
                      onChange={(evt) => setContent(evt.editor.getData())}
                      config={{ allowedContent: true, height: "25em" }}
                    />
                    {contentError !== "" ? (
                      <span className="isError">{contentError}</span>
                    ) : null}
                  </div>
                )}
                {additionalImages.length > 0 && (
                  <div className={"add_images_wrapper"}>
                    {additionalImages.map((item, index) => (
                      <div className={"img_row"} key={index}>
                        <span>{item}</span>
                        <span
                          className={"copy"}
                          onClick={(e) => handleCopyClipboard(e, item)}
                        >
                          <i className={"la la-copy"}></i>
                          <span className="isTooltip">Copy</span>
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                <div className="form-group">
                  <button className={"btn btn-primary image_upload_button"}>
                    <i className={"la la-plus"}></i> Add Additional Images for
                    blog content
                    <input
                      type="file"
                      className={"btn_upload_file"}
                      onChange={(e) => handleImageUpload(e)}
                      accept="image/*"
                    />
                  </button>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label>Blog Image</label>
                  {featuredImages ? (
                    <div className="course_image_uploader">
                      <span
                        className="close_icon"
                        onClick={() => setFeaturedImages(null)}
                      >
                        <i className="ri-close-line"></i>
                      </span>
                      <img
                        src={featuredImages}
                        alt={"feature image"}
                        className="img-fluid"
                      />
                    </div>
                  ) : (
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        uploadVideoHandler(acceptedFiles)
                      }
                      accept={"image/*"}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="course_image_uploader">
                          {uploadProgress > 1 && (
                            <div className="upload_progress">
                              <div
                                className="inner"
                                style={{
                                  width: `${uploadProgress}%`,
                                  height: "100%",
                                  backgroundColor: "#4CAF50",
                                  transition: "all 0.4s ease-in-out",
                                  borderRadius: "20px",
                                }}
                              ></div>
                            </div>
                          )}

                          <div {...getRootProps()} className={"dropZone"}>
                            <input {...getInputProps()} />
                            {/* <i className="ri-image-line uploader_icon"></i> */}
                            <span className="close_icon">
                              <i className="ri-pencil-fill"></i>
                            </span>
                            <img
                              src={require("../../assets/coursepic.png")}
                              alt="Mock demo image"
                              className="img-fluid"
                              height={"100%"}
                            />
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  {isImageError !== "" ? (
                    <span className="isError">{isImageError}</span>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>Blog Tags</label>
                  <TagsInput
                    value={blogTags}
                    onChange={(string) => setBlogTags(string)}
                    inputProps={{ placeholder: "#tags" }}
                  />
                </div>
                <div className="form-group">
                  <label>Author</label>
                  <select
                    className="form-control"
                    name="author"
                    ref={register({ required: true })}
                  >
                    <option value="">Please Select Author</option>
                    <option value={props.user.user.id}>
                      {props.user.user.name}
                    </option>
                  </select>
                  {errors.author && (
                    <span className="isError">Author is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Publish Date</label>
                  <DayPickerInput
                    formatDate={formatDate}
                    format={FORMAT}
                    parseDate={parseDate}
                    placeholder={"Publish Date"}
                    onDayChange={handleDayClick}
                    value={publisheDate}
                    dayPickerProps={{ disabledDays: { before: new Date() } }}
                  />
                </div>
                <div className="form-group">
                  <label>Meta Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={"Meta Title"}
                    name={"meta_title"}
                    ref={register()}
                  />
                </div>
                <div className="form-group">
                  <label>Meta Description</label>
                  <textarea
                    className="form-control"
                    placeholder={"Meta Description"}
                    name={"meta_description"}
                    ref={register()}
                  />
                </div>
                <div className="form-group">
                  <label className={"d-block"}>View this blog in slider</label>
                  <Switch
                    onChange={() => setSliderItem(!sliderItem)}
                    checked={sliderItem}
                  />
                </div>
                <div className="form-group">
                  <label className={"d-block"}>Publish Now</label>
                  <Switch
                    onChange={() => setIsPublished(!isPublished)}
                    checked={isPublished}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className={"row"}>
              <div className="col-md-6">
                <button className={"btn btn-primary"} type="submit">
                  Save Blog
                </button>
              </div>
              <div className="col-md-6 text-right">
              {role === "Admin" ? (<button
                  className={"btn btn-danger"}
                  type="button"
                  onClick={handleDelete}
                >
                  <i
                    className={"ri-delete-bin-line"}
                    style={{ fontSize: "16px" }}
                  />{" "}
                  Delete Blog
                </button>): (<button
                  className={"btn btn-danger"}
                  type="button"
                  style={{ cursor: "not-allowed", opacity: "0.5" }}
                  disabled
                >
                  <i
                    className={"ri-delete-bin-line"}
                    style={{ fontSize: "16px" }}
                  />{" "}
                  Delete Blog
                </button>)}
                
              </div>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditBlog);
