import React, { useState } from "react";
import { Switch } from "antd";
import { Link } from "react-router-dom";

const QuestionCard = (props) => {
  const [isHindi, setIsHindi] = useState(false);
  const { data, index } = props;

  const toggle = () => {
    setIsHindi(!isHindi);
  };
  // console.log("card data >>", data);
  return (
    <div className="question_card">
      <div className="card_header">
        {data.title_hindi && (
          <Switch
            checkedChildren="Hindi"
            unCheckedChildren="English"
            checked={isHindi}
            onChange={toggle}
          />
        )}
        <Link
          to={`/mock/edit-question/${data._id}/${props.quizId}`}
          className="qc_icon"
          style={{ marginLeft: 10 }}
        >
          <i className="ri-pencil-line"></i>
        </Link>
        <span
          className="qc_icon text-danger"
          style={{ cursor: "pointer" }}
          onClick={props.handleDeleteQuestion}
        >
          <i className="ri-delete-bin-line"></i>
        </span>
      </div>
      <div className="card_question">
        <strong>Question {index + 1}: </strong>{" "}
        <p>{isHindi ? data.title_hindi : data.title_english}</p>
      </div>
      <div className="card_options">
        {data.options.map((que, ind) => {
          if (!que.text_english) {
            return;
          } else {
            return (
              <div
                className={`que_row ${que.isCorrect ? "active" : ""}`}
                key={ind}
              >
                {isHindi ? que.text_hindi : que.text_english}
                {que.isCorrect && (
                  <span className="currect_icon">
                    <i className="ri-check-line"></i>
                  </span>
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
export default QuestionCard;
