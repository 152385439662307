import React, { useState, useEffect } from "react";
import MainContainer from "../../../../common/MainContainer";
import { Link } from "react-router-dom";
import axios from "axios";
import { Table } from "antd";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { userLogout } from "../../../../redux/actions/UserActions";
import moment from "moment";

function Metadata(props) {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;

  const getMetaDataList = () => {
    axios
      .get(`${basurl}/frontend/metadata`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const metadata = res.data;
        if (metadata.success) {
          setList(metadata.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  // Delete banner slider
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this page's data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${basurl}/frontend/metadata/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const deleteStatus = res.data;
            if (deleteStatus.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: deleteStatus.message,
                showConfirmButton: false,
                timer: 2500,
              });
              getMetaDataList();
            }
            // console.log("delete status > ", deleteStatus);
          })
          .catch((err) => {
            if (err.response.data === "Invalid token") {
              props.logoutUser();
              localStorage.removeItem("lmsUserInfo");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your token has been expired!, Please login again",
                showConfirmButton: false,
                timer: 1500,
              });
              props.history.push("/");
            }
          });
      }
    });
  };

  useEffect(() => {
    getMetaDataList();
  }, []);

  // Columns
  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (value, item, index) => (
        <div className={"table_image"}>
          {item.ogImage && (
            <img
              src={item.ogImage}
              alt={item.slug}
              style={{ width: "80px" }}
            />
          )}
        </div>
      ),
    },
    {
      title: "Meta Title",
      render: (value, item, index) => <span>{item.metaTitle}</span>,
    },
    {
      title: "Meta Description",
      render: (value, item, index) => <span>{item.metaDescription}</span>,
    },
    {
      title: "Slug",
      render: (value, item, index) => <span>{item.slug}</span>,
    },
    {
      title: "Last Updated",
      render: (value, item, index) => (
        <span>{moment(item.updatedAt).format("MMMM Do YYYY")}</span>
      ),
    },
    {
      title: "Action",
      render: (value, item, index) => (
        <div data-tag="allowRowEvents">
          <Link
            className={"btn btn-success "}
            to={`/frontend/seo/metadata/edit/${item._id}`}
            style={{ marginRight: "10px", minWidth: 100 }}
          >
            <i className={"ri-pencil-line"}></i>
          </Link>
          <button
            className={"btn btn-danger "}
            onClick={() => handleDelete(item._id)}
            style={{ minWidth: 100 }}
          >
            <i className={"ri-delete-bin-line"}></i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <div className={"row"}>
            <div className="col-md-6">
              <h4 className={"header-title"}>SEO: Metadata for website pages</h4>
            </div>
            <div className="col-md-6 text-right">
              <Link className={"btn btn-primary"} to={"/frontend/seo/metadata/create"}>
                Add New Page Metadata
              </Link>
            </div>
          </div>
        </div>
        <div className={"card-body"}>
          <Table
            columns={columns}
            dataSource={list}
            bordered={true}
            loading={isLoading}
          />
        </div>
      </div>
    </MainContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Metadata);
