import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import moment from "moment";
import { Table, DatePicker } from "antd";
import "antd/dist/antd.css";
import { Link, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { ExportCSV } from "../../components/ExportCSV";
import Loader from "../../common/Loader";
import { userLogout } from "../../redux/actions/UserActions";
import Swal from "sweetalert2";

const StudentList = (props) => {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";
  const monthFormat = "YYYY/MM";
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const [students, setStudents] = useState([]);
  const [list, setList] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [exportData, setExportData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const role = useSelector((state) => state.user?.user?.role);
  // get students list
  const getStudents = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/users/students`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const list = res.data;
        if (list.success) {
          setStudents(list.data);
          setList(list.data);
          const newArr = list.data.map((item, index) => ({
            "S. No": index + 1,
            "Student Name": item.name,
            "Email Address": item.email,
            "Mobile No.": item.mobile,
            "Enrolled Courses": item.courses.length,
            Status: item.isActive ? "Confirm" : "Pending",
            "Enrolled Date": moment(item.date).format("DD-MM-YYYY"),
            "Last Loggedin":
              item.lastLoggedin !== null
                ? moment(item.lastLoggedin).format("DD-MM-YYYY")
                : "NA",
            "Total Login": item.loginCount,
          }));
          setExportData(newArr);
        } else {
          setError("There are no students");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  useEffect(() => {
    getStudents();
  }, []);

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  //handleDelete
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        setIsLoading(true);
        axios
          .delete(`${basurl}/users/delete-user/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const result = res.data;
            if (result.success) {
              getStudents();
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Student deleted successfully",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Something went wrong!",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            if (err.response.data === "Invalid token") {
              props.logoutUser();
              localStorage.removeItem("lmsUserInfo");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your token has been expired!, Please login again",
                showConfirmButton: false,
                timer: 1500,
              });
              props.history.push("/");
            }
            console.log("error>> ", err);
          });
      }
    });
  };

  // Columns
  const columns = [
    {
      title: "S. No.",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Student Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, index) => (
        <Link to={`/student/${record._id}`}>{record.name}</Link>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text, record, index) => <span>{record.email}</span>,
    },
    {
      title: "Mobile No.",
      render: (text, record, index) => (
        <span>{record.mobile ? record.mobile : "NA"}</span>
      ),
    },
    {
      title: "Enrolled Course",
      render: (text, record, index) => <span>{record.courses.length}</span>,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      sorter: (a, b) => a.isActive - b.isActive,
      render: (text, record, index) => (
        <span>{record.isActive ? "Confirm" : "Pending"}</span>
      ),
    },
    {
      title: "Enrolled Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      render: (text, record, index) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {moment(record.date).format("DD-MM-YYYY hh:mm A")}
        </span>
      ),
    },
    {
      title: "Action",
      render: (text, record, index) => (
        <span>
          <button
            className="btn btn-danger"
            onClick={() => handleDelete(record._id)}
          >
            <i className="ri-delete-bin-line"></i>
          </button>
        </span>
      ),
    },
    // {
    //   title: "Last Login",
    //   dataIndex: "lastLoggedin",
    //   sorter: (a, b) => a.lastLoggedin.localeCompare(b.lastLoggedin),
    //   render: (text, record, index) => (
    //     <span>
    //       {record.lastLoggedin !== null
    //         ? moment(record.lastLoggedin).format("DD-MM-YYYY")
    //         : "NA"}
    //     </span>
    //   ),
    // },
    // {
    //   title: "Total Login",
    //   dataIndex: "loginCount",
    //   sorter: (a, b) => a.loginCount.localeCompare(b.loginCount),
    //   render: (text, record, index) => <span>{record.loginCount}</span>,
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    // console.log("params", sorter);
    setPagination({
      pageSize: pagination.pageSize,
    });
  }
  // console.log("pagination, ", pagination);

  // for search filter
  const filteredData = students.filter((val) => {
    if (searchInput === "") {
      return val;
    } else if (
      val.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      val.email.toLowerCase().includes(searchInput.toLowerCase())
    ) {
      return val;
    }
  });

  // Disabled Date
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  // handleRange
  const handleRange = (date) => {
    if (date !== null) {
      let date1 = date[0].toDate();
      let date2 = date[1].toDate();
      const newArr = students.filter((item) => {
        return (
          moment(item.date).toDate() <= date2 &&
          moment(item.date).toDate() >= date1
        );
      });
      setStudents(newArr);
    } else {
      setStudents(list);
    }

    // console.log("get date from range >>>", newArr)
  };

  if (role !== "Admin") {
    return <Redirect to="/" />;
  }
  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-sm-6">
              <div className="d-flex align-items-center">
                <h4 className="mt-2" style={{ marginRight: 30 }}>
                  Students
                </h4>
                <Link to="/students/add" className="btn btn-primary">
                  Add Student
                </Link>
              </div>
            </div>
          </div>
          <div className="card mt-4">
            <div className="card-body">
              <div className={"filters mb-2"}>
                <div className={"row"}>
                  <div className={"col-lg-4"}>
                    <div className={"search_filter"}>
                      <input
                        type="text"
                        className={"form-control"}
                        placeholder={"Search by name..."}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                      {searchInput !== "" && (
                        <span
                          className={"clear_search_text"}
                          onClick={() => setSearchInput("")}
                        >
                          <i className={"la la-times"}></i>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className={"col-lg-4"}>
                    <RangePicker
                      format={dateFormat}
                      className={"form-control custom_range_picker"}
                      onChange={handleRange}
                    />
                  </div>
                  <div className={"col-lg-4"}>
                    <div className={"row"}>
                      <div className={"col-sm-6"}>
                        <select
                          className={"form-control"}
                          value={pagination.pageSize}
                          onChange={(e) =>
                            setPagination({
                              pageSize: e.target.value,
                            })
                          }
                        >
                          <option value={10}>10 Rows</option>
                          <option value={20}>20 Rows</option>
                          <option value={50}>50 Rows</option>
                          <option value={100}>100 Rows</option>
                        </select>
                      </div>
                      <div className={"col-sm-6"}>
                        <ExportCSV csvData={exportData} fileName={"Students"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Table
                columns={columns}
                dataSource={filteredData}
                bordered={true}
                rowKey={(item) => item._id}
                loading={isLoading}
                pagination={pagination}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentList);
