import React, { useState, useEffect } from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  useHistory,
  Redirect,
  Link,
} from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

import BillPage from "./pages/Bill/index";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
import { userLoggedIn } from "./redux/actions/UserActions";
import { updateBill, updateBillStatus } from "./store/actions/DashboardActions";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./theme.scss";
import moment from "moment";
const App = (props) => {
  const [updates, setUpdates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [billStatus, setBillStatus] = useState("");
  const [billInfo, setBillInfo] = useState();
  const [isShow, setIsShow] = useState(false);

  const history = useHistory();
  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  const Layout = getLayout();
  // console.log("login status", props.user.isLoggedin);
  const basurl = process.env.REACT_APP_URL;
  const isLoggedIn = props.user.isLoggedin;

  const getUpdates = () => {
    axios
      .get(`${basurl}/updates/get-update-list-admin`)
      .then((res) => {
        const result = res.data;
        if (result.success) {
          const isExists = result.data[0].users.some(
            (item) => item === props.user?.user?.id
          );
          if (isExists) {
            setUpdates([]);
          } else {
            setUpdates(result.data);
          }
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
        }
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      getUpdates();
      getBillInfo();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    // getBillInfo();
    billCalculation();
  }, [props.bill]);

  useEffect(() => {
    if (billStatus == "due") {
      setIsShow(true);
    }
  }, [billStatus]);

  useEffect(() => {
    const getUserData = localStorage.getItem("lmsUserInfo");
    // console.log("uses  ", JSON.parse(getUserData));
    if (getUserData) {
      props.loggedinUser(JSON.parse(getUserData));
    }
  }, []);

  // get user details
  const getBillInfo = () => {
    // setIsLoading(true);
    axios
      .get(`${basurl}/bill/getinfo`, {
        headers: {
          "auth-token": props.user.userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          props.sendBill(result.data);
          setBillInfo(result.data);
        }
        // setIsLoading(false);
      });
  };

  //handleUpdates
  const handleUpdates = (updateId) => {
    setIsLoading(true);
    axios
      .put(
        `${basurl}/updates/update-isRead-status/${updateId}`,
        {
          user: props.user?.user?.id,
        },
        {
          headers: {
            "auth-token": props.user.userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setIsSuccess(true);
          setIsLoading(false);
          setTimeout(() => {
            setUpdates(result.data);
            window.location.reload();
          }, 2000);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
        }
        window.location.reload();
      });
  };

  const billCalculation = () => {
    if (props.bill) {
      const expiryDate = new Date(props.bill?.endDate);
      const lastDayOfMonth = new Date(
        expiryDate.getFullYear(),
        expiryDate.getMonth() + 1,
        0
      ).getDate();

      // current date
      const currentDate = new Date();

      // Calculate the due date, which is 7 days before the last day of the month.
      // const dueDate = new Date(
      //   expiryDate.getFullYear(),
      //   expiryDate.getMonth(),
      //   lastDayOfMonth - 7
      // );
      const dueDate = new Date(
        expiryDate.getFullYear(),
        expiryDate.getMonth(),
        expiryDate.getDate() - 6
      );
      // const dueDate = expiryDate.getDate() - 7;
      // console.log("expiry date:", dueDate);

      // Check if the payment date is already past the expiry date.
      if (currentDate > expiryDate) {
        setBillStatus("over");
        props.updateBillStatus("over");
        toast.error("Your subscription has lapsed.");
      }

      // Check if the payment date is already past the due date.
      if (currentDate >= dueDate && currentDate <= expiryDate) {
        setBillStatus("due");
        props.updateBillStatus("due");
        // toast.success(
        //   `Your subscription will expire on ${moment(expiryDate).format(
        //     "MMMM Do YYYY"
        //   )}`,
        //   {
        //     duration: 10000,
        //     icon: "⚠️",
        //   }
        // );
      }

      if (currentDate < dueDate) {
        props.updateBillStatus("paid");
        setBillStatus("paid");
      }
    }
  };

  return (
    <React.Fragment>
      <Toaster
        toastOptions={{
          style: {
            background: "#FFEDED",
            color: "#F1663F",
            border: "1px solid #F1663F",
            padding: "10px 20px",
          },
          success: {
            iconTheme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      {updates.length > 0 && (
        <div className={isSuccess ? "custom_alert success" : "custom_alert"}>
          {isLoading ? (
            <span className="d-flex flex-row">
              <div class="spinner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
              </div>{" "}
              Loading...
            </span>
          ) : (
            <>
              {isSuccess ? (
                <span>
                  Congratulations! updates are successfully rolled out.
                </span>
              ) : (
                <>
                  <span
                    className="custom_alert_content"
                    onClick={() => handleUpdates(updates[0]._id)}
                  >
                    {updates[0].description}{" "}
                  </span>
                  <span
                    className="custom_alert_close"
                    onClick={() => setUpdates([])}
                  >
                    <i className="ri-close-line"></i>
                  </span>
                </>
              )}
            </>
          )}
        </div>
      )}

      {isShow ? (
        <div
          style={{
            background: "#FFEDED",
            color: "#F1663F",
            border: "1px solid #F1663F",
            padding: "10px 20px",
            width: "calc(100% - 290px)",
            left: "260px",
            borderRadius: 4,
            position: "relative",
            marginTop: "3px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>
            <i
              className="ri-error-warning-fill"
              style={{ fontSize: 20, position: "relative", top: 3 }}
            ></i>{" "}
            Your subscription will expire on{" "}
            <b>{moment(billInfo?.endDate).format("MMMM Do YYYY")} </b>.{" "}
            <a
              href="/admin/bill"
              style={{
                borderRadius: "4px",
                display: "inline-block",
                padding: "3px 10px",
              }}
              className="btn-danger"
            >
              Renew
            </a>
          </span>
          <span
            style={{ cursor: "pointer", fontWeight: "bold", fontSize: 20 }}
            onClick={() => {
              setIsShow(false);
            }}
          >
            <i className="ri-close-line"></i>
          </span>
        </div>
      ) : null}

      <Router>
        <Switch>
          {isLoggedIn ? (
            <>
              {authProtectedRoutes.map((route, idx) => {
                return (
                  <AppRoute
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    exact={route.exact}
                    isAuthProtected={true}
                    billStatus={billStatus}
                    isLoggedIn={isLoggedIn}
                  />
                );
              })}
            </>
          ) : (
            <>
              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  exact={route.exact}
                  isAuthProtected={false}
                  isLoggedIn={isLoggedIn}
                />
              ))}
            </>
          )}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    user: state.user,
    bill: state.dashboard.bill,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loggedinUser: (values) => {
      dispatch(userLoggedIn(values));
    },
    sendBill: (data) => {
      dispatch(updateBill(data));
    },
    updateBillStatus: (val) => {
      dispatch(updateBillStatus(val));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
