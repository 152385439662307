import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import Swal from "sweetalert2";
import Switch from "react-switch";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import Loader from "../../common/Loader";
import { userLogout } from "../../redux/actions/UserActions";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddTestimonial = (props) => {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [featuredImages, setFeaturedImages] = useState(null);
  const [isFeatured, setIsFeatured] = useState(false);
  const [content, setContent] = useState("");
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  const { register, handleSubmit, errors } = useForm();

  // AWS configurations
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: "lms-videos-bucket-prepzone-new/testimonials" },
    region: "ap-south-1",
  });

  // upload image to aws bucket
  const uploadVideoHandler = (files) => {
    const params = {
      ACL: "public-read",
      Key: files[0].name,
      ContentType: files[0].type,
      Body: files[0],
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // that's how you can keep track of your upload progress
        var percent = Math.round((evt.loaded / evt.total) * 100);
        setUploadProgress(percent);
      })
      .on("success", (evt) => {
        setFeaturedImages(
          `https://lms-videos-bucket-prepzone-new.s3.ap-south-1.amazonaws.com/${evt.request.params.Key}`
        );
        setUploadProgress(0);
      })
      .send((err, data) => {
        if (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Uploaded Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post(
        `${basurl}/testimonials/create`,
        {
          title: data.name,
          content: content,
          image: featuredImages,
          institute: data.instituteName,
          featured: isFeatured,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your testimonial added successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
          props.history.push("/testimonials");
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: err.Message,
          showConfirmButton: false,
          timer: 1500,
        });
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          history.push("/");
        }
        setIsLoading(false);
      });
  };

  const onChange = (checked) => {
    setIsFeatured(checked);
  };
  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);
  // console.log("isFeatured>>>", isFeatured);
  return (
    <MainContainer>
      {isLoading && <Loader />}
      <div className="card testimonials_iimg">
        <div className="card-header">
          <h4 className={"header-title"}>Add Testimonial</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-9">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    ref={register({ required: true })}
                    name="name"
                  />
                  {errors.name && (
                    <span className="isError">Name is required</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Institute Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Institute Name"
                    ref={register({ required: true })}
                    name="instituteName"
                  />
                  {errors.instituteName && (
                    <span className="isError">Institute Name required</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={content}
                    style={{ minHeight: "130px" }}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setContent(data);
                      // console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-2">
                  <label>Image</label>
                  {featuredImages ? (
                    <div className="course_image_uploader">
                      <span
                        className="close_icon"
                        onClick={() => setFeaturedImages(null)}
                      >
                        <i className="ri-close-line"></i>
                      </span>
                      <img
                        src={featuredImages}
                        alt={"feature image"}
                        className="img-fluid"
                      />
                    </div>
                  ) : (
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        uploadVideoHandler(acceptedFiles)
                      }
                      accept={"image/*"}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="course_image_uploader">
                          {uploadProgress > 1 && (
                            <div className="upload_progress">
                              <div
                                className="inner"
                                style={{
                                  width: `${uploadProgress}%`,
                                  height: "100%",
                                  backgroundColor: "#4CAF50",
                                  transition: "all 0.4s ease-in-out",
                                  borderRadius: "20px",
                                }}
                              ></div>
                            </div>
                          )}

                          <div {...getRootProps()} className={"dropZone"}>
                            <input {...getInputProps()} />
                            {/* <i className="ri-image-line uploader_icon"></i> */}
                            <span className="close_icon">
                              <i className="ri-pencil-fill"></i>
                            </span>
                            <img
                              src={require("../../assets/coursepic.png")}
                              alt="user image"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </div>
                <div className="d-block mt-4">
                  <label className="d-block">Featured this testimonial</label>
                  <Switch
                    checked={isFeatured}
                    onChange={() => setIsFeatured(!isFeatured)}
                  />
                </div>
              </div>
            </div>

            <div className={"d-flex"}>
              {isLoading ? (
                <button
                  type="button"
                  className={"btn btn-success"}
                  style={{ marginRight: 15 }}
                >
                  Loading...
                </button>
              ) : (
                <button
                  type="submit"
                  className={"btn btn-success"}
                  style={{ marginRight: 15 }}
                >
                  Add Testimonial
                </button>
              )}
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => history.goBack()}
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTestimonial);
