import React, { useState, useEffect } from "react";
import MainContainer from "../../../common/MainContainer";
import Swal from "sweetalert2";
import axios from "axios";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { userLogout } from "../../../redux/actions/UserActions";

const ContactPage = (props) => {
  const [options, setOptions] = useState([]);
  const [allDetails, setAllDetails] = useState(null);
  const { register, handleSubmit, watch, errors, setValue } = useForm();
  const basurl = process.env.REACT_APP_URL;
  const basurlHome = process.env.REACT_APP_BASE_URL;
  const userToken = props.user.userToken;

  const re = /^[0-9\b]+$/;

  // handle submit
  const onSubmit = (data) => {
    console.log("all data>>", data);
    axios
      .post(
        `${basurl}/frontend/contact/add`,
        {
          email: data.email,
          whatsapp: data.whatsapp,
          meta_description: data.meta_description,
          meta_title: data.meta_title,
          mobile: data.mobile,
          emmbedMap: data.map,
          address: data.address,
          conatct_optopns: options,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        console.log("results >> ", result);
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  // get contact page on start
  const getContact = () => {
    axios.get(`${basurl}/frontend/contact`).then((res) => {
      const result = res.data;
      if (result.success) {
        if (result.data) {
          setAllDetails(result.data);
          setOptions(result.data.conatct_optopns);
          setValue("email", result.data.email);
          setValue("whatsapp", result.data.whatsapp);
          setValue("mobile", result.data.mobile);
          setValue("address", result.data.address);
          setValue("map", result.data.emmbedMap);
          setValue("meta_title", result.data.meta_title);
          setValue("meta_description", result.data.meta_description);
        }
      }
    });
  };

  // Update Terms Info
  const UpdateInfoHandler = (data) => {
    console.log("all data >> ", data);
    axios
      .put(
        `${basurl}/frontend/contact/edit/${allDetails._id}`,
        {
          email: data.email,
          whatsapp: data.whatsapp,
          meta_description: data.meta_description,
          meta_title: data.meta_title,
          mobile: data.mobile,
          emmbedMap: data.map,
          address: data.address,
          conatct_optopns: options,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Contact Page Updated successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          getContact();
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  useEffect(() => {
    getContact();
  }, []);

  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <h4 className={"header-title"}>Frontend Contact Us Page</h4>
        </div>
        <div className="card-body">
          <form
            onSubmit={handleSubmit(
              allDetails !== null ? UpdateInfoHandler : onSubmit
            )}
          >
            <h4 className={"section_title"}>Contact Form Options</h4>
            <div className="form-group">
              <label>
                Contact form Options (for new line add please press "Enter")
              </label>
              <TagsInput
                value={options}
                onChange={(string) => setOptions(string)}
                inputProps={{ placeholder: "Contact Form options" }}
              />
            </div>
            <h4 className={"section_title"}>Contact Page Info</h4>
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                className={"form-control"}
                placeholder={"Enter Email Address"}
                name="email"
                ref={register({
                  required: "Email is Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
              />
              {errors.email && (
                <span className="isError">{errors.email.message}</span>
              )}
            </div>
            <div className="form-group">
              <label>Whatsapp No.</label>
              <input
                name="whatsapp"
                type="number"
                pattern="^-?[0-9]\d*\.?\d*$"
                className={"form-control"}
                placeholder={"Pleae Enter Whatsapp No."}
                ref={register({ required: true, minLength: 10, maxLength: 10 })}
                onChange={(e) => re.test(e.target.value)}
              />
              {errors.whatsapp?.type === "required" && (
                <span className="isError">Whatsapp No. required</span>
              )}
              {errors.whatsapp?.type === "maxLength" && (
                <span className="isError">
                  Whatsapp No. can't be greater then 10 digits.
                </span>
              )}
              {errors.whatsapp?.type === "minLength" && (
                <span className="isError">
                  Whatsapp No. can't be less then 10 digits.
                </span>
              )}
            </div>
            <div className="form-group">
              <label>Mobile No.</label>
              <input
                name="mobile"
                type="number"
                pattern="^-?[0-9]\d*\.?\d*$"
                className={"form-control"}
                placeholder={"Pleae Enter Mobile No."}
                ref={register({ required: true, minLength: 10, maxLength: 10 })}
                onChange={(e) => re.test(e.target.value)}
              />
              {errors.mobile?.type === "required" && (
                <span className="isError">Mobile No. required</span>
              )}
              {errors.mobile?.type === "maxLength" && (
                <span className="isError">
                  Mobile No. can't be greater then 10 digits.
                </span>
              )}
              {errors.mobile?.type === "minLength" && (
                <span className="isError">
                  Mobile No. can't be less then 10 digits.
                </span>
              )}
            </div>
            <div className="form-group">
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                placeholder={"Enter Address"}
                name="address"
                ref={register({ required: true })}
              />
              {errors.address && (
                <span className="isError">Address is required.</span>
              )}
            </div>
            <div className="form-group">
              <label>Map Embbed Code</label>
              <textarea
                className="form-control"
                name="map"
                placeholder={"Map Embbed Code"}
                ref={register({ required: true })}
              />
              {errors.map && <span className="isError">Map Embbed Code</span>}
            </div>
            <h4 className={"section_title"}>Seo Section</h4>
            <div className="form-group">
              <label>Meta Title</label>
              <input
                type="text"
                placeholder={"Meta Title"}
                className="form-control"
                ref={register}
                name={"meta_title"}
              />
            </div>
            <div className="form-group">
              <label>Meta Description</label>
              <input
                type="text"
                placeholder={"Meta Description"}
                className="form-control"
                ref={register}
                name={"meta_description"}
              />
            </div>
            <button type={"submit"} className="btn btn-success">
              Save Contact Page Info
            </button>
          </form>
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
