import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
// import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ForgetPasswordOtp from "../pages/Authentication/ForgetPasswordOtp";
// import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// ===================== New Pages ===============================//
// import ForgotPassword from "../pages/Auth/ForgotPassword";
// import ForgotPasswordOTP from "../pages/Auth/ForgotPasswordOTP";
import StudentList from "../pages/Students/StudentList";
import Media from "../pages/Media/Media";
import StudentDetails from "../pages/Students/StudentDetails";
import CourseList from "../pages/Course/CourseList";
import CreateCourse from "../pages/Course/CreateCourse";
import CourseCategories from "../pages/Course/Categories";
import AddCategory from "../pages/Course/AddCategory";
import Blogs from "../pages/Blogs/Blogs";
import CreateBlog from "../pages/Blogs/CreateBlogs";
import CreateCourseStep2 from "../pages/Course/CreateCourseStep2";
import EditCourse from "../pages/Course/EditCourse";
import Documents from "../pages/Documents/Documents";
import CouponList from "../pages/Coupons/CouponList";
import AddCoupon from "../pages/Coupons/AddCoupon";
import EditCoupon from "../pages/Coupons/EditCoupon";
import BlogCategory from "../pages/Blogs/BlogCategory";
import AddBlogCategory from "../pages/Blogs/AddBlogCategory";
import EditBlog from "../pages/Blogs/EditBlog";
import EditBlogCategory from "../pages/Blogs/EditBlogCategory";
import EditCourseCategory from "../pages/Course/EditCourseCategory";
import AllOrders from "../pages/Reports/AllOrders";
import Transactions from "../pages/Reports/Transactions";
import TestimonialsScreen from "../pages/Testimonials/Testimonials";
import AddTestimonial from "../pages/Testimonials/AddTestimonials";
import EditTestimonials from "../pages/Testimonials/EditTestimonials";
import AddCourseContent from "../pages/Course/courseSections/AddCourseContent";
import EditCourseContent from "../pages/Course/courseSections/EditCourseContent";
import CourseReviews from "../pages/CourseReviews/CourseReviews";
import QuizCategoryList from "../pages/Quiz/QuizCategoryList";
import AddQuizCategory from "../pages/Quiz/AddQuizCategory";
// import EditQuizCategory from "../pages/Quiz/EditQuizCategory";
// import QuizList from "../pages/Quiz/QuizList";
// import AddQuiz from "../pages/Quiz/AddQuiz";
// import EditQuiz from "../pages/Quiz/EditQuiz";
// import AddQuestion from "../pages/Quiz/AddQuestion";
// import EditQuestion from "../pages/Quiz/EditQuestion";
import MockCategoryList from "../pages/Mock/MockCategoryList";
import AddMockCategory from "../pages/Mock/AddMockCategory";
import EditMockCategory from "../pages/Mock/EditMockCategory";
import MockList from "../pages/Mock/MockList";
// import AddMock from "../pages/Mock/AddMock";
import EditMock from "../pages/Mock/EditMock";
import AddMockQuestion from "../pages/Mock/AddMockQuestion";
import EditMockQuestion from "../pages/Mock/EditMockQuestion";
import AddStudent from "../pages/Students/AddStudent";
import ChangePassword from "../pages/Profile/ChangePassword";
import EditProfile from "../pages/Profile/EditProfile";
// import CreateQuiz from "../pages/Quiz/CreateQuiz";
import CreateMock from "../pages/Mock/CreateMock";

// Frontend
import FrontHomePage from "../pages/Frontend/pages/HomePage";
import FrontBannerSlider from "../pages/Frontend/pages/banner/BannerSlider";
import AddBanner from "../pages/Frontend/pages/banner/AddBanner";
import EditBanner from "../pages/Frontend/pages/banner/EditBanner";
import General from "../pages/Frontend/pages/General";
import AboutUs from "../pages/Frontend/pages/Aboutus";
import TermsAndConditions from "../pages/Frontend/pages/TermsAndConditions";
import ContactPage from "../pages/Frontend/pages/ContactPage";
import Contact from "../pages/Frontend/pages/Contacts";
import Announcements from "../pages/Announcemnets/Announcements";
import AddAnnouncement from "../pages/Announcemnets/AddAnnouncement";
import EditAnnouncement from "../pages/Announcemnets/EditAnnouncement";
import Partners from "../pages/Frontend/pages/Partners";
import AddPartner from "../pages/Frontend/pages/AddPartner";
import Toppers from "../pages/Frontend/Toppers/Toppers";
import AddTopper from "../pages/Frontend/Toppers/AddTopper";
import EditTopper from "../pages/Frontend/Toppers/EditTopper";
// Metadata
import MetaData from "../pages/Frontend/pages/metadata/index";
import CreateMetaData from "../pages/Frontend/pages/metadata/Create";
import EditMetaData from "../pages/Frontend/pages/metadata/Edit";

// topper category
import TopperCategory from "../pages/Frontend/Toppers/TopperCategory";
import AddTopperCategory from "../pages/Frontend/Toppers/AddTopperCategory";
import EditTopperCategory from "../pages/Frontend/Toppers/EditTopperCategory";

// admin settings
import Settings from "../pages/Settings/Settings";

// Bundles
import BundleList from "../pages/Bundle/BundleList";
import AddBundle from "../pages/Bundle/AddBundle";
import EditBundle from "../pages/Bundle/EditBundle";

// Emails
import Emails from "../pages/Email/Emails";
import EmailDetails from "../pages/Email/EmailDetails";
import CreateEmail from "../pages/Email/CreateEmail";

// Roles
import Roles from "../pages/Roles/index";
import VideoList from "../pages/Media/VideoList";

// New Quiz

import QuizNew from "../pages/QuizNew/index";
import CreateNewQuiz from "../pages/QuizNew/Create";
import EditNewQuiz from "../pages/QuizNew/Edit";
import CreateQuestion from "../pages/QuizNew/AddQuestion";
import EditQuestionNew from "../pages/QuizNew/EditQuestion";
import QuizCategories from "../pages/QuizNew/Categories";
import CreateQuizCategory from "../pages/QuizNew/CreateCategory";
import EditQuizCategory from "../pages/QuizNew/EditCategory";

// Articles
import Articles from "../pages/Frontend/pages/Articles";

// Results
import ResultCategory from "../pages/Results/ResultCategories";
import CreateCategory from "../pages/Results/CreateCategory";
import EditCategory from "../pages/Results/EditCategory";
import Results from "../pages/Results/index";
import CreateResult from "../pages/Results/Create";
import EditResult from "../pages/Results/Edit";

import Discussion from "../pages/Discussion/index";

import Popup from "../pages/Popup/index";
import Predictor from "../pages/Predictor/index";

// Payment
import PaymentGateway from "../pages/PaymentGateway";
import BillPage from "../pages/Bill/index";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // Students
  { path: "/students", exact: true, component: StudentList },
  { path: "/students/add", exact: true, component: AddStudent },
  { path: "/student/:id", exact: true, component: StudentDetails },

  // Roles
  { path: "/roles", exact: true, component: Roles },
  { path: "/predictors", exact: true, component: Predictor },

  // Media
  { path: "/media", component: Media },
  { path: "/video-list", component: VideoList },

  // documents
  { path: "/documents", component: Documents },

  // Courses
  { path: "/categories", exact: true, component: CourseCategories },
  { path: "/categories/edit/:id", exact: true, component: EditCourseCategory },
  { path: "/categories/create", component: AddCategory },
  { path: "/courses", exact: true, component: CourseList },
  { path: "/courses/create/:id", component: CreateCourse },
  { path: "/courses/edit/:id", component: EditCourse },
  {
    path: "/courses/create-step2/:id",
    component: CreateCourseStep2,
  },
  {
    path: "/courses/add-course-content/:id",

    component: AddCourseContent,
  },
  {
    path: "/courses/edit-course-content/:id/:section",
    component: EditCourseContent,
  },

  // Course Review
  { path: "/courseReviews", component: CourseReviews },

  // Testimonials
  { path: "/testimonials", exact: true, component: TestimonialsScreen },
  { path: "/testimonial/add", component: AddTestimonial },
  { path: "/testimonials/edit/:id", component: EditTestimonials },

  // Blogs
  { path: "/blogs", exact: true, component: Blogs },
  { path: "/blogs/create", component: CreateBlog },
  { path: "/blogs/blogcategory", component: BlogCategory },
  { path: "/blogs/edit/:id", component: EditBlog },
  { path: "/blogcategory/add", component: AddBlogCategory },
  { path: "/blogcategory/edit/:id", component: EditBlogCategory },

  // Discussion
  { path: "/discussion", exact: true, component: Discussion },

  // Quiz
  // { path: "/quiz", exact: true, component: QuizList },
  // { path: "/quiz/create/:id", component: CreateQuiz },
  // { path: "/quiz/edit/:id", component: EditQuiz },
  // { path: "/quizcategories", exact: true, component: QuizCategoryList },
  // { path: "/quizcategories/create", component: AddQuizCategory },
  // { path: "/quizcategories/edit/:id", component: EditQuizCategory },
  // { path: "/quiz/add-question/:id", component: AddQuestion },
  // { path: "/quiz/edit-question/:id/:quizId", component: EditQuestion },
  // Quiz
  { path: "/quiz", exact: true, component: QuizNew },
  { path: "/quiz/categories", exact: true, component: QuizCategories },
  {
    path: "/quiz/categories/create",
    exact: true,
    component: CreateQuizCategory,
  },
  {
    path: "/quiz/categories/edit/:id",
    exact: true,
    component: EditQuizCategory,
  },
  { path: "/quiz/create", component: CreateNewQuiz },
  { path: "/quiz/edit/:id", component: EditNewQuiz },
  { path: "/quiz/add-question/:id", component: CreateQuestion },
  { path: "/quiz/edit-question/:id/:quizId", component: EditQuestionNew },

  // Mocks
  { path: "/mock", exact: true, component: MockList },
  { path: "/mock/create/:id", component: CreateMock },
  { path: "/mock/edit/:id", component: EditMock },
  { path: "/mockcategories", exact: true, component: MockCategoryList },
  { path: "/mockcategories/create", component: AddMockCategory },
  { path: "/mockcategories/edit/:id", component: EditMockCategory },
  { path: "/mock/add-question/:id", component: AddMockQuestion },
  { path: "/mock/edit-question/:id/:quizId", component: EditMockQuestion },
  // PaymentGateway
  { path: "/payment", exact: true, component: PaymentGateway },

  // Coupons
  { path: "/coupons", exact: true, component: CouponList },
  { path: "/coupons/add", component: AddCoupon },
  { path: "/coupons/edit/:id", component: EditCoupon },

  // Announcements
  { path: "/announcements", exact: true, component: Announcements },
  { path: "/announcements/add", component: AddAnnouncement },
  { path: "/announcements/edit/:id", component: EditAnnouncement },

  // Reports
  { path: "/reports/all-orders", exact: true, component: AllOrders },
  { path: "/reports/transactions", exact: true, component: Transactions },

  // Bundles
  { path: "/bundles", exact: true, component: BundleList },
  { path: "/bundles/add", component: AddBundle },
  { path: "/bundles/edit/:id", component: EditBundle },

  // Front-end website
  { path: "/frontend/toppers", exact: true, component: Toppers },
  { path: "/frontend/articles", exact: true, component: Articles },
  { path: "/frontend/topperscategory", exact: true, component: TopperCategory },
  {
    path: "/frontend/topperscategory/add",
    exact: true,
    component: AddTopperCategory,
  },
  {
    path: "/frontend/topperscategory/edit/:id",
    exact: true,
    component: EditTopperCategory,
  },
  {
    path: "/frontend/topperscategory/edit/:id",
    exact: true,
    component: EditTopperCategory,
  },
  { path: "/frontend/toppers/add", component: AddTopper },
  { path: "/frontend/toppers/edit/:id", component: EditTopper },
  { path: "/frontend/HomePage", component: FrontHomePage },
  { path: "/frontend/partners", component: Partners },
  { path: "/frontend/partners/add", component: AddPartner },
  { path: "/frontend/banners", component: FrontBannerSlider },
  { path: "/frontend/banners/add", component: AddBanner },
  { path: "/frontend/banners/edit/:id", component: EditBanner },
  { path: "/frontend/general", component: General },
  { path: "/frontend/aboutus", component: AboutUs },
  { path: "/frontend/terms-privacy-policy", component: TermsAndConditions },
  { path: "/frontend/contact-us", component: ContactPage },
  { path: "/frontend/contacts", component: Contact },
  // metadata
  { path: "/frontend/seo/metadata", component: MetaData },
  { path: "/frontend/seo/metadata/create", component: CreateMetaData },
  { path: "/frontend/seo/metadata/edit/:id", component: EditMetaData },

  // Settings
  { path: "/admin/settings", exact: true, component: Settings },
  { path: "/admin/change-password", exact: true, component: ChangePassword },
  { path: "/admin/edit-profile", exact: true, component: EditProfile },
  { path: "/admin/bill", exact: true, component: BillPage },

  // Popup
  { path: "/popup", exact: true, component: Popup },

  // Emails
  { path: "/email", exact: true, component: Emails },
  { path: "/email-create", exact: true, component: CreateEmail },
  { path: "/email/:id", component: EmailDetails },

  // Results
  { path: "/results", exact: true, component: Results },
  { path: "/results/create", exact: true, component: CreateResult },
  { path: "/results/edit/:id", exact: true, component: EditResult },
  { path: "/results/categories", exact: true, component: ResultCategory },
  {
    path: "/results/categories/create",
    exact: true,
    component: CreateCategory,
  },
  {
    path: "/results/categories/edit/:id",
    exact: true,
    component: EditCategory,
  },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: Dashboard },
];

const publicRoutes = [
  // { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/forgot-password-otp/:email", component: ForgetPasswordOtp },
  { path: "/", exact: true, component: Login },
];

export { authProtectedRoutes, publicRoutes };
