import React, { useEffect, useState } from "react";
import MainContainer from "../../common/MainContainer";
import { Link } from "react-router-dom";
import { Table } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import moment from "moment";
const Emails = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);

  const userToken = props.user.userToken;
  const basurl = process.env.REACT_APP_URL;

  const getEmailList = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/emails/get-all-emails`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data.reverse());
        }
        // console.log("email list", result);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getEmailList();
  }, []);

  // Columns
  const columns = [
    {
      title: "Subject",
      render: (value, item, index) => {
        return (
          <Link to={`/email/${item._id}`} className="title">
            {item.subject}
          </Link>
        );
      },
    },
    {
      title: "Scheduled",
      render: (value, item, index) => {
        return (
          <div>
            {item.isScheduled ? (
              <span className="date">
                Scheduled at{" "}
                {moment(item.scheduledTime).format("MMMM Do YYYY, h:mm A")}
              </span>
            ) : (
              "No"
            )}
          </div>
        );
      },
    },
    {
      title: "Email Body",
      render: (value, item, index) => {
        const totalEmails = item.emails.split(",");
        return (
          <div>
            <div className="col-mail col-mail-2">
              <span className="badge-success badge mr-2">
                ({totalEmails.length}){" "}
                {totalEmails.length > 1 ? "Students" : "Student"}
              </span>{" "}
              <span className="teaser">
                {item.message.length > 40 ? (
                  <span>
                    {item.message.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 40) +
                      "..."}
                  </span>
                ) : (
                  <span>{item.message.replace(/<\/?[^>]+(>|$)/g, "")}</span>
                )}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Date",
      render: (value, item, index) => {
        return (
          <div className="date">{moment(item.createdAt).format("MMMM D")}</div>
        );
      },
    },
  ];

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  return (
    <MainContainer>
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <h4 className="mt-2" style={{ marginRight: 30 }}>
              Emails
            </h4>
            <Link className="btn btn-primary" to="/email-create">
              Compose Email
            </Link>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        {isLoading && <Loader />}

        <div className="card-body">
          {/* <ul className="message-list mb-0">
            {list.length > 0 && (
              <>
                {list.map((item, index) => {
                  const totalEmails = item.emails.split(",");

                  return (
                    <li key={index}>
                      <div className="col-mail col-mail-1">
                        <Link to={`/email/${item._id}`} className="title">
                          {props.user.user.name}
                        </Link>
                      </div>
                      <div className="col-mail col-mail-2">
                        <Link to={`/email/${item._id}`} className="subject">
                          <span className="badge-success badge mr-2">
                            ({totalEmails.length}) Students
                          </span>{" "}
                          <span className="teaser">{item.subject}</span>
                        </Link>
                        <div className="date">
                          {moment(item.createdAt).format("MMMM D")}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </>
            )}
          </ul> */}
          <Table
            columns={columns}
            dataSource={list}
            bordered={false}
            loading={isLoading}
          />
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(Emails);
