import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import Swal from "sweetalert2";
import { DatePicker, Table } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ExportCSV } from "../../components/ExportCSV";
import { userLogout } from "../../redux/actions/UserActions";

const Testimonials = (props) => {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";
  const [list, setList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState(true);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;

  // get testimonials
  const getTestimonials = () => {
    axios
      .get(`${basurl}/testimonials/`)
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data);
          setFilterList(result.data);
          const newArr = result.data.map((item, index) => ({
            "S. No": index + 1,
            Name: item.title,
            Message: item.content,
            Institute: item.institute,
          }));
          setExportData(newArr);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    getTestimonials();
  }, []);

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);

  //handleDelete
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${basurl}/testimonials/delete/${id}`, {
            headers: {
              "auth-token": userToken,
            },
          })
          .then((res) => {
            const dl = res.data;
            if (dl.success) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              getTestimonials();
            }
          })
          .catch((err) => {
            if (err.response.data === "Invalid token") {
              props.logoutUser();
              localStorage.removeItem("lmsUserInfo");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Your token has been expired!, Please login again",
                showConfirmButton: false,
                timer: 1500,
              });
              props.history.push("/");
            }
          });
      }
    });
  };

  // Columns
  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (value, item, index) => <img src={item.image} width="100px" />,
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (value, item, index) => <span>{item.title}</span>,
    },
    {
      title: "Institute",
      dataIndex: "institute",
      key: "institute",
      sorter: (a, b) => a.institute.localeCompare(b.institute),
      render: (value, item, index) => <span>{item.institute}</span>,
    },
    {
      title: "Message",
      dataIndex: "content",
      key: "content",
      render: (value, item, index) => (
        <div
          dangerouslySetInnerHTML={{
            __html:
              item.content.length > 150
                ? item.content.substring(0, 150) + "..."
                : item.content,
          }}
        ></div>
      ),
    },
    {
      title: "Action",
      render: (value, item, index) => (
        <div style={{ display: "flex" }}>
          <Link
            to={`/testimonials/edit/${item._id}`}
            className="btn  btn-primary"
            style={{ minWidth: 100 }}
          >
            <i className="ri-pencil-line"></i>
          </Link>
          <button
            className="btn  btn-danger"
            style={{ marginLeft: "10px", minWidth: 100 }}
            onClick={() => handleDelete(item._id)}
          >
            <i className="ri-delete-bin-line"></i>
          </button>
        </div>
      ),
    },
  ];

  // for search filter
  const filteredData = list.filter((val) => {
    if (searchInput === "") {
      return val;
    } else if (
      val.title.toLowerCase().includes(searchInput.toLowerCase()) ||
      val.content.toLowerCase().includes(searchInput.toLowerCase()) ||
      val.institute.toLowerCase().includes(searchInput.toLowerCase())
    ) {
      return val;
    }
  });
  // on change filter
  function onChange(pagination, filters, sorter, extra) {
    // console.log("params", sorter);
    setPagination({
      pageSize: pagination.pageSize,
    });
  }

  console.log(pagination);
  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-sm-6">
              <h4 className={"header-title"}>All Testimonials</h4>
            </div>
            <div className="col-sm-6 text-right">
              <Link to={"/testimonial/add"} className="btn btn-primary">
                Add Testimonial
              </Link>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className={"filters mb-2"}>
            <div className={"row"}>
              <div className={"col-lg-4"}>
                <div className={"search_filter"}>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder={"Search by name, message, institute..."}
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  {searchInput !== "" && (
                    <span
                      className={"clear_search_text"}
                      onClick={() => setSearchInput("")}
                    >
                      <i className={"la la-times"}></i>
                    </span>
                  )}
                </div>
              </div>
              <div className={"col-lg-4"}></div>
              <div className={"col-lg-4"}>
                <div className={"row"}>
                  <div className={"col-sm-6"}>
                    <select
                      className={"form-control"}
                      onChange={(e) =>
                        setPagination({
                          pageSize: e.target.value,
                        })
                      }
                    >
                      <option value={10}>10 Rows</option>
                      <option value={20}>20 Rows</option>
                      <option value={50}>50 Rows</option>
                      <option value={100}>100 Rows</option>
                    </select>
                  </div>
                  <div className={"col-sm-6"}>
                    <ExportCSV csvData={exportData} fileName={"Testimonials"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={filteredData}
            bordered={true}
            rowKey={(item) => item._id}
            loading={isLoading}
            pagination={pagination}
            onChange={onChange}
          />
        </div>
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);
