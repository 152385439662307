import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { userLoggedIn } from "../../store/actions/UserActions";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import logodark from "../../assets/images/logo-dark.png";

const ForgetPasswordOtp = (props) => {
  let { email } = useParams();
  const [otp, setOtp] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const basurl = process.env.REACT_APP_URL;
  let history = useHistory();
  const handleSubmitVerify = () => {
    if (otp === "" || otp === null) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please enter OTP",
        showConfirmButton: false,
        timer: 2500,
      });
    } else {
      setIsLoading(true);
      axios
        .post(`${basurl}/users/verify-otp`, {
          email: email,
          otp: otp,
        })
        .then((res) => {
          const result = res.data;
          if (result.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result.message,
              showConfirmButton: false,
              timer: 2500,
            });
            setIsVerified(true);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: result.message,
              showConfirmButton: true,
              // timer: 1500,
            });
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          // handle error
          setIsLoading(false);
          console.log("error", error);
        });
    }
  };

  const handleNewPassword = (data) => {
    console.log("lorem", data);
    axios
      .post(`${basurl}/users/update-password`, {
        email: email,
        otp: otp,
        password: data.newPassword,
      })
      .then((res) => {
        const newPwd = res.data;
        if (newPwd.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: newPwd.message,
            showConfirmButton: false,
            timer: 2500,
          });
          history.push("/");
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: newPwd.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      });
  };

  // handleResendEmail
  const handleResendEmail = () => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_URL}/users/forgot-password-admin`, {
        email: email,
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result.message,
            showConfirmButton: false,
            timer: 2500,
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
        setIsLoading(false);
        // console.log("Login result >> ", result);
      })
      .catch(function (error) {
        setIsLoading(false);
        // handle error
        console.log("error", error);
      });
  };

  // console.log("vikas",pwdRef.current["password"])
  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <Row className="no-gutters">
          <Col lg={5}>
            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div className="w-100">
                <Row
                  className="justify-content-center"
                  style={{ marginTop: -10 }}
                >
                  <Col lg={9}>
                    <div>
                      <div className="text-left">
                        <div>
                          <Link to="/" className="logo">
                            <img src={logodark} height="50" alt="logo" />
                          </Link>
                        </div>

                        <h4 className="login_title mt-4 text-left">
                          {isVerified
                            ? "Reset your password"
                            : "Forgot Password?"}
                        </h4>
                        <div className="mt-4">
                          <strong>
                            {isVerified ? (
                              "Enter the new password for your MyPrepZone admin account."
                            ) : (
                              <span>
                                We’ve gone ahead and sent an email to{" "}
                                <a href={`mailto:${email}`}>{email}</a>. Please
                                check your inbox and follow instructions to
                                reset your passsword.
                              </span>
                            )}
                          </strong>
                        </div>
                      </div>
                      <div className="mt-5">
                        {isVerified ? (
                          <form
                            className="form-horizontal"
                            onSubmit={handleSubmit(handleNewPassword)}
                          >
                            <FormGroup className="mb-4">
                              <Label htmlFor="username">
                                Enter New Password
                              </Label>
                              <input
                                type="password"
                                className="form-control"
                                id="user-password"
                                placeholder=""
                                name="newPassword"
                                style={{
                                  borderColor: errors.newPassword ? "red" : "",
                                }}
                                ref={register({
                                  required: "You must specify a password",
                                  minLength: {
                                    value: 8,
                                    message:
                                      "Password must have at least 8 characters",
                                  },
                                  pattern: {
                                    value:
                                      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}/,
                                    message:
                                      "Please use atleast 1 special character, and 1 capital letter in the password.",
                                  },
                                })}
                              />
                              {errors.newPassword && (
                                <div className="text-danger mb-2" role="alert">
                                  {errors.newPassword.message}
                                </div>
                              )}
                            </FormGroup>

                            {/* <FormGroup className="mb-4">
                              <Label htmlFor="username">
                                Enter confirm Password
                              </Label>
                              <input
                                type="password"
                                className="form-control"
                                id="user-password"
                                placeholder=""
                                name="confirmPassword"
                                style={{
                                  borderColor: errors.confirmPassword
                                    ? "red"
                                    : "",
                                }}
                                ref={register({
                                  required: "You must specify a password",
                                  minLength: {
                                    value: 8,
                                    message:
                                      "Password must have at least 8 characters",
                                  },
                                  pattern: {
                                    value:
                                      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}/,
                                    message:
                                      "Please use atleast 1 special character, and 1 capital letter in the password.",
                                  },
                                })}
                              />
                              {errors.confirmPassword && (
                                <div className="text-danger mb-2" role="alert">
                                  {errors.newPassword.message}
                                </div>
                              )}
                            </FormGroup> */}

                            <div className="mt-4 text-center">
                              {isLoading ? (
                                <Button
                                  color="primary"
                                  block
                                  className="w-md waves-effect waves-light"
                                  type="button"
                                >
                                  Loading...
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  block
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Save new password
                                </Button>
                              )}
                            </div>
                          </form>
                        ) : (
                          <div className="form-horizontal">
                            <div className="form-group opt_input">
                              <OtpInput
                                value={otp}
                                onChange={(otp) => setOtp(otp)}
                                numInputs={6}
                                separator={""}
                              />
                            </div>

                            <div className="mt-4 text-center">
                              {isLoading ? (
                                <Button
                                  color="primary"
                                  block
                                  className="w-md waves-effect waves-light"
                                  type="button"
                                >
                                  Loading...
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  block
                                  className="w-md waves-effect waves-light"
                                  type="button"
                                  onClick={() => handleSubmitVerify()}
                                >
                                  Verify OTP
                                </Button>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="mt-4 text-left">
                          {!isVerified && (
                            <span>
                              Didn’t receive an email?{" "}
                              <Link onClick={handleResendEmail}>
                                <strong>Send again</strong>
                              </Link>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={7}>
            <div className="authentication-bg">
              <div className="bg-overlay"></div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggedinUser: (values) => {
      dispatch(userLoggedIn(values));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordOtp);
