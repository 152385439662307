import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import axios from "axios";
import { connect } from "react-redux";
import { Tabs } from "antd";
import BasicInfo from "./components/BasicInfo";
import CoursesList from "./components/CoursesList";
import Loader from "../../common/Loader";
import DemoImg from "../../assets/user.png";
import { userLogout } from "../../redux/actions/UserActions";
import Swal from "sweetalert2";

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const StudentDetails = (props) => {
  const { id } = props.match.params;
  const [details, setDetails] = useState(null);
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;
  console.log("available props>> ", courses);
  // get student details
  const getStudentDetail = () => {
    setIsLoading(true);
    axios
      .get(`${basurl}/users/user/${id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          // console.log("student details>>", res);
          setDetails(result.data);
          setCourses(result.courses.length > 0 ? result.courses : []);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error>>", err);
        setIsLoading(false);
        // props.logoutUser();
        // localStorage.removeItem("lmsUserInfo");
        // Swal.fire({
        //   position: "center",
        //   icon: "success",
        //   title: "Your token has been expired!, Please login again",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        // props.history.push("/");
      });
  };

  useEffect(() => {
    getStudentDetail();
  }, [id]);

  useEffect(() => {
    if (props.billStatus == "over") {
      props.history.push("/admin/bill");
    }
  }, [props.billStatus]);
  return (
    <MainContainer>
      <div className="card">
        {isLoading && <Loader />}
        <div className="card-header">
          <h3 className="card-header-title">Student Details</h3>
        </div>
        {details !== null && (
          <div className="card-body">
            <div className="student_detailsPage">
              <div className="student_image_wrap">
                <div className="img_wrapper">
                  <img
                    src={
                      details.profile_picture
                        ? details.profile_picture
                        : DemoImg
                    }
                    alt="user image"
                  />
                </div>
              </div>
              <div className="student_details_body">
                <Tabs defaultActiveKey="1" onChange={callback}>
                  <TabPane tab="Info" key="1">
                    {details !== null && <BasicInfo data={details} />}
                  </TabPane>
                  <TabPane tab="Courses" key="2">
                    {details !== null && (
                      <CoursesList
                        data={courses}
                        refetchList={() => getStudentDetail()}
                        userId={id}
                      />
                    )}
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        )}
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentDetails);
