import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {} from "../../store/actions";

//Simple bar
import SimpleBar from "simplebar-react";

import SidebarContent from "./SidebarContent";

//Import logo Images
import logosmdark from "../../assets/images/logo-sm-dark.png";
import logodark from "../../assets/images/logo-dark.png";
import logosmlight from "../../assets/images/logo-sm-light.png";
import logolight from "../../assets/images/logo-light.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div data-simplebar className="h-100 ppii">
            <div className="navbar-brand-box">
              <Link to="#" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logosmdark} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logodark} alt="" height="50" />
                </span>
              </Link>

              <Link to="#" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logosmlight} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logolight} alt="" height="50" />
                </span>
              </Link>
            </div>
            {this.props.type !== "condensed" ? (
              <SimpleBar style={{ maxHeight: "100%" }}>
                <SidebarContent />
              </SimpleBar>
            ) : (
              <SidebarContent />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));
