import MainContainer from "../../../common/MainContainer";
import React, { useState, useEffect } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import axios from "axios";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { userLogout } from "../../../redux/actions/UserActions";

const FrontHomePage = (props) => {
  let history = useHistory();
  const [details, setDetails] = useState(null);
  const [tyTitle, setTyTitle] = useState("");
  const [tyStrings, setTyStrings] = useState([]);
  const [subheading, setSubheading] = useState("");
  const [video, setVideo] = useState("");
  const basurl = process.env.REACT_APP_URL;
  const userToken = props.user.userToken;

  //get details
  const getDetails = () => {
    axios.get(`${basurl}/frontend/homePage`).then((res) => {
      const result = res.data;
      if (result.success) {
        if (result.data.length > 0) {
          setDetails(result.data[0]);
          setTyTitle(result.data[0].typingHeading);
          setTyStrings(result.data[0].strings);
          setSubheading(result.data[0].subheading);
          setVideo(result.data[0].introVideo);
        }
      } else {
        setDetails(null);
      }
    });
  };

  const handleSubmit = () => {
    axios
      .post(
        `${basurl}/frontend/homePage/home-create`,
        {
          heading: tyTitle,
          strings: tyStrings,
          subheading: subheading,
          introVideo: video,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Home Page info saved successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          getDetails();
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  // handle update

  const handleUpdate = () => {
    axios
      .put(
        `${basurl}/frontend/homePage/update/${details._id}`,
        {
          heading: tyTitle,
          strings: tyStrings,
          subheading: subheading,
          introVideo: video,
        },
        {
          headers: {
            "auth-token": userToken,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Home Page info saved successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          getDetails();
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
      });
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <h4 className={"header-title"}>Front Website Home Page</h4>
        </div>
        <div className="card-body">
          <h4 className={"section_title"}>Typing Section</h4>
          <div className="form-group">
            <label>Typing section heading</label>
            <input
              type="text"
              placeholder={"Heading"}
              className="form-control"
              value={tyTitle}
              name={"tyTitle"}
              onChange={(e) => setTyTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Typing section sub-heading</label>
            <input
              type="text"
              placeholder={"subheading"}
              className="form-control"
              value={subheading}
              onChange={(e) => setSubheading(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Home Page Video</label>
            <input
              type="text"
              placeholder={"enter video youtube url"}
              className="form-control"
              value={video}
              onChange={(e) => setVideo(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>
              Auto Typing lines (for new line add please press "Enter")
            </label>
            <TagsInput
              value={tyStrings}
              onChange={(string) => setTyStrings(string)}
              inputProps={{ placeholder: "Add auto-typing lines" }}
            />
          </div>

          <div className={"d-flex"}>
            <button
              type="button"
              className="btn btn-success"
              onClick={!details ? handleSubmit : handleUpdate}
              style={{ marginRight: 15 }}
            >
              Save changes
            </button>
            <button
              type={"button"}
              className="btn btn-primary"
              onClick={() => history.goBack()}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FrontHomePage);
