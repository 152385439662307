import React, { useState, useEffect } from "react";
import MainContainer from "../../common/MainContainer";
import { useForm } from "react-hook-form";
import { DatePicker, Space } from "antd";
import axios from "axios";
import Switch from "react-switch";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import { userLogout } from "../../redux/actions/UserActions";

const EditAnnouncement = (props) => {
  const { id } = props.match.params;
  const { register, handleSubmit, errors, setValue } = useForm();
  const [expiryDate, setExpiryDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const basurl = process.env.REACT_APP_URL;

  // onSubmit
  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.set("title", data.title);
    formData.set("expiryDate", expiryDate);
    formData.append("image", data.image[0]);
    formData.append("description", data.description);
    axios({
      method: "post",
      url: `${basurl}/announcements/edit/${id}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "auth-token": props.user.userToken,
      },
    })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your announcement added successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
          props.history.push("/announcements");
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: err.Message,
          showConfirmButton: false,
          timer: 1500,
        });
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        setIsLoading(false);
      });
  };
  const onChange = (date, dateString) => {
    // console.log(date, dateString);
    setExpiryDate(date);
  };

  // get announcemnt details
  const getAnnouncementDetails = () => {
    axios
      .get(`${basurl}/announcements/view/${id}`, {
        headers: {
          "auth-token": props.user.userToken,
        },
      })
      .then((res) => {
        const result = res.data;
        console.log(result);
        if (result.success) {
          setExpiryDate(moment(result.data.expiryDate).toDate());
          setValue("title", result.data.title);
          setValue("description", result.data.description);
          setValue("image", result.data.image);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data === "Invalid token") {
          props.logoutUser();
          localStorage.removeItem("lmsUserInfo");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/");
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAnnouncementDetails();
  }, [id]);
  console.log("sdadassads", expiryDate);
  return (
    <MainContainer>
      <div className="card">
        <div className="card-header">
          <h4 className={"header-title"}>Edit Announcement</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Title"
                ref={register({ required: true })}
                name={"title"}
              />
              {errors.title && (
                <span className="isError">Title is required</span>
              )}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Image</label>
                  <input
                    type="file"
                    className="form-control"
                    placeholder="Enter Title"
                    ref={register({ required: true })}
                    name={"image"}
                    accept="image/png, image/jpeg, image/gif"
                  />
                  {errors.image && (
                    <span className="isError">Image is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                {expiryDate && (
                  <div className="form-group">
                    <label>Expiry Date</label>
                    <DatePicker
                      onChange={onChange}
                      defaultValue={moment(expiryDate)}
                      className="form-control"
                      style={{ height: 40, borderRadius: 4 }}
                      format="DD-MM-YYYY"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                className="form-control"
                placeholder="Description"
                ref={register({ required: true })}
                name={"description"}
              />
              {errors.description && (
                <span className="isError">Description is required</span>
              )}
            </div>
            {isLoading ? (
              <button
                type="button"
                className="btn btn-primary"
                style={{ opacity: 0.7 }}
              >
                Loading...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">
                Add Announcement
              </button>
            )}
          </form>
        </div>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(userLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditAnnouncement);
