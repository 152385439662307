import { combineReducers } from "redux";
import userReducer from "./reducer/Userreducer";
import dashboardReducer from "./reducer/Dashboard";
// Front
import Layout from "./layout/reducer";

const rootReducer = combineReducers({
  Layout,
  user: userReducer,
  dashboard: dashboardReducer,
});

export default rootReducer;
